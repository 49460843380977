import { Table } from '@tanstack/react-table';

import { useQueryParamsPagination } from 'shared/ui/table-new';
import { useNewQueryParamsPagination } from 'shared/ui/table-new/hooks/use-new-query-params-pagination';

import { PaginationControls } from '../controls';

type Props = {
  table: Table<any>;
  totalCount: number;
  tableLimit: number;
};

export function ServerPagination({ table, totalCount, tableLimit }: Props) {
  const { page, setPage, setLimit } = useNewQueryParamsPagination({
    limitFromState: tableLimit,
  });

  const prevDisabled = !table.getCanPreviousPage();
  const nextDisabled = !table.getCanNextPage();

  const onPrevClick = () => setPage(page - 1);
  const onNextClick = () => setPage(page + 1);

  const pagesCount = table.getPageCount();

  return (
    <PaginationControls
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      page={page}
      limit={tableLimit}
      setPage={setPage}
      setLimit={setLimit}
      totalCount={totalCount}
      pagesCount={pagesCount}
    />
  );
}
