import {
  Balance,
  BonusProgramAchievement,
} from 'shared/api/services/bonus/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { BonusInfoByCountry } from '../info-by-country';
import { StyledContainer } from './styles';

const getBonusInfoByCountryMapping = (
  balances: Balance[],
  achivements: BonusProgramAchievement[]
) => {
  const res = balances.reduce(
    (acc, el) => {
      const { countryId } = el;

      if (!countryId) {
        return acc;
      }

      acc[countryId] = {
        balance: el,
        achievements: [],
      };

      return acc;
    },
    {} as Record<
      string,
      {
        balance: Balance;
        achievements: BonusProgramAchievement[];
      }
    >
  );

  achivements.forEach((el) => {
    const { bonus_program } = el;

    if (!bonus_program) {
      return;
    }

    const { countryId } = bonus_program;

    res[countryId].achievements.push(el);
  });

  return res;
};

type Props = {
  achivements: BonusProgramAchievement[];
  userId: string;
  countries: GetCountryVm[];
  balances: Balance[];
};

export function BonusesSummaryLayout({
  achivements,
  userId,
  countries,
  balances,
}: Props) {
  if (!balances.length) {
    return <div>Нет данных о балансах юзера</div>;
  }

  const bonusInfoByCountryMapping = getBonusInfoByCountryMapping(
    balances,
    achivements
  );

  const countriesMapping = countries.reduce((acc, el) => {
    acc[el.id] = el;

    return acc;
  }, {} as Record<string, GetCountryVm>);

  const renderBonusInfoByCountry = () => {
    return Object.entries(bonusInfoByCountryMapping).map((entry) => {
      const [countryId, obj] = entry;

      const { achievements, balance } = obj;

      const country = countriesMapping[countryId];

      return (
        <BonusInfoByCountry
          balance={balance}
          achievements={achievements}
          countries={countries}
          userId={userId}
          country={country}
          key={countryId}
        />
      );
    });
  };

  return <StyledContainer>{renderBonusInfoByCountry()}</StyledContainer>;
}
