import { useRef } from 'react';

import { GetAcquiringBillingVm } from 'shared/api/services/analytics/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';
import { BankDto } from 'shared/api/services/payment/rtk/generated';
import { exportInExcel } from 'shared/lib/export-table/export-in-excel';
import { ExportButton } from 'shared/ui';

import { ReportInfo } from 'entities/report/ui/info';

import { Form } from '../form';
import { ReportTable } from '../table';
import { ReportHeader, StyledCard } from './styles';

type Props = {
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
  bankId?: string | undefined;
  mobileAppId?: string | undefined;
  banks: BankDto[];
  countries: GetCountryVm[];
  mobileApps: MobileAppVm[];
  billings: GetAcquiringBillingVm[];
  isLoading: boolean;
  allowFetch: () => void;
};

export function AcquiringReportLayout({
  bankId,
  mobileAppId,
  dateFrom,
  dateTo,
  banks,
  mobileApps,
  isLoading,
  billings,
  allowFetch,
}: Props) {
  const tableRef = useRef(null);

  return (
    <>
      <Form
        banks={banks}
        mobileApps={mobileApps}
        isLoading={isLoading}
        bankId={bankId}
        mobileAppId={mobileAppId}
        dateFrom={dateFrom}
        dateTo={dateTo}
        allowFetch={allowFetch}
      />
      <StyledCard>
        <ReportHeader>
          <ReportInfo
            title="Отчет по эквайрингу"
            dateFromParam={dateFrom}
            dateToParam={dateTo}
          />
          <ExportButton
            onClick={() =>
              exportInExcel({
                tableRef,
                title: 'Отчет по эквайрингу',
              })
            }
            disabled={isLoading}
          />
        </ReportHeader>
        <ReportTable
          banks={banks}
          mobileApps={mobileApps}
          billings={billings}
          isLoading={isLoading}
          tableRef={tableRef}
        />
      </StyledCard>
    </>
  );
}
