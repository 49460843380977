import { PUSH_NOTIFICATION_ROUTES } from 'shared/consts/routes/push-notification';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={PUSH_NOTIFICATION_ROUTES.ADD}
      permissions={[
        {
          controller: 'PushNotificationService',
          right: 'Write',
        },
      ]}
    />
  );
}
