import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  bonusProgramId?: string;
  userId?: string;
};

export function TableContainer({ bonusProgramId, userId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    bonusTransactions,
    bonusTransactionsCount,
    bonusTransactionsCountError,
    bonusTransactionsError,
  } = useQueries({
    bonusProgramId,
    userId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (bonusTransactionsError) {
    return <ErrorMessage text={getServerErrorText(bonusTransactionsError)} />;
  }

  if (bonusTransactionsCountError) {
    return (
      <ErrorMessage text={getServerErrorText(bonusTransactionsCountError)} />
    );
  }
  return (
    <TableInstance
      data={bonusTransactions}
      totalCount={bonusTransactionsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
