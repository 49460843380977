import { SectionMenu } from 'shared/ui/section-menu';
import { CHARTS, PAYMENTS, REPORTS } from 'shared/consts/names';
import { ACQUIRING, REPORT_ROUES } from 'shared/consts/routes/report';
import { useAbility } from 'shared/lib/ability/context';
import { Permission } from 'features/sidebar/types';

const ITEMS: Array<{
  id: string;
  name: string;
  permissions?: Permission[];
}> = [
  { id: ACQUIRING, name: 'Эквайринг' },
  {
    id: PAYMENTS,
    name: 'Выручка',
    permissions: [
      {
        controller: 'all',
        right: 'manage',
      },
    ],
  },
  { id: CHARTS, name: 'Графики' },
  // { id: 'consume', name: 'Потребление' },
  // { id: 'debit', name: 'Задолженность' },
];

export function ReportsMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return (
    <SectionMenu
      loading={false}
      items={allowedItems}
      menuKey={REPORTS}
      absolutePath={REPORT_ROUES.LIST}
      label="Отчеты"
    />
  );
}
