import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { ChargePointOut } from 'shared/api/services/balancing/rtk/generated';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { TableLayout } from './layout';
import { useColumns } from './use-columns';

type Props = {
  data: ChargePointOut[];
  loading: boolean;
  chargePointsMapping: Record<string, GetChargePointVm>;
};

export function TableInstance({ data, loading, chargePointsMapping }: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useColumns({ chargePointsMapping });

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return <TableLayout table={table} loading={loading} />;
}
