import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { InputFilter, SelectFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  FORMAT_TO_SECONDS,
  renderBooleanAsString,
  showTimeString,
} from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { CardDto } from 'shared/api/services/payment/rtk/generated';

import { selectTableColumns, setTableColumns } from '../store/slice';

const columnHelper = createColumnHelper<CardDto>();

const DATA_COLUMNS = [
  columnHelper.accessor('isSelected', {
    id: 'isSelected',
    header: 'Основная',
    cell: (props) => {
      return renderBooleanAsString(props.getValue());
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            {
              label: 'Основная',
              value: 'true',
            },
            { label: 'Не основные', value: 'false' },
          ]}
          paramName="isSelected"
        />
      ),
    },
  }),
  columnHelper.accessor('bankId', {
    id: 'bankId',
    header: 'Банк',
    meta: {
      filterElement: <InputFilter paramName="bankId" />,
    },
  }),
  columnHelper.accessor('expirationString', {
    id: 'expirationString',
    header: 'Дата окончания действия',
    cell: (props) => {
      const value = props.getValue();

      if (!value) {
        return '';
      }

      const year = value.slice(0, 4);
      const month = value.slice(4);

      return `${year}/${month}`;
    },
    meta: {
      filterElement: <InputFilter paramName="expirationString" disabled />,
    },
  }),
  columnHelper.accessor('maskedPan', {
    id: 'maskedPan',
    header: 'Номер карты',
    meta: {
      filterElement: <InputFilter paramName="maskedPan" disabled />,
    },
  }),
  columnHelper.accessor('bindingId', {
    id: 'bindingId',
    header: 'Идентификатор',
    meta: {
      filterElement: <InputFilter paramName="bindingId" />,
    },
  }),
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Дата привязки',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: <InputFilter paramName="createdDate" disabled />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useAppSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
