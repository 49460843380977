import { z } from 'zod';

import {
  useGetPayments,
  useGetPaymentsCount,
} from 'shared/api/services/payment/rtk/enhanced';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getApiPaymentV1PaymentsQueryParams } from 'shared/api/services/payment/orval/zod/schemas';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';

const filteringQueryParams = z.object({
  billingId: z.string().uuid().optional(),
  userId: z.string().uuid().optional(),
  bankId: z.string().optional(),
  bankClientId: z.string().uuid().optional(),
  cardId: z.string().uuid().optional(),
  orderNumber: z.string().optional(),
  bankOrderId: z.string().optional(),
  status: getApiPaymentV1PaymentsQueryParams.shape.Status.optional(),
  errorReason: getApiPaymentV1PaymentsQueryParams.shape.ErrorReason.optional(),
});

const paymentsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const paymentsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  userId?: string;
};

export const useQueries = ({ limit, userId }: Props) => {
  const paymentsParams = useTypedQueryParamsCustom(paymentsQueryParams);
  const paymentsCountParams = useTypedQueryParamsCustom(
    paymentsCountQueryParams
  );

  const paymentsQuery = useGetPayments({
    ...paymentsParams,
    offset: getOffsetQueryParam(paymentsParams.page, limit),
    limit,
    userId,
  });

  const paymentsCountQuery = useGetPaymentsCount({
    ...paymentsCountQueryParams,
    userId,
  });

  const isLoading = paymentsQuery.isLoading || paymentsCountQuery.isLoading;

  const isFetching = paymentsQuery.isFetching || paymentsCountQuery.isFetching;

  return {
    isLoading,
    isFetching,
    payments: paymentsQuery.data?.data ?? [],
    paymentsCount: paymentsCountQuery.data?.data ?? Infinity,
    paymentsError: paymentsQuery.error,
    paymentsCountError: paymentsCountQuery.error,
  };
};
