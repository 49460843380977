import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { CHARTS, PAYMENTS, REPORTS } from 'shared/consts/names';
import { ACQUIRING } from 'shared/consts/routes/report';

import { RequireAuth } from 'entities/authorization';
import { ReportLayout } from 'entities/report';

import { ReportsMenu } from 'features/report/menu';
import { AcquiringReport } from 'features/report/acquiring';
import { PaymentsReport } from 'features/report/payment';
import { PowerbiCharts } from 'features/report/charts';

// import {
//   AcquiringReportPage,
//   // PaymentsReportPage,
//   // DebitReportPage,
//   // ConsumeReportPage,
// } from 'pages/report';

export const routes = (
  <Route
    element={
      <RequireAuth
        permissions={[
          { controller: 'AnalyticsService', right: 'Read' },
          {
            controller: 'PaymentService',
            right: 'Read',
          },
          { controller: 'InformationService', right: 'Read' },
        ]}
      />
    }
  >
    <Route path={REPORTS} element={<SectionLayout menu={<ReportsMenu />} />}>
      <Route element={<ReportLayout />}>
        <Route path={ACQUIRING} element={<AcquiringReport />} />

        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'all', right: 'manage' }]}
            />
          }
        >
          <Route path={PAYMENTS} element={<PaymentsReport />} />
        </Route>
        <Route path={CHARTS} element={<PowerbiCharts />} />
        {/* <Route path={CONSUME} element={<ConsumeReportPage />} />
        
        <Route path={DEBIT} element={<DebitReportPage />} /> */}
      </Route>
    </Route>
  </Route>
);
