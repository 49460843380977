import { enhancedApi as api } from '../generated/user-group-cp-rights';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1UserGroupCpRights: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'UserGroupCpRights', id: arg },
      ];
    },
  },
});

export const {
  useGetApiChargepointV1UserGroupCpRightsQuery: useGetUserGroupCpRights,
  usePostApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsMutation:
    useSetUserGroupCpRights,
} = enhancedApi;
