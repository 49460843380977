import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'shared/redux/types';
import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  FORMAT_TO_SECONDS,
  showTimeString,
  tryParseJSONObject,
} from 'shared/lib';
import {
  EventType,
  GetEventVm,
} from 'shared/api/services/chargepoint/rtk/generated/events';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { COLOR } from 'shared/consts';
import { ConnectorVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { EVENT_NAME, EVENT_TYPE, EVENT_TYPE_SHORT } from 'entities/event';
import { CONNECTOR_STATUS } from 'entities/connector';

import { selectTableColumns, setTableColumns } from '../store/slice';

const columnHelper = createColumnHelper<GetEventVm>();

type Props = {
  connectorIdConnectorMapping: Record<string, ConnectorVm>;
};

export const useColumns = ({ connectorIdConnectorMapping }: Props) => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const DATA_COLUMNS = [
    columnHelper.accessor('createdDate', {
      id: 'createdDate',
      header: 'Время получения',
      size: DATE_TIME_COLUMN_WIDTH,
      minSize: DATE_TIME_COLUMN_WIDTH,
      maxSize: DATE_TIME_COLUMN_WIDTH,
      cell: (props) => {
        const date = props.getValue();

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter
            dateFromName="dateFrom"
            dateToName="dateTo"
          />
        ),
      },
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: 'Тип',
      size: 120,
      minSize: 120,
      maxSize: 120,
      cell: (props) => {
        const val = props.getValue();

        const TEXT_COLOR: Record<EventType, string> = {
          REQUEST: COLOR.red,
          CONFIRMATION: COLOR.green,
          SYSTEM: COLOR.black,
        };

        return (
          <span style={{ color: TEXT_COLOR[val] }}>
            {EVENT_TYPE_SHORT[val]}
          </span>
        );
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              ...Object.entries(EVENT_TYPE).map((entry) => {
                const [value, label] = entry;

                return { value, label };
              }),
            ]}
            paramName="type"
          />
        ),
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Название',
      size: 250,
      minSize: 250,
      maxSize: 250,
      cell: (props) => {
        const val = props.getValue();

        return EVENT_NAME[val];
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              ...Object.entries(EVENT_NAME).map((entry) => {
                const [value, label] = entry;

                return { value, label };
              }),
            ]}
            paramName="name"
          />
        ),
      },
    }),
    columnHelper.accessor('connectorStatus', {
      id: 'connectorStatus',
      header: 'Статус',
      size: 180,
      minSize: 180,
      maxSize: 180,
      cell: (props) => {
        const event = props.row.original;

        const eventType = event.type;
        const eventName = event.name;
        const data = event.data;

        if (eventType === 'REQUEST' && eventName === 'STATUS_NOTIFICATION') {
          if (!event.connectorStatus) {
            return '';
          }

          return CONNECTOR_STATUS[event.connectorStatus];
        }

        if (eventType === 'REQUEST' && eventName === 'STOP_TRANSACTION') {
          const { reason } = event;

          if (!reason) {
            return '';
          }

          return reason;
        }

        if (
          eventType === 'CONFIRMATION' &&
          (eventName === 'REMOTE_START_TRANSACTION' ||
            eventName === 'REMOTE_STOP_TRANSACTION' ||
            eventName === 'RESET' ||
            eventName === 'BOOT_NOTIFICATION' ||
            eventName === 'START_TRANSACTION' ||
            eventName === 'STOP_TRANSACTION' ||
            eventName === 'CHANGE_AVAILABILITY')
        ) {
          if (!data) {
            return '';
          }

          const parsedData = tryParseJSONObject(data);

          if (!parsedData) {
            return '';
          }

          if ('status' in parsedData) {
            return parsedData['status'];
          }

          if (Array.isArray(parsedData)) {
            const [, , obj] = parsedData;

            const { status } = obj || {};

            if (!status) {
              return '';
            }

            return status;
          }
        }
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              ...Object.entries(CONNECTOR_STATUS).map((entry) => {
                const [value, label] = entry;

                return { value, label };
              }),
            ]}
            paramName="connectorStatus"
            disabled
          />
        ),
      },
    }),
    columnHelper.accessor('data', {
      id: 'connectorId',
      header: 'ID коннектора',
      size: 140,
      minSize: 140,
      maxSize: 140,
      cell: (props) => {
        const event = props.row.original;

        const eventType = event.type;
        const eventName = event.name;
        const data = event.data;
        const { connectorId } = event;

        if (
          (eventType === 'REQUEST' && eventName === 'STATUS_NOTIFICATION') ||
          (eventType === 'REQUEST' && eventName === 'METER_VALUES') ||
          (eventType === 'REQUEST' &&
            eventName === 'REMOTE_START_TRANSACTION') ||
          (eventType === 'REQUEST' && eventName === 'START_TRANSACTION') ||
          (eventType === 'REQUEST' && eventName === 'STOP_TRANSACTION')
        ) {
          if (!connectorId) {
            return '';
          }

          const connector = connectorIdConnectorMapping[connectorId];

          if (!connector) {
            return '';
          }

          const { innerConnectorId, type } = connector;

          return `${type} (${innerConnectorId})`;
        }
      },
      meta: {
        filterElement: <InputFilter paramName="connectorId" />,
      },
    }),
    columnHelper.accessor('data', {
      id: 'energy',
      header: 'кВт*ч',
      cell: (props) => {
        const event = props.row.original;

        const eventType = event.type;
        const eventName = event.name;
        const data = event.data;

        if (eventType === 'REQUEST' && eventName === 'STATUS_NOTIFICATION') {
          if (!data) {
            return '';
          }

          const parsedData = tryParseJSONObject(data);

          if (!parsedData) {
            return '';
          }

          const { errorCode } = parsedData || {};

          if (!errorCode) {
            return '';
          }

          return errorCode;
        }

        if (eventType === 'REQUEST' && eventName === 'METER_VALUES') {
          const { countTotalEnergy } = event;

          if (!countTotalEnergy) {
            return '';
          }

          return countTotalEnergy;
        }

        if (eventType === 'REQUEST' && eventName === 'STOP_TRANSACTION') {
          const { meterStop } = event;

          if (!meterStop) {
            return '';
          }

          return meterStop;
        }
      },
      meta: {
        filterElement: <InputFilter paramName="energy" />,
      },
    }),
    columnHelper.accessor('instantPower', {
      id: 'instantPower',
      header: 'Мощность',
      meta: {
        filterElement: <InputFilter paramName="instantPower" />,
      },
    }),
    columnHelper.accessor('instantCurrent', {
      id: 'instantCurrent',
      header: 'Ток',
      meta: {
        filterElement: <InputFilter paramName="instantCurrent" />,
      },
    }),
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
