import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiPaymentV1Banks: (endpoint) => {
      endpoint.providesTags = [{ type: 'Banks', id: 'LIST' }];
    },
    getApiPaymentV1BanksById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Banks', id: arg },
      ];
    },
    postApiPaymentV1Banks: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Banks', id: 'LIST' }];
    },
    patchApiPaymentV1BanksById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Banks', id: 'LIST' },
        {
          type: 'Banks',
          id: arg.id,
        },
      ];
    },
    // Банковские карты
    // getApiPaymentV1Cards: (endpoint) => {
    //   endpoint.providesTags = [{ type: 'BankCards', id: 'LIST' }];
    // },
    // Привязки банковских карт
  },
});

export const {
  useGetApiPaymentV1BanksQuery: useGetBanks,
  useGetApiPaymentV1BanksByIdQuery: useGetBank,
  usePostApiPaymentV1BanksMutation: useAddBank,
  usePatchApiPaymentV1BanksByIdMutation: useUpdateBank,
  // Банковские карты
  useGetApiPaymentV1CardsQuery: useGetBankCards,
  useGetApiPaymentV1CardsCountQuery: useGetBankCardsCount,
  //
  useGetApiPaymentV1PaymentsQuery: useGetPayments,
  useGetApiPaymentV1PaymentsCountQuery: useGetPaymentsCount,
  //
  useGetApiPaymentV1BindingLogsQuery: useGetBindingLogs,
  useGetApiPaymentV1BindingLogsCountQuery: useGetBindingLogsCount,
} = enhancedApi;
