import { enhancedApi as api } from '../generated/countries';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationV1Countries: (endpoint) => {
      endpoint.providesTags = [{ type: 'Countries', id: 'LIST' }];
      endpoint.keepUnusedDataFor = 60 * 60;
    },
    getApiInformationV1CountriesById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Countries', id: arg },
      ];
    },
    postApiInformationV1Countries: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Countries', id: 'LIST' }];
    },
    patchApiInformationV1CountriesById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Countries', id: 'LIST' },
        {
          type: 'Countries',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiInformationV1CountriesQuery: useGetCountries,
  useGetApiInformationV1CountriesByIdQuery: useGetCountry,
  usePostApiInformationV1CountriesMutation: useAddCountry,
  usePatchApiInformationV1CountriesByIdMutation: useUpdateCountry,
} = enhancedApi;
