import {
  Row,
  SortingState,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { BalanceTransactionDto } from 'shared/api/services/billing/rtk/generated';
import {
  ServerTableLayout,
  useQueryParamsPagination,
  useQueryParamsSorting,
} from 'shared/ui/table-new';

import { useColumns } from '../hooks/use-columns';

type Props = {
  data: BalanceTransactionDto[];
  loading: boolean;
  totalCount: number;
  tableLimit: number;
};

export function TableInstance({
  data,
  loading,
  totalCount,
  tableLimit,
}: Props) {
  const columns = useColumns();

  const { page } = useQueryParamsPagination();
  const { orderingField, orderingType } = useQueryParamsSorting();

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: tableLimit,
  };

  const sorting: SortingState = [
    {
      id: orderingField,
      desc: orderingType === 'DESC',
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / tableLimit),
  });

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
      tableLimit={tableLimit}
    />
  );
}
