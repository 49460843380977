import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1UserGroupCpRightsById: build.query<
      GetApiChargepointV1UserGroupCpRightsByIdApiResponse,
      GetApiChargepointV1UserGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserGroupCpRights/${queryArg}`,
      }),
    }),
    patchApiChargepointV1UserGroupCpRightsById: build.mutation<
      PatchApiChargepointV1UserGroupCpRightsByIdApiResponse,
      PatchApiChargepointV1UserGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserGroupCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateUserGroupCpRightRequest,
      }),
    }),
    deleteApiChargepointV1UserGroupCpRightsById: build.mutation<
      DeleteApiChargepointV1UserGroupCpRightsByIdApiResponse,
      DeleteApiChargepointV1UserGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserGroupCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1UserGroupCpRights: build.query<
      GetApiChargepointV1UserGroupCpRightsApiResponse,
      GetApiChargepointV1UserGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserGroupCpRights`,
        params: {
          userName: queryArg,
        },
      }),
    }),
    postApiChargepointV1UserGroupCpRights: build.mutation<
      PostApiChargepointV1UserGroupCpRightsApiResponse,
      PostApiChargepointV1UserGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1UserGroupCpRightsSetUserGroupCpRights: build.mutation<
      PostApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsApiResponse,
      PostApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserGroupCpRights/SetUserGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1UserGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmBaseResponse;
export type GetApiChargepointV1UserGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargepointV1UserGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmBaseResponse;
export type PatchApiChargepointV1UserGroupCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateUserGroupCpRightRequest: UpdateUserGroupCpRightRequest;
};
export type DeleteApiChargepointV1UserGroupCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1UserGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1UserGroupCpRightsApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1UserGroupCpRightsApiArg = string | undefined;
export type PostApiChargepointV1UserGroupCpRightsApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmBaseResponse;
export type PostApiChargepointV1UserGroupCpRightsApiArg =
  /** Параметры */ AddUserGroupCpRightRequest;
export type PostApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsApiArg =
  /** Параметры */ SetUserGroupCpRightsRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetUserGroupCpRightVm = {
  id: string;
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetUserGroupCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserGroupCpRightVm;
};
export type UpdateUserGroupCpRightRequest = {
  userName?: string | null;
  groupId?: string | null;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetUserGroupCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserGroupCpRightVm[] | null;
};
export type AddUserGroupCpRightRequest = {
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type UserGroupCpRightRequest = {
  groupId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetUserGroupCpRightsRequest = {
  userName: string;
  allCpRight?: boolean | null;
  groupCpRights?: UserGroupCpRightRequest[] | null;
};
export const {
  useGetApiChargepointV1UserGroupCpRightsByIdQuery,
  usePatchApiChargepointV1UserGroupCpRightsByIdMutation,
  useDeleteApiChargepointV1UserGroupCpRightsByIdMutation,
  useGetApiChargepointV1UserGroupCpRightsQuery,
  usePostApiChargepointV1UserGroupCpRightsMutation,
  usePostApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsMutation,
} = injectedRtkApi;
