import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1RoleCpRightsById: build.query<
      GetApiChargepointV1RoleCpRightsByIdApiResponse,
      GetApiChargepointV1RoleCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleCpRights/${queryArg}`,
      }),
    }),
    patchApiChargepointV1RoleCpRightsById: build.mutation<
      PatchApiChargepointV1RoleCpRightsByIdApiResponse,
      PatchApiChargepointV1RoleCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateRoleCpRightsRequest,
      }),
    }),
    deleteApiChargepointV1RoleCpRightsById: build.mutation<
      DeleteApiChargepointV1RoleCpRightsByIdApiResponse,
      DeleteApiChargepointV1RoleCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1RoleCpRights: build.query<
      GetApiChargepointV1RoleCpRightsApiResponse,
      GetApiChargepointV1RoleCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleCpRights`,
        params: {
          roleName: queryArg,
        },
      }),
    }),
    postApiChargepointV1RoleCpRights: build.mutation<
      PostApiChargepointV1RoleCpRightsApiResponse,
      PostApiChargepointV1RoleCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1RoleCpRightsSetRoleCpRights: build.mutation<
      PostApiChargepointV1RoleCpRightsSetRoleCpRightsApiResponse,
      PostApiChargepointV1RoleCpRightsSetRoleCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleCpRights/SetRoleCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1RoleCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleCpRightVmBaseResponse;
export type GetApiChargepointV1RoleCpRightsByIdApiArg =
  /** Идентификтаор */ string;
export type PatchApiChargepointV1RoleCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleCpRightVmBaseResponse;
export type PatchApiChargepointV1RoleCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateRoleCpRightsRequest: UpdateRoleCpRightsRequest;
};
export type DeleteApiChargepointV1RoleCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1RoleCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1RoleCpRightsApiResponse =
  /** status 200 Success */ GetRoleCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1RoleCpRightsApiArg =
  /** Идентификтаор пользователя */ string | undefined;
export type PostApiChargepointV1RoleCpRightsApiResponse =
  /** status 200 Success */ GetRoleCpRightVmBaseResponse;
export type PostApiChargepointV1RoleCpRightsApiArg =
  /** Параметры */ AddRoleCpRightsRequest;
export type PostApiChargepointV1RoleCpRightsSetRoleCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1RoleCpRightsSetRoleCpRightsApiArg =
  /** Параметры */ SetRoleCpRightRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetRoleCpRightVm = {
  id: string;
  roleName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetRoleCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleCpRightVm;
};
export type UpdateRoleCpRightsRequest = {
  roleName?: string | null;
  chargePointId?: string | null;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetRoleCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleCpRightVm[] | null;
};
export type AddRoleCpRightsRequest = {
  roleName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type RoleCpRightsRequest = {
  chargePointId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetRoleCpRightRequest = {
  roleName: string;
  allCpRight?: boolean | null;
  roleCpRights?: RoleCpRightsRequest[] | null;
};
export const {
  useGetApiChargepointV1RoleCpRightsByIdQuery,
  usePatchApiChargepointV1RoleCpRightsByIdMutation,
  useDeleteApiChargepointV1RoleCpRightsByIdMutation,
  useGetApiChargepointV1RoleCpRightsQuery,
  usePostApiChargepointV1RoleCpRightsMutation,
  usePostApiChargepointV1RoleCpRightsSetRoleCpRightsMutation,
} = injectedRtkApi;
