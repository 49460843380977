import {
  useGetBalances,
  useGetBonusProgramAchievements,
} from 'shared/api/services/bonus/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';

type Props = {
  userId: string;
};

export const useQueries = ({ userId }: Props) => {
  const achievementsQuery = useGetBonusProgramAchievements(userId);
  const countriesQuery = useGetCountries(undefined);
  const balancesQuery = useGetBalances({ userId });

  const isLoading =
    achievementsQuery.isLoading ||
    countriesQuery.isLoading ||
    balancesQuery.isLoading;

  return {
    isLoading,
    achivements: achievementsQuery.data?.data ?? [],
    countries: countriesQuery.data?.data ?? [],
    balances: balancesQuery.data?.data ?? [],
    achivementsError: achievementsQuery.error,
    countriesError: countriesQuery.error,
    balancesError: balancesQuery.error,
  };
};
