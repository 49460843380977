import { BANK_ROUTES } from 'shared/consts/routes/bank';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={BANK_ROUTES.ADD_BANK}
      permissions={[
        {
          controller: 'PaymentService',
          right: 'Write',
        },
      ]}
    />
  );
}
