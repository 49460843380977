import { z } from 'zod';

import { emptyStringToUndefined, optionalZodDayToISO } from 'shared/lib/schema';
import { getApiAnalyticsReportGetPaymentsReportQueryParams } from 'shared/api/services/analytics/orval/zod/schemas';

const generatedSchema = getApiAnalyticsReportGetPaymentsReportQueryParams;

const FormResultSchema = z.object({
  chargePointsId: z.array(z.string()),
  groupsId: z.array(z.string()),
  companiesId: z.array(z.string()),
  mobileAppsId: z.array(z.string()),
  dateRange: z.tuple([z.string(), z.string()]).nullish(),
  groupBy: generatedSchema.shape.GroupBy.unwrap(),
});

export const FormSchema = z
  .object({
    chargePointsId: z.array(z.string()),
    groupsId: z.array(z.string()),
    companiesId: z.array(z.string()),
    mobileAppsId: z.array(z.string()),
    dateRange: z.tuple([optionalZodDayToISO, optionalZodDayToISO]).nullish(),
    groupBy: z.string().transform(emptyStringToUndefined),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  dateRange: undefined,
  chargePointsId: [],
  companiesId: [],
  groupsId: [],
  mobileAppsId: [],
  groupBy: '',
};

export const queryParamsSchema = z.object({
  chargePointsId: z.string().optional(),
  groupsId: z.string().optional(),
  companiesId: z.string().optional(),
  mobileAppsId: z.string().optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
  groupBy: generatedSchema.shape.GroupBy.optional(),
});
