import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import { getApiChargepointV1SessionsQueryParams } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { POLLING_INTERVAL } from 'shared/consts';
import {
  useGetReserveSessions,
  useGetReserveSessionsCount,
} from 'shared/api/services/reserve/rtk';

const generatedSchema = getApiChargepointV1SessionsQueryParams;

const filteringQueryParams = z.object({
  reserveId: z.string().uuid().optional(),
  beginDateFrom: z.string().datetime().optional(),
  beginDateTo: z.string().datetime().optional(),
  endDateFrom: z.string().datetime().optional(),
  endDateTo: z.string().datetime().optional(),
  lastUpdateFrom: z.string().datetime().optional(),
  lastUpdateTo: z.string().datetime().optional(),
});

const reserveSessionsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const reserveSessionsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  userId?: string;
};

export const useQueries = ({ limit, userId }: Props) => {
  const isWindowFocused = useWindowFocus();

  const reserveSessionsParams = useTypedQueryParamsCustom(
    reserveSessionsQueryParams
  );
  const reserveSessionsCountParams = useTypedQueryParamsCustom(
    reserveSessionsCountQueryParams
  );

  const reserveSessionsQuery = useGetReserveSessions(
    {
      ...reserveSessionsParams,
      offset: getOffsetQueryParam(reserveSessionsParams.page, limit),
      limit,
      userId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const reserveSessionsCountQuery = useGetReserveSessionsCount(
    {
      ...reserveSessionsCountParams,
      userId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const isLoading =
    reserveSessionsQuery.isLoading || reserveSessionsCountQuery.isLoading;
  const isFetching =
    (reserveSessionsQuery.isFetching || reserveSessionsCountQuery.isFetching) &&
    (!reserveSessionsQuery.currentData ||
      !reserveSessionsCountQuery.currentData);

  return {
    isLoading,
    isFetching,
    reserveSessions: reserveSessionsQuery.data?.data ?? [],
    reserveSessionsCount: reserveSessionsCountQuery.data?.data ?? Infinity,
    reserveSessionsError: reserveSessionsQuery.error,
    reserveSessionsCountError: reserveSessionsCountQuery.error,
  };
};
