import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1SessionsById: build.query<
      GetApiChargepointV1SessionsByIdApiResponse,
      GetApiChargepointV1SessionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Sessions/${queryArg}`,
      }),
    }),
    getApiChargepointV1Sessions: build.query<
      GetApiChargepointV1SessionsApiResponse,
      GetApiChargepointV1SessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Sessions`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          CreatedDateFrom: queryArg.createdDateFrom,
          CreatedDateTo: queryArg.createdDateTo,
          ClosedDateFrom: queryArg.closedDateFrom,
          ClosedDateTo: queryArg.closedDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ChargePointGroupId: queryArg.chargePointGroupId,
          CompanyId: queryArg.companyId,
          SearchField: queryArg.searchField,
          SearchText: queryArg.searchText,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiChargepointV1SessionsCount: build.query<
      GetApiChargepointV1SessionsCountApiResponse,
      GetApiChargepointV1SessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Sessions/Count`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          CreatedDateFrom: queryArg.createdDateFrom,
          CreatedDateTo: queryArg.createdDateTo,
          ClosedDateFrom: queryArg.closedDateFrom,
          ClosedDateTo: queryArg.closedDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ChargePointGroupId: queryArg.chargePointGroupId,
          CompanyId: queryArg.companyId,
          SearchField: queryArg.searchField,
          SearchText: queryArg.searchText,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiChargepointV1SessionsByIdGraphMeterValues: build.query<
      GetApiChargepointV1SessionsByIdGraphMeterValuesApiResponse,
      GetApiChargepointV1SessionsByIdGraphMeterValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Sessions/${queryArg.id}/_graphMeterValues`,
        params: {
          DateFrom: queryArg.dateFrom,
        },
      }),
    }),
    postApiChargepointV1SessionsTerminateById: build.mutation<
      PostApiChargepointV1SessionsTerminateByIdApiResponse,
      PostApiChargepointV1SessionsTerminateByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Sessions/Terminate/${queryArg}`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1SessionsByIdApiResponse =
  /** status 200 Success */ GetSessionVmBaseResponse;
export type GetApiChargepointV1SessionsByIdApiArg = /** Идентификатор */ string;
export type GetApiChargepointV1SessionsApiResponse =
  /** status 200 Success */ GetSessionVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1SessionsApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  closedDateFrom?: string;
  closedDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
  chargePointGroupId?: string;
  companyId?: string;
  searchField?: string;
  searchText?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiChargepointV1SessionsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargepointV1SessionsCountApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  closedDateFrom?: string;
  closedDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
  chargePointGroupId?: string;
  companyId?: string;
  searchField?: string;
  searchText?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiChargepointV1SessionsByIdGraphMeterValuesApiResponse =
  /** status 200 Success */ GetGraphMeterVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1SessionsByIdGraphMeterValuesApiArg = {
  id: string;
  /** Временной промежуток ОТ */
  dateFrom?: string;
};
export type PostApiChargepointV1SessionsTerminateByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1SessionsTerminateByIdApiArg =
  /** Идентификатор сессии */ string;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetSessionVmChargePointVm = {
  id: string;
  name: string;
  groupId: string;
  companyId: string;
};
export type ConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type GetSessionVmConnector = {
  id: string;
  liter?: string | null;
  innerConnectorId: number;
  type: ConnectorType;
};
export type SessionStatus =
  | 'NEW'
  | 'PREPARING'
  | 'CHARGING'
  | 'COMPLETED_WITH_SUCCESS'
  | 'COMPLETED_WITH_ERROR';
export type GetSessionVm = {
  id: string;
  chargePoint: GetSessionVmChargePointVm;
  connector: GetSessionVmConnector;
  userId: string;
  status: SessionStatus;
  meterStart?: number | null;
  meterStop?: number | null;
  energy: number;
  instantPower: number;
  instantCurrent: number;
  startChargingPercentage?: number | null;
  chargingPercentage?: number | null;
  maxPower: number;
  maxCurrent: number;
  stopReason?: string | null;
  transactionId: string;
  errorText?: string | null;
  createdDate: string;
  closedDate: string;
  lastUpdate: string;
};
export type GetSessionVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm;
};
export type GetSessionVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetGraphMeterVm = {
  date: string;
  power: number;
  chargingPercentage: number;
  energy: number;
};
export type GetGraphMeterVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGraphMeterVm[] | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  useGetApiChargepointV1SessionsByIdQuery,
  useGetApiChargepointV1SessionsQuery,
  useGetApiChargepointV1SessionsCountQuery,
  useGetApiChargepointV1SessionsByIdGraphMeterValuesQuery,
  usePostApiChargepointV1SessionsTerminateByIdMutation,
} = injectedRtkApi;
