import { Link } from 'react-router-dom';

import {
  GROUPS,
  PAYMENTS,
  RESERVES,
  ROOT,
  SESSIONS,
} from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';

import { AddUserGroupLink } from '../add-user-group-link';
import { AddUserLink } from '../add-user-link';
import { PAYMENT_DEFAULT_SEARCH_PARAMS } from 'entities/payment/consts';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <Link to="">Список клиентов</Link>,
    extraContent: <AddUserLink />,
    permissions: [{ controller: 'InformationService', right: 'Read' }],
  },
  {
    key: GROUPS,
    label: <Link to={GROUPS}>Список групп</Link>,
    extraContent: <AddUserGroupLink />,
  },
  {
    key: SESSIONS,
    label: (
      <Link
        to={{
          pathname: SESSIONS,
          search: SESSIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал сессий
      </Link>
    ),
    permissions: [
      { controller: 'ChargePointServiceChargePoint', right: 'Read' },
    ],
  },
  {
    key: PAYMENTS,
    label: (
      <Link
        to={{
          pathname: PAYMENTS,
          search: PAYMENT_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал оплат
      </Link>
    ),
    permissions: [{ controller: 'all', right: 'manage' }],
  },
  // {
  //   key: RESERVES,
  //   label: <Link to={RESERVES}>Журнал брони</Link>,
  //   disabled: true,
  // },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
