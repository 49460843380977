import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import { getApiChargepointV1SessionsQueryParams } from 'shared/api/services/chargepoint/orval/zod/schemas';
import {
  useGetSessions,
  useGetSessionsCount,
} from 'shared/api/services/chargepoint/rtk';
import { POLLING_INTERVAL } from 'shared/consts';

const generatedSchema = getApiChargepointV1SessionsQueryParams;

const filteringQueryParams = z.object({
  connectorId: z.string().uuid().optional(),
  status: generatedSchema.shape.Status.optional(),
  transactionId: z.string().optional(),
  createdDateFrom: z.string().datetime().optional(),
  createdDateTo: z.string().datetime().optional(),
  closedDateFrom: z.string().datetime().optional(),
  closedDateTo: z.string().datetime().optional(),
  dateFrom: z.string().datetime().optional(),
  dateTo: z.string().datetime().optional(),
  companyId: z.string().uuid().optional(),
});

const sessionsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const sessionsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  chargePointId?: string;
  userId?: string;
  chargePointGroupId?: string;
};

export const useQueries = ({
  limit,
  chargePointGroupId,
  chargePointId,
  userId,
}: Props) => {
  const isWindowFocused = useWindowFocus();

  const sessionsParams = useTypedQueryParamsCustom(sessionsQueryParams);
  const sessionsCountParams = useTypedQueryParamsCustom(
    sessionsCountQueryParams
  );

  const sessionsQuery = useGetSessions(
    {
      ...sessionsParams,
      offset: getOffsetQueryParam(sessionsParams.page, limit),
      limit,
      chargePointId,
      userId,
      chargePointGroupId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const sessionsCountQuery = useGetSessionsCount(
    {
      ...sessionsCountParams,
      chargePointId,
      userId,
      chargePointGroupId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const isLoading = sessionsQuery.isLoading || sessionsCountQuery.isLoading;
  const isFetching =
    (sessionsQuery.isFetching || sessionsCountQuery.isFetching) &&
    (!sessionsQuery.currentData || !sessionsCountQuery.currentData);

  return {
    isLoading,
    isFetching,
    sessions: sessionsQuery.data?.data ?? [],
    sessionsCount: sessionsCountQuery.data?.data ?? Infinity,
    sessionsError: sessionsQuery.error,
    sessionsCountError: sessionsCountQuery.error,
  };
};
