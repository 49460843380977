import { Link } from 'react-router-dom';

import { ROOT } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';
import { AddBonusProgramLink } from './add-link';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <Link to="">Список программ</Link>,
    extraContent: <AddBonusProgramLink />,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
