import { Spin } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
  BalanceName,
  GetPaymentBillingVm,
  ReportGroupingType,
} from 'shared/api/services/analytics/rtk/generated';
import { FORMAT_TO_MINUTES, showTimeString, toFixedDecimals } from 'shared/lib';

import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTfoot,
  StyledTh,
  StyledThead,
  StyledTr,
} from './styles';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  groupBy: ReportGroupingType | undefined;
  billings: GetPaymentBillingVm[];
  isLoading: boolean;
  tableRef?: React.MutableRefObject<null>;
};

export function ReportTable({ billings, groupBy, isLoading, tableRef }: Props) {
  const isGroupByPeriod = groupBy === 'PERIOD';

  const energySummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.energy ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  const paymentSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.payment ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  const discountSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.discount ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  const bonusPaymentSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.bonusesPayment ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  const balancePaymentSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.balancePayment ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  const invoiceSumSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.invoiceSum ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  const benefitPaymentSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.benefitPayment ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  //
  const reserveSummary = 0;

  const renderPaymentDate = (paymentDate: string | null | undefined) => {
    if (!paymentDate) {
      return '';
    }

    if (groupBy === 'MONTH') {
      return dayjs(paymentDate).tz('Europe/Moscow').format('MM.YYYY');
    }

    if (groupBy === 'PERIOD') {
      return '';
    }

    return dayjs(paymentDate).tz('Europe/Moscow').format(FORMAT_TO_MINUTES);
  };

  const renderBalanceName = (balanceName: BalanceName) => {
    if (balanceName === 'BUSINESS') {
      return 'V';
    }
  };

  const getFooterSummarySpanAmount = () => {
    if (isGroupByPeriod) {
      return 2;
    }

    return 3;
  };

  return (
    <Spin spinning={isLoading}>
      <StyledTable ref={tableRef} columns={isGroupByPeriod ? 11 : 12}>
        <StyledThead>
          <StyledTr>
            {!isGroupByPeriod ? <StyledTh>Дата</StyledTh> : null}
            <StyledTh>Номер ЭЗС</StyledTh>
            <StyledTh>Коннектор</StyledTh>
            <StyledTh>Потребление (кВт*ч)</StyledTh>
            <StyledTh>Стоимость услуги</StyledTh>
            <StyledTh>В том числе стоимость брони</StyledTh>
            <StyledTh>Скидка</StyledTh>
            <StyledTh>Оплачено бонусами</StyledTh>
            <StyledTh>Оплачено со счета</StyledTh>
            <StyledTh>Оплачено картой</StyledTh>
            <StyledTh>Итого оплачено</StyledTh>
            <StyledTh>Безнал</StyledTh>
          </StyledTr>
        </StyledThead>

        <StyledTbody>
          {billings.map((el) => {
            const {
              paymentDate,
              chargePointName,
              connectorName,
              energy,
              payment,
              discount,
              bonusesPayment,
              balancePayment,
              invoiceSum,
              benefitPayment,
              balanceName,
            } = el;

            return (
              <StyledTr>
                {!isGroupByPeriod ? (
                  <StyledTd>{renderPaymentDate(paymentDate)}</StyledTd>
                ) : null}
                <StyledTd>{chargePointName}</StyledTd>
                <StyledTd>{connectorName}</StyledTd>
                <StyledTd>{energy}</StyledTd>
                <StyledTd>{payment}</StyledTd>
                <StyledTd></StyledTd>
                <StyledTd>{discount}</StyledTd>
                <StyledTd>{bonusesPayment}</StyledTd>
                <StyledTd>{balancePayment}</StyledTd>
                <StyledTd>{invoiceSum}</StyledTd>
                <StyledTd>{benefitPayment}</StyledTd>
                <StyledTd>{renderBalanceName(balanceName)}</StyledTd>
              </StyledTr>
            );
          })}
        </StyledTbody>

        <StyledTfoot>
          <StyledTr>
            <StyledTd
              style={{ gridColumn: `span ${getFooterSummarySpanAmount()}` }}
            >
              Итого
            </StyledTd>
            <StyledTd>{energySummary}</StyledTd>
            <StyledTd>{paymentSummary}</StyledTd>
            <StyledTd>{reserveSummary}</StyledTd>
            <StyledTd>{discountSummary}</StyledTd>
            <StyledTd>{bonusPaymentSummary}</StyledTd>
            <StyledTd>{balancePaymentSummary}</StyledTd>
            <StyledTd>{invoiceSumSummary}</StyledTd>
            <StyledTd>{benefitPaymentSummary}</StyledTd>
            <StyledTd></StyledTd>
          </StyledTr>
        </StyledTfoot>
      </StyledTable>
    </Spin>
  );
}
