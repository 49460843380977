/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BillingService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Инвалидирует весь RAM(внутренний) КЭШ сервиса
 */
export const getApiBillingV1AuxClearInMemoryCacheResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение балансов по фильтру.
 */
export const getApiBillingV1BalancesQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  CountryId: zod.string().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingV1BalancesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.enum(['PERSONAL', 'BUSINESS']),
        userId: zod.string().uuid(),
        value: zod.number(),
        countryId: zod.string().min(1),
        isFrozen: zod.boolean(),
        debtPermissionDate: zod.string().datetime(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение количества балансов по фильтру.
 */
export const getApiBillingV1BalancesCountQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiBillingV1BalancesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение транзакций по фильтру.
 */
export const getApiBillingV1BalancesTransactionsQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Operation: zod
    .enum(['UNDEFINED', 'DEBIT', 'CREDIT', 'RESET', 'SET'])
    .optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingV1BalancesTransactionsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        balanceId: zod.string().uuid(),
        operation: zod.enum(['UNDEFINED', 'DEBIT', 'CREDIT', 'RESET', 'SET']),
        value: zod.number(),
        reason: zod.string().min(1),
        createdDate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение количества транзакций по фильтру.
 */
export const getApiBillingV1BalancesTransactionsCountQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Operation: zod
    .enum(['UNDEFINED', 'DEBIT', 'CREDIT', 'RESET', 'SET'])
    .optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiBillingV1BalancesTransactionsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создание баланса.
 */
export const postApiBillingV1BalancesActionCreateBody = zod.object({
  userId: zod.string().uuid(),
  countryId: zod.string().min(1),
  balanceName: zod.enum(['PERSONAL', 'BUSINESS']).optional(),
});

export const postApiBillingV1BalancesActionCreateResponse = zod.object({
  data: zod.string().uuid().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Устанавливает значение баланса.
 */
export const postApiBillingV1BalancesActionSetBody = zod.object({
  balanceId: zod.string().uuid(),
  value: zod.number(),
});

export const postApiBillingV1BalancesActionSetResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Зачисление средств на баланс.
 */
export const postApiBillingV1BalancesActionCreditBody = zod.object({
  balanceId: zod.string().uuid(),
  value: zod.number(),
});

export const postApiBillingV1BalancesActionCreditResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Снятие средств с баланса.
 */
export const postApiBillingV1BalancesActionDebitBody = zod.object({
  balanceId: zod.string().uuid(),
  value: zod.number(),
});

export const postApiBillingV1BalancesActionDebitResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Сбрасывает баланс (делает равным 0).
 */
export const postApiBillingV1BalancesActionResetBody = zod.object({
  balanceId: zod.string().uuid(),
});

export const postApiBillingV1BalancesActionResetResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Заморозить баланс.
 */
export const postApiBillingV1BalancesActionFreezeBody = zod.object({
  balanceId: zod.string().uuid(),
});

export const postApiBillingV1BalancesActionFreezeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Разморозить баланс.
 */
export const postApiBillingV1BalancesActionUnfreezeBody = zod.object({
  balanceId: zod.string().uuid(),
});

export const postApiBillingV1BalancesActionUnfreezeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Установить время до которого разрешено работать с отрицательным балансом (информационное поле).
 */
export const postApiBillingV1BalancesActionSetDebtPermissionDateBody =
  zod.object({
    balanceId: zod.string().uuid(),
    debtPermissionDate: zod.string().datetime(),
  });

export const postApiBillingV1BalancesActionSetDebtPermissionDateResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить биллинг по идентификатору.
 */
export const getApiBillingV1BillingsBillingIdParams = zod.object({
  billingId: zod.string().uuid(),
});

export const getApiBillingV1BillingsBillingIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      balanceId: zod.string().uuid(),
      status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']),
      paymentStatus: zod.enum([
        'NOT_PAID',
        'ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'PAID',
        'ERROR_PAYMENT',
      ]),
      fiscalStatus: zod.enum([
        'NOT_FISCALIZED',
        'ERROR',
        'TIME_OUT_ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'FISCALIZED',
      ]),
      createdDate: zod.string().datetime(),
      closedDate: zod.string().datetime().nullish(),
      balancePayment: zod.number(),
      discount: zod.number(),
      payment: zod.number(),
      bonusesPayment: zod.number(),
      benefitPayment: zod.number(),
      invoiceSum: zod.number(),
      chargePointId: zod.string().uuid(),
      mobileAppId: zod.string().uuid(),
      balanceName: zod.enum(['PERSONAL', 'BUSINESS']),
      countryId: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      providedServices: zod.array(
        zod.object({
          id: zod.string().uuid(),
          serviceId: zod.string().uuid(),
          serviceName: zod.string().min(1),
          serviceType: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
          billingId: zod.string().uuid(),
          sessionId: zod.string().uuid(),
          status: zod.enum(['ERROR', 'RUNNING', 'COMPLETED']),
          beginDate: zod.string().datetime(),
          endDate: zod.string().datetime().nullish(),
          payment: zod.number(),
          discount: zod.number(),
          benefitPayment: zod.number(),
          lastUpdate: zod.string().datetime(),
          providedStages: zod.array(
            zod.object({
              id: zod.string().uuid(),
              providedServiceId: zod.string().uuid(),
              stageId: zod.string().uuid(),
              stageName: zod.string().min(1),
              stageEndType: zod.enum([
                'MANUAL',
                'RIGHT_NOW',
                'ENERGY_PERCENT',
                'TIME_MINUTE',
                'POWER_LOSS_KW',
              ]),
              tariffId: zod.string().uuid(),
              tariffName: zod.string().min(1),
              tariffType: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
              tariffPrice: zod.number(),
              beginDate: zod.string().datetime(),
              endDate: zod.string().datetime().nullish(),
              value: zod.number().nullish(),
              payment: zod.number(),
              discount: zod.number(),
              benefitPayment: zod.number(),
              endConditionValue: zod.number(),
              reachedEndConditionDate: zod.string().datetime().nullish(),
              lastUpdate: zod.string().datetime(),
            })
          ),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить биллинги по фильтру.
 */
export const getApiBillingV1BillingsQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  SessionId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  ServiceType: zod.enum(['RESERVING', 'CHARGING', 'PARKING']).optional(),
  ChargePointsIds: zod.array(zod.string().uuid()).optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingV1BillingsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        balanceId: zod.string().uuid(),
        status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']),
        paymentStatus: zod.enum([
          'NOT_PAID',
          'ERROR',
          'IN_PROCESS',
          'NOT_REQUIRED',
          'PAID',
          'ERROR_PAYMENT',
        ]),
        fiscalStatus: zod.enum([
          'NOT_FISCALIZED',
          'ERROR',
          'TIME_OUT_ERROR',
          'IN_PROCESS',
          'NOT_REQUIRED',
          'FISCALIZED',
        ]),
        createdDate: zod.string().datetime(),
        closedDate: zod.string().datetime().nullish(),
        balancePayment: zod.number(),
        discount: zod.number(),
        payment: zod.number(),
        bonusesPayment: zod.number(),
        benefitPayment: zod.number(),
        invoiceSum: zod.number(),
        chargePointId: zod.string().uuid(),
        mobileAppId: zod.string().uuid(),
        balanceName: zod.enum(['PERSONAL', 'BUSINESS']),
        countryId: zod.string().min(1),
        lastUpdate: zod.string().datetime(),
        providedServices: zod.array(
          zod.object({
            id: zod.string().uuid(),
            serviceId: zod.string().uuid(),
            serviceName: zod.string().min(1),
            serviceType: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            billingId: zod.string().uuid(),
            sessionId: zod.string().uuid(),
            status: zod.enum(['ERROR', 'RUNNING', 'COMPLETED']),
            beginDate: zod.string().datetime(),
            endDate: zod.string().datetime().nullish(),
            payment: zod.number(),
            discount: zod.number(),
            benefitPayment: zod.number(),
            lastUpdate: zod.string().datetime(),
            providedStages: zod.array(
              zod.object({
                id: zod.string().uuid(),
                providedServiceId: zod.string().uuid(),
                stageId: zod.string().uuid(),
                stageName: zod.string().min(1),
                stageEndType: zod.enum([
                  'MANUAL',
                  'RIGHT_NOW',
                  'ENERGY_PERCENT',
                  'TIME_MINUTE',
                  'POWER_LOSS_KW',
                ]),
                tariffId: zod.string().uuid(),
                tariffName: zod.string().min(1),
                tariffType: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                tariffPrice: zod.number(),
                beginDate: zod.string().datetime(),
                endDate: zod.string().datetime().nullish(),
                value: zod.number().nullish(),
                payment: zod.number(),
                discount: zod.number(),
                benefitPayment: zod.number(),
                endConditionValue: zod.number(),
                reachedEndConditionDate: zod.string().datetime().nullish(),
                lastUpdate: zod.string().datetime(),
              })
            ),
          })
        ),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество биллингов по фильтру.
 */
export const getApiBillingV1BillingsCountQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  SessionId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  ServiceType: zod.enum(['RESERVING', 'CHARGING', 'PARKING']).optional(),
  ChargePointsIds: zod.array(zod.string().uuid()).optional(),
});

export const getApiBillingV1BillingsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Закрывает биллинг.
 */
export const postApiBillingV1BillingsActionCloseBody = zod.object({
  billingId: zod.string().uuid(),
});

export const postApiBillingV1BillingsActionCloseResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Закрывает активный биллинг пользователя.
 */
export const postApiBillingV1BillingsActionCloseactiveBody = zod.object({
  userId: zod.string().uuid(),
});

export const postApiBillingV1BillingsActionCloseactiveResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Рассчитывает последний созданный биллинг пользователя.
Если биллинг еще не закрыт, то рассчитывает оплату для активных оказываемых услуг и стадий.
Если закрыт - то возвращает уже рассчитанный биллинг.
 */
export const postApiBillingV1BillingsActionCalculatelastBody = zod.object({
  userId: zod.string().uuid(),
});

export const postApiBillingV1BillingsActionCalculatelastResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      balanceId: zod.string().uuid(),
      status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']),
      paymentStatus: zod.enum([
        'NOT_PAID',
        'ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'PAID',
        'ERROR_PAYMENT',
      ]),
      fiscalStatus: zod.enum([
        'NOT_FISCALIZED',
        'ERROR',
        'TIME_OUT_ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'FISCALIZED',
      ]),
      createdDate: zod.string().datetime(),
      closedDate: zod.string().datetime().nullish(),
      balancePayment: zod.number(),
      discount: zod.number(),
      payment: zod.number(),
      bonusesPayment: zod.number(),
      benefitPayment: zod.number(),
      invoiceSum: zod.number(),
      chargePointId: zod.string().uuid(),
      mobileAppId: zod.string().uuid(),
      balanceName: zod.enum(['PERSONAL', 'BUSINESS']),
      countryId: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      providedServices: zod.array(
        zod.object({
          id: zod.string().uuid(),
          serviceId: zod.string().uuid(),
          serviceName: zod.string().min(1),
          serviceType: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
          billingId: zod.string().uuid(),
          sessionId: zod.string().uuid(),
          status: zod.enum(['ERROR', 'RUNNING', 'COMPLETED']),
          beginDate: zod.string().datetime(),
          endDate: zod.string().datetime().nullish(),
          payment: zod.number(),
          discount: zod.number(),
          benefitPayment: zod.number(),
          lastUpdate: zod.string().datetime(),
          providedStages: zod.array(
            zod.object({
              id: zod.string().uuid(),
              providedServiceId: zod.string().uuid(),
              stageId: zod.string().uuid(),
              stageName: zod.string().min(1),
              stageEndType: zod.enum([
                'MANUAL',
                'RIGHT_NOW',
                'ENERGY_PERCENT',
                'TIME_MINUTE',
                'POWER_LOSS_KW',
              ]),
              tariffId: zod.string().uuid(),
              tariffName: zod.string().min(1),
              tariffType: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
              tariffPrice: zod.number(),
              beginDate: zod.string().datetime(),
              endDate: zod.string().datetime().nullish(),
              value: zod.number().nullish(),
              payment: zod.number(),
              discount: zod.number(),
              benefitPayment: zod.number(),
              endConditionValue: zod.number(),
              reachedEndConditionDate: zod.string().datetime().nullish(),
              lastUpdate: zod.string().datetime(),
            })
          ),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выполнить повторную попытку отправки оплаты (результат будет потом).
 */
export const postApiBillingV1BillingsActionRetrypayBody = zod.object({
  billingId: zod.string().uuid(),
});

export const postApiBillingV1BillingsActionRetrypayResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Построить график выручки всех услуг.
 */
export const getApiBillingV1MetricsGraphTOTALREVENUEQueryParams = zod.object({
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  GroupByPeriod: zod.enum(['DAY', 'MONTH']).optional(),
});

export const getApiBillingV1MetricsGraphTOTALREVENUEResponse = zod.object({
  data: zod
    .array(
      zod.object({
        date: zod.string().datetime(),
        value: zod.number(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить отчет по оплатам пользователей.
 */
export const getApiBillingV1ReportsUsersPaymentSumQueryParams = zod.object({
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  BalancesIds: zod.array(zod.string().uuid()).optional(),
  UsersIds: zod.array(zod.string().uuid()).optional(),
  CountryId: zod.string().optional(),
});

export const getApiBillingV1ReportsUsersPaymentSumResponse = zod.object({
  data: zod
    .object({
      usersCalculatedValues: zod.array(
        zod.object({
          userId: zod.string().uuid(),
          calculatedValue: zod.number(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить отчет по счетам пользователей.
 */
export const getApiBillingV1ReportsUsersInvoiceSumQueryParams = zod.object({
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  CountryId: zod.string().optional(),
  UsersIds: zod.array(zod.string().uuid()).optional(),
});

export const getApiBillingV1ReportsUsersInvoiceSumResponse = zod.object({
  data: zod
    .object({
      usersCalculatedValues: zod.array(
        zod.object({
          userId: zod.string().uuid(),
          calculatedValue: zod.number(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Начинает оказываемую услугу.
 */
export const postApiBillingV1ServicesActionBeginBody = zod.object({
  userId: zod.string().uuid(),
  sessionId: zod.string().uuid(),
  balanceId: zod.string().uuid(),
  serviceId: zod.string().uuid(),
  chargePointId: zod.string().uuid(),
  mobileAppId: zod.string().uuid(),
  serviceUserSettings: zod
    .object({
      reserveTimeMinute: zod.number().nullish(),
    })
    .optional(),
});

export const postApiBillingV1ServicesActionBeginResponse = zod.object({
  data: zod.string().uuid().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Завершает оказываемую услугу.
 */
export const postApiBillingV1ServicesActionEndBody = zod.object({
  providedServiceId: zod.string().uuid(),
});

export const postApiBillingV1ServicesActionEndResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Завершает активную оказываемую услугу.
 */
export const postApiBillingV1ServicesActionEndactiveQueryParams = zod.object({
  userId: zod.string().uuid().optional(),
});

export const postApiBillingV1ServicesActionEndactiveResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает услугу.
 */
export const postApiBillingV1ServicesBody = zod.object({
  type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
  name: zod.string().min(1),
});

export const postApiBillingV1ServicesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
      createdDate: zod.string().datetime(),
      stages: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            serviceId: zod.string().uuid(),
            endType: zod.enum([
              'MANUAL',
              'RIGHT_NOW',
              'ENERGY_PERCENT',
              'TIME_MINUTE',
              'POWER_LOSS_KW',
            ]),
            endConditionValue: zod.number(),
            serialNumber: zod.number(),
            createdDate: zod.string().datetime(),
            tariff: zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              countryId: zod.string().min(1),
              type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
              price: zod.number(),
              createdDate: zod.string().datetime(),
            }),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает услуги по фильтру.
 */
export const getApiBillingV1ServicesQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingV1ServicesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
        createdDate: zod.string().datetime(),
        stages: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              serviceId: zod.string().uuid(),
              endType: zod.enum([
                'MANUAL',
                'RIGHT_NOW',
                'ENERGY_PERCENT',
                'TIME_MINUTE',
                'POWER_LOSS_KW',
              ]),
              endConditionValue: zod.number(),
              serialNumber: zod.number(),
              createdDate: zod.string().datetime(),
              tariff: zod.object({
                id: zod.string().uuid(),
                name: zod.string().min(1),
                countryId: zod.string().min(1),
                type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                price: zod.number(),
                createdDate: zod.string().datetime(),
              }),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает услугу по идентификатору.
 */
export const getApiBillingV1ServicesIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiBillingV1ServicesIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
      createdDate: zod.string().datetime(),
      stages: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            serviceId: zod.string().uuid(),
            endType: zod.enum([
              'MANUAL',
              'RIGHT_NOW',
              'ENERGY_PERCENT',
              'TIME_MINUTE',
              'POWER_LOSS_KW',
            ]),
            endConditionValue: zod.number(),
            serialNumber: zod.number(),
            createdDate: zod.string().datetime(),
            tariff: zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              countryId: zod.string().min(1),
              type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
              price: zod.number(),
              createdDate: zod.string().datetime(),
            }),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаяет услугу.
 */
export const deleteApiBillingV1ServicesIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingV1ServicesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество услуг по фильтру.
 */
export const getApiBillingV1ServicesCountQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiBillingV1ServicesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает или обновляет привязки списков услуг к целевым сущностям.
 */
export const putApiBillingV1ServicesListLinksBody = zod.object({
  servicesListsLinks: zod
    .array(
      zod.object({
        targetId: zod.string().uuid(),
        targetName: zod.enum(['CHARGE_POINT', 'CONNECTOR']),
        servicesListId: zod.string().uuid(),
      })
    )
    .nullish(),
});

export const putApiBillingV1ServicesListLinksResponse = zod.object({
  data: zod
    .array(
      zod.object({
        targetId: zod.string().uuid(),
        targetName: zod.enum(['CHARGE_POINT', 'CONNECTOR']),
        servicesListId: zod.string().uuid(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает привязки списков услуг по фильтру.
 */
export const getApiBillingV1ServicesListLinksQueryParams = zod.object({
  ServicesListId: zod.string().uuid().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiBillingV1ServicesListLinksResponse = zod.object({
  data: zod
    .array(
      zod.object({
        targetId: zod.string().uuid(),
        targetName: zod.enum(['CHARGE_POINT', 'CONNECTOR']),
        servicesListId: zod.string().uuid(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество привязок списков услуг по фильтру.
 */
export const getApiBillingV1ServicesListLinksCountQueryParams = zod.object({
  ServicesListId: zod.string().uuid().optional(),
});

export const getApiBillingV1ServicesListLinksCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаление привязки списка услуг.
 */
export const deleteApiBillingV1ServicesListLinksTargetIdParams = zod.object({
  targetId: zod.string().uuid(),
});

export const deleteApiBillingV1ServicesListLinksTargetIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список услуг по идентификатору.
 */
export const getApiBillingV1ServicesListsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiBillingV1ServicesListsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      services: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS_KW',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Обновить список услуг.
 */
export const patchApiBillingV1ServicesListsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiBillingV1ServicesListsIdBody = zod.object({
  name: zod.string().nullish(),
  servicesIds: zod.array(zod.string().uuid()).nullish(),
});

export const patchApiBillingV1ServicesListsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      services: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS_KW',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удалить список услуг.
 */
export const deleteApiBillingV1ServicesListsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingV1ServicesListsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить списки услуг по фильтру.
 */
export const getApiBillingV1ServicesListsQueryParams = zod.object({
  ServiceId: zod.string().uuid().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiBillingV1ServicesListsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        lastUpdate: zod.string().datetime(),
        services: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
              createdDate: zod.string().datetime(),
              stages: zod
                .array(
                  zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    serviceId: zod.string().uuid(),
                    endType: zod.enum([
                      'MANUAL',
                      'RIGHT_NOW',
                      'ENERGY_PERCENT',
                      'TIME_MINUTE',
                      'POWER_LOSS_KW',
                    ]),
                    endConditionValue: zod.number(),
                    serialNumber: zod.number(),
                    createdDate: zod.string().datetime(),
                    tariff: zod.object({
                      id: zod.string().uuid(),
                      name: zod.string().min(1),
                      countryId: zod.string().min(1),
                      type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                      price: zod.number(),
                      createdDate: zod.string().datetime(),
                    }),
                  })
                )
                .nullish(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создать список услуг.
 */
export const postApiBillingV1ServicesListsBody = zod.object({
  name: zod.string().min(1),
  servicesIds: zod.array(zod.string().uuid()).nullish(),
});

export const postApiBillingV1ServicesListsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      services: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS_KW',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество списков услуг по фильтру.
 */
export const getApiBillingV1ServicesListsCountQueryParams = zod.object({
  ServiceId: zod.string().uuid().optional(),
});

export const getApiBillingV1ServicesListsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает стадию услуги.
 */
export const postApiBillingV1StagesBody = zod.object({
  name: zod.string().min(1),
  serviceId: zod.string().uuid(),
  tariffId: zod.string().uuid(),
  endType: zod.enum([
    'MANUAL',
    'RIGHT_NOW',
    'ENERGY_PERCENT',
    'TIME_MINUTE',
    'POWER_LOSS_KW',
  ]),
  endConditionValue: zod.number(),
  serialNumber: zod.number(),
});

export const postApiBillingV1StagesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      serviceId: zod.string().uuid(),
      tariffId: zod.string().uuid(),
      endType: zod.enum([
        'MANUAL',
        'RIGHT_NOW',
        'ENERGY_PERCENT',
        'TIME_MINUTE',
        'POWER_LOSS_KW',
      ]),
      endConditionValue: zod.number(),
      serialNumber: zod.number(),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает стадии услуги по фильтру.
 */
export const getApiBillingV1StagesQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingV1StagesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        serviceId: zod.string().uuid(),
        tariffId: zod.string().uuid(),
        endType: zod.enum([
          'MANUAL',
          'RIGHT_NOW',
          'ENERGY_PERCENT',
          'TIME_MINUTE',
          'POWER_LOSS_KW',
        ]),
        endConditionValue: zod.number(),
        serialNumber: zod.number(),
        createdDate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает стадию услуги по идентификатору.
 */
export const getApiBillingV1StagesIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiBillingV1StagesIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      serviceId: zod.string().uuid(),
      tariffId: zod.string().uuid(),
      endType: zod.enum([
        'MANUAL',
        'RIGHT_NOW',
        'ENERGY_PERCENT',
        'TIME_MINUTE',
        'POWER_LOSS_KW',
      ]),
      endConditionValue: zod.number(),
      serialNumber: zod.number(),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаяет стадию услуги.
 */
export const deleteApiBillingV1StagesIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingV1StagesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество стадий услуг по фильтру.
 */
export const getApiBillingV1StagesCountQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiBillingV1StagesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить привязанные списки услуг для сущностей (напр. коннекторов).
 */
export const getApiBillingV1TargetsServicesListsQueryParams = zod.object({
  TargetsIds: zod.array(zod.string().uuid()),
});

export const getApiBillingV1TargetsServicesListsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        targetId: zod.string().uuid(),
        targetName: zod.enum(['CHARGE_POINT', 'CONNECTOR']),
        servicesListId: zod.string().uuid(),
        name: zod.string().min(1),
        lastUpdate: zod.string().datetime(),
        services: zod.array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS_KW',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        ),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает тариф.
 */
export const postApiBillingV1TariffsBody = zod.object({
  name: zod.string().min(1),
  countryId: zod.string().min(1),
  type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
  price: zod.number(),
});

export const postApiBillingV1TariffsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
      price: zod.number(),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает тарифы по фильтру.
 */
export const getApiBillingV1TariffsQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingV1TariffsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
        price: zod.number(),
        createdDate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает тариф по идентификатору.
 */
export const getApiBillingV1TariffsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiBillingV1TariffsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
      price: zod.number(),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаяет тариф.
 */
export const deleteApiBillingV1TariffsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingV1TariffsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество тарифов по фильтру.
 */
export const getApiBillingV1TariffsCountQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiBillingV1TariffsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
