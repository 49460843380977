import { useParams } from 'react-router-dom';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';
import { useGetChargePointGroups } from 'shared/api/services/chargepoint/rtk';
import { useGetRoleCpRights } from 'shared/api/services/chargepoint/rtk/enhanced/role-cp-rights';
import { useGetRoleGroupCpRights } from 'shared/api/services/chargepoint/rtk/enhanced/role-group-cp-rights';

import { CpRightsForm } from './form';
import { useGetIsAllCpRights } from 'shared/api/services/chargepoint/rtk/enhanced/user-cp-rights';

export function FormContainer() {
  const { roleName } = useParams() as { roleName: string };

  const chargePointGroupsQuery = useGetChargePointGroups({});
  const roleCpRightsQuery = useGetRoleCpRights(roleName);
  const roleGroupCpRightsQuery = useGetRoleGroupCpRights(roleName);
  const isAllCpRightsQuery = useGetIsAllCpRights({ userRole: roleName });

  const isLoading =
    chargePointGroupsQuery.isLoading ||
    roleCpRightsQuery.isLoading ||
    roleGroupCpRightsQuery.isLoading ||
    isAllCpRightsQuery.isLoading;

  const error = [
    chargePointGroupsQuery.error,
    roleCpRightsQuery.error,
    roleGroupCpRightsQuery.error,
    isAllCpRightsQuery.error,
  ].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !chargePointGroupsQuery.data?.data ||
    !roleCpRightsQuery.data?.data ||
    !roleGroupCpRightsQuery.data?.data ||
    isAllCpRightsQuery.data?.data === undefined
  ) {
    return <EmptyData />;
  }

  return (
    <CpRightsForm
      groups={chargePointGroupsQuery.data.data}
      chargePointRights={roleCpRightsQuery.data.data}
      groupCpRights={roleGroupCpRightsQuery.data.data}
      roleName={roleName}
      isAllCpRights={isAllCpRightsQuery.data.data}
    />
  );
}
