import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import {
  useGetTransactions,
  useGetTransactionsCount,
} from 'shared/api/services/bonus/rtk/enhanced';

const filteringQueryParams = z.object({
  balanceId: z.string().uuid().or(z.null()).optional(),
  // bonusProgramId: z.string().uuid().or(z.null()).optional(),
  dateFrom: z.string().datetime().or(z.null()).optional(),
  dateTo: z.string().datetime().or(z.null()).optional(),
  // userId: z.string().uuid().or(z.null()).optional(),
});

const bonusTransactionsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const bonusTransactionsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  bonusProgramId?: string;
  userId?: string;
};

export const useQueries = ({ limit, bonusProgramId, userId }: Props) => {
  const bonusTransactionsParams = useTypedQueryParamsCustom(
    bonusTransactionsQueryParams
  );
  const bonusTransactions = useTypedQueryParamsCustom(
    bonusTransactionsCountQueryParams
  );

  const bonusTransactionsQuery = useGetTransactions({
    ...bonusTransactionsParams,
    offset: getOffsetQueryParam(bonusTransactionsParams.page, limit),
    limit,
    bonusProgramId,
    userId,
  });

  const bonusTransactionsCountQuery = useGetTransactionsCount({
    ...bonusTransactions,
    bonusProgramId,
    userId,
  });

  const isLoading =
    bonusTransactionsQuery.isLoading || bonusTransactionsCountQuery.isLoading;

  const isFetching =
    bonusTransactionsQuery.isFetching || bonusTransactionsCountQuery.isFetching;

  return {
    isLoading,
    isFetching,
    bonusTransactions: bonusTransactionsQuery.data?.data ?? [],
    bonusTransactionsCount: bonusTransactionsCountQuery.data?.data ?? Infinity,
    bonusTransactionsError: bonusTransactionsQuery.error,
    bonusTransactionsCountError: bonusTransactionsCountQuery.error,
  };
};
