import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
  UiDeleteFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  IdentityServiceApplicationDtoUserDto,
  IdentityServiceWebApiDtoGroupDto,
} from 'shared/api/services/identity/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAppSelector } from 'shared/redux/types';
import { selectTokenName } from 'entities/authorization';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { useAbility } from 'shared/lib/ability/context';

import { DeleteIdentityUserModal } from 'features/identity-user/delete';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { useHandleUpdate } from '../hooks/use-handle-update';

type Props = {
  groups: IdentityServiceWebApiDtoGroupDto[];
  user: IdentityServiceApplicationDtoUserDto;
};

const getDefaultValues = (
  user: IdentityServiceApplicationDtoUserDto
): FormInput => {
  const {
    userName,
    email,
    firstName,
    lastName,
    middleName,
    powerBiUrl,
    groups,
  } = user;

  return {
    userName: userName ?? '',
    email: email ?? '',
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    middleName: middleName ?? '',
    newPassword: '',
    groupName: groups?.[0] ?? '',
    powerBiUrl: powerBiUrl ?? '',
  };
};

export function Form({ groups, user }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(user),
  });

  useEffect(() => {
    form.reset({ ...getDefaultValues(user) });
  }, [user]);

  const { handleUpdate, isLoading } = useHandleUpdate();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({
      data,
      id: user.id,
      userName: user.userName,
      group: user.groups?.[0],
    });
  });

  return (
    <UiCard>
      <UiCard.Header>{user.userName}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields groups={groups} isDisabled={isDisabled} />
              <Form.Buttons
                isDisabled={isDisabled}
                enableForm={enableForm}
                userName={user.userName}
              />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = Pick<Props, 'groups'> & {
  isDisabled: boolean;
};

Form.Fields = function Fields({ groups, isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="userName"
            label="Логин"
            required
            disabled
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="lastName"
            label="Фамилия"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="firstName"
            label="Имя"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="middleName"
            label="Отчество"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="newPassword"
            label="Сменить пароль"
            type="password"
            autoComplete="new-password"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="groupName"
            options={groups.map(({ id, name }) => ({
              value: name,
              label: name,
            }))}
            label="Группа"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="powerBiUrl"
            label="Ссылка на дашборд"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
  userName: string;
};

Form.Buttons = function Buttons({
  enableForm,
  isDisabled,
  userName,
}: ButtonsProps) {
  const currentUserName = useAppSelector(selectTokenName);

  const ability = useAbility();

  if (!ability.can('Write', 'IdentityService')) {
    return null;
  }

  const showDeleteModal = () => {
    NiceModal.show(DeleteIdentityUserModal, { userName });
  };

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
        {currentUserName !== userName ? (
          <UiDeleteFormButton onClick={showDeleteModal} />
        ) : null}
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
