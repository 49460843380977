import { z } from 'zod';

import { useGetMobileApps } from 'shared/api/services/information/rtk';
import {
  useGetUsers,
  useGetUsersCount,
} from 'shared/api/services/user/rtk/enhanced';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';

import { getMobileAppsMapping } from 'entities/mobile-app';

const filteringQueryParams = z.object({
  login: z.string().optional(),
  mobileAppId: z.string().uuid().optional(),
  registrationDateFrom: z.string().datetime().optional(),
  registrationDateTo: z.string().datetime().optional(),
  groupId: z.string().uuid().optional(),
});

const usersQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const usersCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  groupId?: string;
};

export const useQueries = ({ limit, groupId }: Props) => {
  const usersParams = useTypedQueryParamsCustom(usersQueryParams);
  const usersCountParams = useTypedQueryParamsCustom(usersCountQueryParams);

  const usersQuery = useGetUsers({
    ...usersParams,
    offset: getOffsetQueryParam(usersParams.page, limit),
    limit,
    groupId,
  });

  const usersCountQuery = useGetUsersCount({
    ...usersCountParams,
    groupId,
  });

  const mobileAppsQuery = useGetMobileApps();

  const isLoading =
    usersQuery.isLoading ||
    usersCountQuery.isLoading ||
    mobileAppsQuery.isLoading;

  const isFetching = usersQuery.isFetching || usersCountQuery.isFetching;

  const mobileAppsMapping = getMobileAppsMapping(
    mobileAppsQuery.data?.data || []
  );

  return {
    isLoading,
    isFetching,
    users: usersQuery.data?.data ?? [],
    usersCount: usersCountQuery.data?.data ?? Infinity,
    mobileAppsMapping,
    usersError: usersQuery.error,
    usersCountError: usersCountQuery.error,
    mobileAppsError: mobileAppsQuery.error,
  };
};
