import { enhancedApi as api } from '../generated/role-cp-rights';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1RoleCpRights: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'RoleCpRights', id: arg },
      ];
    },
  },
});

export const {
  useGetApiChargepointV1RoleCpRightsQuery: useGetRoleCpRights,
  usePostApiChargepointV1RoleCpRightsSetRoleCpRightsMutation:
    useSetRoleCpRights,
} = enhancedApi;
