import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import { getApiChargepointV1EventsQueryParams } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { POLLING_INTERVAL } from 'shared/consts';
import {
  useGetChargePoint,
  useGetEvents,
  useGetEventsCount,
} from 'shared/api/services/chargepoint/rtk';

const generatedSchema = getApiChargepointV1EventsQueryParams;

const filteringQueryParams = z.object({
  name: generatedSchema.shape.Name.optional(),
  type: generatedSchema.shape.Type.optional(),
  sessionId: z.string().uuid().optional(),
  connectorId: z.string().uuid().optional(),
  transactionId: z.string().uuid().optional(),
  dateFrom: z.string().datetime().optional(),
  dateTo: z.string().datetime().optional(),
});

const eventsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const eventsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  chargePointId: string;
};

export const useQueries = ({ limit, chargePointId }: Props) => {
  const isWindowFocused = useWindowFocus();

  const eventsParams = useTypedQueryParamsCustom(eventsQueryParams);
  const eventsCountParams = useTypedQueryParamsCustom(eventsCountQueryParams);

  const eventsQuery = useGetEvents(
    {
      ...eventsParams,
      offset: getOffsetQueryParam(eventsParams.page, limit),
      limit,
      chargePointId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const eventsCountQuery = useGetEventsCount(
    {
      ...eventsCountParams,
      chargePointId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const chargePointQuery = useGetChargePoint(chargePointId);

  const isLoading =
    eventsQuery.isLoading ||
    eventsCountQuery.isLoading ||
    chargePointQuery.isLoading;
  const isFetching =
    (eventsQuery.isFetching || eventsCountQuery.isFetching) &&
    (!eventsQuery.currentData || !eventsCountQuery.currentData);

  return {
    isLoading,
    isFetching,
    events: eventsQuery.data?.data ?? [],
    eventsCount: eventsCountQuery.data?.data ?? Infinity,
    connectors: chargePointQuery.data?.data?.connectors ?? [],
    eventsError: eventsQuery.error,
    eventsCountError: eventsCountQuery.error,
    chargePointError: chargePointQuery.error,
  };
};
