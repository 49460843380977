import {
  Row,
  SortingState,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { useRef, useState } from 'react';

import { GetSessionVm } from 'shared/api/services/chargepoint/rtk/generated/sessions';
import { JsonDataViewer } from 'shared/ui';
import {
  ServerTableLayout,
  useQueryParamsPagination,
  useQueryParamsSorting,
} from 'shared/ui/table-new';
import { ExportTableButton } from './export-button';

import { useColumns } from '../hooks/use-columns';

type Props = {
  data: GetSessionVm[];
  loading: boolean;
  totalCount: number;
  tableLimit: number;
};

export function TableInstance({
  data,
  loading,
  totalCount,
  tableLimit,
}: Props) {
  const [rowToShowJSON, setRowToShowJSON] = useState<null | GetSessionVm>(null);

  const columns = useColumns();

  const { page } = useQueryParamsPagination();
  const { orderingField, orderingType } = useQueryParamsSorting();

  const tableRef = useRef(null);

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: tableLimit,
  };

  const sorting: SortingState = [
    {
      id: orderingField,
      desc: orderingType === 'DESC',
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / tableLimit),
  });

  const handleRowDoubleClick = (row: Row<GetSessionVm>) => {
    setRowToShowJSON(row.original);
  };

  if (rowToShowJSON) {
    return (
      <JsonDataViewer
        data={rowToShowJSON}
        onClick={() => setRowToShowJSON(null)}
      />
    );
  }

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
      handleRowDoubleClick={handleRowDoubleClick}
      tableLimit={tableLimit}
      tableRef={tableRef}
      exportButton={
        <ExportTableButton
          columns={columns}
          disabled={loading || !data.length}
          tableRef={tableRef}
        />
      }
    />
  );
}
