import { ConnectorVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { GetLocksResponse } from 'shared/api/services/reserve/rtk/generated';

import { Actions } from './actions';
import { Layout } from './layout';

type Props = {
  connector: ConnectorVm;
  chargePointId: string;
  lock: GetLocksResponse | undefined;
};

export function ConnectorCard({ connector, chargePointId, lock }: Props) {
  return (
    <Layout
      connector={connector}
      lock={lock}
      actionsSlot={
        <Actions connector={connector} chargePointId={chargePointId} />
      }
    />
  );
}
