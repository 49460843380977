import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  FORMAT_TO_DAYS,
  renderBooleanAsString,
  showTimeString,
  TABLE_PHONE_COLUMN_WIDTH,
} from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { UserDto } from 'shared/api/services/user/rtk/generated';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';

import { selectTableColumns, setTableColumns } from '../store/slice';

const columnHelper = createColumnHelper<UserDto>();

export const useColumns = ({
  mobileAppsMapping,
}: {
  mobileAppsMapping: Record<string, MobileAppVm>;
}) => {
  const dispatch = useAppDispatch();

  const tableColumns = useAppSelector(selectTableColumns);

  const DATA_COLUMNS = [
    columnHelper.accessor('registrationDate', {
      id: 'registrationDate',
      header: 'Дата регистрации',
      size: 120,
      minSize: 120,
      maxSize: 120,
      cell: (props) => {
        const date = props.row.original.registrationDate || '';

        return date ? showTimeString(date, FORMAT_TO_DAYS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter
            dateFromName="registrationDateFrom"
            dateToName="registrationDateTo"
          />
        ),
      },
    }),
    columnHelper.accessor('login', {
      id: 'login',
      header: 'Номер телефона',
      size: TABLE_PHONE_COLUMN_WIDTH,
      minSize: TABLE_PHONE_COLUMN_WIDTH,
      maxSize: TABLE_PHONE_COLUMN_WIDTH,
      meta: {
        filterElement: <InputFilter paramName="login" />,
      },
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: 'Email',
      size: 250,
      minSize: 250,
      maxSize: 250,
      meta: {
        filterElement: <InputFilter paramName="email" disabled />,
      },
    }),
    columnHelper.accessor('firstName', {
      id: 'fullName',
      header: 'Имя',
      cell: (props) => {
        const { firstName, lastName, middleName } = props.row.original;

        return [lastName ?? '', firstName ?? '', middleName ?? ''].join(' ');
      },
      meta: {
        filterElement: <InputFilter paramName="firstName" disabled />,
      },
    }),

    columnHelper.accessor('mobileAppId', {
      id: 'mobileAppId',
      header: 'Приложение',
      cell: (props) => {
        const val = props.getValue();

        const mobileApp = mobileAppsMapping[val];

        if (!mobileApp) {
          return '';
        }

        return mobileApp.name;
      },
      meta: {
        filterElement: <InputFilter paramName="mobileAppId" disabled />,
      },
    }),
    columnHelper.accessor('notesRelatedWithUser', {
      id: 'notesRelatedWithUser',
      header: 'Комментарий',
      size: 250,
      minSize: 250,
      maxSize: 250,
      meta: {
        filterElement: (
          <InputFilter paramName="notesRelatedWithUser" disabled />
        ),
      },
    }),
    columnHelper.accessor('userAgreementAccepted', {
      id: 'userAgreementAccepted',
      header: 'Пользоват. соглашение',
      size: 115,
      minSize: 115,
      maxSize: 115,
      cell: (props) => {
        const value = Boolean(props.row.original.userAgreementAccepted);

        return renderBooleanAsString(value);
      },
      meta: {
        filterElement: (
          <InputFilter paramName="userAgreementAccepted" disabled />
        ),
      },
    }),
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
