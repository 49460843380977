import { Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { ApplicationModelsControllerRweRight } from 'shared/api/services/identity/rtk/generated';
import { useUpdateRoleRights } from 'shared/api/services/identity/rtk/enhanced';
import { useAbility } from 'shared/lib/ability/context';

import { CONTROLLER, CONTROLLERS, RIGHTS } from 'entities/authorization/consts';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';
import { StyledTable, TableContainer } from './styles';

const getDefaultValues = (
  roleRights: ApplicationModelsControllerRweRight[]
): FormInput => {
  if (!roleRights.length) {
    return CONTROLLERS.reduce((acc, el) => {
      acc[el] = {
        read: false,
        write: false,
        execute: false,
      };

      return acc;
    }, {} as FormInput);
  }

  return CONTROLLERS.reduce((acc, el) => {
    const userRight = roleRights.find((right) => right.controllerName === el);

    if (userRight) {
      const { execute, read, write } = userRight;

      acc[el] = {
        read: read,
        write: write,
        execute: execute,
      };
    } else {
      acc[el] = {
        read: false,
        write: false,
        execute: false,
      };
    }

    return acc;
  }, {} as FormInput);
};

type Props = {
  roleName: string;
  roleRights: ApplicationModelsControllerRweRight[];
};

export function Form({ roleName, roleRights }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(roleRights),
  });

  const [trigger, { isLoading }] = useUpdateRoleRights();

  const handleSubmit = form.handleSubmit(async (data) => {
    const rights = (
      Object.keys(data) as unknown as Array<keyof typeof data>
    ).map((el) => {
      const obj = data[el]!;

      const { execute, read, write } = obj;

      return {
        controllerName: el,
        read,
        write,
        execute,
      };
    });

    try {
      const res = await trigger({
        roleName,
        controllerRights: rights,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons />
        </form>
      </Spin>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const renderFormTable = () => {
    return (
      <StyledTable>
        <thead>
          <tr>
            <th>Разделы</th>
            <th>Чтение</th>
            <th>Запись</th>
            <th>Выполнение</th>
          </tr>
        </thead>
        <tbody>
          {CONTROLLER.map((el) => {
            const { label, name } = el;

            return (
              <tr key={name}>
                <td>{label}</td>
                {RIGHTS.map((right) => (
                  <td key={`${name}.${right}`}>
                    <CustomCheckbox<FormInput> name={`${name}.${right}`} />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    );
  };

  return (
    <>
      <TableContainer>{renderFormTable()}</TableContainer>
    </>
  );
};

Form.Buttons = function Buttons() {
  const ability = useAbility();

  if (!ability.can('Write', 'IdentityService')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
