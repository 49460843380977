import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { CustomRangePicker } from 'shared/ui/form/custom-range-picker';
import { getQueryParams } from 'shared/lib/router/get-query-params';
import { DATE_FORMAT_REVERSE } from 'shared/lib';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';
import { ReportGroupingType } from 'shared/api/services/analytics/rtk/generated';

import { REPORT_PAYMENT_GROUP_BY } from 'entities/report/consts';

import { FormContainer } from './styles';
import { FormInput, FormOutput, FormSchema } from '../../consts';

const getDefaultValues = ({
  mobileAppsId,
  chargePointsId,
  companiesId,
  groupBy,
  groupsId,
  dateFrom,
  dateTo,
}: {
  chargePointsId: string | undefined;
  groupsId: string | undefined;
  companiesId: string | undefined;
  mobileAppsId: string | undefined;
  dateFrom: string | undefined;
  dateTo: string | undefined;
  groupBy: ReportGroupingType | undefined;
}): FormInput => {
  return {
    mobileAppsId: mobileAppsId ? mobileAppsId.split(',') : [],
    chargePointsId: chargePointsId ? chargePointsId.split(',') : [],
    companiesId: companiesId ? companiesId.split(',') : [],
    groupsId: groupsId ? groupsId.split(',') : [],
    groupBy: groupBy ?? '',
    dateRange: [
      dateFrom === undefined ? undefined : dayjs(dateFrom),
      dateTo === undefined ? undefined : dayjs(dateTo),
    ],
  };
};

type Props = {
  chargePointsId: string | undefined;
  groupsId: string | undefined;
  companiesId: string | undefined;
  mobileAppsId: string | undefined;
  dateFrom: string | undefined;
  dateTo: string | undefined;
  groupBy: ReportGroupingType | undefined;

  chargePointGroups: GetGroupVm[];
  chargePoints: GetChargePointVm[];
  companies: CompanyDto[];
  mobileApps: MobileAppVm[];
  isLoading: boolean;
  allowFetch: () => void;
};

export function Form({
  chargePointGroups,
  chargePoints,
  companies,
  mobileApps,
  isLoading,
  chargePointsId,
  companiesId,
  groupBy,
  groupsId,
  mobileAppsId,
  dateFrom,
  dateTo,
  allowFetch,
}: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues({
      chargePointsId,
      companiesId,
      groupBy,
      groupsId,
      mobileAppsId,
      dateFrom,
      dateTo,
    }),
  });

  useEffect(() => {
    form.reset({
      ...getDefaultValues({
        chargePointsId,
        companiesId,
        groupBy,
        groupsId,
        mobileAppsId,
        dateFrom,
        dateTo,
      }),
    });
  }, [
    chargePointsId,
    companiesId,
    groupBy,
    groupsId,
    mobileAppsId,
    dateFrom,
    dateTo,
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = form.handleSubmit(async (data) => {
    allowFetch();

    const queryParams = getQueryParams();

    const {
      chargePointsId,
      mobileAppsId,
      companiesId,
      groupsId,
      groupBy,
      dateRange,
    } = data;

    if (!mobileAppsId.length) {
      delete queryParams['mobileAppsId'];
    } else {
      queryParams['mobileAppsId'] = mobileAppsId.join(',');
    }

    if (!chargePointsId.length) {
      delete queryParams['chargePointsId'];
    } else {
      queryParams['chargePointsId'] = chargePointsId.join(',');
    }

    if (!groupsId.length) {
      delete queryParams['groupsId'];
    } else {
      queryParams['groupsId'] = groupsId.join(',');
    }

    if (!companiesId.length) {
      delete queryParams['companiesId'];
    } else {
      queryParams['companiesId'] = companiesId.join(',');
    }

    if (!groupBy) {
      delete queryParams['groupBy'];
    } else {
      queryParams['groupBy'] = groupBy;
    }

    if (dateRange === null || dateRange === undefined) {
      delete queryParams['dateFrom'];
      delete queryParams['dateTo'];
    } else {
      const from = dateRange[0];
      const to = dateRange[1];

      if (from) {
        queryParams['dateFrom'] = dayjs(from).format(DATE_FORMAT_REVERSE);
      }

      if (to) {
        queryParams['dateTo'] = dayjs(to).format(DATE_FORMAT_REVERSE);
      }
    }

    setSearchParams({
      ...queryParams,
    });
  });

  return (
    <FormContainer>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields
              chargePointGroups={chargePointGroups}
              chargePoints={chargePoints}
              companies={companies}
              mobileApps={mobileApps}
            />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </FormContainer>
  );
}

type FieldsProps = Pick<
  Props,
  'chargePointGroups' | 'chargePoints' | 'companies' | 'mobileApps'
>;

Form.Fields = function Fields({
  chargePointGroups,
  chargePoints,
  companies,
  mobileApps,
}: FieldsProps) {
  // const {
  //   watch,
  //   formState: { errors },
  // } = useFormContext<FormInput, void, FormOutput>();

  // console.log('errors', errors);

  return (
    <>
      <FormRow gutter={15}>
        <Col span={8}>
          <CustomRangePicker<FormInput>
            name="dateRange"
            label="Период от и до"
            placeholder={['Дата начала', 'Дата конца']}
          />
        </Col>

        <Col span={6}>
          <CustomSelect<FormInput>
            name="mobileAppsId"
            options={mobileApps.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Мобильное приложение"
            mode="multiple"
            allowClear
          />
        </Col>

        <Col span={6}>
          <CustomSelect<FormInput>
            name="groupBy"
            options={Object.entries(REPORT_PAYMENT_GROUP_BY).map((entry) => {
              const [key, label] = entry;

              return {
                value: key,
                label,
              };
            })}
            label="Тип группировки"
            required
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="groupsId"
            options={chargePointGroups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Группы"
            mode="multiple"
            allowClear
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="chargePointsId"
            options={chargePoints.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="ЭЗС"
            mode="multiple"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            allowClear
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="companiesId"
            options={companies.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Компании"
            mode="multiple"
            allowClear
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton text="Получить отчет" />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
