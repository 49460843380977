import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';

import { LowerCaseRight } from 'entities/authorization/model/types';

import {
  ChargePointRightTableItem,
  CpGroupRight,
  CpGroupRweRight,
  CpRight,
  CpRweRight,
  CP_PREFIX,
  DELIMETER,
  GROUP_PREFIX,
} from '../consts';

export const getFormRightsArrays = (values: Record<string, boolean>) => {
  return Object.keys(values).reduce(
    (acc, el) => {
      const [prefix, id, type] = el.split(DELIMETER) as [
        string,
        string,
        LowerCaseRight
      ];

      const defaultRights = {
        read: false,
        write: false,
        execute: false,
      };

      const isGroupRight = prefix === GROUP_PREFIX;

      if (isGroupRight) {
        if (acc[0][id]) {
          acc[0][id][type] = values[el];
        } else {
          acc[0][id] = {
            ...defaultRights,
            groupId: id,
            [type]: values[el],
          };
        }
      } else {
        if (acc[1][id]) {
          acc[1][id][type] = values[el];
        } else {
          acc[1][id] = {
            ...defaultRights,
            chargePointId: id,
            [type]: values[el],
          };
        }
      }

      return acc;
    },
    [{}, {}] as [Record<string, CpGroupRweRight>, Record<string, CpRweRight>]
  );
};

const insertIntoTable = ({
  existingRows,
  subRowsToInsert,
  path,
}: {
  existingRows: ChargePointRightTableItem[];
  subRowsToInsert: ChargePointRightTableItem[];
  path: string[];
}): ChargePointRightTableItem[] => {
  const id = path[0];

  const isBaseCase = path.length === 1;

  if (isBaseCase) {
    return existingRows.map((row, index) => {
      const isMatchedRowWithoutSubRows = index === Number(id) && !row.subRows;

      if (isMatchedRowWithoutSubRows) {
        return {
          ...row,
          subRows: subRowsToInsert,
        };
      }

      return row;
    });
  }

  return existingRows.map((row, index) => {
    const isMatchedRowWithSubRows = index === Number(id) && row.subRows;

    if (isMatchedRowWithSubRows) {
      const [, ...updatedPath] = path;

      return {
        ...row,
        subRows: insertIntoTable({
          existingRows: row.subRows,
          subRowsToInsert,
          path: updatedPath,
        }),
      };
    }

    return row;
  });
};

export const recursivelyUpdateTable = ({
  tableData,
  childData,
  id,
}: {
  tableData: ChargePointRightTableItem[];
  childData: ChargePointRightTableItem[];
  id: string;
}) => {
  return insertIntoTable({
    existingRows: tableData,
    subRowsToInsert: childData,
    path: id.split('.'),
  });
};

const recursivelyAddSubgroups = (
  groups: GetGroupVm[],
  groupChargePointRights: CpGroupRight[]
) => {
  const result: ChargePointRightTableItem[] = [];

  groups.forEach((group) => {
    const { id, name, children = [] } = group;

    const groupRight = groupChargePointRights.find(
      (right) => right.groupId === id
    ) as CpGroupRight | undefined;

    if (children.length === 0) {
      const row: ChargePointRightTableItem = {
        id: String(id),
        name,
        isGroup: true,
        read: false,
        write: false,
        execute: false,
      };

      if (groupRight) {
        const { read, write, execute } = groupRight;

        row.read = read;
        row.write = write;
        row.execute = execute;
      }

      return result.push(row);
    }

    return result.push({
      isGroup: true,
      id: String(id),
      name,
      subRows: recursivelyAddSubgroups(children, groupChargePointRights),
      read: false,
      write: false,
      execute: false,
    });
  });

  return result;
};

export const getGroupsRightsTableData = (
  groups: GetGroupVm[],
  groupChargePointRights: CpGroupRight[]
) => {
  return groups.map(({ id, name, children }) => {
    const row: ChargePointRightTableItem = {
      id: String(id),
      name,
      isGroup: true,
      read: false,
      write: false,
      execute: false,
    };

    const groupRight = groupChargePointRights.find(
      (right) => right.groupId === id
    );

    if (groupRight) {
      const { read, write, execute } = groupRight;

      row.read = read;
      row.write = write;
      row.execute = execute;
    }

    if (children.length > 0) {
      row.subRows = recursivelyAddSubgroups(children, groupChargePointRights);
    }

    return row;
  });
};

export const getInitialValues = (
  chargePointRights: CpRight[],
  groupChargePointRights: CpGroupRight[]
) => {
  const acc: Record<string, boolean> = {};

  chargePointRights.forEach((right) => {
    const { chargePointId, read, write, execute } = right;

    acc[`${CP_PREFIX}${DELIMETER}${chargePointId}${DELIMETER}read`] = read;
    acc[`${CP_PREFIX}${DELIMETER}${chargePointId}${DELIMETER}write`] = write;
    acc[`${CP_PREFIX}${DELIMETER}${chargePointId}${DELIMETER}execute`] =
      execute;
  });
  groupChargePointRights.forEach((right) => {
    const { groupId, read, write, execute } = right;

    acc[`${GROUP_PREFIX}${DELIMETER}${groupId}${DELIMETER}read`] = read;
    acc[`${GROUP_PREFIX}${DELIMETER}${groupId}${DELIMETER}write`] = write;
    acc[`${GROUP_PREFIX}${DELIMETER}${groupId}${DELIMETER}execute`] = execute;
  });

  return acc;
};
