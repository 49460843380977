import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { BalanceGroupOutWithCp } from 'shared/api/services/balancing/rtk/generated';
import { useAbility } from 'shared/lib/ability/context';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { useUpdateBalancingGroup } from '../hooks/use-update-balancing-group';

const getDefaultValues = (balancingGroup: BalanceGroupOutWithCp): FormInput => {
  const {
    name,
    power_limit,
    is_balancing_enabled,
    charge_point_ids,
    description,
  } = balancingGroup;

  return {
    name,
    description,
    isActive: is_balancing_enabled,
    powerLimit: String(power_limit),
    chargePointIds: charge_point_ids ?? [],
  };
};

type Props = {
  chargePoints: GetChargePointVm[];
  balancingGroup: BalanceGroupOutWithCp;
};

export function Form({ chargePoints, balancingGroup }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...getDefaultValues(balancingGroup),
    },
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  useEffect(() => {
    form.reset({ ...getDefaultValues(balancingGroup) });
  }, [balancingGroup]);

  const { handleUpdate, isLoading } = useUpdateBalancingGroup();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({
      data,
      groupId: balancingGroup.id,
      isEnabled: balancingGroup.is_balancing_enabled,
    });
  });

  return (
    <UiCard>
      <UiCard.Header>{balancingGroup.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                chargePoints={chargePoints}
                isDisabled={isDisabled}
              />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = Pick<Props, 'chargePoints'> & {
  isDisabled: boolean;
};

Form.Fields = function Fields({ chargePoints, isDisabled }: FieldsProps) {
  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Название"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="powerLimit"
            label="Мощность"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="chargePointIds"
            options={chargePoints.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="ЭЗС"
            mode="multiple"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            allowClear
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormInput>
            name="isActive"
            label="Активировать балансировку"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'BalancingService')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
