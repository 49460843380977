import { useEffect, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useLazyGetChargePoints } from 'shared/api/services/chargepoint/rtk';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';

import {
  ChargePointRightTableItem,
  CpGroupRight,
  getGroupsRightsTableData,
  recursivelyUpdateTable,
} from 'entities/charge-point-rights';

export const useGetFormCps = ({
  groupCpRights,
  groups,
  isAllCpRights,
}: {
  groups: GetGroupVm[];
  groupCpRights: CpGroupRight[];
  isAllCpRights: boolean;
}) => {
  const [tableData, setTableData] = useState<ChargePointRightTableItem[]>([]);

  const [isRowLoading, setIsRowLoading] = useState({});
  const [isRowLoaded, setIsRowLoaded] = useState({});

  const [allChecked, setAllChecked] = useState(isAllCpRights);

  const handleAllCheckedChange = (e: CheckboxChangeEvent) => {
    setAllChecked(e.target.checked);
  };

  const [getChargePointsTrigger] = useLazyGetChargePoints();

  // useEffect(() => {
  //   setAllChecked(isAllCpRights);
  // }, [isAllCpRights]);

  useEffect(() => {
    const tableData = getGroupsRightsTableData(groups, groupCpRights);

    setTableData(tableData);
  }, [groups, groupCpRights]);

  const onClickRow = async (row: Row<ChargePointRightTableItem>) => {
    setIsRowLoading({ [row.id]: true });

    const { error, data } = await getChargePointsTrigger({
      groupId: row.original.id,
    });

    if (error) {
      setIsRowLoading({ [row.id]: false });
      return openErrorNotification(getServerErrorText(error));
    }

    if (data?.data) {
      const preparedData = data.data.map(({ id, name }) => {
        return {
          id,
          name,
          read: false,
          write: false,
          execute: false,
        };
      });

      const updatedTableData = recursivelyUpdateTable({
        tableData,
        childData: preparedData,
        id: row.id,
      });

      setTableData(updatedTableData);
    }

    setIsRowLoading({ [row.id]: false });
    setIsRowLoaded((curr) => ({ ...curr, [row.id]: true }));
  };

  return {
    isRowLoaded,
    isRowLoading,
    onClickRow,
    tableData,
    allChecked,
    handleAllCheckedChange,
  };
};
