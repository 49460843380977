import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    transactions,
    transactionsCount,
    transactionsError,
    transactionsCountError,
  } = useQueries({
    userId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (transactionsError) {
    return <ErrorMessage text={getServerErrorText(transactionsError)} />;
  }

  if (transactionsCountError) {
    return <ErrorMessage text={getServerErrorText(transactionsCountError)} />;
  }

  return (
    <TableInstance
      data={transactions}
      totalCount={transactionsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
