import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const ButtonLabel = styled.p``;

export const IconContainer = styled.div`
  height: 19px;
  aspect-ratio: 1;
`;
