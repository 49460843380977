import { Spin } from 'antd';
import { CardContent, CardHeader, StyledCard, Title } from './styles';

type Props = {
  addConnectorSlot: React.ReactNode;
  connectorListSlot: React.ReactNode;
  isLoading: boolean;
};

export function Layout({
  addConnectorSlot,
  connectorListSlot,
  isLoading,
}: Props) {
  return (
    <StyledCard>
      <Spin spinning={isLoading}>
        <CardHeader>
          <Title>Коннекторы</Title>
          {addConnectorSlot}
        </CardHeader>
        <CardContent>{connectorListSlot}</CardContent>
      </Spin>
    </StyledCard>
  );
}
