import {
  Balance,
  BonusProgramAchievement,
} from 'shared/api/services/bonus/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { BonusBalance } from '../balance';
import { ProgramAchievement } from '../program-achievement';
import { AchievementsContainer, StyledContainer } from './styles';

type Props = {
  userId: string;
  balance: Balance;
  achievements: BonusProgramAchievement[];
  country: GetCountryVm;
  countries: GetCountryVm[];
};

export function BonusInfoByCountry({
  country,
  achievements,
  balance,
  userId,
  countries,
}: Props) {
  const { currencyText = '', currencySymbol = '' } = country || {};

  return (
    <StyledContainer>
      <BonusBalance
        userId={userId}
        balance={balance}
        countries={countries}
        currencyText={currencyText}
      />
      <AchievementsContainer>
        {achievements.map((el, idx) => {
          return (
            <ProgramAchievement
              key={idx}
              achievement={el}
              currencySymbol={currencySymbol}
            />
          );
        })}
      </AchievementsContainer>
    </StyledContainer>
  );
}
