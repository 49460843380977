import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { Form, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';
import { ExpandRowIcon } from 'shared/ui/expand-row-icon';
import { RightsTable } from 'shared/ui/table';
import {
  ButtonsContainer,
  FormControlsContainer,
  UiSubmitButton,
} from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { UiCancelFormButton } from 'shared/ui/buttons';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';

import {
  DELIMETER,
  CP_PREFIX,
  GROUP_PREFIX,
  ChargePointRightTableItem,
  getInitialValues,
  CpRight,
  CpGroupRight,
} from 'entities/charge-point-rights';

import {
  AllCpRightsContainer,
  GroupColumnLabel,
  RightsFormItem,
} from './styles';
import { useUpdateUserCpRights } from '../../hooks/use-update-user-cp-rights';
import { useGetFormCps } from '../../hooks/use-get-form-cps';

const columnHelper = createColumnHelper<ChargePointRightTableItem>();

type Props = {
  groups: GetGroupVm[];
  chargePointRights: CpRight[];
  groupCpRights: CpGroupRight[];
  userName: string;
  isAllCpRights: boolean;
};

export function CpRightsForm({
  groups,
  chargePointRights,
  groupCpRights,
  userName,
  isAllCpRights,
}: Props) {
  const ability = useAbility();

  const hasWriteRight = ability.can(
    'Write',
    'ChargePointServiceChargePointRights'
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { handleUpdate, isLoading } = useUpdateUserCpRights();

  const {
    isRowLoaded,
    isRowLoading,
    onClickRow,
    tableData,
    allChecked,
    handleAllCheckedChange,
  } = useGetFormCps({
    groupCpRights,
    groups,
    isAllCpRights,
  });

  const initialValues = useMemo(
    () => getInitialValues(chargePointRights, groupCpRights),
    [chargePointRights, groupCpRights]
  );

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    table.toggleAllRowsExpanded(false);
  }, [initialValues]);

  const [form] = Form.useForm<Record<string, boolean>>();

  const columns = [
    columnHelper.accessor('name', {
      header: 'ЭЗС',
      cell: (props) => {
        const { row } = props;

        const isLoading = Boolean(isRowLoading[row.id]);
        const isExpanded = Boolean(expanded[row.id]);

        const onClick = async (_event: React.MouseEvent<HTMLDivElement>) => {
          if (!isLoading) {
            const shouldFetch =
              !isExpanded &&
              !(row.id in isRowLoading) &&
              !(row.id in isRowLoaded);

            if (shouldFetch) {
              await onClickRow(row);
            }

            row.toggleExpanded();
          }
        };

        return (
          <div
            {...{
              style: {
                paddingLeft: `${row.depth * 2 * 10}px`,
              },
            }}
            {...(row.original.isGroup ? { onClick } : {})}
          >
            <Spin spinning={isLoading}>
              <GroupColumnLabel>
                {props.row.original.name}
                {props.row.original.isGroup ? (
                  row.getIsExpanded() ? (
                    <ExpandRowIcon isOpen />
                  ) : (
                    <ExpandRowIcon />
                  )
                ) : null}
              </GroupColumnLabel>
            </Spin>
          </div>
        );
      },
    }),
    columnHelper.accessor('read', {
      header: 'Чтение',
      cell: (props) => {
        if (props.row.original.isGroup) {
          return (
            <RightsFormItem
              name={`${GROUP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}read`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={allChecked} />
            </RightsFormItem>
          );
        }

        return (
          <RightsFormItem
            name={`${CP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}read`}
            valuePropName="checked"
          >
            <UiCheckbox disabled={allChecked} />
          </RightsFormItem>
        );
      },
    }),
    columnHelper.accessor('write', {
      header: 'Запись',
      cell: (props) => {
        if (props.row.original.isGroup) {
          return (
            <RightsFormItem
              name={`${GROUP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}write`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={allChecked} />
            </RightsFormItem>
          );
        }

        return (
          <RightsFormItem
            name={`${CP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}write`}
            valuePropName="checked"
          >
            <UiCheckbox disabled={allChecked} />
          </RightsFormItem>
        );
      },
    }),
    columnHelper.accessor('execute', {
      header: 'Выполнение',
      cell: (props) => {
        if (props.row.original.isGroup) {
          return (
            <RightsFormItem
              name={`${GROUP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}execute`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={allChecked} />
            </RightsFormItem>
          );
        }

        return (
          <RightsFormItem
            name={`${CP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}execute`}
            valuePropName="checked"
          >
            <UiCheckbox disabled={allChecked} />
          </RightsFormItem>
        );
      },
    }),
  ];

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      expanded,
    },
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const onFinish = (_values: Record<string, boolean>) => {
    const formValues = form.getFieldsValue(true);

    handleUpdate(userName, formValues, allChecked);
  };

  return (
    <>
      <AllCpRightsContainer>
        <UiCheckbox checked={allChecked} onChange={handleAllCheckedChange}>
          Доступ ко всем ЭЗС
        </UiCheckbox>
      </AllCpRightsContainer>
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <RightsTable table={table} />
        {hasWriteRight ? (
          <FormControlsContainer>
            <ButtonsContainer spinning={isLoading}>
              <UiSubmitButton />
              <UiCancelFormButton />
            </ButtonsContainer>
          </FormControlsContainer>
        ) : null}
      </Form>
    </>
  );
}
