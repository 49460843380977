import { Permission } from 'features/sidebar/types';

export const SUPERUSER_ROLE = 'Admin';

export const CONTROLLERS = [
  'ChargePointServiceChargePoint',
  'ChargePointServiceChargePointRights',
  'ChargePointServiceTariff',
  'ChargePointServiceConnector',
  'ChargePointServiceHubs',
  'ChargePointServiceFirmware',
  'ChargePointServiceGroup',
  'AnalyticsService',
  'ArmeniaPay',
  'BalancingService',
  'BankOfGeorgiaConsumers',
  'BankOfGeorgia',
  'Bff',
  'BillingService',
  'BonusService',
  'CompanyService',
  'CpSoapController',
  // 'EpayPayService',
  'FiscalBusinessRu',
  'FiscalOnlineHdMam',
  'FiscalService',
  'IdentityService',
  'InformationService',
  'MtsConsumers',
  'MtsGate',
  'OcpIService',
  'PaymentService',
  'PushNotificationService',
  'ReserveService',
  'SmsSender',
  // 'SoapRestEmulator',
  'UserService',
  'DiscountService',
] as const;

export type Controller = typeof CONTROLLERS[number];

export const CONTROLLER: Array<{ name: Controller; label: string }> = [
  {
    name: 'ChargePointServiceChargePoint',
    label: 'Зарядные станции (общая карточка)',
  },
  {
    name: 'ChargePointServiceChargePointRights',
    label: 'Зарядные станции (Права)',
  },
  { name: 'ChargePointServiceTariff', label: 'Зарядные станции (Тарифы)' },
  {
    name: 'ChargePointServiceConnector',
    label: 'Зарядные станции (Коннекторы)',
  },
  { name: 'ChargePointServiceHubs', label: 'Зарядные станции (Хабы)' },
  { name: 'ChargePointServiceFirmware', label: 'Прошивки' },
  { name: 'ChargePointServiceGroup', label: 'Зарядные станции (Группы)' },
  { name: 'AnalyticsService', label: 'Отчеты' },
  { name: 'ArmeniaPay', label: 'Банк Армении' },
  { name: 'BalancingService', label: 'Балансировка мощности' },
  {
    name: 'BankOfGeorgiaConsumers',
    label: 'BankOfGeorgia (очереди)',
  },
  {
    name: 'BankOfGeorgia',
    label: 'BankOfGeorgia ',
  },
  {
    name: 'Bff',
    label: 'Мобильный шлюз (BFF)',
  },
  { name: 'BillingService', label: 'Тарифы (биллинги)' },
  { name: 'BonusService', label: 'Бонусные баллы' },
  {
    name: 'CompanyService',
    label: 'Контрагенты',
  },
  {
    name: 'CpSoapController',
    label: 'Контроллер SOAP ЭЗС',
  },
  // { name: 'EpayPayService', label: 'EpayPayService' },
  { name: 'FiscalBusinessRu', label: 'Фискальный шлюз Business.ru' },
  { name: 'FiscalOnlineHdMam', label: 'Фискальный шлюз (Армения)' },
  { name: 'FiscalService', label: 'Фискальный сервис' },
  {
    name: 'IdentityService',
    label: 'Сервис авторизации',
  },
  {
    name: 'InformationService',
    label: 'Справочник информации',
  },
  {
    name: 'MtsConsumers',
    label: 'МТС банк (очереди)',
  },
  {
    name: 'MtsGate',
    label: 'МТС банк ',
  },
  {
    name: 'OcpIService',
    label: 'OCPI',
  },
  {
    name: 'PaymentService',
    label: 'Сервис оплат',
  },
  {
    name: 'PushNotificationService',
    label: 'PUSH-уведомления',
  },
  {
    name: 'ReserveService',
    label: 'Сервис бронирования',
  },
  {
    name: 'SmsSender',
    label: 'СМС Шлюз',
  },
  // {
  //   name: 'SoapRestEmulator',
  //   label: 'SoapRestEmulator',
  // },
  {
    name: 'UserService',
    label: 'Клиенты',
  },
  {
    name: 'DiscountService',
    label: 'Скидки',
  },
];

export const RIGHTS = ['read', 'write', 'execute'] as const;

//

export const ADD_CP_PERMISSIONS: Permission[] = [
  {
    controller: 'ChargePointServiceChargePoint',
    right: 'Write',
  },
  { controller: 'CompanyService', right: 'Read' },
  { controller: 'BillingService', right: 'Read' },
  { controller: 'InformationService', right: 'Read' },
  { controller: 'ChargePointServiceHubs', right: 'Read' },
];
