import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { useAbility } from 'shared/lib/ability/context';
import { GROUPS } from 'shared/consts/names';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';

import { DeleteChargePointGroupModal } from '../../delete/ui';

type Props = {
  group: GetGroupVm;
};

export function GroupTableActions({ group }: Props) {
  const ability = useAbility();

  const navigate = useNavigate();

  const showDeleteModal = () => {
    NiceModal.show(DeleteChargePointGroupModal, { group });
  };

  const content = (
    <>
      <button
        onClick={() => {
          navigate(
            `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${GROUPS}/${group.id}`
          );
        }}
      >
        Редактировать
      </button>
      {ability.can('Write', 'ChargePointServiceGroup') && (
        <button onClick={showDeleteModal}>Удалить</button>
      )}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
