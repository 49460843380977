import { useSelector } from 'react-redux';
import { defineAbility, MongoQuery, PureAbility } from '@casl/ability';
import jwt_decode from 'jwt-decode';

import { selectCurrentToken } from 'entities/authorization';
import { Controller, Right } from 'entities/authorization';
import { AccessToken } from 'entities/authorization/model/token';
import { SUPERUSER_ROLE } from 'entities/authorization/consts';

import { createStrictContext, useStrictContext } from '../react';

type Actions = Right | 'manage';
type Subjects = Controller | 'all';

type Ability = [Actions, Subjects];

type AppAbility = PureAbility<Ability, MongoQuery>;

const abilityContext = createStrictContext<AppAbility>();

type Props = {
  children?: React.ReactNode;
};

export function AbilityContextProvider({ children }: Props) {
  const token = useSelector(selectCurrentToken) as AccessToken | '';

  const ability = defineAbility<AppAbility>((can) => {
    const {
      Roles,
      Read,
      Execute,
      ReadExecute,
      ReadWrite,
      ReadWriteExecute,
      Write,
      WriteExecute,
    } = jwt_decode(token) as AccessToken;

    if (
      Roles === SUPERUSER_ROLE ||
      (Array.isArray(Roles) && Roles.includes(SUPERUSER_ROLE))
    ) {
      can('manage', 'all');
    } else {
      Read && can('Read', Read);
      Write && can('Write', Write);
      Execute && can('Execute', Execute);

      ReadWrite && can(['Read', 'Write'], ReadWrite);
      ReadExecute && can(['Read', 'Execute'], ReadExecute);
      WriteExecute && can(['Write', 'Execute'], WriteExecute);

      ReadWriteExecute && can('manage', ReadWriteExecute);
    }
  });

  return (
    <abilityContext.Provider value={ability}>
      {children}
    </abilityContext.Provider>
  );
}

export const useAbility = () => useStrictContext(abilityContext);
