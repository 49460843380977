import { useEffect } from 'react';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { useTableLimit } from 'shared/hooks/use-table-limit';

import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';
import { useQueries } from '../hooks/use-queries';

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    bindingLogs,
    bindingLogsCount,
    mobileAppsMapping,
    bindingLogsError,
    bindingLogsCountError,
    mobileAppsError,
  } = useQueries({
    userId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (mobileAppsError) {
    openErrorNotification(getServerErrorText(mobileAppsError));
  }

  if (bindingLogsError) {
    return <ErrorMessage text={getServerErrorText(bindingLogsError)} />;
  }

  if (bindingLogsCountError) {
    return <ErrorMessage text={getServerErrorText(bindingLogsCountError)} />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={bindingLogs}
      totalCount={bindingLogsCount}
      mobileAppsMapping={mobileAppsMapping}
      tableLimit={tableLimit}
    />
  );
}
