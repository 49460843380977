import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={FIRMWARE_ROUTES.ADD_FIRMWARE}
      permissions={[
        {
          controller: 'ChargePointServiceFirmware',
          right: 'Write',
        },
      ]}
    />
  );
}
