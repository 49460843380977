import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { PaymentsTable } from 'features/user/payments-table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UserPaymentsPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <PaymentsTable userId={userId} />;
}
