import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from './hooks/use-queries';
import { selectTableLimit, setTableLimit } from './store/slice';
import { TableInstance } from './table';

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    cards,
    cardsCount,
    cardsCountError,
    cardsError,
    isFetching,
    isLoading,
  } = useQueries({ userId, limit: tableLimit });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (cardsError) {
    return <ErrorMessage text={getServerErrorText(cardsError)} />;
  }

  if (cardsCountError) {
    return <ErrorMessage text={getServerErrorText(cardsCountError)} />;
  }

  return (
    <TableInstance
      data={cards}
      totalCount={cardsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
