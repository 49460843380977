import { enhancedApi as api } from '../generated/user-cp-rights';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1UserCpRights: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'UserCpRights', id: arg },
      ];
    },
    getApiChargepointV1UserCpRightsIsAllCpRights: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'isAllCpRights', id: arg.userName || arg.userRole },
      ];
    },
  },
});

export const {
  useGetApiChargepointV1UserCpRightsQuery: useGetUserCpRights,
  usePostApiChargepointV1UserCpRightsSetUserCpRightsMutation:
    useSetUserCpRights,
  useGetApiChargepointV1UserCpRightsIsAllCpRightsQuery: useGetIsAllCpRights,
} = enhancedApi;
