import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  chargePointId: string;
};

export function TableContainer({ chargePointId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    chargePointError,
    connectors,
    events,
    eventsCount,
    eventsCountError,
    eventsError,
  } = useQueries({
    chargePointId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (chargePointError) {
    openErrorNotification(getServerErrorText(chargePointError));
  }

  if (eventsError) {
    return <ErrorMessage text={getServerErrorText(eventsError)} />;
  }

  if (eventsCountError) {
    return <ErrorMessage text={getServerErrorText(eventsCountError)} />;
  }

  return (
    <TableInstance
      data={events}
      totalCount={eventsCount}
      loading={isFetching}
      tableLimit={tableLimit}
      connectors={connectors}
    />
  );
}
