import { useAbility } from 'shared/lib/ability/context';
import { Container } from './container';

type Props = {
  userId: string;
};

export function BillingBalances({ userId }: Props) {
  const ability = useAbility();

  if (
    !ability.can('Read', 'BillingService') ||
    !ability.can('Read', 'InformationService')
  ) {
    return <div>Не хватает прав</div>;
  }

  return <Container userId={userId} />;
}
