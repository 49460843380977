import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';
import { GetCarModelVm } from 'shared/api/services/information/rtk/generated/car-models';

import { DeleteCarModelModal } from 'features/car-model/delete';

type Props = {
  carModel: GetCarModelVm;
};

export function Actions({ carModel }: Props) {
  const ability = useAbility();

  const navigate = useNavigate();

  const { carBrandId, id } = carModel;

  const showDeleteModal = () => {
    NiceModal.show(DeleteCarModelModal, { carModel });
  };

  const content = (
    <>
      <button
        onClick={() =>
          navigate(`${CAR_BRAND_ROUTES.CAR_BRANDS}/${carBrandId}/${id}`)
        }
      >
        Редактировать
      </button>
      {ability.can('Write', 'InformationService') && (
        <button onClick={showDeleteModal}>Удалить</button>
      )}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
