import { postApiUserV1GroupsBody } from 'shared/api/services/user/orval/zod/schemas';
import { z } from 'zod';

export const FormSchema = postApiUserV1GroupsBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  comment: '',
  parentId: undefined,
  isDefault: false,
};
