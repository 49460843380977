import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createBalanceGroupApiBalancingBalanceGroupsPost: build.mutation<
      CreateBalanceGroupApiBalancingBalanceGroupsPostApiResponse,
      CreateBalanceGroupApiBalancingBalanceGroupsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/balance_groups/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    listBalanceGroupsApiBalancingBalanceGroupsGet: build.query<
      ListBalanceGroupsApiBalancingBalanceGroupsGetApiResponse,
      ListBalanceGroupsApiBalancingBalanceGroupsGetApiArg
    >({
      query: () => ({ url: `/api/balancing/balance_groups/` }),
    }),
    updateBalanceGroupApiBalancingBalanceGroupsPatch: build.mutation<
      UpdateBalanceGroupApiBalancingBalanceGroupsPatchApiResponse,
      UpdateBalanceGroupApiBalancingBalanceGroupsPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/balance_groups/`,
        method: 'PATCH',
        body: queryArg.balanceGroupUpdate,
        params: {
          group_id: queryArg.groupId,
        },
      }),
    }),
    getActiveBalanceGroupsApiBalancingBalanceGroupsActiveGet: build.query<
      GetActiveBalanceGroupsApiBalancingBalanceGroupsActiveGetApiResponse,
      GetActiveBalanceGroupsApiBalancingBalanceGroupsActiveGetApiArg
    >({
      query: () => ({ url: `/api/balancing/balance_groups/active` }),
    }),
    getBalanceGroupApiBalancingBalanceGroupsGroupIdGet: build.query<
      GetBalanceGroupApiBalancingBalanceGroupsGroupIdGetApiResponse,
      GetBalanceGroupApiBalancingBalanceGroupsGroupIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/balance_groups/${queryArg}`,
      }),
    }),
    updateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPut: build.mutation<
      UpdateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPutApiResponse,
      UpdateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/balance_groups/${queryArg.groupId}`,
        method: 'PUT',
        body: queryArg.balanceGroupUpdateWithCp,
      }),
    }),
    deleteBalanceGroupApiBalancingBalanceGroupsGroupIdDelete: build.mutation<
      DeleteBalanceGroupApiBalancingBalanceGroupsGroupIdDeleteApiResponse,
      DeleteBalanceGroupApiBalancingBalanceGroupsGroupIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/balance_groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    toggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatch:
      build.mutation<
        ToggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatchApiResponse,
        ToggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/balancing/balance_groups/${queryArg.groupId}/toggle_balancing`,
          method: 'PATCH',
          params: {
            state: queryArg.state,
          },
        }),
      }),
    listChargePointsApiBalancingChargePointsGet: build.query<
      ListChargePointsApiBalancingChargePointsGetApiResponse,
      ListChargePointsApiBalancingChargePointsGetApiArg
    >({
      query: () => ({ url: `/api/balancing/charge_points/` }),
    }),
    updateChargePointApiBalancingChargePointsPatch: build.mutation<
      UpdateChargePointApiBalancingChargePointsPatchApiResponse,
      UpdateChargePointApiBalancingChargePointsPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/charge_points/`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getChargePointApiBalancingChargePointsChargePointIdGet: build.query<
      GetChargePointApiBalancingChargePointsChargePointIdGetApiResponse,
      GetChargePointApiBalancingChargePointsChargePointIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/balancing/charge_points/${queryArg}`,
      }),
    }),
    deleteChargePointApiBalancingChargePointsChargePointIdDelete:
      build.mutation<
        DeleteChargePointApiBalancingChargePointsChargePointIdDeleteApiResponse,
        DeleteChargePointApiBalancingChargePointsChargePointIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/balancing/charge_points/${queryArg}`,
          method: 'DELETE',
        }),
      }),
    listChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGet:
      build.query<
        ListChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGetApiResponse,
        ListChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/balancing/charge_points/by_group_id/${queryArg}`,
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type CreateBalanceGroupApiBalancingBalanceGroupsPostApiResponse =
  /** status 200 Successful Response */ BalanceGroupOut;
export type CreateBalanceGroupApiBalancingBalanceGroupsPostApiArg =
  BalanceGroupCreate;
export type ListBalanceGroupsApiBalancingBalanceGroupsGetApiResponse =
  /** status 200 Successful Response */ BalanceGroupOut[];
export type ListBalanceGroupsApiBalancingBalanceGroupsGetApiArg = void;
export type UpdateBalanceGroupApiBalancingBalanceGroupsPatchApiResponse =
  /** status 200 Successful Response */ BalanceGroupOut;
export type UpdateBalanceGroupApiBalancingBalanceGroupsPatchApiArg = {
  groupId: string;
  balanceGroupUpdate: BalanceGroupUpdate;
};
export type GetActiveBalanceGroupsApiBalancingBalanceGroupsActiveGetApiResponse =
  /** status 200 Successful Response */ BalanceGroupOut[];
export type GetActiveBalanceGroupsApiBalancingBalanceGroupsActiveGetApiArg =
  void;
export type GetBalanceGroupApiBalancingBalanceGroupsGroupIdGetApiResponse =
  /** status 200 Successful Response */ BalanceGroupOutWithCp;
export type GetBalanceGroupApiBalancingBalanceGroupsGroupIdGetApiArg = string;
export type UpdateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPutApiResponse =
  /** status 200 Successful Response */ BalanceGroupOut;
export type UpdateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPutApiArg =
  {
    groupId: string;
    balanceGroupUpdateWithCp: BalanceGroupUpdateWithCp;
  };
export type DeleteBalanceGroupApiBalancingBalanceGroupsGroupIdDeleteApiResponse =
  unknown;
export type DeleteBalanceGroupApiBalancingBalanceGroupsGroupIdDeleteApiArg =
  string;
export type ToggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatchApiResponse =
  /** status 200 Successful Response */ any;
export type ToggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatchApiArg =
  {
    groupId: string;
    state: BalancingStateEnum;
  };
export type ListChargePointsApiBalancingChargePointsGetApiResponse =
  /** status 200 Successful Response */ ChargePointOut[];
export type ListChargePointsApiBalancingChargePointsGetApiArg = void;
export type UpdateChargePointApiBalancingChargePointsPatchApiResponse =
  /** status 200 Successful Response */ ChargePointOut;
export type UpdateChargePointApiBalancingChargePointsPatchApiArg =
  ChargePointUpdate;
export type GetChargePointApiBalancingChargePointsChargePointIdGetApiResponse =
  /** status 200 Successful Response */ ChargePointOut;
export type GetChargePointApiBalancingChargePointsChargePointIdGetApiArg =
  string;
export type DeleteChargePointApiBalancingChargePointsChargePointIdDeleteApiResponse =
  /** status 200 Successful Response */ object;
export type DeleteChargePointApiBalancingChargePointsChargePointIdDeleteApiArg =
  string;
export type ListChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGetApiResponse =
  /** status 200 Successful Response */ ChargePointOut[];
export type ListChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGetApiArg =
  string;
export type BalanceGroupOut = {
  id: string;
  name: string;
  power_limit: number;
  charging_station_count: number;
  is_balancing_enabled: boolean;
  description: string | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ChargePointIn = {
  id: string;
  default_power: number;
};
export type BalanceGroupCreate = {
  name: string;
  power_limit: number;
  description: string | null;
  charge_points?: ChargePointIn[] | null;
};
export type BalanceGroupUpdate = {
  name?: string;
  power_limit?: number;
  charging_station_count?: number | null;
  description?: string | null;
};
export type BalanceGroupOutWithCp = {
  id: string;
  name: string;
  power_limit: number;
  is_balancing_enabled: boolean;
  description: string | null;
  charging_station_count: number;
  charge_point_ids?: string[] | null;
};
export type BalanceGroupUpdateWithCp = {
  name: string;
  power_limit: number;
  description?: string | null;
  charge_points: ChargePointIn[];
};
export type BalancingStateEnum = 'ACTIVATED' | 'DEACTIVATED';
export type ChargePointOut = {
  id: string;
  max_power?: number | null;
  is_charging?: boolean | null;
  default_power: number;
  balance_group_id: string;
};
export type ChargePointUpdate = {
  id: string;
  max_power?: number;
  is_charging?: boolean;
  default_power?: number;
  balance_group_id?: string | null;
};
export const {
  useCreateBalanceGroupApiBalancingBalanceGroupsPostMutation,
  useListBalanceGroupsApiBalancingBalanceGroupsGetQuery,
  useUpdateBalanceGroupApiBalancingBalanceGroupsPatchMutation,
  useGetActiveBalanceGroupsApiBalancingBalanceGroupsActiveGetQuery,
  useGetBalanceGroupApiBalancingBalanceGroupsGroupIdGetQuery,
  useUpdateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPutMutation,
  useDeleteBalanceGroupApiBalancingBalanceGroupsGroupIdDeleteMutation,
  useToggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatchMutation,
  useListChargePointsApiBalancingChargePointsGetQuery,
  useUpdateChargePointApiBalancingChargePointsPatchMutation,
  useGetChargePointApiBalancingChargePointsChargePointIdGetQuery,
  useDeleteChargePointApiBalancingChargePointsChargePointIdDeleteMutation,
  useListChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGetQuery,
} = injectedRtkApi;
