import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { getErrorMessage } from 'shared/lib/http';
import {
  enhancedApi,
  useDeleteChargePoint,
} from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const SUCCESS_MSG = 'ЭЗС удалена';

type Props = {
  chargePointId: string;
};

export const Modal = NiceModal.create(({ chargePointId }: Props) => {
  const modal = useModal();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [trigger, { isLoading }] = useDeleteChargePoint();

  const handleDelete = async () => {
    try {
      const res = await trigger(chargePointId).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'ChargePoints', id: 'LIST' }])
      );
    } catch (err) {
      const errorText = getErrorMessage(err);

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить ЭЗС?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
