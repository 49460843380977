import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { useGetTargetsServicesLists } from 'shared/api/services/billing/rtk/enhanced';
import { BILLING_ROUTES } from 'shared/consts/routes/billing';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { useAbility } from 'shared/lib/ability/context';

import {
  CHARGE_POINT_STATUS,
  CHARGE_POINT_SPEED_TYPE_BADGE,
} from 'entities/charge-point';

import {
  StyledCard,
  CardHeader,
  StatusBadge,
  ParamLabel,
  Param,
  ParamValue,
  ParamsContainer,
  CardHeaderContent,
  ChargePointName,
  CardContent,
  TypeBadge,
  OwnerInfoContainer,
  OwnerIcon,
  MaxPowerContainer,
  ChargePointTitle,
  ServicesListContainer,
  ServicesListButton,
  ServicesListTitleContainer,
  ServicesListTitle,
} from './styles';
import { ExclamationIcon } from '../../icons/exclamation';
import { ServicesListModal } from '../services-list-info/modal';

type Props = {
  chargePoint: GetChargePointVm;
  countries: GetCountryVm[];
  actionsSlot: React.ReactNode;
};

export function Layout({ chargePoint, actionsSlot, countries }: Props) {
  const { name, status, speedType, maxPower, address, id } = chargePoint;

  const ability = useAbility();

  const targetsServicesListsQuery = useGetTargetsServicesLists([id]);

  const renderedMaxPower = () => {
    let base = <ParamValue>{`${maxPower} кВт`}</ParamValue>;

    if (speedType === 'UNDEFINED') {
      return base;
    }

    return (
      <>
        {base}
        <span>&#x2022;</span>
        <TypeBadge
          speedType={speedType}
        >{` ${CHARGE_POINT_SPEED_TYPE_BADGE[speedType]}`}</TypeBadge>
      </>
    );
  };

  const renderServicesList = (chargePointName: string) => {
    const servicesList = targetsServicesListsQuery.data?.data?.[0];

    if (targetsServicesListsQuery.error) {
      openErrorNotification(
        getServerErrorText(targetsServicesListsQuery.error)
      );
    }

    if (targetsServicesListsQuery.data?.status === 'ERROR') {
      openErrorNotification(
        targetsServicesListsQuery.data?.statusMessage ?? 'Неизвестная ошибка'
      );
    }

    if (!servicesList) {
      return null;
    }

    const showModal = () => {
      const title = (
        <ServicesListTitleContainer>
          <ServicesListTitle>Тариф ЭЗС</ServicesListTitle>
          <StatusBadge status={status}>{`ЭЗС ${chargePointName}`}</StatusBadge>
        </ServicesListTitleContainer>
      );

      NiceModal.show(ServicesListModal, { title, servicesList, countries });
    };

    const { name, servicesListId } = servicesList;

    const servicesListName = ability.can('Execute', 'BillingService') ? (
      <Link to={`${BILLING_ROUTES.LIST}/${servicesListId}`}>{name}</Link>
    ) : (
      name
    );

    return (
      <ServicesListContainer>
        {servicesListName}
        <ServicesListButton onClick={showModal}>
          <ExclamationIcon />
        </ServicesListButton>
      </ServicesListContainer>
    );
  };

  return (
    <>
      <StyledCard>
        <CardContent>
          <CardHeader>
            <CardHeaderContent>
              <StatusBadge status={status}>
                {CHARGE_POINT_STATUS[status]}
              </StatusBadge>
              {actionsSlot}
            </CardHeaderContent>
            <ChargePointTitle>
              <ParamLabel>ЭЗС</ParamLabel>
              <ChargePointName>{name}</ChargePointName>
            </ChargePointTitle>
          </CardHeader>
          <ParamsContainer>
            <Param>
              <Spin spinning={targetsServicesListsQuery.isLoading}>
                <ParamLabel>Тариф</ParamLabel>
                <ParamValue style={{ minHeight: '22px' }}>
                  {renderServicesList(name)}
                </ParamValue>
              </Spin>
            </Param>
            <Param>
              <ParamLabel>Мощность</ParamLabel>
              <MaxPowerContainer>{renderedMaxPower()}</MaxPowerContainer>
            </Param>
            <Param>
              <ParamLabel>Адрес</ParamLabel>
              <ParamValue>{address}</ParamValue>
            </Param>
          </ParamsContainer>
        </CardContent>
      </StyledCard>
    </>
  );
}
