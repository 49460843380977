import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

const FormResultSchema = z.object({
  name: z.string().min(3).max(100),
  description: z.string().nullish(),
  powerLimit: z.number().min(1).max(1500),
  chargePointIds: z.array(z.string()),
  isActive: z.boolean(),
});

export const FormSchema = z
  .object({
    name: z.string().transform(emptyStringToUndefined),
    description: z.string().transform(emptyStringToUndefined).nullish(),
    powerLimit: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
    chargePointIds: z.array(z.string()),
    isActive: z.boolean(),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  powerLimit: '',
  chargePointIds: [],
  isActive: false,
};
