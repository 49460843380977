import {
  PaymentErrorReason,
  PaymentOperation,
  PaymentStatus,
} from 'shared/api/services/payment/rtk/generated';

export const PAYMENT_OPERATION: Record<PaymentOperation, string> = {
  HOLDING: 'HOLDING',
  PAYMENT: 'PAYMENT',
};

export const PAYMENT_STATUS: Record<PaymentStatus, string> = {
  DEPOSITED: 'DEPOSITED',
  ERROR: 'ERROR',
  ERROR_PAYMENT: 'ERROR_PAYMENT',
  HOLD: 'HOLD',
  IN_PROCESS: 'IN_PROCESS',
  NEW: 'NEW',
  PAID: 'PAID',
  REVERSE: 'REVERSE',
  UNBINDED: 'UNBINDED',
};

export const PAYMENT_ERROR_REASON: Record<PaymentErrorReason, string> = {
  BANK_SERVICE_IS_UNAVAILABLE: 'BANK_SERVICE_IS_UNAVAILABLE',
  NOT_ENOUGH_MONEY: 'NOT_ENOUGH_MONEY',
  PAY_SERVICE_ERROR: 'PAY_SERVICE_ERROR',
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',
  UNDEFINED: 'UNDEFINED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  ORDER_NUMBER_NOT_FOUND: 'ORDER_NUMBER_NOT_FOUND',
};

export const PAYMENT_DEFAULT_SEARCH_PARAMS =
  'orderingField=lastUpdate&orderingType=DESC';
