import { useState } from 'react';

import {
  enhancedApi,
  useToggleBalancing,
  useUpdateBalancingGroupMutation,
} from 'shared/api/services/balancing/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Балансировка обновлена';
const ERROR_MSG = 'Не удалось обновить балансировку';

export const useUpdateBalancingGroup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [updateTrigger] = useUpdateBalancingGroupMutation();
  const [toggleTrigger] = useToggleBalancing();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    data,
    groupId,
    isEnabled,
  }: {
    data: FormOutput;
    groupId: string;
    isEnabled: boolean;
  }) => {
    const { isActive, chargePointIds, name, powerLimit, description } = data;

    const updateBody = {
      name,
      power_limit: powerLimit,
      description,
      charge_points: chargePointIds.map((el) => {
        return {
          id: el,
          default_power: powerLimit,
        };
      }),
    };

    try {
      setIsLoading(true);

      const updateRes = await updateTrigger({
        balanceGroupUpdateWithCp: updateBody,
        groupId,
      }).unwrap();

      if (isActive !== isEnabled) {
        const toggleRes = await toggleTrigger({
          groupId,
          state: isActive ? 'ACTIVATED' : 'DEACTIVATED',
        });
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BalancingGroups', id: 'LIST' },
          { type: 'BalancingGroups', id: groupId },
          { type: 'BalancingGroupChargePoints', id: groupId },
        ])
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleUpdate,
  };
};
