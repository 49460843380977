import { Col, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  BalanceDto,
  BalanceName,
} from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { FormRow } from 'shared/ui/form';
import { UiInput } from 'shared/ui/ui-kit';
import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';
import { useAbility } from 'shared/lib/ability/context';

import { ManageBillingBalanceButton } from 'features/user/manage-balances';

import { StyledLabel, StyledLayout } from './styles';
import { useToggleFreeze } from '../../hooks/use-toggle-freeze';

const getBalanceNameRender = (name: BalanceName) => {
  switch (name) {
    case 'BUSINESS':
      return 'бизнес';
    case 'PERSONAL':
      return 'физ. лицо';
  }
};

type Props = {
  balance: BalanceDto;
  countries: GetCountryVm[];
};

export function BillingBalance({ balance, countries }: Props) {
  const { lastUpdate, id, countryId, value, isFrozen, userId, name } = balance;

  const country = countries.find((el) => el.id === countryId);

  const { currencyText = '' } = country || {};

  const ability = useAbility();

  const hasWritePermission = ability.can('Write', 'BonusService');

  const { isLoading, toggleFreeze } = useToggleFreeze(id, userId);

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    toggleFreeze(e.target.checked);
  };

  return (
    <StyledLayout>
      <div>
        <StyledLabel>{`Баланс, (${currencyText}, ${getBalanceNameRender(
          name
        )})`}</StyledLabel>
        <FormRow gutter={10}>
          <Col span={6}>
            <UiInput value={value} disabled style={{ height: '100%' }} />
          </Col>
          {hasWritePermission && (
            <Col>
              <ManageBillingBalanceButton balanceId={id} userId={userId} />
            </Col>
          )}
        </FormRow>
      </div>
      <StyledLabel>{`Обновлено: ${showTimeString(
        lastUpdate,
        FORMAT_TO_SECONDS
      )}`}</StyledLabel>
      <Spin spinning={isLoading}>
        <UiCheckbox
          checked={isFrozen}
          onChange={onCheckboxChange}
          style={{ backgroundColor: '#F5F5F5', padding: '6px' }}
          disabled={!hasWritePermission}
        >
          Заблокирован
        </UiCheckbox>
      </Spin>
    </StyledLayout>
  );
}
