import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';
import { useSetUserCpRights } from 'shared/api/services/chargepoint/rtk/enhanced/user-cp-rights';
import { useSetUserGroupCpRights } from 'shared/api/services/chargepoint/rtk/enhanced/user-group-cp-rights';
import { baseApi } from 'shared/api/base';

import { getFormRightsArrays } from 'entities/charge-point-rights';

const SUCCESS_MSG = 'Права на ЭЗС обновлены';
const ERROR_MSG = 'Не удалось обновить права на ЭЗС';

export const useUpdateUserCpRights = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [cpRightsTrigger] = useSetUserCpRights();
  const [groupCpRightsTrigger] = useSetUserGroupCpRights();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleUpdate = async (
    userName: string,
    values: Record<string, boolean>,
    allChecked: boolean
  ) => {
    setIsLoading(true);

    const [groupFormRights, cpFormRights] = getFormRightsArrays(values);

    const groupRightsArray = Object.values(groupFormRights);
    const cpRightsArray = Object.values(cpFormRights);

    try {
      const cpRes = await cpRightsTrigger({
        userName,
        allCpRight: allChecked,
        cpRights: cpRightsArray,
      }).unwrap();

      const groupRes = await groupCpRightsTrigger({
        userName,
        allCpRight: allChecked,
        groupCpRights: groupRightsArray,
      }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      dispatch(
        baseApi.util.invalidateTags([{ type: 'UserCpRights', id: userName }])
      );
      dispatch(
        baseApi.util.invalidateTags([
          { type: 'UserGroupCpRights', id: userName },
        ])
      );
      dispatch(
        baseApi.util.invalidateTags([{ type: 'isAllCpRights', id: userName }])
      );

      // setIsRowLoading({});
      // setIsRowLoaded({});

      // invalidating ?
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdate,
    isLoading,
  };
};
