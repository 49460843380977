import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { IdentityServiceApplicationDtoUserDto } from 'shared/api/services/identity/rtk/generated';

import { FormInput, FormOutput, FormSchema } from '../../consts/schema';
import { SectionTitle } from './styles';
import { useUpdateUserProfile } from '../../hooks/use-update-user-profile';

const getDefaultValues = (
  user: IdentityServiceApplicationDtoUserDto
): FormInput => {
  const { email, firstName, lastName, middleName } = user;

  return {
    email: email ?? '',
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    middleName: middleName ?? '',
    newPassword: '',
  };
};

type Props = {
  user: IdentityServiceApplicationDtoUserDto;
  closeModal: () => void;
};

export function Form({ user, closeModal }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(user),
  });

  const { handleUpdate, isLoading } = useUpdateUserProfile();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({
      data,
      id: user.id,
      userName: user.userName,
    });
  });

  return (
    <UiCard>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <SectionTitle>Личные данные</SectionTitle>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="firstName" label="Имя" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="lastName" label="Фамилия" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="middleName" label="Отчество" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="email" label="E-mail" required />
        </Col>
      </FormRow>
      <SectionTitle>Смена пароля</SectionTitle>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="newPassword"
            label="Новый пароль"
            type="password"
            autoComplete="new-password"
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
