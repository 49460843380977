import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { BANK } from 'shared/consts/routes/bank';
import { ADD_PREFIX, BANKS } from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';

import { BanksMenu } from 'features/bank/menu';

import { AddBankPage, EditBankPage } from 'pages/bank';

export const BankRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[
          { controller: 'PaymentService', right: 'Read' },
          { controller: 'InformationService', right: 'Read' },
        ]}
      />
    }
  >
    <Route path={BANKS} element={<SectionLayout menu={<BanksMenu />} />}>
      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'PaymentService', right: 'Write' }]}
          />
        }
      >
        <Route path={ADD_PREFIX} element={<AddBankPage />} />
      </Route>
      <Route path={BANK} element={<EditBankPage />} />
    </Route>
  </Route>
);
