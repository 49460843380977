import { useAbility } from 'shared/lib/ability/context';
import {
  BALANCING_GROUPS,
  BILLINGS,
  BONUSES,
  CHARGE_POINTS,
  DISCOUNTS,
  HUBS,
  PUSH_NOTIFICATIONS,
  REPORTS,
  USERS,
} from 'shared/consts/names';

import { SectionNavMenuItem } from 'features/sidebar/types';
import { UsersIcon } from 'features/sidebar/icons/users';
import { TariffsIcon } from 'features/sidebar/icons/tariffs';
import { DiscountsIcon } from 'features/sidebar/icons/discounts';
import { ChargePointsIcon } from 'features/sidebar/icons/charge-points';
import { HubsIcon } from 'features/sidebar/icons/hubs';
import { ReportsIcon } from 'features/sidebar/icons/reports';
import { PushNotificationsIcon } from 'features/sidebar/icons/push-notifications';
import { BonusesIcon } from 'features/sidebar/icons/bonuses';

import { SidebarLayout } from '../layout';
import { BalancingIcon } from 'features/sidebar/icons/balancing';

const ITEMS: SectionNavMenuItem[] = [
  {
    label: 'Биллинг',
    path: BILLINGS,
    icon: <TariffsIcon />,
    permissions: [
      {
        controller: 'BillingService',
        right: 'Execute',
      },
    ],
  },
  // {
  //   label: 'Скидки',
  //   path: DISCOUNTS,
  //   icon: <DiscountsIcon />,
  //   permissions: [],
  // },
  {
    label: 'Список ЭЗС',
    path: CHARGE_POINTS,
    icon: <ChargePointsIcon />,
    permissions: [
      {
        controller: 'ChargePointServiceChargePoint',
        right: 'Read',
      },
      {
        controller: 'ChargePointServiceGroup',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Зарядные хабы',
    path: HUBS,
    icon: <HubsIcon />,
    permissions: [
      {
        controller: 'ChargePointServiceHubs',
        right: 'Execute',
      },
    ],
  },
  {
    label: 'Клиенты',
    path: USERS,
    icon: <UsersIcon />,
    permissions: [
      {
        controller: 'UserService',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Отчеты',
    path: REPORTS,
    icon: <ReportsIcon />,
    permissions: [
      {
        controller: 'AnalyticsService',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Пуш уведомления',
    path: PUSH_NOTIFICATIONS,
    icon: <PushNotificationsIcon />,
    permissions: [
      {
        controller: 'PushNotificationService',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Кешбэк и награды',
    path: BONUSES,
    icon: <BonusesIcon />,
    permissions: [
      {
        controller: 'BonusService',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Баланс мощности',
    path: BALANCING_GROUPS,
    icon: <BalancingIcon />,
    permissions: [
      {
        controller: 'BalancingService',
        right: 'Read',
      },
    ],
  },
];

export function MonitoringSidebar() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter((item) =>
    item.permissions.every((item) => {
      const { controller, right } = item;

      return ability.can(right, controller);
    })
  );

  return <SidebarLayout items={allowedItems} />;
}
