import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { ADD_PREFIX, COMPANIES } from 'shared/consts/names';
import { COMPANY } from 'shared/consts/routes/company';

import { RequireAuth } from 'entities/authorization';

import { CompaniesMenu } from 'features/company/menu';

import { AddCompanyPage } from 'pages/company/add';
import { EditCompanyPage } from 'pages/company/edit';

export const CompanyRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[{ controller: 'CompanyService', right: 'Read' }]}
      />
    }
  >
    <Route
      path={COMPANIES}
      element={<SectionLayout menu={<CompaniesMenu />} />}
    >
      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'CompanyService', right: 'Write' }]}
          />
        }
      >
        <Route path={ADD_PREFIX} element={<AddCompanyPage />} />
      </Route>
      <Route path={COMPANY} element={<EditCompanyPage />} />
    </Route>
  </Route>
);
