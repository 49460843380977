import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import {
  useGetChargePoints,
  useGetChargePointsCount,
} from 'shared/api/services/chargepoint/rtk';
import { getApiChargepointV1ChargePointsQueryParams } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { ChargePointStatus } from 'shared/api/services/chargepoint/rtk/generated/metrics';

import { getCompaniesMapping } from 'entities/company';

const generatedSchema = getApiChargepointV1ChargePointsQueryParams;

// Из фильтров: тип, статус, тестовые, владелец

const filteringQueryParams = z.object({
  name: z.string().optional(),
  // statuses: generatedSchema.shape.Statuses.optional(),
  connectorId: z.string().uuid().optional(),
  // companyIds: z.array(z.string().uuid()).optional(),
  // notСompaniesId: z.array(z.string().uuid()).optional(),
  connectorStatus: generatedSchema.shape.ConnectorStatus.optional(),
  ids: z.array(z.string().uuid()).optional(),
  // groupId: z.string().uuid().optional(),
  hubId: z.string().uuid().optional(),
  mobileAppId: z.string().uuid().optional(),
  withTestCp: z.boolean().optional(),
  dateFrom: z.string().datetime().optional(),
  dateTo: z.string().datetime().optional(),
  isBlocked: z.boolean().optional(),
});

const chargePointsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const chargePointsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  groupId?: string;
  withTestCp?: boolean;
  statuses?: ChargePointStatus[];
  companyIds?: string[];
  notCompaniesId: string[];
};

export const useQueries = ({
  limit,
  groupId,
  notCompaniesId,
  companyIds,
  statuses,
  withTestCp,
}: Props) => {
  const chargePointsParams = useTypedQueryParamsCustom(chargePointsQueryParams);
  const chargePointsCountParams = useTypedQueryParamsCustom(
    chargePointsCountQueryParams
  );

  const chargePointsQuery = useGetChargePoints({
    ...chargePointsParams,
    offset: getOffsetQueryParam(chargePointsParams.page, limit),
    limit,
    groupId,
    NotСompaniesId: notCompaniesId,
    statuses,
    companyIds,
    withTestCp,
  });

  const chargePointsCountQuery = useGetChargePointsCount({
    ...chargePointsCountParams,
    groupId,
    NotСompaniesId: notCompaniesId,
    statuses,
    companyIds,
    withTestCp,
  });

  const companiesQuery = useGetCompanies(undefined);

  const isLoading =
    chargePointsQuery.isLoading ||
    chargePointsCountQuery.isLoading ||
    companiesQuery.isLoading;

  const isFetching =
    chargePointsQuery.isFetching || chargePointsCountQuery.isFetching;

  const companiesMapping = getCompaniesMapping(companiesQuery.data?.data || []);

  return {
    isLoading,
    isFetching,
    chargePoints: chargePointsQuery.data?.data ?? [],
    chargePointsCount: chargePointsCountQuery.data?.data ?? Infinity,
    companiesMapping,
    chargePointsError: chargePointsQuery.error,
    chargePointsCountError: chargePointsCountQuery.error,
    companiesError: companiesQuery.error,
  };
};
