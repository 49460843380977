import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

const FormResultSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  countryId: z.string().min(1),
});

export const FormSchema = z
  .object({
    id: z.string().transform(emptyStringToUndefined),
    name: z.string().transform(emptyStringToUndefined),
    countryId: z.string().transform(emptyStringToUndefined),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  countryId: '',
  id: '',
  name: '',
};
