import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddChargePointGroupButton() {
  return (
    <SectionMenuAddButton
      label="Добавить группу"
      path={CHARGE_POINT_ROUTES.ADD_CHARGE_POINT_GROUP}
      permissions={[
        {
          controller: 'ChargePointServiceGroup',
          right: 'Write',
        },
      ]}
    />
  );
}
