import { Col, Row } from 'antd';

import { UiInput } from 'shared/ui/ui-kit';
import { useAbility } from 'shared/lib/ability/context';
import { Balance } from 'shared/api/services/bonus/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { ManageBonusesButton } from 'features/user/manage-bonuses';

import { StyledLabel } from './styles';

type Props = {
  balance: Balance;
  userId: string;
  countries: GetCountryVm[];
  currencyText: string;
};

export function BonusBalance({
  balance,
  userId,
  countries,
  currencyText,
}: Props) {
  const ability = useAbility();

  const manageButton = ability.can('Write', 'BonusService') && (
    <Col>
      <ManageBonusesButton userId={userId} countries={countries} />
    </Col>
  );

  const { value, countryId } = balance;

  return (
    <div>
      <StyledLabel>{`Бонусов на счете (${currencyText})`}</StyledLabel>
      <Row gutter={10}>
        <Col span={12}>
          <UiInput value={value} disabled style={{ height: '100%' }} />
        </Col>

        {manageButton}
      </Row>
    </div>
  );
}
