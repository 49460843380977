import { z } from 'zod';

export const FormSchema = z.object({
  email: z.string().email(),
  newPassword: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().nullable(),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;
