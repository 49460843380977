/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * CompanyService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Get assembly version.
 */
export const getApiCompanyV1AppInfoResponse = zod.string();

/**
 * @summary Get company by Id.
 */
export const getApiCompanyV1CompaniesCompanyIdParams = zod.object({
  companyId: zod.string().uuid(),
});

export const getApiCompanyV1CompaniesCompanyIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      mobileName: zod.string().min(1),
      legalName: zod.string().min(1),
      email: zod.string().nullish(),
      contractData: zod.string().nullish(),
      address: zod.string().nullish(),
      phoneNumber: zod.string().nullish(),
      logoUrl: zod.string().nullish(),
      inn: zod.string().min(1),
      kpp: zod.string().nullish(),
      directorName: zod.string().nullish(),
      telegram: zod.string().nullish(),
      companyBalanceForPeriod: zod.number().nullish(),
      employeeBalanceForPeriod: zod.number().nullish(),
      balanceRefreshPeriod: zod.enum(['FIRST_DAY_MONTH']).optional(),
      isBalanceRefreshLocked: zod.boolean(),
      creationDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isDefault: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update company.
 */
export const patchApiCompanyV1CompaniesCompanyIdParams = zod.object({
  companyId: zod.string().uuid(),
});

export const patchApiCompanyV1CompaniesCompanyIdBody = zod.object({
  name: zod.string().nullish(),
  legalName: zod.string().nullish(),
  email: zod.string().nullish(),
  contractData: zod.string().nullish(),
  address: zod.string().nullish(),
  phoneNumber: zod.string().nullish(),
  logoUrl: zod.string().nullish(),
  inn: zod.string().nullish(),
  kpp: zod.string().nullish(),
  mobileName: zod.string().nullish(),
  directorName: zod.string().nullish(),
  telegram: zod.string().nullish(),
  companyBalanceForPeriod: zod.number().nullish(),
  employeeBalanceForPeriod: zod.number().nullish(),
  balanceRefreshPeriod: zod.enum(['FIRST_DAY_MONTH']).optional(),
  isBalanceRefreshLocked: zod.boolean().nullish(),
  isDefault: zod.boolean().nullish(),
});

export const patchApiCompanyV1CompaniesCompanyIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      mobileName: zod.string().min(1),
      legalName: zod.string().min(1),
      email: zod.string().nullish(),
      contractData: zod.string().nullish(),
      address: zod.string().nullish(),
      phoneNumber: zod.string().nullish(),
      logoUrl: zod.string().nullish(),
      inn: zod.string().min(1),
      kpp: zod.string().nullish(),
      directorName: zod.string().nullish(),
      telegram: zod.string().nullish(),
      companyBalanceForPeriod: zod.number().nullish(),
      employeeBalanceForPeriod: zod.number().nullish(),
      balanceRefreshPeriod: zod.enum(['FIRST_DAY_MONTH']).optional(),
      isBalanceRefreshLocked: zod.boolean(),
      creationDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isDefault: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete company.
 */
export const deleteApiCompanyV1CompaniesCompanyIdParams = zod.object({
  companyId: zod.string().uuid(),
});

export const deleteApiCompanyV1CompaniesCompanyIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get companies by filter.
 */
export const getApiCompanyV1CompaniesQueryParams = zod.object({
  IsDefault: zod.boolean().optional(),
});

export const getApiCompanyV1CompaniesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        mobileName: zod.string().min(1),
        legalName: zod.string().min(1),
        email: zod.string().nullish(),
        contractData: zod.string().nullish(),
        address: zod.string().nullish(),
        phoneNumber: zod.string().nullish(),
        logoUrl: zod.string().nullish(),
        inn: zod.string().min(1),
        kpp: zod.string().nullish(),
        directorName: zod.string().nullish(),
        telegram: zod.string().nullish(),
        companyBalanceForPeriod: zod.number().nullish(),
        employeeBalanceForPeriod: zod.number().nullish(),
        balanceRefreshPeriod: zod.enum(['FIRST_DAY_MONTH']).optional(),
        isBalanceRefreshLocked: zod.boolean(),
        creationDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        isDefault: zod.boolean(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create company.
 */
export const postApiCompanyV1CompaniesBody = zod.object({
  name: zod.string().min(1),
  legalName: zod.string().min(1),
  email: zod.string().min(1),
  contractData: zod.string().nullish(),
  address: zod.string().min(1),
  phoneNumber: zod.string().min(1),
  logoUrl: zod.string().nullish(),
  inn: zod.string().min(1),
  kpp: zod.string().nullish(),
  mobileName: zod.string().min(1),
  directorName: zod.string().nullish(),
  telegram: zod.string().nullish(),
  companyBalanceForPeriod: zod.number().nullish(),
  employeeBalanceForPeriod: zod.number().nullish(),
  balanceRefreshPeriod: zod.enum(['FIRST_DAY_MONTH']).optional(),
  isBalanceRefreshLocked: zod.boolean(),
  isDefault: zod.boolean(),
});

export const postApiCompanyV1CompaniesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      mobileName: zod.string().min(1),
      legalName: zod.string().min(1),
      email: zod.string().nullish(),
      contractData: zod.string().nullish(),
      address: zod.string().nullish(),
      phoneNumber: zod.string().nullish(),
      logoUrl: zod.string().nullish(),
      inn: zod.string().min(1),
      kpp: zod.string().nullish(),
      directorName: zod.string().nullish(),
      telegram: zod.string().nullish(),
      companyBalanceForPeriod: zod.number().nullish(),
      employeeBalanceForPeriod: zod.number().nullish(),
      balanceRefreshPeriod: zod.enum(['FIRST_DAY_MONTH']).optional(),
      isBalanceRefreshLocked: zod.boolean(),
      creationDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isDefault: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get employee by Id.
 */
export const getApiCompanyV1EmployeesEmployeeIdParams = zod.object({
  employeeId: zod.string().uuid(),
});

export const getApiCompanyV1EmployeesEmployeeIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      name: zod.string().nullish(),
      balanceId: zod.string().uuid(),
      companyId: zod.string().uuid(),
      creationDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update employee.
 */
export const patchApiCompanyV1EmployeesEmployeeIdParams = zod.object({
  employeeId: zod.string().uuid(),
});

export const patchApiCompanyV1EmployeesEmployeeIdBody = zod.object({
  userId: zod.string().uuid().nullish(),
  balanceId: zod.string().uuid().nullish(),
  companyId: zod.string().uuid().nullish(),
  name: zod.string().nullish(),
});

export const patchApiCompanyV1EmployeesEmployeeIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      name: zod.string().nullish(),
      balanceId: zod.string().uuid(),
      companyId: zod.string().uuid(),
      creationDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete employee.
 */
export const deleteApiCompanyV1EmployeesEmployeeIdParams = zod.object({
  employeeId: zod.string().uuid(),
});

export const deleteApiCompanyV1EmployeesEmployeeIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get employees by filter.
 */
export const getApiCompanyV1EmployeesQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiCompanyV1EmployeesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        name: zod.string().nullish(),
        balanceId: zod.string().uuid(),
        companyId: zod.string().uuid(),
        creationDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create employee.
 */
export const postApiCompanyV1EmployeesBody = zod.object({
  userId: zod.string().uuid(),
  balanceId: zod.string().uuid(),
  companyId: zod.string().uuid(),
  name: zod.string().nullish(),
});

export const postApiCompanyV1EmployeesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      name: zod.string().nullish(),
      balanceId: zod.string().uuid(),
      companyId: zod.string().uuid(),
      creationDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get employees count by filter.
 */
export const getApiCompanyV1EmployeesCountQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
});

export const getApiCompanyV1EmployeesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
