import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { selectTableColumns, setTableColumns } from '../store/slice';
import { useAppDispatch } from 'shared/redux/types';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { PaymentDto } from 'shared/api/services/payment/rtk/generated';

import {
  PAYMENT_ERROR_REASON,
  PAYMENT_OPERATION,
  PAYMENT_STATUS,
} from 'entities/payment/consts';

import { SessionInfo } from '../ui/session-info';

const columnHelper = createColumnHelper<PaymentDto>();

const DATA_COLUMNS = [
  columnHelper.accessor('billingId', {
    id: 'billingId',
    header: 'Зарядная сессия',
    cell: (props) => {
      const billingId = props.getValue();

      return <SessionInfo billingId={billingId} />;
    },
    meta: {
      filterElement: <InputFilter paramName="billingId" disabled />,
    },
  }),
  columnHelper.accessor('lastUpdate', {
    id: 'lastUpdate',
    header: 'Дата обновления',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="lastUpdateFrom"
          dateToName="lastUpdateTo"
          disabled
        />
      ),
    },
  }),
  columnHelper.accessor('paymentDate', {
    id: 'paymentDate',
    header: 'Дата оплаты',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="paymentDateFrom"
          dateToName="paymentDateTo"
          disabled
        />
      ),
    },
  }),
  columnHelper.accessor('operation', {
    id: 'operation',
    header: 'Тип операции',
    cell: (props) => {
      const val = props.getValue();

      return PAYMENT_OPERATION[val];
    },
    meta: {
      filterElement: <InputFilter paramName="operation" disabled />,
    },
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Статус',
    cell: (props) => {
      const val = props.getValue();

      return PAYMENT_STATUS[val];
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(PAYMENT_STATUS).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="status"
        />
      ),
    },
  }),
  columnHelper.accessor('invoiceSum', {
    id: 'invoiceSum',
    header: 'Сумма',
    cell: (props) => {
      const val = props.getValue();

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="invoiceSum" disabled />,
    },
  }),
  columnHelper.accessor('holdSum', {
    id: 'holdSum',
    header: 'Сумма резерва',
    cell: (props) => {
      const val = props.getValue();

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="holdSum" disabled />,
    },
  }),
  columnHelper.accessor('errorReason', {
    id: 'errorReason',
    header: 'Причина ошибки',
    cell: (props) => {
      const val = props.getValue();

      if (!val) {
        return '';
      }

      return PAYMENT_ERROR_REASON[val];
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(PAYMENT_ERROR_REASON).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="errorReason"
        />
      ),
    },
  }),
  columnHelper.accessor('errorText', {
    id: 'errorText',
    header: 'Ошибка',

    meta: {
      filterElement: <InputFilter paramName="errorText" disabled />,
    },
  }),
  columnHelper.accessor('orderNumber', {
    id: 'orderNumber',
    header: 'Номер заказа платформы ЭЗС',
    meta: {
      filterElement: <InputFilter paramName="orderNumber" />,
    },
  }),
  columnHelper.accessor('bankOrderId', {
    id: 'bankOrderId',
    header: 'Номер заказа банка',
    meta: {
      filterElement: <InputFilter paramName="bankOrderId" />,
    },
  }),
  columnHelper.accessor('bankId', {
    id: 'bankId',
    header: 'Банк',
    cell: (props) => {
      const val = props.getValue();

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="bankId" />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
