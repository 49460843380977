import { Route } from 'react-router-dom';

import {
  ADD_PREFIX,
  GROUPS,
  MANUAL_COMMANDS,
  RESERVES,
  CHARGE_POINTS,
  EVENTS,
  STATUSES,
  BILLINGS,
  EDIT,
} from 'shared/consts/names';
import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { GROUP } from 'shared/consts/routes/common';
import { SESSIONS } from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';

import {
  ChargePointCommandsPage,
  ChargePointEventsPage,
  ChargePointInfoPage,
  ChargePointListPage,
  ChargePointReservesPage,
  ChargePointsCommandsPage,
  ChargePointSessionLogPage,
  ChargePointsGroupCommandsPage,
  ChargePointsGroupListPage,
  ChargePointsGroupReservesPage,
  ChargePointsGroupsListPage,
  ChargePointsSessionLogPage,
} from 'pages/charge-point';

import { ChargePointLayout } from 'entities/charge-point/ui/charge-point-layout';
import {
  ChargePointsGroupLayout,
  ChargePointsLayout,
} from 'entities/charge-point';
import { ChargePointGroupLayout } from 'entities/charge-point-group';

import { ChargePointsMenu } from 'features/charge-point/menu';

import { EditChargePointGroupPage } from 'pages/charge-point-group/edit';
import { AddChargePointPage } from 'pages/charge-point/add';
import { EditChargePointPage } from 'pages/charge-point/edit';
import { AddChargePointGroupPage } from 'pages/charge-point-group/add';
import { ChargePointGroupSessionsPage } from 'pages/charge-point-group/sessions';
import { ChargePointsReserveSessionsPage } from 'pages/charge-point/charge-points-reserve-sessions';
import { ChargePointBillingsPage } from 'pages/charge-point/charge-point-bilings';
import { ChargePointsBillingsPage } from 'pages/charge-point/charge-points-billings';
import { ADD_CP_PERMISSIONS } from 'entities/authorization/consts';

export const ChargePointRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[
          { controller: 'ChargePointServiceChargePoint', right: 'Read' },
          { controller: 'ChargePointServiceGroup', right: 'Read' },
        ]}
      />
    }
  >
    <Route
      path={CHARGE_POINTS}
      element={<SectionLayout menu={<ChargePointsMenu />} />}
    >
      <Route element={<ChargePointsLayout />}>
        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'CompanyService', right: 'Read' }]}
            />
          }
        >
          <Route index element={<ChargePointListPage />} />
        </Route>

        <Route path={GROUPS} element={<ChargePointsGroupsListPage />} />

        <Route path={SESSIONS} element={<ChargePointsSessionLogPage />} />

        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'ReserveService', right: 'Execute' }]}
            />
          }
        >
          <Route
            path={RESERVES}
            element={<ChargePointsReserveSessionsPage />}
          />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'BillingService', right: 'Read' },
                { controller: 'InformationService', right: 'Read' },
              ]}
            />
          }
        >
          <Route path={BILLINGS} element={<ChargePointsBillingsPage />} />
        </Route>
        <Route element={<RequireAuth permissions={[]} />}>
          <Route
            path={MANUAL_COMMANDS}
            element={<ChargePointsCommandsPage />}
          />
        </Route>
      </Route>

      <Route element={<RequireAuth permissions={ADD_CP_PERMISSIONS} />}>
        <Route path={ADD_PREFIX} element={<AddChargePointPage />} />
      </Route>

      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'InformationService', right: 'Read' }]}
          />
        }
      >
        <Route path={`groups/:groupId`} element={<ChargePointGroupLayout />}>
          <Route index element={<EditChargePointGroupPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'ChargePointServiceGroup', right: 'Write' },
                { controller: 'InformationService', right: 'Read' },
              ]}
            />
          }
        >
          <Route
            path={`${GROUPS}/${ADD_PREFIX}`}
            element={
              <MainContentLayout element={<AddChargePointGroupPage />} />
            }
          />
        </Route>

        <Route path={GROUP} element={<ChargePointsGroupLayout />}>
          <Route
            element={
              <RequireAuth
                permissions={[{ controller: 'CompanyService', right: 'Read' }]}
              />
            }
          >
            <Route index element={<ChargePointsGroupListPage />} />
          </Route>

          <Route path={SESSIONS} element={<ChargePointGroupSessionsPage />} />

          {/* <Route
            element={
              <RequireAuth  permissions={[{ controller: 'ReserveService', right: 'Read' }]} />
            }
          >
            <Route
              path={RESERVES}
              element={<ChargePointsGroupReservesPage />}
            />
          </Route> */}
          <Route element={<RequireAuth permissions={[]} />}>
            <Route
              path={MANUAL_COMMANDS}
              element={<ChargePointsGroupCommandsPage />}
            />
          </Route>
        </Route>

        {/* Рауты отдельной ЭЗС */}
        <Route path={':groupId/:chargePointId'} element={<ChargePointLayout />}>
          <Route
            element={
              <RequireAuth
                permissions={[
                  { controller: 'CompanyService', right: 'Read' },

                  { controller: 'InformationService', right: 'Read' },
                ]}
              />
            }
          >
            <Route index element={<ChargePointInfoPage />} />
          </Route>

          <Route path={SESSIONS} element={<ChargePointSessionLogPage />} />

          <Route
            element={
              <RequireAuth
                permissions={[{ controller: 'ReserveService', right: 'Read' }]}
              />
            }
          >
            <Route path={RESERVES} element={<ChargePointReservesPage />} />
          </Route>

          <Route
            element={
              <RequireAuth
                permissions={[
                  { controller: 'BillingService', right: 'Read' },
                  { controller: 'InformationService', right: 'Read' },
                ]}
              />
            }
          >
            <Route path={BILLINGS} element={<ChargePointBillingsPage />} />
          </Route>

          <Route path={EVENTS} element={<ChargePointEventsPage />} />

          <Route element={<RequireAuth permissions={[]} />}>
            <Route
              path={MANUAL_COMMANDS}
              element={<ChargePointCommandsPage />}
            />
          </Route>

          {/* <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={STATUSES} element={<div>ChargePointStatusesPage</div>} />
        </Route> */}

          <Route
            element={
              <RequireAuth
                permissions={[
                  { controller: 'CompanyService', right: 'Read' },
                  { controller: 'BillingService', right: 'Read' },
                  { controller: 'InformationService', right: 'Read' },
                  { controller: 'ChargePointServiceHubs', right: 'Read' },
                ]}
              />
            }
          >
            <Route path={EDIT} element={<EditChargePointPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);
