import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {},
});

export const {
  useGetApiAnalyticsReportAcquiringQuery: useGetReportAcquiring,
  useGetApiAnalyticsReportGetPaymentsReportQuery: useGetReportPayments,
} = enhancedApi;
