import { Header } from 'widgets/header';

import { CompanyLogo } from '../../company-logo';
import {
  AppGridLayout,
  Filler,
  HeaderContainer,
  CompanyLogoContainer,
} from './styles';

type Props = {
  children: React.ReactNode;
};

export function RootLayout({ children }: Props) {
  return (
    <AppGridLayout>
      <Filler />
      <CompanyLogoContainer>
        <CompanyLogo />
      </CompanyLogoContainer>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      {children}
    </AppGridLayout>
  );
}
