import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAddIdentityUser } from 'shared/api/services/identity/rtk';
import {
  enhancedApi,
  useAddIdentityUserIdentityGroupMutation,
} from 'shared/api/services/identity/rtk/enhanced';
import { IDENTITY_USER_ROUTES } from 'shared/consts/routes/identity-user';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Пользователь добавлен';
const ERROR_MSG = 'Не удалось добавить пользователя';

export const useHandleCreate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [addTrigger] = useAddIdentityUser();
  const [addUserToGroupTrigger] = useAddIdentityUserIdentityGroupMutation();

  const handleCreate = async ({ data }: { data: FormOutput }) => {
    const { groupName, ...rest } = data;

    try {
      setIsLoading(true);

      const addRes = await addTrigger(rest).unwrap();

      const addUserToGroupRes = await addUserToGroupTrigger({
        groupName,
        userName: data.userName,
      });

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'IdentityUsers', id: 'LIST' }])
      );

      navigate(IDENTITY_USER_ROUTES.USERS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleCreate,
  };
};
