import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { GetSessionResponse } from 'shared/api/services/reserve/rtk/generated';

import { UserPopover } from 'features/user/popover';

import { selectTableColumns, setTableColumns } from '../store/slice';
import { TableActions } from '../ui/actions';

const columnHelper = createColumnHelper<GetSessionResponse>();

const DATA_COLUMNS = [
  columnHelper.accessor('reserveId', {
    id: 'reserveId',
    header: 'ID объекта резерва',
    meta: {
      filterElement: <InputFilter paramName="reserveId" />,
    },
  }),
  columnHelper.accessor('reserveName', {
    id: 'reserveName',
    header: 'Описание',
    meta: {
      filterElement: <InputFilter paramName="reserveName" disabled />,
    },
  }),
  columnHelper.accessor('userId', {
    id: 'userId',
    header: 'Пользователь',
    size: 360,
    cell: (props) => {
      return <UserPopover userId={props.getValue()} />;
    },
    meta: {
      filterElement: <InputFilter paramName="userId" />,
    },
  }),
  columnHelper.accessor('dateFromReserve', {
    id: 'dateFromReserve',
    header: 'Начало резерва',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="beginDateFrom"
          dateToName="beginDateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('dateToReserve', {
    id: 'dateToReserve',
    header: 'Конец резерва',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="endDateFrom"
          dateToName="endDateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('lastUpdate', {
    id: 'lastUpdate',
    header: 'Дата обновления',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="lastUpdateFrom"
          dateToName="lastUpdateTo"
        />
      ),
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useAppSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      const session = props.row.original;

      return <TableActions session={session} />;
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
