import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { BALANCING_GROUP_ROUTES } from 'shared/consts/routes/balancing';
import { BALANCING_GROUPS } from 'shared/consts/names';
import { useGetBalancingGroups } from 'shared/api/services/balancing/rtk/enhanced';

import { AddButton } from './add-button';

export function MenuContainer() {
  const { data, isFetching, error } = useGetBalancingGroups();

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={BALANCING_GROUPS}
      absolutePath={BALANCING_GROUP_ROUTES.LIST}
      label="Балансировки"
      addButton={<AddButton />}
    />
  );
}
