export const replaceDotsWithCommas = (val: string | number) => {
  return String(val).replaceAll('.', ',');
};

export const convertStringToNumber = (val: string) => {
  if (!val) {
    return '';
  }

  return Number(val);
};
