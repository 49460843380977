/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PaymentService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Принимает команду на оплату (списание) средств с карты пользователя.
Результат вернется асинхронно сообщением в очереди.
 */
export const postApiPaymentV1ActionsPayHeader = zod.object({
  idempotencyKey: zod.string().uuid(),
});

export const postApiPaymentV1ActionsPayBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  countryId: zod.string().min(1),
  invoiceSum: zod.number(),
  phone: zod.string().min(1),
  email: zod.string().nullish(),
});

export const postApiPaymentV1ActionsPayResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      billingId: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankId: zod.string().min(1),
      cardId: zod.string().uuid(),
      bankClientId: zod.string().uuid(),
      operation: zod.enum(['PAYMENT', 'HOLDING']),
      orderNumber: zod.string().min(1),
      bankOrderId: zod.string().nullish(),
      invoiceSum: zod.number(),
      holdSum: zod.number().nullish(),
      returnSum: zod.number().nullish(),
      description: zod.string().nullish(),
      status: zod.enum([
        'NEW',
        'IN_PROCESS',
        'PAID',
        'ERROR',
        'HOLD',
        'DEPOSITED',
        'REVERSE',
        'ERROR_PAYMENT',
        'UNBINDED',
      ]),
      errorReason: zod
        .enum([
          'UNDEFINED',
          'NOT_ENOUGH_MONEY',
          'PAY_SERVICE_ERROR',
          'BANK_SERVICE_IS_UNAVAILABLE',
          'TIMEOUT_ERROR',
          'UNKNOWN_ERROR',
          'ORDER_NUMBER_NOT_FOUND',
        ])
        .optional(),
      errorText: zod.string().nullish(),
      bankResponse: zod.string().nullish(),
      paymentDate: zod.string().datetime().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      idempotencyKey: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Привязка банковской карты пользователя к лк.
 */
export const postApiPaymentV1ActionsBindingBody = zod.object({
  userId: zod.string().uuid(),
  bankClientId: zod.string().uuid().nullish(),
  countryId: zod.string().min(1),
  phone: zod.string().min(1),
  email: zod.string().nullish(),
});

export const postApiPaymentV1ActionsBindingResponse = zod.object({
  data: zod
    .object({
      orderNumber: zod.string().min(1),
      formUrl: zod.string().min(1),
      returnUrl: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Отвязка банковской карты пользователя.
 */
export const postApiPaymentV1ActionsUnbindBody = zod.object({
  cardId: zod.string().uuid(),
});

export const postApiPaymentV1ActionsUnbindResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Холдирование средств с карты пользователя.
Результат будет выпущен асинхронно сообщением в очереди.
 */
export const postApiPaymentV1ActionsHoldHeader = zod.object({
  idempotencyKey: zod.string().uuid().optional(),
});

export const postApiPaymentV1ActionsHoldBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  countryId: zod.string().min(1),
  holdSum: zod.number(),
  phone: zod.string().min(1),
  email: zod.string().nullish(),
});

export const postApiPaymentV1ActionsHoldResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      billingId: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankId: zod.string().min(1),
      cardId: zod.string().uuid(),
      bankClientId: zod.string().uuid(),
      operation: zod.enum(['PAYMENT', 'HOLDING']),
      orderNumber: zod.string().min(1),
      bankOrderId: zod.string().nullish(),
      invoiceSum: zod.number(),
      holdSum: zod.number().nullish(),
      returnSum: zod.number().nullish(),
      description: zod.string().nullish(),
      status: zod.enum([
        'NEW',
        'IN_PROCESS',
        'PAID',
        'ERROR',
        'HOLD',
        'DEPOSITED',
        'REVERSE',
        'ERROR_PAYMENT',
        'UNBINDED',
      ]),
      errorReason: zod
        .enum([
          'UNDEFINED',
          'NOT_ENOUGH_MONEY',
          'PAY_SERVICE_ERROR',
          'BANK_SERVICE_IS_UNAVAILABLE',
          'TIMEOUT_ERROR',
          'UNKNOWN_ERROR',
          'ORDER_NUMBER_NOT_FOUND',
        ])
        .optional(),
      errorText: zod.string().nullish(),
      bankResponse: zod.string().nullish(),
      paymentDate: zod.string().datetime().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      idempotencyKey: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Списание средств (заранее холдированных).
Результат будет выпущен асинхронно сообщением в очереди.
 */
export const postApiPaymentV1ActionsDepositBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  debetSum: zod.number(),
});

export const postApiPaymentV1ActionsDepositResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Отменяется ранее (последний) захолдированный платеж в полном объеме.
 */
export const postApiPaymentV1ActionsReverseBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  amount: zod.number().nullish(),
});

export const postApiPaymentV1ActionsReverseResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Установка "основной" карты пользователя.
 */
export const postApiPaymentV1ActionsSelectcardBody = zod.object({
  cardId: zod.string().uuid(),
});

export const postApiPaymentV1ActionsSelectcardResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const getApiPaymentV1AppInfoResponse = zod.string();

/**
 * @summary Получить банк по идентификатору.
 */
export const getApiPaymentV1BanksIdParams = zod.object({
  id: zod.string(),
});

export const getApiPaymentV1BanksIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Обновить банк.
 */
export const patchApiPaymentV1BanksIdParams = zod.object({
  id: zod.string(),
});

export const patchApiPaymentV1BanksIdBody = zod.object({
  name: zod.string().nullish(),
});

export const patchApiPaymentV1BanksIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удалить банк.
 */
export const deleteApiPaymentV1BanksIdParams = zod.object({
  id: zod.string(),
});

export const deleteApiPaymentV1BanksIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить банки по фильтру.
 */
export const getApiPaymentV1BanksQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiPaymentV1BanksResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().min(1),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создать банк.
 */
export const postApiPaymentV1BanksBody = zod.object({
  id: zod.string().min(1),
  name: zod.string().min(1),
  countryId: zod.string().min(1),
});

export const postApiPaymentV1BanksResponse = zod.object({
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить запрос на привязку банковской карты по идентификатору.
 */
export const getApiPaymentV1BindingLogsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiPaymentV1BindingLogsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankId: zod.string().min(1),
      bankClientId: zod.string().uuid(),
      status: zod.enum([
        'NEW',
        'IN_PROCESS',
        'PAID',
        'ERROR',
        'HOLD',
        'DEPOSITED',
        'REVERSE',
        'ERROR_PAYMENT',
        'UNBINDED',
      ]),
      orderNumber: zod.string().min(1),
      bankOrderId: zod.string().nullish(),
      formUrl: zod.string().nullish(),
      errorReason: zod
        .enum([
          'UNDEFINED',
          'NOT_ENOUGH_MONEY',
          'PAY_SERVICE_ERROR',
          'BANK_SERVICE_IS_UNAVAILABLE',
          'TIMEOUT_ERROR',
          'UNKNOWN_ERROR',
          'ORDER_NUMBER_NOT_FOUND',
        ])
        .optional(),
      errorText: zod.string().nullish(),
      bankResponse: zod.string().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить запросы на привязку банковских карт по фильтру.
 */
export const getApiPaymentV1BindingLogsQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BankClientId: zod.string().uuid().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
      'ORDER_NUMBER_NOT_FOUND',
    ])
    .optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiPaymentV1BindingLogsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        bankId: zod.string().min(1),
        bankClientId: zod.string().uuid(),
        status: zod.enum([
          'NEW',
          'IN_PROCESS',
          'PAID',
          'ERROR',
          'HOLD',
          'DEPOSITED',
          'REVERSE',
          'ERROR_PAYMENT',
          'UNBINDED',
        ]),
        orderNumber: zod.string().min(1),
        bankOrderId: zod.string().nullish(),
        formUrl: zod.string().nullish(),
        errorReason: zod
          .enum([
            'UNDEFINED',
            'NOT_ENOUGH_MONEY',
            'PAY_SERVICE_ERROR',
            'BANK_SERVICE_IS_UNAVAILABLE',
            'TIMEOUT_ERROR',
            'UNKNOWN_ERROR',
            'ORDER_NUMBER_NOT_FOUND',
          ])
          .optional(),
        errorText: zod.string().nullish(),
        bankResponse: zod.string().nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество запросов на привязку банковских карт по фильтру.
 */
export const getApiPaymentV1BindingLogsCountQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BankClientId: zod.string().uuid().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
      'ORDER_NUMBER_NOT_FOUND',
    ])
    .optional(),
});

export const getApiPaymentV1BindingLogsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить банковскую карту по идентификатору.
 */
export const getApiPaymentV1CardsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiPaymentV1CardsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankClientId: zod.string().uuid(),
      bindingId: zod.string().min(1),
      maskedPan: zod.string().min(1),
      expirationString: zod.string().min(1),
      expirationDate: zod.string().datetime(),
      isSelected: zod.boolean(),
      createdDate: zod.string().datetime(),
      bank: zod.object({
        id: zod.string().min(1),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      }),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить банковские карты по фильтру.
 */
export const getApiPaymentV1CardsQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BankClientId: zod.string().uuid().optional(),
  BindingId: zod.string().optional(),
  CountryId: zod.string().optional(),
  IsSelected: zod.boolean().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiPaymentV1CardsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        bankClientId: zod.string().uuid(),
        bindingId: zod.string().min(1),
        maskedPan: zod.string().min(1),
        expirationString: zod.string().min(1),
        expirationDate: zod.string().datetime(),
        isSelected: zod.boolean(),
        createdDate: zod.string().datetime(),
        bank: zod.object({
          id: zod.string().min(1),
          name: zod.string().min(1),
          countryId: zod.string().min(1),
          createdDate: zod.string().datetime(),
          lastUpdate: zod.string().datetime(),
        }),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество банковских карт по фильтру.
 */
export const getApiPaymentV1CardsCountQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BankClientId: zod.string().uuid().optional(),
  BindingId: zod.string().optional(),
  IsSelected: zod.boolean().optional(),
});

export const getApiPaymentV1CardsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить оплату по идентификатору.
 */
export const getApiPaymentV1PaymentsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiPaymentV1PaymentsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      billingId: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankId: zod.string().min(1),
      cardId: zod.string().uuid(),
      bankClientId: zod.string().uuid(),
      operation: zod.enum(['PAYMENT', 'HOLDING']),
      orderNumber: zod.string().min(1),
      bankOrderId: zod.string().nullish(),
      invoiceSum: zod.number(),
      holdSum: zod.number().nullish(),
      returnSum: zod.number().nullish(),
      description: zod.string().nullish(),
      status: zod.enum([
        'NEW',
        'IN_PROCESS',
        'PAID',
        'ERROR',
        'HOLD',
        'DEPOSITED',
        'REVERSE',
        'ERROR_PAYMENT',
        'UNBINDED',
      ]),
      errorReason: zod
        .enum([
          'UNDEFINED',
          'NOT_ENOUGH_MONEY',
          'PAY_SERVICE_ERROR',
          'BANK_SERVICE_IS_UNAVAILABLE',
          'TIMEOUT_ERROR',
          'UNKNOWN_ERROR',
          'ORDER_NUMBER_NOT_FOUND',
        ])
        .optional(),
      errorText: zod.string().nullish(),
      bankResponse: zod.string().nullish(),
      paymentDate: zod.string().datetime().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      idempotencyKey: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить оплаты по фильтру.
 */
export const getApiPaymentV1PaymentsQueryParams = zod.object({
  BillingId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BankClientId: zod.string().uuid().optional(),
  CardId: zod.string().uuid().optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
      'ORDER_NUMBER_NOT_FOUND',
    ])
    .optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiPaymentV1PaymentsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        billingId: zod.string().uuid(),
        userId: zod.string().uuid(),
        bankId: zod.string().min(1),
        cardId: zod.string().uuid(),
        bankClientId: zod.string().uuid(),
        operation: zod.enum(['PAYMENT', 'HOLDING']),
        orderNumber: zod.string().min(1),
        bankOrderId: zod.string().nullish(),
        invoiceSum: zod.number(),
        holdSum: zod.number().nullish(),
        returnSum: zod.number().nullish(),
        description: zod.string().nullish(),
        status: zod.enum([
          'NEW',
          'IN_PROCESS',
          'PAID',
          'ERROR',
          'HOLD',
          'DEPOSITED',
          'REVERSE',
          'ERROR_PAYMENT',
          'UNBINDED',
        ]),
        errorReason: zod
          .enum([
            'UNDEFINED',
            'NOT_ENOUGH_MONEY',
            'PAY_SERVICE_ERROR',
            'BANK_SERVICE_IS_UNAVAILABLE',
            'TIMEOUT_ERROR',
            'UNKNOWN_ERROR',
            'ORDER_NUMBER_NOT_FOUND',
          ])
          .optional(),
        errorText: zod.string().nullish(),
        bankResponse: zod.string().nullish(),
        paymentDate: zod.string().datetime().nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        idempotencyKey: zod.string().uuid(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество оплат по фильтру.
 */
export const getApiPaymentV1PaymentsCountQueryParams = zod.object({
  BillingId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BankClientId: zod.string().uuid().optional(),
  CardId: zod.string().uuid().optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
      'ORDER_NUMBER_NOT_FOUND',
    ])
    .optional(),
});

export const getApiPaymentV1PaymentsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
