import { Link } from 'react-router-dom';

import { useAppSelector } from 'shared/redux/types';

import BlackLogo from './images/black.svg';
import BlackSmallLogo from './images/black-small.svg';
import WhiteLogo from './images/white.svg';
import WhiteSmallLogo from './images/white-small.svg';
import RossetiLogo from './images/rosseti.svg';

import { CompanyLogoContainer } from './styles';

import { selectGroupFromToken } from 'entities/authorization/lib/select-group-from-token';

// type CompanyLogoType = 'black' | 'black-small' | 'white' | 'white-small';

// const LOGO: Record<CompanyLogoType, string> = {
//   black: BlackLogo,
//   'black-small': BlackSmallLogo,
//   white: WhiteLogo,
//   'white-small': WhiteSmallLogo,
// };

const STANDART_LOGO = BlackLogo;

const NON_STANDART_LOGO: Record<string, string> = {
  Россети: RossetiLogo,
};

const getLogo = (group: string | undefined) => {
  if (!group) {
    return STANDART_LOGO;
  }

  const nonStandartLogo = NON_STANDART_LOGO[group];

  return nonStandartLogo ?? STANDART_LOGO;
};

export function CompanyLogo() {
  const group = useAppSelector(selectGroupFromToken);

  return (
    <CompanyLogoContainer>
      <Link to="/">
        <img src={getLogo(group)} alt="company logo" />
      </Link>
    </CompanyLogoContainer>
  );
}
