import Excel from 'exceljs';
import { ReportGroupingType } from 'shared/api/services/analytics/rtk/generated';

import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { getRuFormatDateString } from 'shared/lib';
import {
  downloadReport,
  setColumnsAutoWidth,
  setReportMetadata,
} from 'shared/lib/export-table';
import {
  applyReportTitleStyles,
  applyTbodyStyles,
  applyTheadStyles,
} from 'shared/lib/export-table/apply-worksheet-styles';
import { convertStringToNumber } from 'shared/lib/format/replace-dots-with-commas';
import { buildTable } from './build-table';

const FORMAT_FN: Record<number, (val: string) => void> = {
  3: convertStringToNumber,
  4: convertStringToNumber,
  5: convertStringToNumber,
  6: convertStringToNumber,
  7: convertStringToNumber,
  8: convertStringToNumber,
  9: convertStringToNumber,
  10: convertStringToNumber,
};

const TITLE = 'Отчет по выручке';

type Props = {
  tableRef: React.MutableRefObject<null>;
  dateFromParam: string | undefined;
  dateToParam: string | undefined;
  chargePointsId: string | undefined;
  groupsId: string | undefined;
  chargePointsMapping: Record<string, GetChargePointVm>;
  groupsMapping: Record<string, GetGroupVm>;
  groupBy?: ReportGroupingType | undefined;
};

export const exportInExcel = async ({
  tableRef,
  dateFromParam,
  dateToParam,
  chargePointsId,
  groupsId,
  chargePointsMapping,
  groupsMapping,
  groupBy,
}: Props) => {
  const currentDate = getRuFormatDateString();

  const dateFrom =
    dateFromParam === undefined
      ? currentDate
      : getRuFormatDateString(dateFromParam);
  const dateTo =
    dateToParam === undefined
      ? currentDate
      : getRuFormatDateString(dateToParam);

  const table = tableRef.current as unknown as HTMLElement;

  const trsLength = Array.from(table.querySelectorAll('tr')).length;

  const wb = new Excel.Workbook();

  setReportMetadata(wb);

  const ws = wb.addWorksheet(TITLE);

  const getChargePointsNamesList = () => {
    if (!chargePointsId) {
      return '';
    }

    return chargePointsId
      .split(',')
      .map((el) => {
        return chargePointsMapping[el].name;
      })
      .join(', ');
  };

  const getGroupsNamesList = () => {
    if (!groupsId) {
      return '';
    }

    return groupsId
      .split(',')
      .map((el) => groupsMapping[el].name)
      .join(', ');
  };

  ws.addRow(1).values = ['Сводный отчет по выручке (дни)'];
  ws.addRow(2).values = ['Начало периода', dateFrom];
  ws.addRow(3).values = ['Конец периода', dateTo];
  ws.addRow(4).values = ['Дата отчета', currentDate];
  ws.addRow(5).values = ['Список ЭЗС:', getChargePointsNamesList()];
  ws.addRow(6).values = ['Группы ЭЗС:', getGroupsNamesList()];
  ws.addRow(7);

  const FIRST_TABLE_ROW = 8;

  const FOOTER_ROW = FIRST_TABLE_ROW - 1 + trsLength;

  buildTable({ FOOTER_ROW, table, ws, groupBy });

  applyTbodyStyles(ws, FIRST_TABLE_ROW + 1, FIRST_TABLE_ROW - 2 + trsLength);

  applyReportTitleStyles(ws, 1);

  applyTheadStyles(ws, FIRST_TABLE_ROW);

  // applyWorksheetStyles(ws, 1);

  setColumnsAutoWidth(ws, 1);

  const reportFileName = TITLE;

  downloadReport(wb, reportFileName);
};
