import { getServerErrorText, openErrorNotification } from 'shared/lib';

import { AddConnectorButton } from 'features/connector/add';

import { ConnectorCard } from './connector-card';
import { Layout } from './layout';
import { useQueries } from '../hooks/use-queries';

type Props = {
  chargePointId: string;
};

export function ConnectorListWidget({ chargePointId }: Props) {
  const { connectors, locksMapping, isLoading, errors, isSuccess } = useQueries(
    {
      chargePointId,
    }
  );

  errors.forEach((el) => {
    if (el) {
      openErrorNotification(getServerErrorText(el));
    }
  });

  let connectorsContent: React.ReactNode;

  if (isSuccess) {
    if (connectors?.length) {
      connectorsContent = (
        <>
          {connectors.map((el) => {
            return (
              <ConnectorCard
                key={el.id}
                chargePointId={chargePointId}
                connector={el}
                lock={locksMapping?.[el.id]}
              />
            );
          })}
        </>
      );
    } else {
      connectorsContent = <div>Нет доступных коннекторов</div>;
    }
  } else {
    connectorsContent = null;
  }

  return (
    <Layout
      addConnectorSlot={<AddConnectorButton chargePointId={chargePointId} />}
      connectorListSlot={connectorsContent}
      isLoading={isLoading}
    />
  );
}
