import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAnalyticsReportAcquiring: build.query<
      GetApiAnalyticsReportAcquiringApiResponse,
      GetApiAnalyticsReportAcquiringApiArg
    >({
      query: (queryArg) => ({
        url: `/api/analytics/Report/ACQUIRING`,
        params: {
          BankId: queryArg.bankId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          MobileAppId: queryArg.mobileAppId,
        },
      }),
    }),
    postApiAnalyticsReportUploadBillings: build.mutation<
      PostApiAnalyticsReportUploadBillingsApiResponse,
      PostApiAnalyticsReportUploadBillingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/analytics/Report/UploadBillings`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiAnalyticsReportGetPaymentsReport: build.query<
      GetApiAnalyticsReportGetPaymentsReportApiResponse,
      GetApiAnalyticsReportGetPaymentsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/analytics/Report/GetPaymentsReport`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          ChargePointsId: queryArg.chargePointsId,
          GroupsId: queryArg.groupsId,
          CompaniesId: queryArg.companiesId,
          MobileAppsId: queryArg.mobileAppsId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          GroupBy: queryArg.groupBy,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiAnalyticsReportAcquiringApiResponse =
  /** status 200 OK */ GetAcquiringVmResponseBase;
export type GetApiAnalyticsReportAcquiringApiArg = {
  bankId?: string;
  dateFrom?: string;
  dateTo?: string;
  mobileAppId?: string;
};
export type PostApiAnalyticsReportUploadBillingsApiResponse =
  /** status 200 OK */ ResponseBase;
export type PostApiAnalyticsReportUploadBillingsApiArg = UploadBillingsRequest;
export type GetApiAnalyticsReportGetPaymentsReportApiResponse =
  /** status 200 OK */ GetPaymentsReportVmResponseBaseRead;
export type GetApiAnalyticsReportGetPaymentsReportApiArg = {
  limit?: number;
  offset?: number;
  chargePointsId?: string[];
  groupsId?: string[];
  companiesId?: string[];
  mobileAppsId?: string[];
  dateFrom?: string;
  dateTo?: string;
  groupBy?: ReportGroupingType;
};
export type GetAcquiringPaymentVm = {
  userLogin?: string | null;
  companyINN?: string | null;
  companyAgentReceipt?: boolean;
  createdDate?: string;
  chargePointName?: string | null;
  chargePointAddress?: string | null;
  invoiceSum?: number;
  paymentDate?: string | null;
  bonus?: number;
};
export type GetAcquiringVm = {
  billings?: GetAcquiringPaymentVm[] | null;
  count?: number;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetAcquiringVmResponseBase = {
  data?: GetAcquiringVm;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UploadBillingsRequest = {
  billingIds?: string[] | null;
  dateFrom?: string | null;
  dateTo?: string | null;
};
export type BalanceName = 'PERSONAL' | 'BUSINESS';
export type GetPaymentBillingVm = {
  paymentDate?: string | null;
  chargePointName: string | null;
  balanceName: BalanceName;
  connectorName: string | null;
  chargePointAddress: string | null;
  invoiceSum: number;
  payment: number;
  bonusesPayment: number;
  discount: number;
  benefitPayment: number;
  balancePayment: number;
  energy: number;
};
export type GetPaymentsReportVm = {
  billings: GetPaymentBillingVm[] | null;
};
export type GetPaymentsReportVmRead = {
  billings: GetPaymentBillingVm[] | null;
  count?: number;
};
export type GetPaymentsReportVmResponseBase = {
  data?: GetPaymentsReportVm;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetPaymentsReportVmResponseBaseRead = {
  data?: GetPaymentsReportVmRead;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ReportGroupingType = 'DAILY' | 'MONTH' | 'PERIOD' | 'NONE';
export const {
  useGetApiAnalyticsReportAcquiringQuery,
  usePostApiAnalyticsReportUploadBillingsMutation,
  useGetApiAnalyticsReportGetPaymentsReportQuery,
} = injectedRtkApi;
