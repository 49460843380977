/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * bonus_service
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * Получение списка балансов бонусов.
 * @summary Получение списка балансов бонусов
 */
export const getAllBalancesApiBonusV1BalancesGetQueryOffsetMin = 0;

export const getAllBalancesApiBonusV1BalancesGetQueryParams = zod.object({
  limit: zod.number().optional(),
  offset: zod
    .number()
    .min(getAllBalancesApiBonusV1BalancesGetQueryOffsetMin)
    .optional(),
  orderingType: zod.enum(['ASC', 'DESC']).optional(),
  orderingField: zod.string().or(zod.null()).optional(),
  userId: zod.string().uuid().or(zod.null()).optional(),
  countryId: zod.string().or(zod.null()).optional(),
  balanceId: zod.string().uuid().or(zod.null()).optional(),
  dateFrom: zod.string().datetime().or(zod.null()).optional(),
  dateTo: zod.string().datetime().or(zod.null()).optional(),
});

export const getAllBalancesApiBonusV1BalancesGetResponse = zod.object({
  data: zod.array(
    zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      userId: zod.string().uuid().or(zod.null()).optional(),
      countryId: zod.string().or(zod.null()).optional(),
      name: zod.string(),
      value: zod.number(),
      createdDate: zod.string().datetime().optional(),
    })
  ),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Изменение бонусного баланса автоматически.
 * @summary Изменение бонусного баланса при оплате сессии
 */
export const postDebitApiBonusV1BalancesActionDebitPostBody = zod.object({
  userId: zod.string().uuid(),
  countryId: zod.string(),
  limiterId: zod.string().uuid().or(zod.null()).optional(),
  debitSum: zod.number().or(zod.string()),
  externalLinkId: zod.string().or(zod.null()).optional(),
  reason: zod.string().or(zod.null()).optional(),
});

export const postDebitApiBonusV1BalancesActionDebitPostResponse = zod.object({
  data: zod.object({
    bonusesDebited: zod.number().optional(),
  }),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Зачисление бонусного баланса вручную.
 * @summary Зачисление бонусного баланса вручную
 */
export const postCreditApiBonusV1BalancesActionManualCreditPostBody =
  zod.object({
    userId: zod.string().uuid().or(zod.null()).optional(),
    countryId: zod.string().or(zod.null()).optional(),
    creditSum: zod.number().or(zod.string()),
    reason: zod.string().optional(),
  });

export const postCreditApiBonusV1BalancesActionManualCreditPostResponse =
  zod.object({
    data: zod.object({
      bonusesCredited: zod.number().optional(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Списание бонусного баланса вручную.
 * @summary Списание бонусного баланса вручную
 */
export const postDebitManualApiBonusV1BalancesActionManualDebitPostBody =
  zod.object({
    userId: zod.string().uuid().or(zod.null()).optional(),
    countryId: zod.string().or(zod.null()).optional(),
    debitSum: zod.number().or(zod.string()),
    reason: zod.string().optional(),
  });

export const postDebitManualApiBonusV1BalancesActionManualDebitPostResponse =
  zod.object({
    data: zod.object({
      bonusesDebited: zod.number().optional(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение количества балансов бонусов.
 * @summary Получение количества балансов бонусов
 */
export const getBalancesCountApiBonusV1BalancesCountGetQueryParams = zod.object(
  {
    userId: zod.string().uuid().or(zod.null()).optional(),
    countryId: zod.string().or(zod.null()).optional(),
    balanceId: zod.string().uuid().or(zod.null()).optional(),
    dateFrom: zod.string().datetime().or(zod.null()).optional(),
    dateTo: zod.string().datetime().or(zod.null()).optional(),
  }
);

export const getBalancesCountApiBonusV1BalancesCountGetResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Получение списка всех бонусных транзакций по балансу.
 * @summary Получение списка всех транзакций по бонусному балансу
 */
export const getAllTransactionsApiBonusV1BalancesTransactionsGetQueryOffsetMin = 0;

export const getAllTransactionsApiBonusV1BalancesTransactionsGetQueryParams =
  zod.object({
    limit: zod.number().optional(),
    offset: zod
      .number()
      .min(getAllTransactionsApiBonusV1BalancesTransactionsGetQueryOffsetMin)
      .optional(),
    orderingType: zod.enum(['ASC', 'DESC']).optional(),
    orderingField: zod.string().or(zod.null()).optional(),
    userId: zod.string().uuid().or(zod.null()).optional(),
    countryId: zod.string().or(zod.null()).optional(),
    balanceId: zod.string().uuid().or(zod.null()).optional(),
    bonusProgramId: zod.string().uuid().or(zod.null()).optional(),
    externalLinkId: zod.string().or(zod.null()).optional(),
    dateFrom: zod.string().datetime().or(zod.null()).optional(),
    dateTo: zod.string().datetime().or(zod.null()).optional(),
  });

export const getAllTransactionsApiBonusV1BalancesTransactionsGetResponse =
  zod.object({
    data: zod.array(
      zod.object({
        balanceId: zod.string().uuid().or(zod.null()),
        bonusProgramId: zod.string().uuid().or(zod.null()).optional(),
        id: zod.string().uuid(),
        createdDate: zod.string().datetime(),
        operation: zod.enum(['UNDEFINED', 'DEBIT', 'CREDIT']),
        value: zod.number(),
        reason: zod.string(),
      })
    ),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение количества бонусных транзакций по балансу.
 * @summary Получение количества транзакций по бонусному балансу
 */
export const getTransactionsCountApiBonusV1BalancesTransactionsCountGetQueryParams =
  zod.object({
    userId: zod.string().uuid().or(zod.null()).optional(),
    countryId: zod.string().or(zod.null()).optional(),
    balanceId: zod.string().uuid().or(zod.null()).optional(),
    bonusProgramId: zod.string().uuid().or(zod.null()).optional(),
    externalLinkId: zod.string().or(zod.null()).optional(),
    dateFrom: zod.string().datetime().or(zod.null()).optional(),
    dateTo: zod.string().datetime().or(zod.null()).optional(),
  });

export const getTransactionsCountApiBonusV1BalancesTransactionsCountGetResponse =
  zod.object({
    data: zod.number().optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение всех уровней бонусных программ.
 * @summary Получение всех уровней бонусных программ
 */
export const getAllBonusLevelApiBonusV1BonusLevelsGetQueryOffsetMin = 0;

export const getAllBonusLevelApiBonusV1BonusLevelsGetQueryParams = zod.object({
  limit: zod.number().optional(),
  offset: zod
    .number()
    .min(getAllBonusLevelApiBonusV1BonusLevelsGetQueryOffsetMin)
    .optional(),
  orderingType: zod.enum(['ASC', 'DESC']).optional(),
  orderingField: zod.string().or(zod.null()).optional(),
});

export const getAllBonusLevelApiBonusV1BonusLevelsGetResponse = zod.object({
  data: zod.array(
    zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      bonusProgramId: zod.string().uuid(),
      name: zod.string(),
      level: zod.number(),
      condition: zod.number(),
      awardPercent: zod.number(),
      awardSum: zod.number(),
    })
  ),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Создание уровня бонусной программы.
 * @summary Создание уровня бонусной программы
 */
export const createBonusProgramLevelApiBonusV1BonusLevelsPostBody = zod.object({
  bonusProgramId: zod.string().uuid(),
  name: zod.string(),
  level: zod.number(),
  condition: zod.number(),
  awardPercent: zod.number(),
  awardSum: zod.number(),
});

/**
 * Получение всех уровней по бонусной программе.
 * @summary Получение всех уровней по Id бонусной программы
 */
export const getBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetParams =
  zod.object({
    bonus_program_id: zod.string().uuid(),
  });

export const getBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetResponse =
  zod.object({
    data: zod.array(
      zod.object({
        id: zod.string().uuid(),
        lastUpdate: zod.string().datetime(),
        bonusProgramId: zod.string().uuid(),
        name: zod.string(),
        level: zod.number(),
        condition: zod.number(),
        awardPercent: zod.number(),
        awardSum: zod.number(),
      })
    ),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Удаление уровня бонусной программы.
 * @summary Удаление уровня бонусной программы
 */
export const deleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteParams =
  zod.object({
    id: zod.string().uuid(),
  });

export const deleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteResponse =
  zod.object({
    data: zod.any().optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение уровня бонусной программы по Id.
 * @summary Получение уровня бонусной программы по Id
 */
export const getBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetParams =
  zod.object({
    id: zod.string().uuid(),
  });

export const getBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetResponse =
  zod.object({
    data: zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      bonusProgramId: zod.string().uuid(),
      name: zod.string(),
      level: zod.number(),
      condition: zod.number(),
      awardPercent: zod.number(),
      awardSum: zod.number(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Обновление значений уровня бонусной программы.
 * @summary Обновление значений уровня бонусной программы
 */
export const updateBonusProgramLevelApiBonusV1BonusLevelsIdPatchParams =
  zod.object({
    id: zod.string().uuid(),
  });

export const updateBonusProgramLevelApiBonusV1BonusLevelsIdPatchBody =
  zod.object({
    bonusProgramId: zod.string().uuid(),
    name: zod.string(),
    level: zod.number(),
    condition: zod.number(),
    awardPercent: zod.number(),
    awardSum: zod.number(),
  });

export const updateBonusProgramLevelApiBonusV1BonusLevelsIdPatchResponse =
  zod.object({
    data: zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      bonusProgramId: zod.string().uuid(),
      name: zod.string(),
      level: zod.number(),
      condition: zod.number(),
      awardPercent: zod.number(),
      awardSum: zod.number(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение списка всех бонусных программ.
 * @summary Получение списка всех бонусных программ
 */
export const getAllBonusProgramApiBonusV1BonusProgramGetQueryOffsetMin = 0;

export const getAllBonusProgramApiBonusV1BonusProgramGetQueryParams =
  zod.object({
    limit: zod.number().optional(),
    offset: zod
      .number()
      .min(getAllBonusProgramApiBonusV1BonusProgramGetQueryOffsetMin)
      .optional(),
    orderingType: zod.enum(['ASC', 'DESC']).optional(),
    orderingField: zod.string().or(zod.null()).optional(),
  });

export const getAllBonusProgramApiBonusV1BonusProgramGetResponse = zod.object({
  data: zod.array(
    zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      name: zod.string(),
      type: zod
        .enum(['ChargedByCapacity', 'ChargedByStations', 'SpendMoney'])
        .optional(),
      description: zod.string(),
      dateStart: zod.string().datetime().optional(),
      dateStop: zod.string().datetime().optional(),
      countryId: zod.string(),
      executionCron: zod.string(),
      frequency: zod.enum(['MONTH', 'WEEK', 'DAY']).optional(),
      frequencyValue: zod.number(),
      isDeleted: zod.boolean().or(zod.null()).optional(),
    })
  ),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Создание бонусной программы.
 * @summary Создание бонусной программы
 */
export const createBonusProgramApiBonusV1BonusProgramPostBody = zod.object({
  name: zod.string(),
  type: zod
    .enum(['ChargedByCapacity', 'ChargedByStations', 'SpendMoney'])
    .optional(),
  description: zod.string(),
  dateStart: zod.string().datetime().optional(),
  dateStop: zod.string().datetime().optional(),
  countryId: zod.string(),
  executionCron: zod.string(),
  frequency: zod.enum(['MONTH', 'WEEK', 'DAY']).optional(),
  frequencyValue: zod.number(),
  isDeleted: zod.boolean().or(zod.null()).optional(),
});

/**
 * Получение достижений пользователя в текущем периоде по бонусной программе.
 * @summary Получение прогресса достижения в текущем периоде по бонусной программе
 */
export const getBonusProgramAchievementApiBonusV1BonusProgramAchievementGetQueryParams =
  zod.object({
    user_id: zod.string().uuid(),
    country_id: zod.string().optional(),
  });

export const getBonusProgramAchievementApiBonusV1BonusProgramAchievementGetResponse =
  zod.object({
    data: zod.object({
      bonus_program: zod
        .object({
          id: zod.string().uuid(),
          lastUpdate: zod.string().datetime(),
          name: zod.string(),
          type: zod
            .enum(['ChargedByCapacity', 'ChargedByStations', 'SpendMoney'])
            .optional(),
          description: zod.string(),
          dateStart: zod.string().datetime().optional(),
          dateStop: zod.string().datetime().optional(),
          countryId: zod.string(),
          executionCron: zod.string(),
          frequency: zod.enum(['MONTH', 'WEEK', 'DAY']).optional(),
          frequencyValue: zod.number(),
          isDeleted: zod.boolean().or(zod.null()).optional(),
          programLevels: zod.array(
            zod.object({
              id: zod.string().uuid(),
              lastUpdate: zod.string().datetime(),
              bonusProgramId: zod.string().uuid(),
              name: zod.string(),
              level: zod.number(),
              condition: zod.number(),
              awardPercent: zod.number(),
              awardSum: zod.number(),
            })
          ),
        })
        .or(zod.array(zod.any()))
        .optional(),
      current_sum: zod.number().optional(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение количества бонусных программ.
 * @summary Получение количества бонусных программ
 */
export const getProgramCountApiBonusV1BonusProgramCountGetResponse = zod.object(
  {
    data: zod.number().optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  }
);

/**
 * Удаление бонусной программы.
 * @summary Удаление бонусной программы
 */
export const deleteBonusProgramApiBonusV1BonusProgramIdDeleteParams =
  zod.object({
    id: zod.string().uuid(),
  });

export const deleteBonusProgramApiBonusV1BonusProgramIdDeleteResponse =
  zod.object({
    data: zod.any().optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение бонусной программы по Id.
 * @summary Получение бонусной программы по Id
 */
export const getBonusProgramByIdApiBonusV1BonusProgramIdGetParams = zod.object({
  id: zod.string().uuid(),
});

export const getBonusProgramByIdApiBonusV1BonusProgramIdGetResponse =
  zod.object({
    data: zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      name: zod.string(),
      type: zod
        .enum(['ChargedByCapacity', 'ChargedByStations', 'SpendMoney'])
        .optional(),
      description: zod.string(),
      dateStart: zod.string().datetime().optional(),
      dateStop: zod.string().datetime().optional(),
      countryId: zod.string(),
      executionCron: zod.string(),
      frequency: zod.enum(['MONTH', 'WEEK', 'DAY']).optional(),
      frequencyValue: zod.number(),
      isDeleted: zod.boolean().or(zod.null()).optional(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Обновление бонусной программы.
 * @summary Обновление бонусной программы
 */
export const updateBonusProgramApiBonusV1BonusProgramIdPatchParams = zod.object(
  {
    id: zod.string().uuid(),
  }
);

export const updateBonusProgramApiBonusV1BonusProgramIdPatchBody = zod.object({
  name: zod.string().or(zod.null()).optional(),
  type: zod
    .enum(['ChargedByCapacity', 'ChargedByStations', 'SpendMoney'])
    .or(zod.string())
    .or(zod.null())
    .optional(),
  dateStart: zod.string().or(zod.string().datetime()).or(zod.null()).optional(),
  dateStop: zod.string().or(zod.string().datetime()).or(zod.null()).optional(),
  countryId: zod.string().or(zod.null()).optional(),
  executionCron: zod.string().or(zod.null()).optional(),
  frequency: zod
    .enum(['MONTH', 'WEEK', 'DAY'])
    .or(zod.string())
    .or(zod.null())
    .optional(),
  frequencyValue: zod.number().or(zod.null()).optional(),
  isDeleted: zod.boolean().or(zod.null()).optional(),
});

export const updateBonusProgramApiBonusV1BonusProgramIdPatchResponse =
  zod.object({
    data: zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      name: zod.string(),
      type: zod
        .enum(['ChargedByCapacity', 'ChargedByStations', 'SpendMoney'])
        .optional(),
      description: zod.string(),
      dateStart: zod.string().datetime().optional(),
      dateStop: zod.string().datetime().optional(),
      countryId: zod.string(),
      executionCron: zod.string(),
      frequency: zod.enum(['MONTH', 'WEEK', 'DAY']).optional(),
      frequencyValue: zod.number(),
      isDeleted: zod.boolean().or(zod.null()).optional(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Checks the health of a project.

It returns 200 if the project is healthy.
 * @summary Health Check
 */
export const healthCheckApiBonusV1HealthGetResponse = zod.any();

/**
 * Получение списка максимальных бонусов.
 * @summary Получение списка максимальных бонусов владельца
 */
export const getAllMaxBonusPayApiBonusV1MaxBonusPayGetQueryOffsetMin = 0;

export const getAllMaxBonusPayApiBonusV1MaxBonusPayGetQueryParams = zod.object({
  limit: zod.number().optional(),
  offset: zod
    .number()
    .min(getAllMaxBonusPayApiBonusV1MaxBonusPayGetQueryOffsetMin)
    .optional(),
  orderingType: zod.enum(['ASC', 'DESC']).optional(),
  orderingField: zod.string().or(zod.null()).optional(),
});

export const getAllMaxBonusPayApiBonusV1MaxBonusPayGetResponse = zod.object({
  data: zod.array(
    zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      limiterId: zod.string().uuid(),
      maxBonusPayPercent: zod.number(),
    })
  ),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Создание максимальных бонусов владельца.
 * @summary Создание максимальных бонусов владельца
 */
export const createMaxBonusPayApiBonusV1MaxBonusPayPostBody = zod.object({
  limiterId: zod.string().uuid(),
  maxBonusPayPercent: zod.number(),
});

/**
 * Получение максимального бонуса владельца по Id владельца.
 * @summary Получение максимального бонуса владельца по Id владельца
 */
export const getMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetParams =
  zod.object({
    limiter_id: zod.string().uuid(),
  });

export const getMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetResponse =
  zod.object({
    data: zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      limiterId: zod.string().uuid(),
      maxBonusPayPercent: zod.number(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Удаление максимальных бонусов владельца.
 * @summary Удаление максимальных бонусов владельца
 */
export const deleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteResponse =
  zod.object({
    data: zod.any().optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение максимального бонуса владельца по Id бонуса.
 * @summary Получение максимального бонуса владельца по Id
 */
export const getMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetParams = zod.object({
  id: zod.string().uuid(),
});

export const getMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetResponse = zod.object({
  data: zod.object({
    id: zod.string().uuid(),
    lastUpdate: zod.string().datetime(),
    limiterId: zod.string().uuid(),
    maxBonusPayPercent: zod.number(),
  }),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().optional(),
});

/**
 * Обновление максимальных бонусов владельца.
 * @summary Обновление максимальных бонусов владельца
 */
export const updateMaxBonusPayApiBonusV1MaxBonusPayIdPatchParams = zod.object({
  id: zod.string().uuid(),
});

export const updateMaxBonusPayApiBonusV1MaxBonusPayIdPatchBody = zod.object({
  limiterId: zod.string().uuid(),
  maxBonusPayPercent: zod.number(),
});

export const updateMaxBonusPayApiBonusV1MaxBonusPayIdPatchResponse = zod.object(
  {
    data: zod.object({
      id: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
      limiterId: zod.string().uuid(),
      maxBonusPayPercent: zod.number(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  }
);

/**
 * Получение списка по списанию бонусов ддя всех пользователей.
 * @summary Получение списка по списанию бонусов ддя всех пользователей
 */
export const getAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetQueryOffsetMin = 0;

export const getAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetQueryParams =
  zod.object({
    limit: zod.number().optional(),
    offset: zod
      .number()
      .min(getAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetQueryOffsetMin)
      .optional(),
    orderingType: zod.enum(['ASC', 'DESC']).optional(),
    orderingField: zod.string().or(zod.null()).optional(),
  });

export const getAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetResponse =
  zod.object({
    data: zod.array(
      zod.object({
        userId: zod.string().uuid(),
        isSpendBonuses: zod.boolean(),
        lastUpdate: zod.string().datetime(),
      })
    ),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Удаление информации по списанию бонусов для пользователя по user_id.
 * @summary Удаление информации по списанию бонусов для пользователя по user_id
 */
export const deleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteParams =
  zod.object({
    user_id: zod.string().uuid(),
  });

export const deleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteResponse =
  zod.object({
    data: zod.any().optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Получение информации по списанию бонусов для пользователя по user_id.
 * @summary Получение информации по списанию бонусов для пользователя по user_id
 */
export const getUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetParams =
  zod.object({
    user_id: zod.string().uuid(),
  });

export const getUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetResponse =
  zod.object({
    data: zod.object({
      userId: zod.string().uuid(),
      isSpendBonuses: zod.boolean(),
      lastUpdate: zod.string().datetime(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });

/**
 * Обновление информации по списанию бонусов для пользователя по user_id.
 * @summary Обновление информации по списанию бонусов для пользователя по user_id
 */
export const putUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutParams =
  zod.object({
    user_id: zod.string().uuid(),
  });

export const putUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutBody =
  zod.object({
    isSpendBonuses: zod.boolean(),
  });

export const putUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutResponse =
  zod.object({
    data: zod.object({
      userId: zod.string().uuid(),
      isSpendBonuses: zod.boolean(),
      lastUpdate: zod.string().datetime(),
    }),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().optional(),
  });
