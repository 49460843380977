import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { PopoverContentLayout } from 'shared/ui/ui-popover/content-layout';

import { CommandModal } from 'features/command';
import { Permission } from 'features/sidebar/types';
import { DeleteChargePointModal } from 'features/charge-point/delete';

import { AVAILABLE_COMMAND_TYPE } from '../consts';
import { AvailableCommandType } from '../model';

type Props = {
  chargePointId: string;
};

export function Actions({ chargePointId }: Props) {
  const ability = useAbility();

  const navigate = useNavigate();

  const showCommandModal = (type: AvailableCommandType) => {
    NiceModal.show(CommandModal, {
      commandType: type,
      chargePointId,
    });
  };

  const showDeleteChargePointModal = () => {
    NiceModal.show(DeleteChargePointModal, {
      chargePointId,
    });
  };

  const commands = ability.can('Execute', 'ChargePointServiceChargePoint') ? (
    <>
      {(
        Object.keys(AVAILABLE_COMMAND_TYPE) as unknown as Array<
          keyof typeof AVAILABLE_COMMAND_TYPE
        >
      ).map((type) => {
        return (
          <button
            key={AVAILABLE_COMMAND_TYPE[type]}
            onClick={() => {
              showCommandModal(type);
            }}
          >
            {AVAILABLE_COMMAND_TYPE[type]}
          </button>
        );
      })}
    </>
  ) : null;

  const CP_ACTIONS_PERMISSIONS: Permission[] = [
    { controller: 'CompanyService', right: 'Read' },
    { controller: 'BillingService', right: 'Read' },
    { controller: 'InformationService', right: 'Read' },
    { controller: 'ChargePointServiceHubs', right: 'Read' },
  ];

  const allowedCpActions = CP_ACTIONS_PERMISSIONS.every((el) => {
    const { controller, right } = el;

    return ability.can(right, controller);
  });

  const editChargePointButton = allowedCpActions ? (
    <button
      onClick={() => {
        navigate('edit');
      }}
    >
      Редактировать
    </button>
  ) : null;
  const deleteChargePointButton = ability.can('manage', 'all') ? (
    <button onClick={showDeleteChargePointModal}>Удалить</button>
  ) : null;

  const chargePointActions = (
    <>
      {editChargePointButton}
      {deleteChargePointButton}
    </>
  );

  const content = (
    <PopoverContentLayout contentGroups={[commands, chargePointActions]} />
  );

  if (
    !allowedCpActions &&
    !ability.can('Execute', 'ChargePointServiceChargePoint')
  ) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
