import { skipToken } from '@reduxjs/toolkit/query';

import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetReserveLocks } from 'shared/api/services/reserve/rtk/enhanced';
import { GetLocksResponse } from 'shared/api/services/reserve/rtk/generated';

export const useQueries = ({ chargePointId }: { chargePointId: string }) => {
  const chargePointQuery = useGetChargePoint(chargePointId);

  const connectorIds = chargePointQuery.data?.data?.connectors?.map(
    (el) => el.id
  );

  const locksQuery = useGetReserveLocks(
    connectorIds ? { reserveIds: connectorIds } : skipToken
  );

  const locksMapping = locksQuery.data?.data?.reduce((acc, el) => {
    return {
      ...acc,
      [el.reserveId]: el,
    };
  }, {} as Record<string, GetLocksResponse>);

  return {
    locksMapping,
    connectors: chargePointQuery.data?.data?.connectors,
    isLoading: chargePointQuery.isLoading || locksQuery.isLoading,
    isSuccess: chargePointQuery.isSuccess,
    errors: [chargePointQuery.error, locksQuery.error],
  };
};
