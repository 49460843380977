import { COUNTRY_ROUTES } from 'shared/consts/routes/country';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={COUNTRY_ROUTES.ADD_COUNTRY}
      permissions={[{ controller: 'InformationService', right: 'Write' }]}
    />
  );
}
