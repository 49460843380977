import { z } from 'zod';

import { useGetMobileApps } from 'shared/api/services/information/rtk';
import {
  useGetBindingLogs,
  useGetBindingLogsCount,
} from 'shared/api/services/payment/rtk/enhanced';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getApiPaymentV1BindingLogsQueryParams } from 'shared/api/services/payment/orval/zod/schemas';
import { getOffsetQueryParam } from 'shared/lib/schema';

import { getMobileAppsMapping } from 'entities/mobile-app';

const generatedSchema = getApiPaymentV1BindingLogsQueryParams;

const bindingLogsQueryParams = z.object({
  bankId: z.string().optional(),
  bankClientId: z.string().optional(),
  status: generatedSchema.shape.Status.optional(),
  orderNumber: z.string().optional(),
  bankOrderId: z.string().optional(),
  errorReason: generatedSchema.shape.ErrorReason.optional(),
  page: z.string().default('1').pipe(z.coerce.number().min(1)),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(),
});

const bindingLogsCountQueryParams = bindingLogsQueryParams.omit({
  page: true,
  orderingField: true,
  orderingType: true,
});

type Props = {
  limit: number;
  userId?: string;
};

export const useQueries = ({ limit, userId }: Props) => {
  const bindingLogsParams = useTypedQueryParamsCustom(bindingLogsQueryParams);
  const bindingLogsCountParams = useTypedQueryParamsCustom(
    bindingLogsCountQueryParams
  );

  const bindingLogsQuery = useGetBindingLogs({
    ...bindingLogsParams,
    offset: getOffsetQueryParam(bindingLogsParams.page, limit),
    limit,
    userId,
  });

  const bindingLogsCountQuery = useGetBindingLogsCount({
    ...bindingLogsCountParams,
    userId,
  });

  const mobileAppsQuery = useGetMobileApps();

  const isLoading =
    bindingLogsQuery.isLoading ||
    bindingLogsCountQuery.isLoading ||
    mobileAppsQuery.isLoading;

  const isFetching =
    bindingLogsQuery.isFetching || bindingLogsCountQuery.isFetching;

  const mobileAppsMapping = getMobileAppsMapping(
    mobileAppsQuery.data?.data || []
  );

  return {
    isLoading,
    isFetching,
    bindingLogs: bindingLogsQuery.data?.data ?? [],
    bindingLogsCount: bindingLogsCountQuery.data?.data ?? Infinity,
    mobileAppsMapping,
    bindingLogsError: bindingLogsQuery.error,
    bindingLogsCountError: bindingLogsCountQuery.error,
    mobileAppsError: mobileAppsQuery.error,
  };
};
