import NiceModal from '@ebay/nice-modal-react';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import { Modal } from '../modal';
import { StyledButton } from './styles';

type Props = {
  userId: string;
};

export function AddButton({ userId }: Props) {
  const onClick = () => {
    NiceModal.show(Modal, { userId });
  };

  return (
    <StyledButton onClick={onClick} type="button">
      <PlusInCircleIcon />
    </StyledButton>
  );
}
