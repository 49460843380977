/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UserService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Get assembly version.
 */
export const getApiUserV1AppInfoResponse = zod.string();

/**
 * @summary Get car connector by Id.
 */
export const getApiUserV1CarConnectorsCarConnectorIdParams = zod.object({
  carConnectorId: zod.string().uuid(),
});

export const getApiUserV1CarConnectorsCarConnectorIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      carId: zod.string().uuid(),
      type: zod.enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ]),
      isAdapterNeeded: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update car connector.
 */
export const patchApiUserV1CarConnectorsCarConnectorIdParams = zod.object({
  carConnectorId: zod.string().uuid(),
});

export const patchApiUserV1CarConnectorsCarConnectorIdBody = zod.object({
  carId: zod.string().uuid().nullish(),
  type: zod
    .enum([
      'SCHUKO',
      'TYPE2',
      'CCS2',
      'CHADEMO',
      'GBTDC',
      'GBTAC',
      'CCS1',
      'TYPE1',
      'TESLA',
    ])
    .optional(),
  isAdapterNeeded: zod.boolean().nullish(),
});

export const patchApiUserV1CarConnectorsCarConnectorIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      carId: zod.string().uuid(),
      type: zod.enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ]),
      isAdapterNeeded: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Remove car connector.
 */
export const deleteApiUserV1CarConnectorsCarConnectorIdParams = zod.object({
  carConnectorId: zod.string().uuid(),
});

export const deleteApiUserV1CarConnectorsCarConnectorIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get car connectors by filter.
 */
export const getApiUserV1CarConnectorsQueryParams = zod.object({
  CarId: zod.string().uuid(),
});

export const getApiUserV1CarConnectorsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        carId: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        isAdapterNeeded: zod.boolean(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Add car connector.
 */
export const postApiUserV1CarConnectorsBody = zod.object({
  carId: zod.string().uuid(),
  type: zod.enum([
    'SCHUKO',
    'TYPE2',
    'CCS2',
    'CHADEMO',
    'GBTDC',
    'GBTAC',
    'CCS1',
    'TYPE1',
    'TESLA',
  ]),
  isAdapterNeeded: zod.boolean(),
});

export const postApiUserV1CarConnectorsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      carId: zod.string().uuid(),
      type: zod.enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ]),
      isAdapterNeeded: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get car by Id.
 */
export const getApiUserV1CarsCarIdParams = zod.object({
  carId: zod.string().uuid(),
});

export const getApiUserV1CarsCarIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      userId: zod.string().uuid(),
      carModelId: zod.string().uuid(),
      connectors: zod.array(
        zod.object({
          id: zod.string().uuid(),
          type: zod.enum([
            'SCHUKO',
            'TYPE2',
            'CCS2',
            'CHADEMO',
            'GBTDC',
            'GBTAC',
            'CCS1',
            'TYPE1',
            'TESLA',
          ]),
          isAdapterNeeded: zod.boolean(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update car.
 */
export const patchApiUserV1CarsCarIdParams = zod.object({
  carId: zod.string().uuid(),
});

export const patchApiUserV1CarsCarIdBody = zod.object({
  name: zod.string().nullish(),
  userId: zod.string().uuid().nullish(),
  carModelId: zod.string().uuid().nullish(),
});

export const patchApiUserV1CarsCarIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      userId: zod.string().uuid(),
      carModelId: zod.string().uuid(),
      connectors: zod.array(
        zod.object({
          id: zod.string().uuid(),
          type: zod.enum([
            'SCHUKO',
            'TYPE2',
            'CCS2',
            'CHADEMO',
            'GBTDC',
            'GBTAC',
            'CCS1',
            'TYPE1',
            'TESLA',
          ]),
          isAdapterNeeded: zod.boolean(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete car.
 */
export const deleteApiUserV1CarsCarIdParams = zod.object({
  carId: zod.string().uuid(),
});

export const deleteApiUserV1CarsCarIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get cars by filter.
 */
export const getApiUserV1CarsQueryParams = zod.object({
  UserId: zod.string().uuid(),
});

export const getApiUserV1CarsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        userId: zod.string().uuid(),
        carModelId: zod.string().uuid(),
        connectors: zod.array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            isAdapterNeeded: zod.boolean(),
          })
        ),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create car.
 */
export const postApiUserV1CarsBody = zod.object({
  name: zod.string().min(1),
  userId: zod.string().uuid(),
  carModelId: zod.string().uuid(),
});

export const postApiUserV1CarsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      userId: zod.string().uuid(),
      carModelId: zod.string().uuid(),
      connectors: zod.array(
        zod.object({
          id: zod.string().uuid(),
          type: zod.enum([
            'SCHUKO',
            'TYPE2',
            'CCS2',
            'CHADEMO',
            'GBTDC',
            'GBTAC',
            'CCS1',
            'TYPE1',
            'TESLA',
          ]),
          isAdapterNeeded: zod.boolean(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get favorite charge points ids by filter.
 */
export const getApiUserV1FavoriteChargePointsQueryParams = zod.object({
  UserId: zod.string().uuid(),
});

export const getApiUserV1FavoriteChargePointsResponse = zod.object({
  data: zod.array(zod.string().uuid()).nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Add favorite charge point.
 */
export const postApiUserV1FavoriteChargePointsBody = zod.object({
  chargePointId: zod.string().uuid(),
  userId: zod.string().uuid(),
});

export const postApiUserV1FavoriteChargePointsResponse = zod.object({
  data: zod
    .object({
      chargePointId: zod.string().uuid(),
      userId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Remove favorite charge point.
 */
export const deleteApiUserV1FavoriteChargePointsBody = zod.object({
  chargePointId: zod.string().uuid(),
  userId: zod.string().uuid(),
});

export const deleteApiUserV1FavoriteChargePointsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get group by Id.
 */
export const getApiUserV1GroupsGroupIdParams = zod.object({
  groupId: zod.string().uuid(),
});

export const getApiUserV1GroupsGroupIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      comment: zod.string().nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      children: zod.array(zod.any()),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update group.
 */
export const patchApiUserV1GroupsGroupIdParams = zod.object({
  groupId: zod.string().uuid(),
});

export const patchApiUserV1GroupsGroupIdBody = zod.object({
  name: zod.string().nullish(),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean().nullish(),
});

export const patchApiUserV1GroupsGroupIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      comment: zod.string().nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      children: zod.array(zod.any()),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete group.
 */
export const deleteApiUserV1GroupsGroupIdParams = zod.object({
  groupId: zod.string().uuid(),
});

export const deleteApiUserV1GroupsGroupIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get groups by filter.
 */
export const getApiUserV1GroupsQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
});

export const getApiUserV1GroupsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        comment: zod.string().nullish(),
        parentId: zod.string().uuid().nullish(),
        isDefault: zod.boolean(),
        children: zod.array(zod.any()),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create group.
 */
export const postApiUserV1GroupsBody = zod.object({
  name: zod.string().min(1),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean(),
});

export const postApiUserV1GroupsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      comment: zod.string().nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      children: zod.array(zod.any()),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Send verification code to user.
 */
export const postApiUserV1LoginCodeBody = zod.object({
  login: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
});

export const postApiUserV1LoginCodeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get authorization tokens.
 */
export const postApiUserV1LoginBody = zod.object({
  login: zod.string().min(1),
  countryId: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
  code: zod.string().min(1),
});

export const postApiUserV1LoginResponse = zod.object({
  data: zod
    .object({
      accessToken: zod.string().min(1),
      refreshToken: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Refresh authorization tokens.
 */
export const postApiUserV1LoginRefreshTokensResponse = zod.object({
  data: zod
    .object({
      accessToken: zod.string().min(1),
      refreshToken: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Lock or unlock user.
If need to unlock - set LockDateTo to now.
 */
export const postApiUserV1LoginLockBody = zod.object({
  userId: zod.string().uuid(),
  lockDateTo: zod.string().datetime(),
});

export const postApiUserV1LoginLockResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @deprecated
 * @summary Send verification code to user.
 */
export const postApiUserV1OldActionLoginCodeBody = zod.object({
  login: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
});

export const postApiUserV1OldActionLoginCodeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @deprecated
 * @summary Get session token.
 */
export const postApiUserV1OldActionLoginBody = zod.object({
  login: zod.string().min(1),
  countryId: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
  code: zod.string().min(1),
});

export const postApiUserV1OldActionLoginResponse = zod.object({
  data: zod
    .object({
      sessionToken: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @deprecated
 * @summary Get session user by token.
 */
export const postApiUserV1OldActionGetsessionQueryParams = zod.object({
  sessionToken: zod.string().optional(),
});

export const postApiUserV1OldActionGetsessionResponse = zod.object({
  data: zod
    .object({
      userId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get user by Id.
 */
export const getApiUserV1UsersUserIdParams = zod.object({
  userId: zod.string().uuid(),
});

export const getApiUserV1UsersUserIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      login: zod.string().min(1),
      mobileAppId: zod.string().uuid(),
      lockoutEndDate: zod.string().datetime(),
      email: zod.string().nullish(),
      firstName: zod.string().nullish(),
      lastName: zod.string().nullish(),
      middleName: zod.string().nullish(),
      birthDate: zod.string().datetime().nullish(),
      sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
      registrationDate: zod.string().datetime(),
      countryId: zod.string().min(1),
      iconUrl: zod.string().nullish(),
      groupId: zod.string().uuid().nullish(),
      notesRelatedWithUser: zod.string().nullish(),
      userAgreementAccepted: zod.boolean(),
      interfaceLanguage: zod.enum(['RU', 'BY', 'HY', 'KZ', 'KY']),
      isDebug: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update user.
 */
export const patchApiUserV1UsersUserIdParams = zod.object({
  userId: zod.string().uuid(),
});

export const patchApiUserV1UsersUserIdBody = zod.object({
  login: zod.string().nullish(),
  mobileAppId: zod.string().uuid().nullish(),
  lockoutEndDate: zod.string().datetime().nullish(),
  email: zod.string().nullish(),
  firstName: zod.string().nullish(),
  lastName: zod.string().nullish(),
  middleName: zod.string().nullish(),
  birthDate: zod.string().datetime().nullish(),
  sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
  countryId: zod.string().nullish(),
  iconUrl: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  notesRelatedWithUser: zod.string().nullish(),
  userAgreementAccepted: zod.boolean().nullish(),
  interfaceLanguage: zod.enum(['RU', 'BY', 'HY', 'KZ', 'KY']).optional(),
  isDebug: zod.boolean().nullish(),
});

export const patchApiUserV1UsersUserIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      login: zod.string().min(1),
      mobileAppId: zod.string().uuid(),
      lockoutEndDate: zod.string().datetime(),
      email: zod.string().nullish(),
      firstName: zod.string().nullish(),
      lastName: zod.string().nullish(),
      middleName: zod.string().nullish(),
      birthDate: zod.string().datetime().nullish(),
      sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
      registrationDate: zod.string().datetime(),
      countryId: zod.string().min(1),
      iconUrl: zod.string().nullish(),
      groupId: zod.string().uuid().nullish(),
      notesRelatedWithUser: zod.string().nullish(),
      userAgreementAccepted: zod.boolean(),
      interfaceLanguage: zod.enum(['RU', 'BY', 'HY', 'KZ', 'KY']),
      isDebug: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete user.
 */
export const deleteApiUserV1UsersUserIdParams = zod.object({
  userId: zod.string().uuid(),
});

export const deleteApiUserV1UsersUserIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get users by filter.
 */
export const getApiUserV1UsersQueryParams = zod.object({
  Login: zod.string().optional(),
  MobileAppId: zod.string().uuid().optional(),
  RegistrationDateFrom: zod.string().datetime().optional(),
  RegistrationDateTo: zod.string().datetime().optional(),
  GroupId: zod.string().uuid().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiUserV1UsersResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        login: zod.string().min(1),
        mobileAppId: zod.string().uuid(),
        lockoutEndDate: zod.string().datetime(),
        email: zod.string().nullish(),
        firstName: zod.string().nullish(),
        lastName: zod.string().nullish(),
        middleName: zod.string().nullish(),
        birthDate: zod.string().datetime().nullish(),
        sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
        registrationDate: zod.string().datetime(),
        countryId: zod.string().min(1),
        iconUrl: zod.string().nullish(),
        groupId: zod.string().uuid().nullish(),
        notesRelatedWithUser: zod.string().nullish(),
        userAgreementAccepted: zod.boolean(),
        interfaceLanguage: zod.enum(['RU', 'BY', 'HY', 'KZ', 'KY']),
        isDebug: zod.boolean(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create user.
 */
export const postApiUserV1UsersBody = zod.object({
  login: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
  lockoutEndDate: zod.string().datetime().nullish(),
  email: zod.string().nullish(),
  firstName: zod.string().nullish(),
  lastName: zod.string().nullish(),
  middleName: zod.string().nullish(),
  birthDate: zod.string().datetime().nullish(),
  sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
  countryId: zod.string().min(1),
  iconUrl: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  notesRelatedWithUser: zod.string().nullish(),
  userAgreementAccepted: zod.boolean(),
  interfaceLanguage: zod.enum(['RU', 'BY', 'HY', 'KZ', 'KY']).optional(),
  isDebug: zod.boolean().nullish(),
});

export const postApiUserV1UsersResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      login: zod.string().min(1),
      mobileAppId: zod.string().uuid(),
      lockoutEndDate: zod.string().datetime(),
      email: zod.string().nullish(),
      firstName: zod.string().nullish(),
      lastName: zod.string().nullish(),
      middleName: zod.string().nullish(),
      birthDate: zod.string().datetime().nullish(),
      sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
      registrationDate: zod.string().datetime(),
      countryId: zod.string().min(1),
      iconUrl: zod.string().nullish(),
      groupId: zod.string().uuid().nullish(),
      notesRelatedWithUser: zod.string().nullish(),
      userAgreementAccepted: zod.boolean(),
      interfaceLanguage: zod.enum(['RU', 'BY', 'HY', 'KZ', 'KY']),
      isDebug: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get users count by filter.
 */
export const getApiUserV1UsersCountQueryParams = zod.object({
  Login: zod.string().optional(),
  MobileAppId: zod.string().uuid().optional(),
  RegistrationDateFrom: zod.string().datetime().optional(),
  RegistrationDateTo: zod.string().datetime().optional(),
  GroupId: zod.string().uuid().optional(),
});

export const getApiUserV1UsersCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
