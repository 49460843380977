import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ROLE } from 'shared/consts/routes/identity-role';
import {
  ADD_PREFIX,
  CONTROLLER_CLAIMS,
  IDENTITY_ROLES,
  IDENTITY_USERS,
  CHARGE_POINT_RIGHTS,
} from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';
import { IdentityRolesLayout } from 'entities/identity-role/ui/roles-layout';
import { IdentityRoleLayout } from 'entities/identity-role/ui/role-layout';

import { IdentityRolesMenu } from 'features/identity-role/menu';
import { RoleListTable } from 'features/identity-role/table';
import { RoleUserListTable } from 'features/identity-role/users-table';
import { IdentityRoleControllerClaimsForm } from 'features/identity-role/controller-claims';
import { IdentityRoleCpRightsForm } from 'features/identity-role/charge-point-rights';

import { AddIdentityRolePage } from 'pages/identity-role/add';
import { EditIdentityRolePage } from 'pages/identity-role/edit';

export const RoleRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[{ controller: 'IdentityService', right: 'Read' }]}
      />
    }
  >
    <Route
      path={IDENTITY_ROLES}
      element={<SectionLayout menu={<IdentityRolesMenu />} />}
    >
      <Route element={<IdentityRolesLayout />}>
        <Route index element={<RoleListTable />} />
      </Route>

      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'IdentityService', right: 'Write' }]}
          />
        }
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityRolePage />} />}
        />
      </Route>
      <Route path={ROLE} element={<IdentityRoleLayout />}>
        <Route index element={<EditIdentityRolePage />} />

        <Route
          path={CONTROLLER_CLAIMS}
          element={<IdentityRoleControllerClaimsForm />}
        />

        <Route
          path={CHARGE_POINT_RIGHTS}
          element={<IdentityRoleCpRightsForm />}
        />
        <Route path={IDENTITY_USERS} element={<RoleUserListTable />} />
      </Route>
    </Route>
  </Route>
);
