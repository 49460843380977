import { z } from 'zod';

import { optionalZodDayToISO } from 'shared/lib/schema';

export const FormSchema = z.object({
  bankId: z.string().optional(),
  mobileAppId: z.string().optional(),
  dateRange: z.tuple([optionalZodDayToISO, optionalZodDayToISO]).nullish(),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  dateRange: undefined,
};

export const queryParamsSchema = z.object({
  bankId: z.string().optional(),
  mobileAppId: z.string().optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
});
