import { useState } from 'react';

import {
  enhancedApi,
  useCreateBillingBalanceMutation,
  useSetBillingBalanceMutation,
} from 'shared/api/services/billing/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Баланс создан';
const ERROR_MSG = 'Не удалось создать баланс';

type Props = {
  closeModal: () => void;
};

export const useCreateBillingBalance = ({ closeModal }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [createTrigger] = useCreateBillingBalanceMutation();
  const [setTrigger] = useSetBillingBalanceMutation();

  const dispatch = useAppDispatch();

  const handleCreate = async ({
    data,
    userId,
  }: {
    data: FormOutput;
    userId: string;
  }) => {
    const { balanceName, countryId, value } = data;

    try {
      setIsLoading(true);

      const createRes = await createTrigger({
        userId,
        balanceName,
        countryId,
      }).unwrap();

      const balanceId = createRes.data;

      if (!balanceId) {
        throw 'Неизвестная ошибка';
      }

      const setRes = await setTrigger({ balanceId, value });

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BillingBalances', id: userId },
        ])
      );

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreate,
    isLoading,
  };
};
