export const CREATE_ENTITY_PREFIX = 'create';

export { COLOR, DEFAULT_BORDER } from './colors';

export { PERCENT_INPUT_HTML_SIZE } from './dom';

export {
  ACCEPTED_IMAGE_EXTENSIONS,
  IMAGE_MAX_SIZE,
  IMAGE_EXTENSION_ERROR,
  IMAGE_SIZE_ERROR,
} from './image';

export { POLLING_INTERVAL } from './server';
