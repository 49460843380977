import { Col, Divider, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { useEffect, useMemo } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { DeleteIcon } from 'shared/icons/delete';
import {
  ServicesListDto,
  ServicesListLinkDto,
  ServiceType,
  StageWithTariffDto,
  TariffDto,
} from 'shared/api/services/billing/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import {
  AddStageButton,
  AddServiceButton,
  getTariffOptionsList,
  getTariffOptionLabel,
  STAGE_END_TYPE,
} from 'entities/billing';

import { AddTariffButton } from 'features/billing/add-tariff';
import { DeleteStageModal } from 'features/billing/delete-stage';
import { DeleteServiceModal } from 'features/billing/delete-service';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';
import { useUpdateServicesList } from '../hooks/use-update-services-list';
import { getStageNextSerialNumber } from '../lib/get-stage-next-serial-number';
import {
  ControlButton,
  FormFieldsContainer,
  FormFieldsGroup,
  FormFieldsGroupHeader,
  Label,
  ServiceTitle,
} from './styles';
import { useAbility } from 'shared/lib/ability/context';

const getDefaultValues = (
  servicesList: ServicesListDto,
  servicesListLinks: ServicesListLinkDto[]
): FormInput => {
  const { name } = servicesList;

  return {
    name,
    chargePointIds: servicesListLinks.length
      ? servicesListLinks.map((el) => el.targetId)
      : [],
  };
};

type Props = {
  servicesList: ServicesListDto;
  tariffs: TariffDto[];
  countries: GetCountryVm[];
  chargePoints: GetChargePointVm[];
  servicesListLinks: ServicesListLinkDto[];
};

export function Form({
  tariffs,
  servicesList,
  countries,
  chargePoints,
  servicesListLinks,
}: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: useMemo(
      () => getDefaultValues(servicesList, servicesListLinks),
      [servicesList, servicesListLinks]
    ),
  });

  useEffect(() => {
    form.reset({ ...getDefaultValues(servicesList, servicesListLinks) });
  }, [servicesList, servicesListLinks]);

  const { handleUpdate, isLoading } = useUpdateServicesList();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ data, servicesList, servicesListLinks });
  });

  return (
    <UiCard>
      <UiCard.Header>{servicesList.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                tariffs={tariffs}
                countries={countries}
                chargePoints={chargePoints}
                servicesList={servicesList}
              />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = Omit<Props, 'servicesListLinks'>;

Form.Fields = function Fields({
  tariffs,
  countries,
  chargePoints,
  servicesList,
}: FieldsProps) {
  const {
    getValues,
    control,
    watch,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const tariffOptions = useMemo(() => {
    return getTariffOptionsList(tariffs, countries);
  }, [tariffs, countries]);

  const reservingFieldArray = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const chargingFieldArray = useFieldArray({
    name: 'chargingStages',
    control,
  });

  const services =
    servicesList.services?.reduce((acc, el) => {
      if (el.type === 'CHARGING') {
        if (!acc.CHARGING) {
          acc.CHARGING = {
            stages: [],
            id: el.id,
          };
        }

        if (el.stages) {
          acc.CHARGING.stages = [...acc.CHARGING.stages, ...el.stages];
        }

        return acc;
      }

      if (el.type === 'RESERVING') {
        if (!acc.RESERVING) {
          acc.RESERVING = {
            stages: [],
            id: el.id,
          };
        }

        if (el.stages) {
          acc.RESERVING.stages = [...acc.RESERVING.stages, ...el.stages];
        }

        return acc;
      }
      return acc;
    }, {} as Partial<Record<ServiceType, { id: string; stages: StageWithTariffDto[] }>>) ||
    {};

  const { CHARGING, RESERVING } = services;

  const reservingStagesMaxSerialNumber = useMemo(() => {
    return getStageNextSerialNumber(RESERVING?.stages);
  }, [RESERVING]);
  const chargingStagesMaxSerialNumber = useMemo(() => {
    return getStageNextSerialNumber(CHARGING?.stages);
  }, [CHARGING]);

  const showDeleteServiceModal = (serviceId: string) => {
    NiceModal.show(DeleteServiceModal, {
      serviceId,
      servicesListId: servicesList.id,
    });
  };

  const showDeleteStageModal = (stageId: string) => {
    NiceModal.show(DeleteStageModal, {
      stageId,
      servicesListId: servicesList.id,
    });
  };

  const renderReservingService = () => {
    if (!RESERVING && !reservingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Бронирование"
          onClick={() => {
            reservingFieldArray.append({
              name: '',
              serialNumber: '0',
              tariffId: '',
              endConditionValue: '0',
            });
          }}
        />
      );
    }

    return (
      <>
        <FormFieldsGroupHeader>
          <ServiceTitle>Услуга Бронирование</ServiceTitle>
          {RESERVING?.id && (
            <ControlButton
              onClick={() => showDeleteServiceModal(RESERVING?.id)}
              type="button"
            >
              <DeleteIcon />
            </ControlButton>
          )}
        </FormFieldsGroupHeader>

        {RESERVING?.stages
          ?.sort((a, b) => a.serialNumber - b.serialNumber)
          .map((el, idx) => {
            const { name, tariff, endType, endConditionValue, id } = el;

            return (
              <FormRow gutter={12} wrap={false}>
                <Col flex="auto">
                  <FormRow gutter={20}>
                    <Col span={6}>
                      {idx === 0 ? <Label>Название стадии услуги</Label> : null}
                      <UiInput value={name} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Стоимость</Label> : null}
                      <UiSelect
                        value={getTariffOptionLabel(tariff, countries)}
                        disabled
                      />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Триггер окончания</Label> : null}
                      <UiSelect value={STAGE_END_TYPE[endType]} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? (
                        <Label>Значение % батареи / мин</Label>
                      ) : null}
                      <UiInput value={endConditionValue} disabled />
                    </Col>
                  </FormRow>
                </Col>
                <Col flex="60px" style={{ alignSelf: 'center' }}>
                  <Space size={20}>
                    <ControlButton
                      onClick={() => showDeleteStageModal(id)}
                      type="button"
                    >
                      <DeleteIcon />
                    </ControlButton>
                  </Space>
                </Col>
              </FormRow>
            );
          })}

        {reservingFieldArray.fields.map((field, index) => {
          const endType = watch(`reservingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={tariffOptions}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton countries={countries} />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => reservingFieldArray.remove(index)}
                    type="button"
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() => {
            reservingFieldArray.append({
              name: '',
              serialNumber: String(
                (reservingStagesMaxSerialNumber ?? 0) +
                  reservingFieldArray.fields.length
              ),
              tariffId: '',
              endConditionValue: '0',
            });
          }}
        />
      </>
    );
  };

  const renderChargingService = () => {
    if (!CHARGING && !chargingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Заряд"
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: '0',
              tariffId: '',
              endConditionValue: '0',
            })
          }
        />
      );
    }

    return (
      <>
        <FormFieldsGroupHeader>
          <ServiceTitle>Услуга Заряд</ServiceTitle>
          {CHARGING?.id && (
            <ControlButton
              onClick={() => showDeleteServiceModal(CHARGING?.id)}
              type="button"
            >
              <DeleteIcon />
            </ControlButton>
          )}
        </FormFieldsGroupHeader>
        {CHARGING?.stages
          ?.sort((a, b) => a.serialNumber - b.serialNumber)
          .map((el, idx) => {
            const { name, tariff, endType, endConditionValue, id } = el;

            return (
              <FormRow gutter={12} wrap={false}>
                <Col flex="auto">
                  <FormRow gutter={20}>
                    <Col span={6}>
                      {idx === 0 ? <Label>Название стадии услуги</Label> : null}
                      <UiInput value={name} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Стоимость</Label> : null}
                      <UiSelect
                        value={getTariffOptionLabel(tariff, countries)}
                        disabled
                      />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? <Label>Триггер окончания</Label> : null}
                      <UiSelect value={STAGE_END_TYPE[endType]} disabled />
                    </Col>
                    <Col span={6}>
                      {idx === 0 ? (
                        <Label>Значение % батареи / мин</Label>
                      ) : null}
                      <UiInput value={endConditionValue} disabled />
                    </Col>
                  </FormRow>
                </Col>
                <Col flex="60px" style={{ alignSelf: 'center' }}>
                  <Space size={20}>
                    <ControlButton
                      onClick={() => showDeleteStageModal(id)}
                      type="button"
                    >
                      <DeleteIcon />
                    </ControlButton>
                  </Space>
                </Col>
              </FormRow>
            );
          })}

        {chargingFieldArray.fields.map((field, index) => {
          const endType = watch(`chargingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={tariffOptions}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton countries={countries} />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => chargingFieldArray.remove(index)}
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: String(
                (chargingStagesMaxSerialNumber ?? 0) +
                  chargingFieldArray.fields.length
              ),
              tariffId: '',
              endConditionValue: '0',
            })
          }
        />
      </>
    );
  };

  const renderChargePoints = () => {
    return (
      <>
        <FormFieldsGroupHeader>
          <ServiceTitle>Выбрать ЭЗС</ServiceTitle>
        </FormFieldsGroupHeader>

        <FormRow>
          <Col span={18}>
            <CustomSelect<FormInput>
              name="chargePointIds"
              options={chargePoints.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              label="ЭЗС"
              mode="multiple"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              allowClear
            />
          </Col>
        </FormRow>
      </>
    );
  };

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomInput<FormInput>
              name="name"
              label="Название списка услуг"
              required
            />
          </Col>
        </FormRow>
      </FormFieldsGroup>

      <FormFieldsGroup>{renderReservingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargePoints()}</FormFieldsGroup>
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  const ability = useAbility();

  if (!ability.can('Write', 'BillingService')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
