import { MoreOutlined } from '@ant-design/icons';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { GetSessionResponse } from 'shared/api/services/reserve/rtk/generated';
import { useResetReserve } from 'shared/api/services/reserve/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import dayjs from 'dayjs';

type Props = {
  session: GetSessionResponse;
};

const SUCCESS_MSG = 'Команда отправлена';
const ERROR_MSG = 'Не удалось отправить команду';

export function TableActions({ session }: Props) {
  const ability = useAbility();

  const [trigger, {}] = useResetReserve();

  const { userId, reserveId, dateToReserve } = session;

  const isReserveActive = dayjs(dateToReserve).isAfter(dayjs());

  const handleClick = async () => {
    try {
      const res = await trigger({ reserveId, userId }).unwrap();

      openSuccessNotification(SUCCESS_MSG);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  const content = (
    <>
      <button onClick={handleClick}>Завершить резерв</button>
    </>
  );

  if (!ability.can('Execute', 'ReserveService')) {
    return null;
  }

  if (!isReserveActive) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
