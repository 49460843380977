import { Col, Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useState } from 'react';

import { FormRow } from 'shared/ui/form';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import {
  WebApiDtoRoleDto,
  WebApiDtoRoleWithUsersDto,
} from 'shared/api/services/identity/rtk/generated';
import { updateUserRoles } from 'shared/api/services/identity/rtk/enhanced/thunks';
import { useAppDispatch } from 'shared/redux/types';
import { useAbility } from 'shared/lib/ability/context';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';
import { StyledTable, TableContainer } from './styles';

const getDefaultValues = (
  roles: WebApiDtoRoleWithUsersDto[],
  userName: string
) => {
  return roles.reduce((acc, el) => {
    acc[el.name as string] = Boolean(
      el.users?.find((user) => user.userName === userName)
    );

    return acc;
  }, {} as Record<string, boolean>);
};

type Props = {
  userName: string;
  roles: WebApiDtoRoleWithUsersDto[];
  userRoles: WebApiDtoRoleDto[];
};

export function Form({ userName, roles, userRoles }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(roles, userName),
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleSubmit = form.handleSubmit(async (data) => {
    const acc = Object.keys(data).reduce(
      (acc, el) => {
        const prevHasRole = Boolean(
          roles
            .find((role) => role?.name === el)
            ?.users?.find((user) => user?.userName === userName)
        );

        if (data[el] === true && !prevHasRole) {
          acc.add = [...acc.add, el];
        }

        if (data[el] === false && prevHasRole) {
          acc.delete = [...acc.delete, el];
        }

        return acc;
      },
      {
        add: [],
        delete: [],
      } as Record<'add' | 'delete', string[]>
    );

    try {
      setIsLoading(true);

      await dispatch(
        updateUserRoles({
          addRoles: acc.add,
          deleteRoles: acc.delete,
          userName,
        })
      );

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields roles={roles} />
          <Form.Buttons />
        </form>
      </Spin>
    </FormProvider>
  );
}

type FieldsProps = Pick<Props, 'roles'>;

Form.Fields = function Fields({ roles }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const renderFormTable = () => {
    return (
      <StyledTable>
        <thead>
          <tr>
            <th>Роль</th>
            <th>Добавить</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((el) => {
            return (
              <tr key={el.id}>
                <td>{el.name}</td>
                <td>
                  <CustomCheckbox<FormInput> name={el.name} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    );
  };

  return <TableContainer>{renderFormTable()}</TableContainer>;
};

Form.Buttons = function Buttons() {
  const ability = useAbility();

  if (!ability.can('Write', 'IdentityService')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
