import { ExportButton } from 'shared/ui';

import { exportInExcel } from '../../lib/export-in-excel';

const TITLE = 'Журнал сессий';

type Props = {
  tableRef: React.MutableRefObject<null>;
  disabled: boolean;
  columns: Object[];
};

export function ExportTableButton({ disabled, tableRef, columns }: Props) {
  return (
    <ExportButton
      onClick={() =>
        exportInExcel({
          tableRef,
          title: TITLE,
          columns,
        })
      }
      disabled={disabled}
    />
  );
}
