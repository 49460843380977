import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

export function TableContainer() {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    groups,
    groupsCount,
    groupsError,
    groupsCountError,
  } = useQueries({
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (groupsError) {
    return <ErrorMessage text={getServerErrorText(groupsError)} />;
  }

  if (groupsCountError) {
    return <ErrorMessage text={getServerErrorText(groupsCountError)} />;
  }

  return (
    <TableInstance
      data={groups}
      totalCount={groupsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
