import { Link } from 'react-router-dom';

import {
  BILLINGS,
  BILLING_TRANSACTIONS,
  PAYMENTS,
  RESERVES,
  ROOT,
  TRANSACTIONS,
} from 'shared/consts/names';
import { BINDING_LOGS, PROFILE } from 'shared/consts/routes/user';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { BILLING_DEFAULT_SEARCH_PARAMS } from 'entities/billing';
import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';
import { PAYMENT_DEFAULT_SEARCH_PARAMS } from 'entities/payment/consts';
import { BILLING_TRANSACTIONS_DEFAULT_SEARCH_PARAMS } from 'entities/billing/consts';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: (
      <Link
        to={{
          pathname: '',
          search: SESSIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал сессий
      </Link>
    ),
    permissions: [
      { controller: 'ChargePointServiceChargePoint', right: 'Read' },
    ],
  },
  {
    key: RESERVES,
    label: <Link to={RESERVES}>Журнал брони</Link>,
    permissions: [{ controller: 'ReserveService', right: 'Read' }],
  },
  {
    key: PAYMENTS,
    label: (
      <Link
        to={{
          pathname: PAYMENTS,
          search: PAYMENT_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал оплат
      </Link>
    ),
    permissions: [{ controller: 'PaymentService', right: 'Read' }],
  },
  {
    key: BILLINGS,
    label: (
      <Link
        to={{
          pathname: BILLINGS,
          search: BILLING_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал услуг
      </Link>
    ),
    permissions: [
      { controller: 'BillingService', right: 'Read' },
      { controller: 'InformationService', right: 'Read' },
    ],
  },
  {
    key: 'bindings',
    label: <Link to="bindings">Статус привязки БК</Link>,
    permissions: [{ controller: 'PaymentService', right: 'Read' }],
  },
  {
    key: BINDING_LOGS,
    label: <Link to={BINDING_LOGS}>Журнал привязки БК</Link>,
  },
  {
    key: PROFILE,
    label: <Link to={PROFILE}>Профиль пользователя</Link>,
  },
  {
    key: TRANSACTIONS,
    label: <Link to={TRANSACTIONS}>Бонусные баллы</Link>,
    permissions: [{ controller: 'BonusService', right: 'Read' }],
  },
  {
    key: BILLING_TRANSACTIONS,
    label: (
      <Link
        to={{
          pathname: BILLING_TRANSACTIONS,
          search: BILLING_TRANSACTIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Балансы
      </Link>
    ),
    permissions: [{ controller: 'BillingService', right: 'Read' }],
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
