/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * AnalyticsService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

export const getApiAnalyticsReportACQUIRINGQueryParams = zod.object({
  BankId: zod.string().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  MobileAppId: zod.string().uuid().optional(),
});

export const getApiAnalyticsReportACQUIRINGResponse = zod.object({
  data: zod
    .object({
      billings: zod
        .array(
          zod.object({
            userLogin: zod.string().nullish(),
            companyINN: zod.string().nullish(),
            companyAgentReceipt: zod.boolean().optional(),
            createdDate: zod.string().datetime().optional(),
            chargePointName: zod.string().nullish(),
            chargePointAddress: zod.string().nullish(),
            invoiceSum: zod.number().optional(),
            paymentDate: zod.string().datetime().nullish(),
            bonus: zod.number().optional(),
          })
        )
        .nullish(),
      count: zod.number().optional(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiAnalyticsReportUploadBillingsBody = zod.object({
  billingIds: zod.array(zod.string().uuid()).nullish(),
  dateFrom: zod.string().datetime().nullish(),
  dateTo: zod.string().datetime().nullish(),
});

export const postApiAnalyticsReportUploadBillingsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const getApiAnalyticsReportGetPaymentsReportQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  ChargePointsId: zod.array(zod.string().uuid()).optional(),
  GroupsId: zod.array(zod.string().uuid()).optional(),
  CompaniesId: zod.array(zod.string().uuid()).optional(),
  MobileAppsId: zod.array(zod.string().uuid()).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  GroupBy: zod.enum(['DAILY', 'MONTH', 'PERIOD', 'NONE']).optional(),
});

export const getApiAnalyticsReportGetPaymentsReportResponse = zod.object({
  data: zod
    .object({
      billings: zod
        .array(
          zod.object({
            paymentDate: zod.string().datetime().nullish(),
            chargePointName: zod.string().nullable(),
            balanceName: zod.enum(['PERSONAL', 'BUSINESS']),
            connectorName: zod.string().nullable(),
            chargePointAddress: zod.string().nullable(),
            invoiceSum: zod.number(),
            payment: zod.number(),
            bonusesPayment: zod.number(),
            discount: zod.number(),
            benefitPayment: zod.number(),
            balancePayment: zod.number(),
            energy: zod.number(),
          })
        )
        .nullable(),
      count: zod.number().optional(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
