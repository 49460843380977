import { enhancedApi as api } from '../generated/role-group-cp-rights';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1RoleGroupCpRights: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'RoleGroupCpRights', id: arg },
      ];
    },
  },
});

export const {
  useGetApiChargepointV1RoleGroupCpRightsQuery: useGetRoleGroupCpRights,
  usePostApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsMutation:
    useSetRoleGroupCpRights,
} = enhancedApi;
