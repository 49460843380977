import { Route } from 'react-router-dom';

import { MonitoringSidebar } from 'features/sidebar';

import { ChapterLayout } from 'widgets/layout/chapter';

import { MonitoringPage } from 'pages/monitoring';
import { NotFoundPage } from 'pages/not-found';

import { PushNotificationRoutes } from '../push-notification';
import { HubRoutes } from '../hub';
import { ChargePointRoutes } from '../charge-point';
import { UserRoutes } from '../user';

import { routes as BillingRoutes } from '../billing';
import { routes as BonusProgramRoutes } from '../bonus-program';
import { routes as ReportRoutes } from '../report';
import { routes as BalancingGroupRoutes } from '../balancing-group';

export const MonitoringRoutes = (
  <Route path="/" element={<ChapterLayout siderbar={<MonitoringSidebar />} />}>
    <Route index element={<MonitoringPage />} />
    {ChargePointRoutes}
    {HubRoutes}
    {UserRoutes}
    {BillingRoutes}
    {PushNotificationRoutes}
    {BonusProgramRoutes}
    {ReportRoutes}
    {BalancingGroupRoutes}
    <Route path="*" element={<NotFoundPage />} />
  </Route>
);
