import { GetRoleCpRightVm } from 'shared/api/services/chargepoint/rtk/generated/role-cp-rights';
import { GetRoleGroupCpRightVm } from 'shared/api/services/chargepoint/rtk/generated/role-group-cp-rights';
import { GetUserCpRightVm } from 'shared/api/services/chargepoint/rtk/generated/user-cp-rights';
import { GetUserGroupCpRightVm } from 'shared/api/services/chargepoint/rtk/generated/user-group-cp-rights';

export const CP_PREFIX = 'cp';
export const GROUP_PREFIX = 'group';
export const DELIMETER = '/';

export type ChargePointRightTableItem = {
  id: string;
  name: string;
  read: boolean;
  write: boolean;
  execute: boolean;
  subRows?: ChargePointRightTableItem[];
  isGroup?: boolean;
};

export type CpRight = Omit<GetUserCpRightVm, 'userName'> &
  Omit<GetRoleCpRightVm, 'roleName'>;

export type CpGroupRight = Omit<GetUserGroupCpRightVm, 'userName'> &
  Omit<GetRoleGroupCpRightVm, 'roleName'>;

export type CpRweRight = Omit<CpRight, 'id'>;
export type CpGroupRweRight = Omit<CpGroupRight, 'id'>;
