import { Link, useNavigate } from 'react-router-dom';

import { CREATE_ENTITY_PREFIX } from 'shared/consts';
import { useAbility } from 'shared/lib/ability/context';
import { NavigationTabButton } from 'shared/ui';

export function AddChargePointGroupLink() {
  const ability = useAbility();

  if (!ability.can('Write', 'ChargePointServiceGroup')) {
    return null;
  }

  const path = `/charge-points/groups/${CREATE_ENTITY_PREFIX}`;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
