import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { BALANCING_GROUP } from 'shared/consts/routes/balancing';
import { ADD_PREFIX, BALANCING_GROUPS } from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';

import { BalancingGroupsMenu } from 'features/balancing/menu';

import { AddBalancingGroupPage } from 'pages/balancing/add';
import { EditBalancingGroupPage } from 'pages/balancing/edit';

export const routes = (
  <Route
    element={
      <RequireAuth
        permissions={[
          { controller: 'ChargePointServiceChargePoint', right: 'Read' },
          { controller: 'BalancingService', right: 'Read' },
        ]}
      />
    }
  >
    <Route
      path={BALANCING_GROUPS}
      element={<SectionLayout menu={<BalancingGroupsMenu />} />}
    >
      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'BalancingService', right: 'Write' }]}
          />
        }
      >
        <Route path={ADD_PREFIX} element={<AddBalancingGroupPage />} />
      </Route>
      <Route path={BALANCING_GROUP} element={<EditBalancingGroupPage />} />
    </Route>
  </Route>
);
