import { useState } from 'react';

import { useGetReportAcquiring } from 'shared/api/services/analytics/rtk/enhanced';
import {
  useGetCountries,
  useGetMobileApps,
} from 'shared/api/services/information/rtk';
import { useGetBanks } from 'shared/api/services/payment/rtk/enhanced';
import { getServerErrorText } from 'shared/lib';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { queryParamsSchema } from '../consts';
import { AcquiringReportLayout } from './layout';

export function TableContainer() {
  const [skip, setSkip] = useState(true);

  const queryParams = useTypedQueryParamsCustom(queryParamsSchema);

  const banksQuery = useGetBanks(undefined);
  const countriesQuery = useGetCountries(undefined);
  const reportQuery = useGetReportAcquiring(queryParams, {
    skip,
  });
  const mobileAppsQuery = useGetMobileApps();

  const allowFetch = () => setSkip(false);

  const isLoading =
    banksQuery.isLoading ||
    countriesQuery.isLoading ||
    mobileAppsQuery.isLoading;

  const isFetching = reportQuery.isFetching;

  const error = [
    banksQuery.error,
    countriesQuery.error,
    reportQuery.error,
    mobileAppsQuery.error,
  ].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !banksQuery.data?.data ||
    !countriesQuery.data?.data ||
    !mobileAppsQuery.data?.data
  ) {
    return <EmptyData />;
  }

  return (
    <AcquiringReportLayout
      {...queryParams}
      isLoading={isFetching}
      banks={banksQuery.data.data}
      countries={countriesQuery.data.data}
      mobileApps={mobileAppsQuery.data.data}
      billings={reportQuery.data?.data?.billings ?? []}
      allowFetch={allowFetch}
    />
  );
}
