import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import {
  useGetBonusPrograms,
  useGetBonusProgramsCount,
} from 'shared/api/services/bonus/rtk/enhanced';

const filteringQueryParams = z.object({});

const bonusProgramsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const bonusProgramsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
};

export const useQueries = ({ limit }: Props) => {
  const bonusProgramsParams = useTypedQueryParamsCustom(
    bonusProgramsQueryParams
  );
  const bonusProgramsCountParams = useTypedQueryParamsCustom(
    bonusProgramsCountQueryParams
  );

  const bonusProgramsQuery = useGetBonusPrograms({
    ...bonusProgramsParams,
    offset: getOffsetQueryParam(bonusProgramsParams.page, limit),
    limit,
  });

  const bonusProgramsCountQuery = useGetBonusProgramsCount();

  const isLoading =
    bonusProgramsQuery.isLoading || bonusProgramsCountQuery.isLoading;
  const isFetching =
    bonusProgramsQuery.isFetching || bonusProgramsCountQuery.isFetching;

  return {
    isLoading,
    isFetching,
    bonusPrograms: bonusProgramsQuery.data?.data ?? [],
    bonusProgramsCount: bonusProgramsCountQuery.data?.data ?? Infinity,
    bonusProgramsError: bonusProgramsQuery.error,
    bonusProgramsCountError: bonusProgramsCountQuery.error,
  };
};
