import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const TableContainer = styled.div`
  border-radius: 8px;
  overflow: auto;
`;

export const StyledTable = styled.table`
  min-width: 100%;
  width: max-content;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: ${COLOR.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};

  & thead {
    // position: sticky;
    top: 0;
    color: ${COLOR.darkGrey};
    border-bottom: ${DEFAULT_BORDER};
  }

  & thead tr {
    // display: block;
  }

  & th {
    background-color: ${COLOR.white};
    text-align: left;
    vertical-align: bottom;

    padding: 20px 8px 18px 8px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }

    & .cursor-pointer {
      cursor: pointer;
    }

    &.sorted {
      background-color: ${COLOR.sortColumn};
    }

    &.settings {
      vertical-align: top;
    }
  }

  & td {
    // width: max-content;
    padding: 7px 8px 7px 8px;

    &.sorted {
      background-color: ${COLOR.sortColumn};
    }

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  & tbody tr {
    &:nth-child(odd) {
      background-color: ${COLOR.tableEvenRow};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledRightsTable = styled(StyledTable)`
  & td {
    padding: 14px 16px;

    vertical-align: middle;
  }
`;

export const HeaderCellContainer = styled.div``;

export const HeaderCellText = styled.span``;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 20px;
`;

export const ReportPaginationWrapper = styled(PaginationWrapper)`
  justify-content: space-between;
`;

export const PaginationContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const HeaderCellRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 4px;
`;
