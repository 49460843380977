import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1ChargePointsById: build.query<
      GetApiChargepointV1ChargePointsByIdApiResponse,
      GetApiChargepointV1ChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/${queryArg}`,
      }),
    }),
    patchApiChargepointV1ChargePointsById: build.mutation<
      PatchApiChargepointV1ChargePointsByIdApiResponse,
      PatchApiChargepointV1ChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateChargePointRequest,
      }),
    }),
    deleteApiChargepointV1ChargePointsById: build.mutation<
      DeleteApiChargepointV1ChargePointsByIdApiResponse,
      DeleteApiChargepointV1ChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1ChargePoints: build.query<
      GetApiChargepointV1ChargePointsApiResponse,
      GetApiChargepointV1ChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints`,
        params: {
          Name: queryArg.name,
          Statuses: queryArg.statuses,
          ConnectorId: queryArg.connectorId,
          CompanyIds: queryArg.companyIds,
          NotСompaniesId: queryArg.NotСompaniesId,
          ConnectorStatus: queryArg.connectorStatus,
          Ids: queryArg.ids,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          IsBlocked: queryArg.isBlocked,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    postApiChargepointV1ChargePoints: build.mutation<
      PostApiChargepointV1ChargePointsApiResponse,
      PostApiChargepointV1ChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargepointV1ChargePointsCount: build.query<
      GetApiChargepointV1ChargePointsCountApiResponse,
      GetApiChargepointV1ChargePointsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/Count`,
        params: {
          Name: queryArg.name,
          Statuses: queryArg.statuses,
          ConnectorId: queryArg.connectorId,
          CompanyIds: queryArg.companyIds,
          NotСompaniesId: queryArg.NotСompaniesId,
          ConnectorStatus: queryArg.connectorStatus,
          Ids: queryArg.ids,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          IsBlocked: queryArg.isBlocked,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiChargepointV1ChargePointsCpStatuses: build.query<
      GetApiChargepointV1ChargePointsCpStatusesApiResponse,
      GetApiChargepointV1ChargePointsCpStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/_cpStatuses`,
        params: {
          CompanyIds: queryArg.companyIds,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          WithVisibleCp: queryArg.withVisibleCp,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargepointV1ChargePointsInfo: build.query<
      GetApiChargepointV1ChargePointsInfoApiResponse,
      GetApiChargepointV1ChargePointsInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/_info`,
        params: {
          chargeBoxIdentity: queryArg,
        },
      }),
    }),
    getApiChargepointV1ChargePointsInfoByConnectors: build.query<
      GetApiChargepointV1ChargePointsInfoByConnectorsApiResponse,
      GetApiChargepointV1ChargePointsInfoByConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/_infoByConnectors`,
        params: {
          connectorIds: queryArg,
        },
      }),
    }),
    getApiChargepointV1ChargePointsChargePointsIdsByReadRights: build.query<
      GetApiChargepointV1ChargePointsChargePointsIdsByReadRightsApiResponse,
      GetApiChargepointV1ChargePointsChargePointsIdsByReadRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/chargePointsIdsByReadRights`,
        params: {
          UserName: queryArg.userName,
          UserRoles: queryArg.userRoles,
        },
      }),
    }),
    getApiChargepointV1ChargePointsLastActivityById: build.query<
      GetApiChargepointV1ChargePointsLastActivityByIdApiResponse,
      GetApiChargepointV1ChargePointsLastActivityByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/lastActivity/${queryArg}`,
      }),
    }),
    getApiChargepointV1ChargePointsByChargePointsIdAndConnectorId: build.query<
      GetApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse,
      GetApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
      }),
    }),
    patchApiChargepointV1ChargePointsByChargePointsIdAndConnectorId:
      build.mutation<
        PatchApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse,
        PatchApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargepoint/V1/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
          method: 'PATCH',
          body: queryArg.updateConnectorRequest,
        }),
      }),
    deleteApiChargepointV1ChargePointsByChargePointsIdAndConnectorId:
      build.mutation<
        DeleteApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse,
        DeleteApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargepoint/V1/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
          method: 'DELETE',
        }),
      }),
    postApiChargepointV1ChargePointsByChargePointsIdConnector: build.mutation<
      PostApiChargepointV1ChargePointsByChargePointsIdConnectorApiResponse,
      PostApiChargepointV1ChargePointsByChargePointsIdConnectorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/ChargePoints/${queryArg.chargePointsId}/Connector`,
        method: 'POST',
        body: queryArg.addConnectorRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1ChargePointsByIdApiResponse =
  /** status 200 Success */ GetChargePointVmBaseResponse;
export type GetApiChargepointV1ChargePointsByIdApiArg =
  /** Идентитфикатор сущности */ string;
export type PatchApiChargepointV1ChargePointsByIdApiResponse =
  /** status 200 Success */ UpdateChargePointVmBaseResponse;
export type PatchApiChargepointV1ChargePointsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateChargePointRequest: UpdateChargePointRequest;
};
export type DeleteApiChargepointV1ChargePointsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1ChargePointsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargepointV1ChargePointsApiResponse =
  /** status 200 Success */ GetChargePointVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1ChargePointsApiArg = {
  name?: string;
  statuses?: ChargePointStatus[];
  connectorId?: string;
  companyIds?: string[];
  NotСompaniesId?: string[];
  connectorStatus?: ConnectorStatus[];
  ids?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  dateFrom?: string;
  dateTo?: string;
  isBlocked?: boolean;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type PostApiChargepointV1ChargePointsApiResponse =
  /** status 200 Success */ GetChargePointVmBaseResponse;
export type PostApiChargepointV1ChargePointsApiArg = AddChargePointRequest;
export type GetApiChargepointV1ChargePointsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargepointV1ChargePointsCountApiArg = {
  name?: string;
  statuses?: ChargePointStatus[];
  connectorId?: string;
  companyIds?: string[];
  NotСompaniesId?: string[];
  connectorStatus?: ConnectorStatus[];
  ids?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  dateFrom?: string;
  dateTo?: string;
  isBlocked?: boolean;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiChargepointV1ChargePointsCpStatusesApiResponse =
  /** status 200 Success */ GetStatusesVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1ChargePointsCpStatusesApiArg = {
  companyIds?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  withVisibleCp?: boolean;
  dateFrom?: string;
  dateTo?: string;
  offset?: number;
  limit?: number;
};
export type GetApiChargepointV1ChargePointsInfoApiResponse =
  /** status 200 Success */ GetChargeBoxIdentityVmBaseResponse;
export type GetApiChargepointV1ChargePointsInfoApiArg =
  /** Спецальное уникальное название станции на уровне протокола(защита от перебора). Когда станция подключается к контроллеру она сообщает этот алиас */
    | string
    | undefined;
export type GetApiChargepointV1ChargePointsInfoByConnectorsApiResponse =
  /** status 200 Success */ GetInfoByConnectorsVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1ChargePointsInfoByConnectorsApiArg =
  /** Идентификаторы коннекторов */ string[] | undefined;
export type GetApiChargepointV1ChargePointsChargePointsIdsByReadRightsApiResponse =
  /** status 200 Success */ ChargePointsIdsByRigthVmBaseResponse;
export type GetApiChargepointV1ChargePointsChargePointsIdsByReadRightsApiArg = {
  userName?: string;
  userRoles?: string[];
};
export type GetApiChargepointV1ChargePointsLastActivityByIdApiResponse =
  /** status 200 Success */ GetLastActivityVmBaseResponse;
export type GetApiChargepointV1ChargePointsLastActivityByIdApiArg =
  /** Идентификатор станции */ string;
export type GetApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type GetApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** индетификатор зарядки */
    chargePointsId: string;
    /** индетификатор коннектора */
    connectorId: string;
  };
export type PatchApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type PatchApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** Идентификатор зарядки */
    chargePointsId: string;
    /** Идентификатор коннектора */
    connectorId: string;
    /** Параметры */
    updateConnectorRequest: UpdateConnectorRequest;
  };
export type DeleteApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** Идентификатор зарядной станции */
    chargePointsId: string;
    /** Идентификатор коннектора */
    connectorId: string;
  };
export type PostApiChargepointV1ChargePointsByChargePointsIdConnectorApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type PostApiChargepointV1ChargePointsByChargePointsIdConnectorApiArg = {
  /** Идентификатор зарядной станции */
  chargePointsId: string;
  /** Параметры */
  addConnectorRequest: AddConnectorRequest;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type SpeedType = 'UNDEFINED' | 'LOW_SPEED_AC' | 'HIGH_SPEED_DC';
export type ChargePointProtocol = 'OCPP16' | 'OCPP16J' | 'OCPP20';
export type ChargePointStatus =
  | 'NO_CONNECT'
  | 'AVAILABLE'
  | 'FAULTED'
  | 'UNAVAILABLE';
export type CountryCode = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type GroupVm = {
  id: string;
  name: string;
};
export type HubVm = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type ConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type ConnectorFormat = 'SOCKET' | 'CABLE';
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type StatusForUser =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'CHARGING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type ConnectorVm = {
  id: string;
  type: ConnectorType;
  format: ConnectorFormat;
  status: ConnectorStatus;
  statusForUser: StatusForUser;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage?: number | null;
  manualBlock: boolean;
  lastUpdate: string;
  liter?: string | null;
};
export type PhotoVm = {
  url?: string | null;
  priority: number;
};
export type GetChargePointVm = {
  id: string;
  number?: number | null;
  name: string;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  passUrl?: string | null;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  dipLinkCharby?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  companyId: string;
  createdDate: string;
  lastUpdate: string;
  isTestCp: boolean;
  isBlocked: boolean;
  ocppConnectionBlockDate: string;
  infrastructureInfo?: string | null;
  group: GroupVm;
  hub?: HubVm;
  connectors?: ConnectorVm[] | null;
  photos?: PhotoVm[] | null;
};
export type GetChargePointVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointVm;
};
export type UpdateChargePointVm = {
  id: string;
  number: number;
  name: string;
  passUrl?: string | null;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  isBlocked: boolean;
  ocppConnectionBlockDate: string;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  dipLinkCharby?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  companyId?: string | null;
  supportContact?: string | null;
  createdDate: string;
  lastUpdate: string;
  isTestCp: boolean;
  infrastructureInfo?: string | null;
  group?: GroupVm;
  hub?: HubVm;
  connectors?: ConnectorVm[] | null;
  photos?: PhotoVm[] | null;
};
export type UpdateChargePointVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateChargePointVm;
};
export type UpdateChargePointRequest = {
  number?: number | null;
  speedType?: SpeedType;
  multiconnectorSupport?: boolean | null;
  name?: string | null;
  chargePointProtocol?: ChargePointProtocol;
  status?: ChargePointStatus;
  passUrl?: string | null;
  isVisible?: boolean | null;
  maxPower?: number | null;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode?: CountryCode;
  city?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  model?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  dipLinkCharby?: string | null;
  chargeBoxIdentity?: string | null;
  supportContact?: string | null;
  groupId?: string | null;
  companyId?: string | null;
  hubId?: string | null;
  isTestCp?: boolean | null;
  infrastructureInfo?: string | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetChargePointVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointVm[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type AddChargePointRequest = {
  number?: number | null;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  name: string;
  passUrl?: string | null;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  dipLinkCharby?: string | null;
  groupId: string;
  companyId: string;
  hubId?: string | null;
  isTestCp: boolean;
  infrastructureInfo?: string | null;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetStatusesConnectorVm = {
  id: string;
  status: ConnectorStatus;
  type: ConnectorType;
  innerConnectorId: number;
  statusForUser: StatusForUser;
  maxPower: number;
};
export type GetStatusesVm = {
  chargePointId: string;
  status: ChargePointStatus;
  multiconnectorSupport: boolean;
  name: string;
  maxPower: number;
  city: string;
  address: string;
  number?: number | null;
  warningText?: string | null;
  dipLinkCharby?: string | null;
  speedType: SpeedType;
  groupId?: string | null;
  hubId?: string | null;
  latitude: number;
  longitude: number;
  lastUpdate: string;
  connectors?: GetStatusesConnectorVm[] | null;
};
export type GetStatusesVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetStatusesVm[] | null;
};
export type GetChargeBoxIdentityVm = {
  chargePointId: string;
  isBlocked: boolean;
  ipAddress?: string | null;
};
export type GetChargeBoxIdentityVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargeBoxIdentityVm;
};
export type GetInfoByConnectorsVm = {
  chargePointId: string;
  chargePointName: string;
  companyId: string;
  connectorId: string;
  connectorType: ConnectorType;
  address: string;
};
export type GetInfoByConnectorsVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetInfoByConnectorsVm[] | null;
};
export type ChargePointsIdsByRigthVm = {
  chargePointIds: string[];
  isAllCpRight: boolean;
};
export type ChargePointsIdsByRigthVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ChargePointsIdsByRigthVm;
};
export type GetLastActivityVm = {
  date?: string;
  eventName?: string | null;
};
export type GetLastActivityVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetLastActivityVm;
};
export type GetConnectorsVm = {
  id: string;
  type: ConnectorType;
  format: ConnectorFormat;
  chargePointId: string;
  status: ConnectorStatus;
  statusForUser: StatusForUser;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage: number;
  manualBlock: boolean;
  lastUpdate: string;
  liter?: string | null;
};
export type GetConnectorsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetConnectorsVm;
};
export type UpdateConnectorRequest = {
  type?: ConnectorType;
  format?: ConnectorFormat;
  status?: ConnectorStatus;
  innerConnectorId?: number | null;
  maxPower?: number | null;
  maxAmperage?: number | null;
  manualBlock?: boolean | null;
  maxVoltage?: number | null;
  liter?: string | null;
};
export type AddConnectorRequest = {
  type: ConnectorType;
  format: ConnectorFormat;
  status: ConnectorStatus;
  innerConnectorId: number;
  maxPower: number;
  manualBlock?: boolean | null;
  maxAmperage?: number | null;
  maxVoltage?: number | null;
  liter?: string | null;
};
export const {
  useGetApiChargepointV1ChargePointsByIdQuery,
  usePatchApiChargepointV1ChargePointsByIdMutation,
  useDeleteApiChargepointV1ChargePointsByIdMutation,
  useGetApiChargepointV1ChargePointsQuery,
  usePostApiChargepointV1ChargePointsMutation,
  useGetApiChargepointV1ChargePointsCountQuery,
  useGetApiChargepointV1ChargePointsCpStatusesQuery,
  useGetApiChargepointV1ChargePointsInfoQuery,
  useGetApiChargepointV1ChargePointsInfoByConnectorsQuery,
  useGetApiChargepointV1ChargePointsChargePointsIdsByReadRightsQuery,
  useGetApiChargepointV1ChargePointsLastActivityByIdQuery,
  useGetApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdQuery,
  usePatchApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdMutation,
  useDeleteApiChargepointV1ChargePointsByChargePointsIdAndConnectorIdMutation,
  usePostApiChargepointV1ChargePointsByChargePointsIdConnectorMutation,
} = injectedRtkApi;
