import { useState } from 'react';

import { useGetReportPayments } from 'shared/api/services/analytics/rtk/enhanced';
import {
  useGetChargePointGroups,
  useGetChargePoints,
} from 'shared/api/services/chargepoint/rtk';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import { useGetMobileApps } from 'shared/api/services/information/rtk';
import { getServerErrorText } from 'shared/lib';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { ErrorMessage } from 'shared/ui';

import { queryParamsSchema } from '../consts';
import { ReportLayout } from './layout';

export function TableContainer() {
  const [skip, setSkip] = useState(true);

  const queryParams = useTypedQueryParamsCustom(queryParamsSchema);

  const chargePointGroupsQuery = useGetChargePointGroups({});
  const chargePointsQuery = useGetChargePoints({ limit: 10 * 1000 });
  const companiesQuery = useGetCompanies(undefined);
  const mobileAppsQuery = useGetMobileApps();

  const {
    chargePointsId,
    companiesId,
    dateFrom,
    dateTo,
    groupBy,
    groupsId,
    mobileAppsId,
  } = queryParams;

  const reportQuery = useGetReportPayments(
    {
      chargePointsId: chargePointsId ? chargePointsId.split(',') : undefined,
      mobileAppsId: mobileAppsId ? mobileAppsId.split(',') : undefined,
      companiesId: companiesId ? companiesId.split(',') : undefined,
      groupsId: groupsId ? groupsId.split(',') : undefined,
      dateFrom,
      dateTo,
      groupBy,
    },
    {
      skip,
    }
  );

  const allowFetch = () => setSkip(false);

  const isLoading =
    chargePointGroupsQuery.isLoading ||
    chargePointsQuery.isLoading ||
    companiesQuery.isLoading ||
    mobileAppsQuery.isLoading;

  const isFetching = reportQuery.isFetching;

  const error = [
    chargePointGroupsQuery.error,
    chargePointsQuery.error,
    companiesQuery.error,
    mobileAppsQuery.error,
    reportQuery.error,
  ].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <ReportLayout
      {...queryParams}
      isLoading={isFetching}
      chargePointGroups={chargePointGroupsQuery.data?.data ?? []}
      chargePoints={chargePointsQuery.data?.data ?? []}
      companies={companiesQuery.data?.data ?? []}
      mobileApps={mobileAppsQuery.data?.data ?? []}
      billings={reportQuery.data?.data?.billings ?? []}
      allowFetch={allowFetch}
    />
  );
}
