import { useAbility } from 'shared/lib/ability/context';
import { Container } from './container';

type Props = {
  userId: string;
};

export function UserCarList({ userId }: Props) {
  const ability = useAbility();

  if (!ability.can('Read', 'InformationService')) {
    return <div>Не хватает прав</div>;
  }

  return <Container userId={userId} />;
}
