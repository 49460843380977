import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1RoleGroupCpRightsById: build.query<
      GetApiChargepointV1RoleGroupCpRightsByIdApiResponse,
      GetApiChargepointV1RoleGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleGroupCpRights/${queryArg}`,
      }),
    }),
    patchApiChargepointV1RoleGroupCpRightsById: build.mutation<
      PatchApiChargepointV1RoleGroupCpRightsByIdApiResponse,
      PatchApiChargepointV1RoleGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleGroupCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateRoleGroupCpRightRequest,
      }),
    }),
    deleteApiChargepointV1RoleGroupCpRightsById: build.mutation<
      DeleteApiChargepointV1RoleGroupCpRightsByIdApiResponse,
      DeleteApiChargepointV1RoleGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleGroupCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1RoleGroupCpRights: build.query<
      GetApiChargepointV1RoleGroupCpRightsApiResponse,
      GetApiChargepointV1RoleGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleGroupCpRights`,
        params: {
          roleName: queryArg,
        },
      }),
    }),
    postApiChargepointV1RoleGroupCpRights: build.mutation<
      PostApiChargepointV1RoleGroupCpRightsApiResponse,
      PostApiChargepointV1RoleGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRights: build.mutation<
      PostApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsApiResponse,
      PostApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/RoleGroupCpRights/SetRoleGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1RoleGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmBaseResponse;
export type GetApiChargepointV1RoleGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargepointV1RoleGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmBaseResponse;
export type PatchApiChargepointV1RoleGroupCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateRoleGroupCpRightRequest: UpdateRoleGroupCpRightRequest;
};
export type DeleteApiChargepointV1RoleGroupCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1RoleGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1RoleGroupCpRightsApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1RoleGroupCpRightsApiArg = string | undefined;
export type PostApiChargepointV1RoleGroupCpRightsApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmBaseResponse;
export type PostApiChargepointV1RoleGroupCpRightsApiArg =
  /** Параметры */ AddRoleGroupCpRightRequest;
export type PostApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsApiArg =
  /** Параметры */ SetRoleGroupCpRightsRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetRoleGroupCpRightVm = {
  id: string;
  roleName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetRoleGroupCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleGroupCpRightVm;
};
export type UpdateRoleGroupCpRightRequest = {
  roleName?: string | null;
  groupId?: string | null;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetRoleGroupCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleGroupCpRightVm[] | null;
};
export type AddRoleGroupCpRightRequest = {
  roleName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type RoleGroupCpRightRequest = {
  groupId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetRoleGroupCpRightsRequest = {
  roleName: string;
  allCpRight?: boolean | null;
  groupCpRights?: RoleGroupCpRightRequest[] | null;
};
export const {
  useGetApiChargepointV1RoleGroupCpRightsByIdQuery,
  usePatchApiChargepointV1RoleGroupCpRightsByIdMutation,
  useDeleteApiChargepointV1RoleGroupCpRightsByIdMutation,
  useGetApiChargepointV1RoleGroupCpRightsQuery,
  usePostApiChargepointV1RoleGroupCpRightsMutation,
  usePostApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsMutation,
} = injectedRtkApi;
