import { Link } from 'react-router-dom';

import {
  RESERVES,
  ROOT,
  SESSIONS,
  GROUPS,
  MANUAL_COMMANDS,
  BILLINGS,
} from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';
import { BILLING_DEFAULT_SEARCH_PARAMS } from 'entities/billing';
// import { OpenManualCommandButton } from 'features/command/ui/button';

import { AddChargePointLink } from '../add-link';
import { AddChargePointGroupLink } from '../add-group-link';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <Link to="">Мониторинг</Link>,
    extraContent: <AddChargePointLink />,
  },
  {
    key: GROUPS,
    label: <Link to={GROUPS}>Список групп</Link>,
    extraContent: <AddChargePointGroupLink />,
  },
  {
    key: SESSIONS,
    label: (
      <Link
        to={{
          pathname: SESSIONS,
          search: SESSIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал сессий
      </Link>
    ),
  },
  {
    key: RESERVES,
    label: <Link to={RESERVES}>Журнал брони</Link>,
    permissions: [
      {
        controller: 'ReserveService',
        right: 'Execute',
      },
    ],
  },
  {
    key: BILLINGS,
    label: (
      <Link
        to={{
          pathname: BILLINGS,
          search: BILLING_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал услуг
      </Link>
    ),
    permissions: [
      { controller: 'BillingService', right: 'Read' },
      { controller: 'InformationService', right: 'Read' },
    ],
  },
  // {
  //   key: MANUAL_COMMANDS,
  //   label: <Link to={MANUAL_COMMANDS}>Управление</Link>,

  //   // extraContent: <OpenManualCommandButton />,
  //   disabled: true,
  // },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
