import { postApiCompanyV1CompaniesBody } from 'shared/api/services/company/orval/zod/schemas';
import { EmployeesBalancePeriod } from 'shared/api/services/company/rtk/generated';

export const EMPLOYEES_BALANCE_PERIOD: Record<EmployeesBalancePeriod, string> =
  {
    FIRST_DAY_MONTH: 'FIRST_DAY_MONTH',
  };

export const balanceRefreshPeriod =
  postApiCompanyV1CompaniesBody.shape.balanceRefreshPeriod.unwrap();
