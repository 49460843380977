import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  groupId?: string;
};

export function TableContainer({ groupId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    users,
    usersCount,
    mobileAppsMapping,
    mobileAppsError,
    usersCountError,
    usersError,
  } = useQueries({
    groupId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (mobileAppsError) {
    openErrorNotification(getServerErrorText(mobileAppsError));
  }

  if (usersError) {
    return <ErrorMessage text={getServerErrorText(usersError)} />;
  }

  if (usersCountError) {
    return <ErrorMessage text={getServerErrorText(usersCountError)} />;
  }

  return (
    <TableInstance
      data={users}
      totalCount={usersCount}
      loading={isFetching}
      mobileAppsMapping={mobileAppsMapping}
      tableLimit={tableLimit}
    />
  );
}
