import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { ReserveSessionListTable } from 'features/reserve/sessions-table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UserReservesPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <ReserveSessionListTable userId={userId} />;
}
