import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from './hooks/use-queries';
import { selectTableLimit, setTableLimit } from './store/slice';
import { TableInstance } from './table';

type Props = {
  userId?: string;
  chargePointsIds?: string[];
};

export function TableContainer({ userId, chargePointsIds }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    billings,
    billingsCount,
    countries,
    mobileAppsMapping,
    billingsError,
    billingsCountError,
    mobileAppsError,
    countriesError,
  } = useQueries({
    chargePointsIds,
    userId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (mobileAppsError) {
    openErrorNotification(getServerErrorText(mobileAppsError));
  }

  if (countriesError) {
    openErrorNotification(getServerErrorText(countriesError));
  }

  if (billingsError) {
    return <ErrorMessage text={getServerErrorText(billingsError)} />;
  }

  if (billingsCountError) {
    return <ErrorMessage text={getServerErrorText(billingsCountError)} />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={billings}
      totalCount={billingsCount}
      countries={countries}
      mobileAppsMapping={mobileAppsMapping}
      tableLimit={tableLimit}
    />
  );
}
