import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { BalanceTransaction } from 'shared/api/services/bonus/rtk/generated';

import { OPERATION_TYPE } from 'entities/bonus-program';

import { selectTableColumns, setTableColumns } from '../store/slice';

const columnHelper = createColumnHelper<BalanceTransaction>();

const DATA_COLUMNS = [
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Дата и время',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue() || '';

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateFrom"
          dateToName="dateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('balanceId', {
    id: 'balanceId',
    header: 'ID баланса',
    cell: (props) => {
      const val = props.getValue();

      if (!val) {
        return '';
      }

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="balanceId" />,
    },
  }),
  columnHelper.accessor('bonusProgramId', {
    id: 'bonusProgramId',
    header: 'ID бонусной программы',
    cell: (props) => {
      const val = props.getValue();

      if (!val) {
        return '';
      }

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="bonusProgramId" disabled />,
    },
  }),
  columnHelper.accessor('value', {
    id: 'value',
    header: 'value',
    cell: (props) => {
      const val = props.getValue();

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="value" disabled />,
    },
  }),
  columnHelper.accessor('operation', {
    id: 'operation',
    header: 'Тип',
    cell: (props) => {
      const val = props.getValue();

      return OPERATION_TYPE[val];
    },
    meta: {
      filterElement: <InputFilter paramName="operation" disabled />,
    },
  }),
  columnHelper.accessor('reason', {
    id: 'reason',
    header: 'Причина',
    cell: (props) => {
      const val = props.getValue();

      return val;
    },
    meta: {
      filterElement: <InputFilter paramName="reason" disabled />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
