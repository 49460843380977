import { z } from 'zod';

import { MainContentLayout } from 'shared/ui/layout';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { BalancingGroupChargePointListTable } from 'entities/balancing-group';

import { EditBalancingGroupForm } from 'features/balancing/edit';

import { StyledLayout } from './styles';

const pageParamsSchema = z.object({
  groupId: z.string(),
});

export function EditBalancingGroupPage() {
  const { groupId } = useTypedParams(pageParamsSchema);

  const element = (
    <StyledLayout>
      <EditBalancingGroupForm groupId={groupId} />
      <BalancingGroupChargePointListTable balancingGroupId={groupId} />
    </StyledLayout>
  );

  return <MainContentLayout element={element} />;
}
