import { enhancedApi as api } from '../generated/sessions';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1Sessions: (endpoint) => {
      endpoint.providesTags = [{ type: 'Sessions', id: 'LIST' }];
      endpoint.keepUnusedDataFor = 5;
    },
    getApiChargepointV1SessionsCount: (endpoint) => {
      endpoint.keepUnusedDataFor = 5;
    },
  },
});

export const {
  useGetApiChargepointV1SessionsQuery: useGetSessions,
  useGetApiChargepointV1SessionsCountQuery: useGetSessionsCount,
  useGetApiChargepointV1SessionsByIdQuery: useGetSession,
  useLazyGetApiChargepointV1SessionsByIdQuery: useLazyGetSession,
  usePostApiChargepointV1SessionsTerminateByIdMutation: useSessionTerminate,
} = enhancedApi;
