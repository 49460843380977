import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_TABLE_PAGE_SIZE } from 'shared/consts/table';

type State = {
  tableColumns: {
    createdDate: boolean;
    balanceId: boolean;
    bonusProgramId: boolean;
    value: boolean;
    operation: boolean;
    reason: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    createdDate: true,
    balanceId: true,
    bonusProgramId: true,
    value: true,
    operation: true,
    reason: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'bonusTransactionsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectTableColumns = (state: RootState) =>
  state.bonusTransactions.tableColumns;
export const selectTableLimit = (state: RootState) =>
  state.bonusTransactions.tableLimit;

export default slice.reducer;
