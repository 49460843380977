import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  useGetBankCards,
  useGetBankCardsCount,
} from 'shared/api/services/payment/rtk/enhanced';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';

import { getIsSelectedParam } from '../lib/get-is-selected-param';

const filteringQueryParams = z.object({
  bankId: z.string().optional(),
  bankClientId: z.string().uuid().optional(),
  bindingId: z.string().optional(),
  countryId: z.string().optional(),
  isSelected: z.enum(['true', 'false']).optional(),
});

const cardsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const cardsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  userId?: string;
};

export const useQueries = ({ limit, userId }: Props) => {
  const cardsParams = useTypedQueryParamsCustom(cardsQueryParams);
  const cardsCountParams = useTypedQueryParamsCustom(cardsCountQueryParams);

  const isSelectedParam = getIsSelectedParam({
    isSelected: cardsParams.isSelected,
  });

  const cardsQuery = useGetBankCards({
    ...cardsParams,
    isSelected: isSelectedParam,
    offset: getOffsetQueryParam(cardsParams.page, limit),
    limit,
    userId,
  });

  const cardsCountQuery = useGetBankCardsCount({
    ...cardsCountParams,
    isSelected: isSelectedParam,
    userId,
  });

  const isLoading = cardsQuery.isLoading || cardsCountQuery.isLoading;
  const isFetching = cardsQuery.isFetching || cardsCountQuery.isFetching;

  return {
    isLoading,
    isFetching,
    cards: cardsQuery.data?.data ?? [],
    cardsCount: cardsCountQuery.data?.data ?? Infinity,
    cardsError: cardsQuery.error,
    cardsCountError: cardsCountQuery.error,
  };
};
