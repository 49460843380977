import { createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { useAppDispatch } from 'shared/redux/types';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';

import { selectTableColumns, setTableColumns } from '../store/slice';
import { GroupTableActions } from '../ui/actions';

const columnHelper = createColumnHelper<GetGroupVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('id', {
    id: 'id',
    header: 'ID группы',
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Наименование',
  }),
  columnHelper.accessor('comment', {
    id: 'comment',
    header: 'Описание',
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      const group = props.row.original;

      return <GroupTableActions group={group} />;
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataColumns = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataColumns, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
