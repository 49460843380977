import { getErrorMessage } from 'shared/lib/http';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../../hooks/use-queries';
import { BonusesSummaryLayout } from './layout';

type Props = {
  userId: string;
};

export function Container({ userId }: Props) {
  const {
    achivements,
    balances,
    countries,
    isLoading,
    achivementsError,
    balancesError,
    countriesError,
  } = useQueries({ userId });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (achivementsError) {
    return <ErrorMessage text={getErrorMessage(achivementsError)} />;
  }

  if (balancesError) {
    return <ErrorMessage text={getErrorMessage(balancesError)} />;
  }

  if (countriesError) {
    return <ErrorMessage text={getErrorMessage(countriesError)} />;
  }

  return (
    <BonusesSummaryLayout
      achivements={achivements}
      countries={countries}
      balances={balances}
      userId={userId}
    />
  );
}
