import { useEffect } from 'react';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { useTableLimit } from 'shared/hooks/use-table-limit';

import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';
import { useQueries } from '../hooks/use-queries';

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    payments,
    paymentsCount,
    paymentsCountError,
    paymentsError,
  } = useQueries({
    userId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (paymentsError) {
    return <ErrorMessage text={getServerErrorText(paymentsError)} />;
  }

  if (paymentsCountError) {
    return <ErrorMessage text={getServerErrorText(paymentsCountError)} />;
  }

  return (
    <TableInstance
      data={payments}
      totalCount={paymentsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
