import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

const FormResultSchema = z.object({
  userName: z.string().min(1),
  email: z.string().email(),
  newPassword: z.string().nullish(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  middleName: z.string().min(1),
  powerBiUrl: z.string().nullish(),
  groupName: z.string().min(1),
});

export const FormSchema = z
  .object({
    userName: z.string().transform(emptyStringToUndefined),
    email: z.string().transform(emptyStringToUndefined),
    newPassword: z.string().transform(emptyStringToUndefined),
    firstName: z.string().transform(emptyStringToUndefined),
    lastName: z.string().transform(emptyStringToUndefined),
    middleName: z.string().transform(emptyStringToUndefined),
    powerBiUrl: z.string().transform(emptyStringToUndefined),
    groupName: z.string().transform(emptyStringToUndefined),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;
