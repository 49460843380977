import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { USER } from 'shared/consts/routes/identity-user';
import {
  ADD_PREFIX,
  CHARGE_POINT_RIGHTS,
  CONTROLLER_CLAIMS,
  IDENTITY_ROLES,
  IDENTITY_USERS,
} from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';
import { IdentityUserLayout } from 'entities/identity-user/ui/user-layout';
import { IdentityUsersLayout } from 'entities/identity-user/ui/users-layout';

import { IdentityUsersMenu } from 'features/identity-user/menu';
import { IdentityUserControllerClaimsForm } from 'features/identity-user/controller-claims';

import { AddIdentityUserPage } from 'pages/identity-user/add';
import { EditIdentityUserPage } from 'pages/identity-user/edit';
import { IdentityUserListPage } from 'pages/identity-user/list';
import { IdentityUserRolesPage } from 'pages/identity-user/roles';
import { IdentityUserCpRightsForm } from 'features/identity-user/charge-point-rights';

export const IdentityUserRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[{ controller: 'IdentityService', right: 'Read' }]}
      />
    }
  >
    <Route
      path={IDENTITY_USERS}
      element={<SectionLayout menu={<IdentityUsersMenu />} />}
    >
      <Route element={<IdentityUsersLayout />}>
        <Route index element={<IdentityUserListPage />} />
      </Route>
      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'IdentityService', right: 'Write' }]}
          />
        }
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityUserPage />} />}
        />
      </Route>

      <Route path={USER} element={<IdentityUserLayout />}>
        <Route index element={<EditIdentityUserPage />} />
        <Route
          path={CONTROLLER_CLAIMS}
          element={<IdentityUserControllerClaimsForm />}
        />
        <Route
          path={CHARGE_POINT_RIGHTS}
          element={<IdentityUserCpRightsForm />}
        />

        <Route path={IDENTITY_ROLES} element={<IdentityUserRolesPage />} />
      </Route>
    </Route>
  </Route>
);
