import Excel from 'exceljs';

import { convertStringToNumber } from 'shared/lib/format/replace-dots-with-commas';

const FORMAT_FN: Record<number, (val: string) => void> = {
  3: convertStringToNumber,
  4: convertStringToNumber,
  5: convertStringToNumber,
  6: convertStringToNumber,
  7: convertStringToNumber,
  8: convertStringToNumber,
  9: convertStringToNumber,
  10: convertStringToNumber,
};

type Props = {
  ws: Excel.Worksheet;
  table: HTMLElement;
  FOOTER_ROW: number;
};

export const buildBase = ({ ws, table, FOOTER_ROW }: Props) => {
  const thead = table.querySelector('thead') as HTMLTableSectionElement;
  const tbody = table.querySelector('tbody') as HTMLTableSectionElement;
  const tfoot = table.querySelector('tfoot') as HTMLTableSectionElement;

  const theadTrs = thead.querySelectorAll('tr');
  const tbodyTrs = tbody.querySelectorAll('tr');
  const tfootTrs = tfoot.querySelectorAll('tr');

  ws.mergeCells(`A1:L1`);

  Array.from(theadTrs).forEach((tr) => {
    const ths = Array.from(tr.querySelectorAll('th'));

    ws.addRow(ths.slice().map((th) => th.innerText));
  });

  Array.from(tbodyTrs).forEach((tr) => {
    const tds = Array.from(tr.querySelectorAll('td'));

    ws.addRow(
      tds.slice().map((td, idx) => {
        const formatFn = FORMAT_FN[idx];

        if (formatFn) {
          return formatFn(td.innerText);
        }

        return td.innerText;
      })
    );
  });

  Array.from(tfootTrs).forEach((tr) => {
    const tds = Array.from(tr.querySelectorAll('td'));

    ws.addRow(
      tds.slice().map((td, idx) => {
        return td.innerText;
      })
    );

    ws.getCell(`L${FOOTER_ROW}`).value = ws.getCell(`J${FOOTER_ROW}`).value;
    ws.getCell(`K${FOOTER_ROW}`).value = ws.getCell(`I${FOOTER_ROW}`).value;
    ws.getCell(`J${FOOTER_ROW}`).value = ws.getCell(`H${FOOTER_ROW}`).value;
    ws.getCell(`I${FOOTER_ROW}`).value = ws.getCell(`G${FOOTER_ROW}`).value;
    ws.getCell(`H${FOOTER_ROW}`).value = ws.getCell(`F${FOOTER_ROW}`).value;
    ws.getCell(`G${FOOTER_ROW}`).value = ws.getCell(`E${FOOTER_ROW}`).value;
    ws.getCell(`F${FOOTER_ROW}`).value = ws.getCell(`D${FOOTER_ROW}`).value;
    ws.getCell(`E${FOOTER_ROW}`).value = ws.getCell(`C${FOOTER_ROW}`).value;
    ws.getCell(`D${FOOTER_ROW}`).value = ws.getCell(`B${FOOTER_ROW}`).value;

    ws.mergeCells(`A${FOOTER_ROW}:C${FOOTER_ROW}`);
  });
};
