import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { GroupDto } from 'shared/api/services/user/rtk/generated';
import { ClientTableLayout, useClientPagination } from 'shared/ui/table-new';

import { useColumns } from './hooks/use-columns';

type Props = {
  data: GroupDto[];
  loading: boolean;
};

export function TableInstance({ data, loading }: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);

  // Достать из слайса pageSize (если нет - дефолт 20)

  const [pagination, setPagination] = useClientPagination();

  const columns = useColumns();

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return <ClientTableLayout table={table} loading={loading} />;
}
