import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1UserCpRightsById: build.query<
      GetApiChargepointV1UserCpRightsByIdApiResponse,
      GetApiChargepointV1UserCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights/${queryArg}`,
      }),
    }),
    patchApiChargepointV1UserCpRightsById: build.mutation<
      PatchApiChargepointV1UserCpRightsByIdApiResponse,
      PatchApiChargepointV1UserCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateUserCpRightRequest,
      }),
    }),
    deleteApiChargepointV1UserCpRightsById: build.mutation<
      DeleteApiChargepointV1UserCpRightsByIdApiResponse,
      DeleteApiChargepointV1UserCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1UserCpRights: build.query<
      GetApiChargepointV1UserCpRightsApiResponse,
      GetApiChargepointV1UserCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights`,
        params: {
          userName: queryArg,
        },
      }),
    }),
    postApiChargepointV1UserCpRights: build.mutation<
      PostApiChargepointV1UserCpRightsApiResponse,
      PostApiChargepointV1UserCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1UserCpRightsSetUserCpRights: build.mutation<
      PostApiChargepointV1UserCpRightsSetUserCpRightsApiResponse,
      PostApiChargepointV1UserCpRightsSetUserCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights/SetUserCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargepointV1UserCpRightsIsAllCpRights: build.query<
      GetApiChargepointV1UserCpRightsIsAllCpRightsApiResponse,
      GetApiChargepointV1UserCpRightsIsAllCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/UserCpRights/IsAllCpRights`,
        params: {
          userName: queryArg.userName,
          userRole: queryArg.userRole,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1UserCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserCpRightVmBaseResponse;
export type GetApiChargepointV1UserCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargepointV1UserCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserCpRightVmBaseResponse;
export type PatchApiChargepointV1UserCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateUserCpRightRequest: UpdateUserCpRightRequest;
};
export type DeleteApiChargepointV1UserCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1UserCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1UserCpRightsApiResponse =
  /** status 200 Success */ GetUserCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1UserCpRightsApiArg =
  /** Идентификатор пользователя */ string | undefined;
export type PostApiChargepointV1UserCpRightsApiResponse =
  /** status 200 Success */ GetUserCpRightVmBaseResponse;
export type PostApiChargepointV1UserCpRightsApiArg =
  /** Параметры */ AddUserCpRightRequest;
export type PostApiChargepointV1UserCpRightsSetUserCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1UserCpRightsSetUserCpRightsApiArg =
  /** Параметры */ SetUserCpRightRequest;
export type GetApiChargepointV1UserCpRightsIsAllCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type GetApiChargepointV1UserCpRightsIsAllCpRightsApiArg = {
  userName?: string;
  userRole?: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetUserCpRightVm = {
  id: string;
  userName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetUserCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserCpRightVm;
};
export type UpdateUserCpRightRequest = {
  userName?: string | null;
  chargePointId?: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetUserCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserCpRightVm[] | null;
};
export type AddUserCpRightRequest = {
  userName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type UserCpRightRequest = {
  chargePointId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetUserCpRightRequest = {
  userName: string;
  allCpRight?: boolean | null;
  cpRights?: UserCpRightRequest[] | null;
};
export const {
  useGetApiChargepointV1UserCpRightsByIdQuery,
  usePatchApiChargepointV1UserCpRightsByIdMutation,
  useDeleteApiChargepointV1UserCpRightsByIdMutation,
  useGetApiChargepointV1UserCpRightsQuery,
  usePostApiChargepointV1UserCpRightsMutation,
  usePostApiChargepointV1UserCpRightsSetUserCpRightsMutation,
  useGetApiChargepointV1UserCpRightsIsAllCpRightsQuery,
} = injectedRtkApi;
