import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAddCompany } from 'shared/api/services/company/rtk/enhanced';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import { EMPLOYEES_BALANCE_PERIOD } from 'entities/company';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

export function Form() {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddCompany();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

Form.Fields = function Fields() {
  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="name" label="Наименование" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="legalName"
            label="Юридическое наименование"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="email" label="Email" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="contractData" label="Договор" />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="address" label="Адрес" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="phoneNumber"
            label="Телефонный номер"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="logoUrl" label="URL логотипа" />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="inn" label="ИНН" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="kpp" label="КПП" />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="mobileName"
            label="Название для мобильного приложения"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="directorName" label="Директор" />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="telegram" label="Телеграм" />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="companyBalanceForPeriod"
            label="Величина баланса компании на период в рублях"
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="employeeBalanceForPeriod"
            label="Величина установки баланса сотрудникам за переод времени в рублях"
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="balanceRefreshPeriod"
            label="Переод времени за который устанавливается новый баланс сотруднику и компании"
            options={Object.entries(EMPLOYEES_BALANCE_PERIOD).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormInput>
            name="isBalanceRefreshLocked"
            label="isBalanceRefreshLocked"
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormInput> name="isDefault" label="isDefault" />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
