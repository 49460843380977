import { BALANCING_GROUP_ROUTES } from 'shared/consts/routes/balancing';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={BALANCING_GROUP_ROUTES.ADD_BALANCING_GROUP}
      permissions={[
        {
          controller: 'BalancingService',
          right: 'Write',
        },
      ]}
    />
  );
}
