import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

import { balanceRefreshPeriod } from 'entities/company/consts';

const FormResultSchema = z.object({
  name: z.string(),
  legalName: z.string(),
  email: z.string().email(),
  contractData: z.string().nullish(),
  address: z.string(),
  phoneNumber: z.string(),
  logoUrl: z.string().nullish(),
  inn: z.string(),
  kpp: z.string().nullish(),
  mobileName: z.string(),
  directorName: z.string().nullish(),
  telegram: z.string().nullish(),
  companyBalanceForPeriod: z.number().nullish(),
  employeeBalanceForPeriod: z.number().nullish(),
  balanceRefreshPeriod: balanceRefreshPeriod.optional(),
  isBalanceRefreshLocked: z.boolean(),
  isDefault: z.boolean(),
});

export const FormSchema = z
  .object({
    name: z.string().transform(emptyStringToUndefined),
    legalName: z.string().transform(emptyStringToUndefined),
    email: z.string().transform(emptyStringToUndefined),
    contractData: z.string().transform(emptyStringToUndefined).nullish(),
    address: z.string().transform(emptyStringToUndefined),
    phoneNumber: z.string().transform(emptyStringToUndefined),
    logoUrl: z.string().transform(emptyStringToUndefined).nullish(),
    inn: z.string().transform(emptyStringToUndefined),
    kpp: z.string().transform(emptyStringToUndefined).nullish(),
    mobileName: z.string().transform(emptyStringToUndefined),
    directorName: z.string().transform(emptyStringToUndefined).nullish(),
    telegram: z.string().transform(emptyStringToUndefined).nullish(),
    companyBalanceForPeriod: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined))
      .nullish(),
    employeeBalanceForPeriod: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined))
      .nullish(),
    balanceRefreshPeriod: z.string().transform(emptyStringToUndefined),
    isBalanceRefreshLocked: z.boolean(),
    isDefault: z.boolean(),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
