import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_TABLE_PAGE_SIZE } from 'shared/consts/table';

type State = {
  tableColumns: {
    sessionId: boolean;
    providedServices: boolean;
    createdDate: boolean;
    closedDate: boolean;
    paymentStatus: boolean;
    status: boolean;
    userId: boolean;
    benefitPayment: boolean;
    payment: boolean;
    discount: boolean;
    bonusesPayment: boolean;
    invoiceSum: boolean;
    mobileAppId: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    sessionId: true,
    providedServices: true,
    createdDate: true,
    closedDate: true,
    paymentStatus: true,
    status: true,
    userId: true,
    benefitPayment: true,
    payment: true,
    discount: true,
    bonusesPayment: true,
    invoiceSum: true,
    mobileAppId: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'billingsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectTableColumns = (state: RootState) =>
  state.billings.tableColumns;
export const selectTableLimit = (state: RootState) => state.billings.tableLimit;

export default slice.reducer;
