import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { ChargePointOut } from 'shared/api/services/balancing/rtk/generated';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { renderBooleanAsString } from 'shared/lib';
import { HighlightedText } from 'shared/ui/styles';

const columnHelper = createColumnHelper<ChargePointOut>();

type Props = {
  chargePointsMapping: Record<string, GetChargePointVm>;
};

export const useColumns = ({ chargePointsMapping }: Props) => {
  const DATA_COLUMNS = [
    columnHelper.accessor('id', {
      id: 'id',
      header: 'Номер ЭЗС',
      cell: (props) => {
        const chargePoint = chargePointsMapping[props.getValue()];

        if (!chargePoint) {
          return '';
        }

        const { name, id, group } = chargePoint;

        return (
          <Link
            to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
              group?.id ?? null
            }/${id}`}
          >
            <HighlightedText>{name}</HighlightedText>
          </Link>
        );
      },
    }),
    columnHelper.accessor('max_power', {
      id: 'max_power',
      header: 'Максимальная мощность, кВт',
    }),
    columnHelper.accessor('is_charging', {
      id: 'is_charging',
      header: 'Заряжает сейчас',
      cell: (props) => {
        const val = props.getValue();

        if (val === null || val === undefined) {
          return '';
        }

        return renderBooleanAsString(val);
      },
    }),
    columnHelper.accessor('default_power', {
      id: 'default_power',
      header: 'Текущая мощность, кВт*ч', // ?
    }),
  ];

  return DATA_COLUMNS;
};
