import { useState } from 'react';

import {
  useAddIdentityUserIdentityGroupMutation,
  useDeleteIdentityUserIdentityGroupMutation,
  useUpdateIdentityUser,
} from 'shared/api/services/identity/rtk/enhanced';
import { enhancedApi } from 'shared/api/services/identity/rtk/generated';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Пользователь обновлен';
const ERROR_MSG = 'Не удалось обновить пользователя';

export const useHandleUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [updateIdentityUser] = useUpdateIdentityUser();
  const [addIdentityGroupToIdentityUser] =
    useAddIdentityUserIdentityGroupMutation();
  const [deleteIdentityGroupFromIdentityUser] =
    useDeleteIdentityUserIdentityGroupMutation();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    data,
    group,
    userName,
    id,
  }: {
    id: string;
    userName: string;
    data: FormOutput;
    group: string | undefined;
  }) => {
    setIsLoading(true);

    const { groupName, newPassword, ...rest } = data;

    const updateUserReq = {
      id,
      ...rest,
      ...(newPassword ? { newPassword } : {}),
    };

    try {
      const updateUserRes = await updateIdentityUser(updateUserReq).unwrap();

      if (groupName !== group) {
        if (group) {
          const deleteGroupRes = await deleteIdentityGroupFromIdentityUser({
            userName,
            groupName: group,
          });
        }

        const updateGroupRes = await addIdentityGroupToIdentityUser({
          userName,
          groupName,
        }).unwrap();
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'IdentityUsers', id: 'LIST' }])
      );
      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'IdentityUsers', id: userName },
        ])
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdate,
    isLoading,
  };
};
