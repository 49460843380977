import Excel from 'exceljs';

import {
  applyWorksheetStyles,
  downloadReport,
  setColumnsAutoWidth,
  setReportMetadata,
} from 'shared/lib/export-table';

type Props<T> = {
  tableRef: React.MutableRefObject<null>;
  title: string;
  columns: T[];
};

export const exportInExcel = async <T extends { id?: string }>({
  title,
  tableRef,
  columns,
}: Props<T>) => {
  const table = tableRef.current as unknown as HTMLElement;

  const thead = table.querySelector('thead') as HTMLTableSectionElement;
  const tbody = table.querySelector('tbody') as HTMLTableSectionElement;

  const trs = tbody.querySelectorAll('tr');

  Array.from(trs).forEach((tr) => {
    const tds = tr.querySelectorAll('td');

    // TODO: delete
    // tds.forEach((td) => console.log('td', td.innerText));
  });

  const wb = new Excel.Workbook();

  setReportMetadata(wb);

  const ws = wb.addWorksheet(title);

  const thArr = Array.from(
    thead.querySelectorAll('span.header')
  ) as HTMLElement[];

  ws.addRow(1).values = thArr
    .slice(0, thArr.length - 1)
    .map((el) => el.innerText);

  ws.columns = columns.map((el) => ({ key: el.id }));

  Array.from(trs).forEach((tr) => {
    const tdsArr = Array.from(tr.querySelectorAll('td'));

    ws.addRow(tdsArr.slice(0, tdsArr.length - 1).map((td) => td.innerText));
  });

  applyWorksheetStyles(ws, 1);

  setColumnsAutoWidth(ws, 1);

  const reportFileName = title;

  downloadReport(wb, reportFileName);
};
