import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
  UiCancelFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { BILLING_BALANCE_NAME } from 'entities/billing';

import {
  FormInput,
  FormOutput,
  FormSchema,
  DEFAULT_VALUES,
} from '../consts/schema';
import { useCreateBillingBalance } from '../hooks/use-create-billing-balance';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

type Props = {
  closeModal: () => void;
  userId: string;
};

export function Form({ closeModal, userId }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const { handleCreate, isLoading } = useCreateBillingBalance({
    closeModal,
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    handleCreate({ data, userId });
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons onCancel={closeModal} />
        </form>
      </Spin>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  const countriesQuery = useGetCountries(undefined);

  const countries = countriesQuery.data?.data ?? [];

  if (countriesQuery.error) {
    openErrorNotification(getServerErrorText(countriesQuery.error));
  }

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="balanceName"
            label="Тип баланса"
            options={Object.entries(BILLING_BALANCE_NAME).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <Spin spinning={countriesQuery.isLoading}>
            <CustomSelect<FormInput>
              name="countryId"
              options={countries.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              label="Страна"
              required
            />
          </Spin>
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="value"
            label="Стартовый баланс"
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  onCancel: () => void;
};

Form.Buttons = function Buttons({ onCancel }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton text="Применить" />
        <UiCancelFormButton onClick={onCancel} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
