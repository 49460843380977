import { SUPPORT_ROUTES } from 'shared/consts/routes/support';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={SUPPORT_ROUTES.ADD_SUPPORT}
      permissions={[
        {
          controller: 'InformationService',
          right: 'Write',
        },
      ]}
    />
  );
}
