import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetChargePoints } from 'shared/api/services/chargepoint/rtk';
import { useGetBalancingGroup } from 'shared/api/services/balancing/rtk/enhanced';

import { Form } from './form';

type Props = {
  groupId: string;
};

export function FormContainer({ groupId }: Props) {
  const chargePointsQuery = useGetChargePoints({ limit: 10 * 1000 });
  const balancingGroupQuery = useGetBalancingGroup(groupId);

  const isLoading =
    chargePointsQuery.isFetching || balancingGroupQuery.isFetching;

  const apiResponseError = [chargePointsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [chargePointsQuery.error, balancingGroupQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!chargePointsQuery.data?.data || !balancingGroupQuery.data) {
    return <EmptyData />;
  }

  return (
    <Form
      chargePoints={chargePointsQuery.data.data}
      balancingGroup={balancingGroupQuery.data}
    />
  );
}
