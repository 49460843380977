import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { HUB } from 'shared/consts/routes/hub';
import { ADD_PREFIX, HUBS } from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';

import { HubsMenu } from 'features/hub/menu';

import { AddHubPage, EditHubPage } from 'pages/hub';

export const HubRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[
          { controller: 'ChargePointServiceHubs', right: 'Read' },
          { controller: 'ChargePointServiceChargePoint', right: 'Read' },
        ]}
      />
    }
  >
    <Route path={HUBS} element={<SectionLayout menu={<HubsMenu />} />}>
      <Route
        element={
          <RequireAuth
            permissions={[
              { controller: 'ChargePointServiceHubs', right: 'Write' },
            ]}
          />
        }
      >
        <Route path={ADD_PREFIX} element={<AddHubPage />} />
      </Route>
      <Route path={HUB} element={<EditHubPage />} />
    </Route>
  </Route>
);
