import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  chargePointId?: string;
  userId?: string;
  chargePointGroupId?: string;
};

export function TableContainer({
  chargePointId,
  userId,
  chargePointGroupId,
}: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    sessions,
    sessionsCount,
    sessionsCountError,
    sessionsError,
  } = useQueries({
    chargePointId,
    userId,
    chargePointGroupId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (sessionsError) {
    return <ErrorMessage text={getServerErrorText(sessionsError)} />;
  }

  if (sessionsCountError) {
    return <ErrorMessage text={getServerErrorText(sessionsCountError)} />;
  }

  return (
    <TableInstance
      data={sessions}
      totalCount={sessionsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
