import {
  useBalancingGroupChargePoint,
  useGetBalancingGroups,
} from 'shared/api/services/balancing/rtk/enhanced';
import { useGetChargePointLastActivity } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';

export const useQueries = (chargePointId: string) => {
  const lastActivityQuery = useGetChargePointLastActivity(chargePointId);
  const balancingGroupChargePointQuery =
    useBalancingGroupChargePoint(chargePointId);
  const balancingGroupsQuery = useGetBalancingGroups();

  const getBalancingGroup = () => {
    if (balancingGroupChargePointQuery.data && balancingGroupsQuery.data) {
      const balancingGroup = balancingGroupsQuery.data.find(
        (el) => el.id === balancingGroupChargePointQuery.data?.balance_group_id
      );

      return balancingGroup ?? null;
    }
  };

  return {
    lastActivity: lastActivityQuery.data?.data,
    balancingGroup: getBalancingGroup(),
    isLastActivityLoading: lastActivityQuery.isLoading,
    isBalancingGroupLoading:
      balancingGroupChargePointQuery.isLoading ||
      balancingGroupsQuery.isLoading,
    errors: [lastActivityQuery.error, balancingGroupsQuery.error],
  };
};
