import { useEffect } from 'react';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    reserveSessions,
    reserveSessionsCount,
    reserveSessionsCountError,
    reserveSessionsError,
  } = useQueries({
    userId,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (reserveSessionsError) {
    return <ErrorMessage text={getServerErrorText(reserveSessionsError)} />;
  }

  if (reserveSessionsCountError) {
    return (
      <ErrorMessage text={getServerErrorText(reserveSessionsCountError)} />
    );
  }

  return (
    <TableInstance
      data={reserveSessions}
      totalCount={reserveSessionsCount}
      loading={isFetching}
      tableLimit={tableLimit}
    />
  );
}
