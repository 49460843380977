import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetBalancingGroupChargePoints } from 'shared/api/services/balancing/rtk/enhanced';
import { useGetChargePoints } from 'shared/api/services/chargepoint/rtk';

import { TableInstance } from './table';
import { getChargePointsMapping } from 'entities/charge-point';

type Props = {
  balancingGroupId: string;
};

export function TableContainer({ balancingGroupId }: Props) {
  const balancingGroupChargePointsQuery =
    useGetBalancingGroupChargePoints(balancingGroupId);
  const chargePointsQuery = useGetChargePoints({ limit: 10 * 1000 });

  const isLoading =
    balancingGroupChargePointsQuery.isLoading || chargePointsQuery.isLoading;

  const error = [balancingGroupChargePointsQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <TableInstance
      loading={isLoading}
      data={balancingGroupChargePointsQuery.data ?? []}
      chargePointsMapping={getChargePointsMapping(
        chargePointsQuery.data?.data || []
      )}
    />
  );
}
