import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { BonusTransactionListTable } from 'features/bonus-program/transactions-table';

const pageParamsSchema = z.object({
  bonusProgramId: z.string(),
});

export function BonusProgramTransactionListPage() {
  const { bonusProgramId } = useTypedParams(pageParamsSchema);

  return <BonusTransactionListTable bonusProgramId={bonusProgramId} />;
}
