import { Tooltip } from 'antd';

import { CustomLink } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';

import { Permission } from 'features/sidebar/types';

import { HeaderNavMenuItem, StyledHeaderNavMenu } from './styles';
import { MonitoringIcon } from '../../../icons/monitoring';
import { SecurityIcon } from '../../../icons/security';
import { SettingsIcon } from '../../../icons/settings';

type MenuItem = {
  key: string;
  path: string;
  icon: JSX.Element;
  permissions: Permission[];
  checkIsActive?: (path: string) => boolean;
};

const MENU_ITEMS: MenuItem[] = [
  {
    key: 'monitoring',
    path: '/',
    icon: (
      <Tooltip title="Мониторинг сети ЭЗС">
        <span>
          <MonitoringIcon />
        </span>
      </Tooltip>
    ),
    checkIsActive: (path: string) => {
      return !path.startsWith('/settings') && !path.startsWith('/security');
    },
    permissions: [
      {
        controller: 'BillingService',
        right: 'Read',
      },
      {
        controller: 'ChargePointServiceChargePoint',
        right: 'Read',
      },
      {
        controller: 'ChargePointServiceGroup',
        right: 'Read',
      },
      {
        controller: 'ChargePointServiceHubs',
        right: 'Read',
      },
      {
        controller: 'UserService',
        right: 'Read',
      },
      {
        controller: 'AnalyticsService',
        right: 'Read',
      },
      {
        controller: 'PushNotificationService',
        right: 'Read',
      },
      {
        controller: 'BonusService',
        right: 'Read',
      },
    ],
  },
  {
    key: 'settings',
    path: '/settings',
    icon: (
      <Tooltip title="Системные справочники">
        <span>
          <SettingsIcon />
        </span>
      </Tooltip>
    ),
    permissions: [
      {
        controller: 'InformationService',
        right: 'Read',
      },
      {
        controller: 'PaymentService',
        right: 'Read',
      },
      {
        controller: 'CompanyService',
        right: 'Read',
      },
      {
        controller: 'ChargePointServiceFirmware',
        right: 'Read',
      },
    ],
  },
  {
    key: 'security',
    path: '/security',
    icon: (
      <Tooltip title="Пользователи и администраторы">
        <span>
          <SecurityIcon />
        </span>
      </Tooltip>
    ),
    permissions: [{ controller: 'IdentityService', right: 'Read' }],
  },
];

export function NavMenu() {
  const ability = useAbility();

  const filteredMenuItems = MENU_ITEMS.filter((item) => {
    return item.permissions.every((item) => {
      const { controller, right } = item;

      return ability.can(right, controller);
    });
  });

  const menuItems = filteredMenuItems.map(
    ({ icon, key, path, checkIsActive }) => (
      <HeaderNavMenuItem key={key}>
        <CustomLink to={path} checkIsActive={checkIsActive}>
          {icon}
        </CustomLink>
      </HeaderNavMenuItem>
    )
  );

  return <StyledHeaderNavMenu>{menuItems}</StyledHeaderNavMenu>;
}
