import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { useAbility } from 'shared/lib/ability/context';

import { ADD_CP_PERMISSIONS } from 'entities/authorization/consts';

export function AddChargePointLink() {
  const ability = useAbility();

  const allowed = ADD_CP_PERMISSIONS.every((el) => {
    const { controller, right } = el;

    return ability.can(right, controller);
  });

  if (!allowed) {
    return null;
  }

  return (
    <Link to={CHARGE_POINT_ROUTES.ADD_CHARGE_POINT}>
      <NavigationTabButton title="Добавить ЭЗС" />
    </Link>
  );
}
