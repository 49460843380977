import {
  enhancedApi,
  useUpdateIdentityUser,
} from 'shared/api/services/identity/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Профиль обновлен';
const ERROR_MSG = 'Не удалось обновить профиль';

export const useUpdateUserProfile = () => {
  const [updateIdentityUser, { isLoading }] = useUpdateIdentityUser();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    data,
    userName,
    id,
  }: {
    id: string;
    userName: string;
    data: FormOutput;
  }) => {
    try {
      const { newPassword, ...rest } = data;

      const updateUserReq = {
        id,
        ...rest,
        ...(newPassword ? { newPassword } : {}),
      };

      const updateUserRes = await updateIdentityUser(updateUserReq).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'IdentityUsers', id: 'LIST' }])
      );
      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'IdentityUsers', id: userName },
        ])
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return {
    isLoading,
    handleUpdate,
  };
};
