import { USER_ROUTES } from 'shared/consts/routes/user';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddUserGroupLink() {
  return (
    <SectionMenuAddButton
      label="Добавить группу"
      path={USER_ROUTES.ADD_PERSONS_GROUP}
      permissions={[
        {
          controller: 'UserService',
          right: 'Write',
        },
      ]}
    />
  );
}
