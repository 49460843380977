import { Cell, flexRender, Header, Row, Table } from '@tanstack/react-table';
import { Spin, Typography } from 'antd';

import { SortingButton } from 'shared/ui/table-new/components/sorting/button';

import {
  HeaderCellContainer,
  HeaderCellContent,
  HeaderCellText,
  StyledFixedTable,
  TablaLayoutContainer,
  TableContainer,
} from './styles';

const { Text } = Typography;

type Props = {
  table: Table<any>;
  loading: boolean;
};

export function TableLayout({ table, loading }: Props) {
  const getTdClassName = (cell: Cell<any, unknown>) => {
    return cell.column.getIsSorted() !== false ? 'sorted' : '';
  };

  const handleSortChange = (header: Header<any, unknown>) => {
    return header.column.getToggleSortingHandler();
  };

  return (
    <TablaLayoutContainer>
      <TableContainer>
        <Spin spinning={loading}>
          <StyledFixedTable>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const filterElement =
                      header.column.columnDef.meta?.filterElement;

                    return (
                      <th
                        {...{
                          key: header.id,
                          style: {
                            width: header.getSize(),
                          },
                          className: `${
                            header.column.getIsSorted() !== false
                              ? 'sorted'
                              : ''
                          }${header.id === 'settings' ? 'settings' : ''}`,
                        }}
                      >
                        <HeaderCellContainer>
                          <HeaderCellContent
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer'
                                : '',
                              onClick:
                                header.id !== 'settings' &&
                                header.column.getCanSort()
                                  ? handleSortChange(header)
                                  : undefined,
                            }}
                          >
                            <HeaderCellText className="header">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </HeaderCellText>
                            {header.id !== 'settings' &&
                            header.column.getCanSort() ? (
                              <SortingButton
                                isDesc={header.column.getIsSorted() === 'desc'}
                              />
                            ) : null}
                          </HeaderCellContent>
                          {filterElement || null}
                        </HeaderCellContainer>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const cellContent = flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    );

                    return (
                      <td
                        key={cell.id}
                        className={getTdClassName(cell)}
                        style={{
                          width: cell.column.getSize(),
                        }}
                      >
                        <Text
                          style={{ width: cell.column.getSize() }}
                          ellipsis={{
                            tooltip: cellContent,
                          }}
                        >
                          {cellContent}
                        </Text>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </StyledFixedTable>
        </Spin>
      </TableContainer>
    </TablaLayoutContainer>
  );
}
