import { z } from 'zod';

import { postApiBillingV1StagesBody } from 'shared/api/services/billing/orval/zod/schemas';
import { emptyStringToUndefined } from 'shared/lib/schema';

const endType = postApiBillingV1StagesBody.shape.endType
  .nullish()
  .transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  });

export const StageResultSchema = z.object({
  name: z.string().min(1),
  tariffId: z.string().uuid(),
  serialNumber: z.number().int(),
  endConditionValue: z.number().gte(0),
  endType: endType,
});

export const StageSchema = z.object({
  name: z.string(),
  tariffId: z.string().uuid(),
  serialNumber: z
    .string()
    .transform(emptyStringToUndefined)
    .transform((value) => (value ? Number(value) : undefined)),
  endConditionValue: z
    .string()
    .transform(emptyStringToUndefined)
    .transform((value) => (value ? Number(value) : undefined)),
  endType: endType,
});
