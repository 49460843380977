import {
  BalanceName,
  ReportGroupingType,
} from 'shared/api/services/analytics/rtk/generated';

export const REPORT_PAYMENT_GROUP_BY: Record<ReportGroupingType, string> = {
  DAILY: 'по дням',
  MONTH: 'по месяцам',
  PERIOD: 'за период',
  NONE: 'транзакции',
};

export const REPORT_PAYMENT_BALANCE_NAME: Record<BalanceName, string> = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
};
