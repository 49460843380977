import { Link } from 'react-router-dom';

import { RESERVES, ROOT, SESSIONS, MANUAL_COMMANDS } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { AddChargePointLink } from 'entities/charge-point/ui/add-link';
import { SESSIONS_DEFAULT_SEARCH_PARAMS } from 'entities/session';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <Link to="">Мониторинг</Link>,
    extraContent: <AddChargePointLink />,
  },
  {
    key: SESSIONS,
    label: (
      <Link
        to={{
          pathname: SESSIONS,
          search: SESSIONS_DEFAULT_SEARCH_PARAMS,
        }}
      >
        Журнал сессий
      </Link>
    ),
  },
  // {
  //   key: RESERVES,
  //   label: <Link to={RESERVES}>Журнал брони</Link>,
  // permissions: [
  //   {
  //     controller: 'ReserveService',
  //     right: 'Execute',
  //   },
  // ],
  //   disabled: true,
  // },
  // {
  //   key: MANUAL_COMMANDS,
  //   label: <Link to={MANUAL_COMMANDS}>Управление</Link>,

  //   // extraContent: <OpenCommandButton />,
  //   disabled: true,
  // },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
