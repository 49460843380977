import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiPaymentV1ActionsPay: build.mutation<
      PostApiPaymentV1ActionsPayApiResponse,
      PostApiPaymentV1ActionsPayApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/pay`,
        method: 'POST',
        body: queryArg.payRequestDto,
        headers: {
          idempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiPaymentV1ActionsBinding: build.mutation<
      PostApiPaymentV1ActionsBindingApiResponse,
      PostApiPaymentV1ActionsBindingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/binding`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentV1ActionsUnbind: build.mutation<
      PostApiPaymentV1ActionsUnbindApiResponse,
      PostApiPaymentV1ActionsUnbindApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/unbind`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentV1ActionsHold: build.mutation<
      PostApiPaymentV1ActionsHoldApiResponse,
      PostApiPaymentV1ActionsHoldApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/hold`,
        method: 'POST',
        body: queryArg.holdRequestDto,
        headers: {
          idempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiPaymentV1ActionsDeposit: build.mutation<
      PostApiPaymentV1ActionsDepositApiResponse,
      PostApiPaymentV1ActionsDepositApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/deposit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentV1ActionsReverse: build.mutation<
      PostApiPaymentV1ActionsReverseApiResponse,
      PostApiPaymentV1ActionsReverseApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/reverse`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentV1ActionsSelectcard: build.mutation<
      PostApiPaymentV1ActionsSelectcardApiResponse,
      PostApiPaymentV1ActionsSelectcardApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Actions/selectcard`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiPaymentV1AppInfo: build.query<
      GetApiPaymentV1AppInfoApiResponse,
      GetApiPaymentV1AppInfoApiArg
    >({
      query: () => ({ url: `/Api/Payment/V1/AppInfo` }),
    }),
    getApiPaymentV1BanksById: build.query<
      GetApiPaymentV1BanksByIdApiResponse,
      GetApiPaymentV1BanksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/V1/Banks/${queryArg}` }),
    }),
    patchApiPaymentV1BanksById: build.mutation<
      PatchApiPaymentV1BanksByIdApiResponse,
      PatchApiPaymentV1BanksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Banks/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateBankRequestDto,
      }),
    }),
    deleteApiPaymentV1BanksById: build.mutation<
      DeleteApiPaymentV1BanksByIdApiResponse,
      DeleteApiPaymentV1BanksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Banks/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiPaymentV1Banks: build.query<
      GetApiPaymentV1BanksApiResponse,
      GetApiPaymentV1BanksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Banks`,
        params: {
          requestDto: queryArg,
        },
      }),
    }),
    postApiPaymentV1Banks: build.mutation<
      PostApiPaymentV1BanksApiResponse,
      PostApiPaymentV1BanksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Banks`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiPaymentV1BindingLogsById: build.query<
      GetApiPaymentV1BindingLogsByIdApiResponse,
      GetApiPaymentV1BindingLogsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/V1/BindingLogs/${queryArg}` }),
    }),
    getApiPaymentV1BindingLogs: build.query<
      GetApiPaymentV1BindingLogsApiResponse,
      GetApiPaymentV1BindingLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/BindingLogs`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BankClientId: queryArg.bankClientId,
          Status: queryArg.status,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          ErrorReason: queryArg.errorReason,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiPaymentV1BindingLogsCount: build.query<
      GetApiPaymentV1BindingLogsCountApiResponse,
      GetApiPaymentV1BindingLogsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/BindingLogs/count`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BankClientId: queryArg.bankClientId,
          Status: queryArg.status,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          ErrorReason: queryArg.errorReason,
        },
      }),
    }),
    getApiPaymentV1CardsById: build.query<
      GetApiPaymentV1CardsByIdApiResponse,
      GetApiPaymentV1CardsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/V1/Cards/${queryArg}` }),
    }),
    getApiPaymentV1Cards: build.query<
      GetApiPaymentV1CardsApiResponse,
      GetApiPaymentV1CardsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Cards`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BankClientId: queryArg.bankClientId,
          BindingId: queryArg.bindingId,
          CountryId: queryArg.countryId,
          IsSelected: queryArg.isSelected,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiPaymentV1CardsCount: build.query<
      GetApiPaymentV1CardsCountApiResponse,
      GetApiPaymentV1CardsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Cards/count`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BankClientId: queryArg.bankClientId,
          BindingId: queryArg.bindingId,
          IsSelected: queryArg.isSelected,
        },
      }),
    }),
    getApiPaymentV1PaymentsById: build.query<
      GetApiPaymentV1PaymentsByIdApiResponse,
      GetApiPaymentV1PaymentsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/V1/Payments/${queryArg}` }),
    }),
    getApiPaymentV1Payments: build.query<
      GetApiPaymentV1PaymentsApiResponse,
      GetApiPaymentV1PaymentsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Payments`,
        params: {
          BillingId: queryArg.billingId,
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BankClientId: queryArg.bankClientId,
          CardId: queryArg.cardId,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          Status: queryArg.status,
          ErrorReason: queryArg.errorReason,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiPaymentV1PaymentsCount: build.query<
      GetApiPaymentV1PaymentsCountApiResponse,
      GetApiPaymentV1PaymentsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/V1/Payments/count`,
        params: {
          BillingId: queryArg.billingId,
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BankClientId: queryArg.bankClientId,
          CardId: queryArg.cardId,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          Status: queryArg.status,
          ErrorReason: queryArg.errorReason,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiPaymentV1ActionsPayApiResponse =
  /** status 200 Success */ PaymentDtoResponseBase;
export type PostApiPaymentV1ActionsPayApiArg = {
  idempotencyKey: string;
  payRequestDto: PayRequestDto;
};
export type PostApiPaymentV1ActionsBindingApiResponse =
  /** status 200 Success */ InitBindingResultDtoResponseBase;
export type PostApiPaymentV1ActionsBindingApiArg = BindingRequestDto;
export type PostApiPaymentV1ActionsUnbindApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentV1ActionsUnbindApiArg = UnbindCardRequestDto;
export type PostApiPaymentV1ActionsHoldApiResponse =
  /** status 200 Success */ PaymentDtoResponseBase;
export type PostApiPaymentV1ActionsHoldApiArg = {
  idempotencyKey?: string;
  holdRequestDto: HoldRequestDto;
};
export type PostApiPaymentV1ActionsDepositApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentV1ActionsDepositApiArg = DepositRequestDto;
export type PostApiPaymentV1ActionsReverseApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentV1ActionsReverseApiArg = ReverseRequestDto;
export type PostApiPaymentV1ActionsSelectcardApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentV1ActionsSelectcardApiArg = SetSelectedCardRequestDto;
export type GetApiPaymentV1AppInfoApiResponse =
  /** status 200 Success */ string;
export type GetApiPaymentV1AppInfoApiArg = void;
export type GetApiPaymentV1BanksByIdApiResponse =
  /** status 200 Success */ BankDtoResponseBase;
export type GetApiPaymentV1BanksByIdApiArg = string;
export type PatchApiPaymentV1BanksByIdApiResponse =
  /** status 200 Success */ BankDtoResponseBase;
export type PatchApiPaymentV1BanksByIdApiArg = {
  id: string;
  updateBankRequestDto: UpdateBankRequestDto;
};
export type DeleteApiPaymentV1BanksByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiPaymentV1BanksByIdApiArg = string;
export type GetApiPaymentV1BanksApiResponse =
  /** status 200 Success */ BankDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentV1BanksApiArg = GetBanksByFilterRequestDto | undefined;
export type PostApiPaymentV1BanksApiResponse =
  /** status 200 Success */ BankDtoResponseBase;
export type PostApiPaymentV1BanksApiArg = CreateBankRequestDto;
export type GetApiPaymentV1BindingLogsByIdApiResponse =
  /** status 200 Success */ BindingLogDtoResponseBase;
export type GetApiPaymentV1BindingLogsByIdApiArg = string;
export type GetApiPaymentV1BindingLogsApiResponse =
  /** status 200 Success */ BindingLogDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentV1BindingLogsApiArg = {
  userId?: string;
  bankId?: string;
  bankClientId?: string;
  status?: PaymentStatus;
  orderNumber?: string;
  bankOrderId?: string;
  errorReason?: PaymentErrorReason;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiPaymentV1BindingLogsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiPaymentV1BindingLogsCountApiArg = {
  userId?: string;
  bankId?: string;
  bankClientId?: string;
  status?: PaymentStatus;
  orderNumber?: string;
  bankOrderId?: string;
  errorReason?: PaymentErrorReason;
};
export type GetApiPaymentV1CardsByIdApiResponse =
  /** status 200 Success */ CardDtoResponseBase;
export type GetApiPaymentV1CardsByIdApiArg = string;
export type GetApiPaymentV1CardsApiResponse =
  /** status 200 Success */ CardDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentV1CardsApiArg = {
  userId?: string;
  bankId?: string;
  bankClientId?: string;
  bindingId?: string;
  countryId?: string;
  isSelected?: boolean;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiPaymentV1CardsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiPaymentV1CardsCountApiArg = {
  userId?: string;
  bankId?: string;
  bankClientId?: string;
  bindingId?: string;
  isSelected?: boolean;
};
export type GetApiPaymentV1PaymentsByIdApiResponse =
  /** status 200 Success */ PaymentDtoResponseBase;
export type GetApiPaymentV1PaymentsByIdApiArg = string;
export type GetApiPaymentV1PaymentsApiResponse =
  /** status 200 Success */ PaymentDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentV1PaymentsApiArg = {
  billingId?: string;
  userId?: string;
  bankId?: string;
  bankClientId?: string;
  cardId?: string;
  orderNumber?: string;
  bankOrderId?: string;
  status?: PaymentStatus;
  errorReason?: PaymentErrorReason;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiPaymentV1PaymentsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiPaymentV1PaymentsCountApiArg = {
  billingId?: string;
  userId?: string;
  bankId?: string;
  bankClientId?: string;
  cardId?: string;
  orderNumber?: string;
  bankOrderId?: string;
  status?: PaymentStatus;
  errorReason?: PaymentErrorReason;
};
export type PaymentOperation = 'PAYMENT' | 'HOLDING';
export type PaymentStatus =
  | 'NEW'
  | 'IN_PROCESS'
  | 'PAID'
  | 'ERROR'
  | 'HOLD'
  | 'DEPOSITED'
  | 'REVERSE'
  | 'ERROR_PAYMENT'
  | 'UNBINDED';
export type PaymentErrorReason =
  | 'UNDEFINED'
  | 'NOT_ENOUGH_MONEY'
  | 'PAY_SERVICE_ERROR'
  | 'BANK_SERVICE_IS_UNAVAILABLE'
  | 'TIMEOUT_ERROR'
  | 'UNKNOWN_ERROR'
  | 'ORDER_NUMBER_NOT_FOUND';
export type PaymentDto = {
  id: string;
  billingId: string;
  userId: string;
  bankId: string;
  cardId: string;
  bankClientId: string;
  operation: PaymentOperation;
  orderNumber: string;
  bankOrderId?: string | null;
  invoiceSum: number;
  holdSum?: number | null;
  returnSum?: number | null;
  description?: string | null;
  status: PaymentStatus;
  errorReason?: PaymentErrorReason;
  errorText?: string | null;
  bankResponse?: string | null;
  paymentDate?: string | null;
  createdDate: string;
  lastUpdate: string;
  idempotencyKey: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type PaymentDtoResponseBase = {
  data?: PaymentDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type PayRequestDto = {
  userId: string;
  billingId: string;
  countryId: string;
  invoiceSum: number;
  phone: string;
  email?: string | null;
};
export type InitBindingResultDto = {
  orderNumber: string;
  formUrl: string;
  returnUrl: string;
};
export type InitBindingResultDtoResponseBase = {
  data?: InitBindingResultDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BindingRequestDto = {
  userId: string;
  bankClientId?: string | null;
  countryId: string;
  phone: string;
  email?: string | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UnbindCardRequestDto = {
  cardId: string;
};
export type HoldRequestDto = {
  userId: string;
  billingId: string;
  countryId: string;
  holdSum: number;
  phone: string;
  email?: string | null;
};
export type DepositRequestDto = {
  userId: string;
  billingId: string;
  debetSum: number;
};
export type ReverseRequestDto = {
  userId: string;
  billingId: string;
  amount?: number | null;
};
export type SetSelectedCardRequestDto = {
  cardId: string;
};
export type BankDto = {
  id: string;
  name: string;
  countryId: string;
  createdDate: string;
  lastUpdate: string;
};
export type BankDtoResponseBase = {
  data?: BankDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateBankRequestDto = {
  name?: string | null;
};
export type BankDtoIReadOnlyCollectionResponseBase = {
  data?: BankDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetBanksByFilterRequestDto = object;
export type CreateBankRequestDto = {
  id: string;
  name: string;
  countryId: string;
};
export type BindingLogDto = {
  id: string;
  userId: string;
  bankId: string;
  bankClientId: string;
  status: PaymentStatus;
  orderNumber: string;
  bankOrderId?: string | null;
  formUrl?: string | null;
  errorReason?: PaymentErrorReason;
  errorText?: string | null;
  bankResponse?: string | null;
  createdDate: string;
  lastUpdate: string;
};
export type BindingLogDtoResponseBase = {
  data?: BindingLogDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BindingLogDtoIReadOnlyCollectionResponseBase = {
  data?: BindingLogDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CardDto = {
  id: string;
  userId: string;
  bankClientId: string;
  bindingId: string;
  maskedPan: string;
  expirationString: string;
  expirationDate: string;
  isSelected: boolean;
  createdDate: string;
  bank: BankDto;
};
export type CardDtoResponseBase = {
  data?: CardDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CardDtoIReadOnlyCollectionResponseBase = {
  data?: CardDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type PaymentDtoIReadOnlyCollectionResponseBase = {
  data?: PaymentDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  usePostApiPaymentV1ActionsPayMutation,
  usePostApiPaymentV1ActionsBindingMutation,
  usePostApiPaymentV1ActionsUnbindMutation,
  usePostApiPaymentV1ActionsHoldMutation,
  usePostApiPaymentV1ActionsDepositMutation,
  usePostApiPaymentV1ActionsReverseMutation,
  usePostApiPaymentV1ActionsSelectcardMutation,
  useGetApiPaymentV1AppInfoQuery,
  useGetApiPaymentV1BanksByIdQuery,
  usePatchApiPaymentV1BanksByIdMutation,
  useDeleteApiPaymentV1BanksByIdMutation,
  useGetApiPaymentV1BanksQuery,
  usePostApiPaymentV1BanksMutation,
  useGetApiPaymentV1BindingLogsByIdQuery,
  useGetApiPaymentV1BindingLogsQuery,
  useGetApiPaymentV1BindingLogsCountQuery,
  useGetApiPaymentV1CardsByIdQuery,
  useGetApiPaymentV1CardsQuery,
  useGetApiPaymentV1CardsCountQuery,
  useGetApiPaymentV1PaymentsByIdQuery,
  useGetApiPaymentV1PaymentsQuery,
  useGetApiPaymentV1PaymentsCountQuery,
} = injectedRtkApi;
