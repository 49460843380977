import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import { baseApi } from 'shared/api/base';

import userGroupsReducer from 'shared/redux/slices/user-groups/slice';
import carModelsReducer from 'shared/redux/slices/car-models/slice';
import identityUsersReducer from 'shared/redux/slices/identity-users/slice';
import identityRolesReducer from 'shared/redux/slices/identity-roles/slice';
import identityRoleUsersReducer from 'shared/redux/slices/identity-role-users/slice';
import identityGroupUsersReducer from 'shared/redux/slices/identity-group-users/slice';
import identityGroupsReducer from 'shared/redux/slices/identity-groups/slice';
import batchesReducer from 'shared/redux/slices/batches/slice';

import bindingLogsReducer from 'features/user/binding-logs-table/store/slice';
import paymentsReducer from 'features/user/payments-table/store/slice';
import billingTransactionsReducer from 'features/billing/transactions-table/store/slice';
import bankCardsReducer from 'features/user/bank-cards-table/store/slice';
import billingsReducer from 'features/billing/table/store/slice';
import sessionsReducer from 'features/sessions/table/store/slice';
import reserveSessionsReducer from 'features/reserve/sessions-table/store/slice';
import eventsReducer from 'features/events/table/store/slice';
import usersReducer from 'features/user/table/store/slice';
import chargePointGroupsReducer from 'features/charge-point-group/table/store/slice';
import chargePointsReducer from 'features/charge-point/table/store/slice';
import bonusTransactionsReducer from 'features/bonus-program/transactions-table/store/slice';
import bonusProgramsReducer from 'features/bonus-program/table/store/slice';

import filtersReducer from 'features/charge-point/filters/store/slice';

import { monitoringPageSettingsReducer } from 'pages/monitoring';

import authReducer from './slices/auth/authSlice';
import authSessionReducer from './slices/auth/authSessionSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'monitoringPageSettings', 'chargePointsFilters', 'users'],
};
const authSessionPersistConfig = {
  key: 'authSession',
  storage: storageSession,
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  authSession: persistReducer(authSessionPersistConfig, authSessionReducer),
  monitoringPageSettings: monitoringPageSettingsReducer,
  chargePointFilters: filtersReducer,
  userGroups: userGroupsReducer,
  users: usersReducer,
  chargePoints: chargePointsReducer,
  chargePointGroups: chargePointGroupsReducer,
  events: eventsReducer,
  sessions: sessionsReducer,
  reserveSessions: reserveSessionsReducer,
  carModels: carModelsReducer,
  bankCards: bankCardsReducer,
  identityUsers: identityUsersReducer,
  identityRoles: identityRolesReducer,
  identityRoleUsers: identityRoleUsersReducer,
  identityGroups: identityGroupsReducer,
  identityGroupUsers: identityGroupUsersReducer,
  batches: batchesReducer,
  billings: billingsReducer,
  bonusPrograms: bonusProgramsReducer,
  bonusTransactions: bonusTransactionsReducer,
  bindingLogs: bindingLogsReducer,
  payments: paymentsReducer,
  billingTransactions: billingTransactionsReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);
