import { useGetIdentityUser } from 'shared/api/services/identity/rtk';
import { useAppSelector } from 'shared/redux/types';

import { selectTokenName } from 'entities/authorization';

import { StyledContainer } from './styles';

export function PowerbiCharts() {
  const userName = useAppSelector(selectTokenName);

  const identityUserQuery = useGetIdentityUser(userName);

  if (identityUserQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (identityUserQuery.isSuccess) {
    const { powerBiUrl } = identityUserQuery.data.user;

    if (!powerBiUrl) {
      return <div>Нет ссылки на дашборд</div>;
    }

    return (
      <StyledContainer>
        <iframe
          title="Сеть ЭЗС Россети"
          width="1150"
          height="715"
          src={powerBiUrl}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </StyledContainer>
    );
  }

  return null;
}
