import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Tooltip } from 'antd';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { BindingLogDto } from 'shared/api/services/payment/rtk/generated';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';

import { selectTableColumns, setTableColumns } from '../store/slice';
import { PAYMENT_ERROR_REASON, PAYMENT_STATUS } from '../consts';

const columnHelper = createColumnHelper<BindingLogDto>();

export const useColumns = ({
  mobileAppsMapping,
}: {
  mobileAppsMapping: Record<string, MobileAppVm>;
}) => {
  const dispatch = useAppDispatch();

  const tableColumns = useAppSelector(selectTableColumns);

  const DATA_COLUMNS = [
    columnHelper.accessor('createdDate', {
      id: 'createdDate',
      header: 'Дата создания',
      size: DATE_TIME_COLUMN_WIDTH,
      minSize: DATE_TIME_COLUMN_WIDTH,
      maxSize: DATE_TIME_COLUMN_WIDTH,
      cell: (props) => {
        const date = props.getValue();

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter
            dateFromName="dateFrom"
            dateToName="dateTo"
            disabled
          />
        ),
      },
    }),
    columnHelper.accessor('bankId', {
      id: 'bankId',
      header: 'Банк',
      meta: {
        filterElement: <InputFilter paramName="bankId" />,
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Статус',
      cell: (props) => {
        const val = props.getValue();

        return PAYMENT_STATUS[val];
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              ...Object.entries(PAYMENT_STATUS).map((entry) => {
                const [value, label] = entry;

                return { value, label };
              }),
            ]}
            paramName="status"
          />
        ),
      },
    }),
    columnHelper.accessor('bankClientId', {
      id: 'bankClientId',
      header: 'Приложение',
      cell: (props) => {
        const val = props.getValue();

        const mobileApp = mobileAppsMapping[val];

        if (!mobileApp) {
          return '';
        }

        return mobileApp.name;
      },
      meta: {
        filterElement: <InputFilter paramName="bankClientId" />,
      },
    }),
    columnHelper.accessor('orderNumber', {
      id: 'orderNumber',
      header: 'Код заказа',
      size: 360,
      minSize: 360,
      maxSize: 360,
      cell: (props) => {
        const val = props.getValue();

        const bindingLog = props.row.original;

        const { errorReason } = bindingLog;

        if (!errorReason) {
          return val;
        }

        return <Tooltip title={errorReason}>{val}</Tooltip>;
      },
      meta: {
        filterElement: <InputFilter paramName="orderNumber" />,
      },
    }),
    columnHelper.accessor('errorText', {
      id: 'errorText',
      header: 'Текст ошибки',
      meta: {
        filterElement: <InputFilter paramName="errorText" disabled />,
      },
    }),
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
