import jwt_decode from 'jwt-decode';

import { selectCurrentToken } from './selectCurrentToken';

export const selectGroupFromToken = (state: RootState) => {
  const token = selectCurrentToken(state);

  const decoded = jwt_decode(token) as { Groups: string | undefined };

  return decoded.Groups;
};
