import { groupBy } from 'lodash';
import { ReportGroupingType } from 'shared/api/services/analytics/rtk/generated';
import { getRuFormatDateString } from 'shared/lib';

import {
  ParamsContainer,
  ReportInfoContainer,
  ReportParam,
  ReportTitle,
} from './styles';

const BASE_TITLE = 'Сводный отчет по выручке';

const getReportTitle = (groupBy?: ReportGroupingType | undefined) => {
  if (!groupBy) {
    return BASE_TITLE;
  }

  switch (groupBy) {
    case 'DAILY':
      return `${BASE_TITLE} (дни)`;
    case 'MONTH':
      return `${BASE_TITLE} (месяцы)`;
    case 'PERIOD':
      return `${BASE_TITLE} (период)`;
    case 'NONE':
      return `${BASE_TITLE} (транзакции)`;
  }
};

type Props = {
  groupBy: ReportGroupingType | undefined;
  dateFromParam: string | undefined;
  dateToParam: string | undefined;
};

export function ReportInfo({ dateFromParam, dateToParam, groupBy }: Props) {
  const currentDate = getRuFormatDateString();

  const dateFrom =
    dateFromParam === undefined
      ? currentDate
      : getRuFormatDateString(dateFromParam);
  const dateTo =
    dateToParam === undefined
      ? currentDate
      : getRuFormatDateString(dateToParam);

  return (
    <ReportInfoContainer>
      <ReportTitle>{getReportTitle(groupBy)}</ReportTitle>
      <ParamsContainer>
        <ReportParam>{`Начало периода: ${dateFrom}`}</ReportParam>
        <ReportParam>{`Окончание периода: ${dateTo}`}</ReportParam>
        <ReportParam>{`Дата отчета: ${currentDate}`}</ReportParam>
        <ReportParam>{`Список ЭЗС: `}</ReportParam>
        <ReportParam>{`Группы ЭЗС: `}</ReportParam>
      </ParamsContainer>
    </ReportInfoContainer>
  );
}
