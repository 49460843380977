import { useRef } from 'react';

import {
  GetPaymentBillingVm,
  ReportGroupingType,
} from 'shared/api/services/analytics/rtk/generated';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';

import { ExportButton } from 'shared/ui';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';

import { Form } from '../form';
import { ReportTable } from '../table';
import { ReportHeader, StyledCard } from './styles';
import { ReportInfo } from '../info';
import { exportInExcel } from '../../lib/export-in-excel';

type Props = {
  chargePointsId?: string | undefined;
  groupsId?: string | undefined;
  companiesId?: string | undefined;
  mobileAppsId?: string | undefined;
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
  groupBy?: ReportGroupingType | undefined;
  //
  chargePointGroups: GetGroupVm[];
  chargePoints: GetChargePointVm[];
  companies: CompanyDto[];
  mobileApps: MobileAppVm[];
  billings: GetPaymentBillingVm[];

  isLoading: boolean;
  allowFetch: () => void;
};

export function ReportLayout({
  mobileAppsId,
  dateFrom,
  dateTo,
  chargePointsId,
  companiesId,
  groupsId,
  groupBy,
  chargePointGroups,
  chargePoints,
  companies,
  mobileApps,
  isLoading,
  allowFetch,
  billings,
}: Props) {
  const tableRef = useRef(null);

  const chargePointsMapping = chargePoints.reduce((acc, el) => {
    acc[el.id] = el;
    return acc;
  }, {} as Record<string, GetChargePointVm>);

  const groupsMapping = chargePointGroups.reduce((acc, el) => {
    acc[el.id] = el;
    return acc;
  }, {} as Record<string, GetGroupVm>);

  return (
    <>
      <Form
        chargePointGroups={chargePointGroups}
        chargePoints={chargePoints}
        companies={companies}
        mobileApps={mobileApps}
        isLoading={isLoading}
        chargePointsId={chargePointsId}
        companiesId={companiesId}
        groupsId={groupsId}
        mobileAppsId={mobileAppsId}
        groupBy={groupBy}
        dateFrom={dateFrom}
        dateTo={dateTo}
        allowFetch={allowFetch}
      />
      <StyledCard>
        <ReportHeader>
          <ReportInfo
            dateFromParam={dateFrom}
            dateToParam={dateTo}
            groupBy={groupBy}
          />
          <ExportButton
            onClick={() =>
              exportInExcel({
                tableRef,
                groupBy,
                dateFromParam: dateFrom,
                dateToParam: dateTo,
                chargePointsMapping,
                groupsMapping,
                chargePointsId,
                groupsId,
              })
            }
            disabled={isLoading}
          />
        </ReportHeader>
        <ReportTable
          billings={billings}
          isLoading={isLoading}
          tableRef={tableRef}
          groupBy={groupBy}
        />
      </StyledCard>
    </>
  );
}
