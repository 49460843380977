import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_TABLE_PAGE_SIZE } from 'shared/consts/table';

type State = {
  tableColumns: {
    isSelected: boolean;
    bankId: boolean;
    expirationString: boolean;
    maskedPan: boolean;
    bindingId: boolean;
    createdDate: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    isSelected: true,
    bankId: true,
    expirationString: true,
    maskedPan: true,
    bindingId: true,
    createdDate: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'bankCardsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
  selectors: {
    selectTableColumns: (state) => state.tableColumns,
    selectTableLimit: (state) => state.tableLimit,
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectTableColumns = (state: RootState) =>
  state.bankCards.tableColumns;
export const selectTableLimit = (state: RootState) =>
  state.bankCards.tableLimit;

export default slice.reducer;
