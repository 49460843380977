import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1EventsById: build.query<
      GetApiChargepointV1EventsByIdApiResponse,
      GetApiChargepointV1EventsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/chargepoint/V1/Events/${queryArg}` }),
    }),
    deleteApiChargepointV1EventsById: build.mutation<
      DeleteApiChargepointV1EventsByIdApiResponse,
      DeleteApiChargepointV1EventsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1Events: build.query<
      GetApiChargepointV1EventsApiResponse,
      GetApiChargepointV1EventsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          SessionId: queryArg.sessionId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiChargepointV1EventsCount: build.query<
      GetApiChargepointV1EventsCountApiResponse,
      GetApiChargepointV1EventsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/Count`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          SessionId: queryArg.sessionId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiChargepointV1EventsDisconnectEvent: build.mutation<
      PostApiChargepointV1EventsDisconnectEventApiResponse,
      PostApiChargepointV1EventsDisconnectEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/DisconnectEvent`,
        method: 'POST',
        body: queryArg.disconnectEventRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsConnectEvent: build.mutation<
      PostApiChargepointV1EventsConnectEventApiResponse,
      PostApiChargepointV1EventsConnectEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/ConnectEvent`,
        method: 'POST',
        body: queryArg.connectEventRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsBootNotificationRequest: build.mutation<
      PostApiChargepointV1EventsBootNotificationRequestApiResponse,
      PostApiChargepointV1EventsBootNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/BOOT_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.bootNotificationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsBootNotificationConfirmation: build.mutation<
      PostApiChargepointV1EventsBootNotificationConfirmationApiResponse,
      PostApiChargepointV1EventsBootNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/BOOT_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.bootNotificationConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsRemoteStartTransactionRequest: build.mutation<
      PostApiChargepointV1EventsRemoteStartTransactionRequestApiResponse,
      PostApiChargepointV1EventsRemoteStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/REMOTE_START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStartTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsRemoteStartTransactionConfirmation:
      build.mutation<
        PostApiChargepointV1EventsRemoteStartTransactionConfirmationApiResponse,
        PostApiChargepointV1EventsRemoteStartTransactionConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargepoint/V1/Events/REMOTE_START_TRANSACTION/CONFIRMATION`,
          method: 'POST',
          body: queryArg.remoteStartTransactionConfirmationRequest,
          headers: {
            IdempotencyKey: queryArg.idempotencyKey,
          },
        }),
      }),
    postApiChargepointV1EventsStartTransactionRequest: build.mutation<
      PostApiChargepointV1EventsStartTransactionRequestApiResponse,
      PostApiChargepointV1EventsStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.startTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsStartTransactionConfirmation: build.mutation<
      PostApiChargepointV1EventsStartTransactionConfirmationApiResponse,
      PostApiChargepointV1EventsStartTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/START_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.startTransactionConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsRemoteStopTransactionRequest: build.mutation<
      PostApiChargepointV1EventsRemoteStopTransactionRequestApiResponse,
      PostApiChargepointV1EventsRemoteStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/REMOTE_STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStopTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsRemoteStopTransactionConfirmation: build.mutation<
      PostApiChargepointV1EventsRemoteStopTransactionConfirmationApiResponse,
      PostApiChargepointV1EventsRemoteStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/REMOTE_STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.remoteStopTransactionConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsStopTransactionRequest: build.mutation<
      PostApiChargepointV1EventsStopTransactionRequestApiResponse,
      PostApiChargepointV1EventsStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.stopTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsStopTransactionConfirmation: build.mutation<
      PostApiChargepointV1EventsStopTransactionConfirmationApiResponse,
      PostApiChargepointV1EventsStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.stopTransactionConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsStatusNotificationRequest: build.mutation<
      PostApiChargepointV1EventsStatusNotificationRequestApiResponse,
      PostApiChargepointV1EventsStatusNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/STATUS_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.statusNotificationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsStatusNotificationConfirmation: build.mutation<
      PostApiChargepointV1EventsStatusNotificationConfirmationApiResponse,
      PostApiChargepointV1EventsStatusNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/STATUS_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.statusNotificationConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsMeterValuesRequest: build.mutation<
      PostApiChargepointV1EventsMeterValuesRequestApiResponse,
      PostApiChargepointV1EventsMeterValuesRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/METER_VALUES/REQUEST`,
        method: 'POST',
        body: queryArg.meterValuesRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsMeterValuesConfirmation: build.mutation<
      PostApiChargepointV1EventsMeterValuesConfirmationApiResponse,
      PostApiChargepointV1EventsMeterValuesConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/METER_VALUES/CONFIRMATION`,
        method: 'POST',
        body: queryArg.meterValuesConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsChangeConfigurationRequest: build.mutation<
      PostApiChargepointV1EventsChangeConfigurationRequestApiResponse,
      PostApiChargepointV1EventsChangeConfigurationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/CHANGE_CONFIGURATION/REQUEST`,
        method: 'POST',
        body: queryArg.changeConfigurationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsChangeConfigurationConfirmation: build.mutation<
      PostApiChargepointV1EventsChangeConfigurationConfirmationApiResponse,
      PostApiChargepointV1EventsChangeConfigurationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/CHANGE_CONFIGURATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeConfigurationConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsUnlockConnectorRequest: build.mutation<
      PostApiChargepointV1EventsUnlockConnectorRequestApiResponse,
      PostApiChargepointV1EventsUnlockConnectorRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/UNLOCK_CONNECTOR/REQUEST`,
        method: 'POST',
        body: queryArg.unlockConnectorRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsUnlockConnectorConfirmation: build.mutation<
      PostApiChargepointV1EventsUnlockConnectorConfirmationApiResponse,
      PostApiChargepointV1EventsUnlockConnectorConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/UNLOCK_CONNECTOR/CONFIRMATION`,
        method: 'POST',
        body: queryArg.unlockConnectorConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsChangeAvailabilityRequest: build.mutation<
      PostApiChargepointV1EventsChangeAvailabilityRequestApiResponse,
      PostApiChargepointV1EventsChangeAvailabilityRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/CHANGE_AVAILABILITY/REQUEST`,
        method: 'POST',
        body: queryArg.changeAvailablilityRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsChangeAvailabilityConfirmation: build.mutation<
      PostApiChargepointV1EventsChangeAvailabilityConfirmationApiResponse,
      PostApiChargepointV1EventsChangeAvailabilityConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/CHANGE_AVAILABILITY/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeAvailablilityConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsDataTransferRequest: build.mutation<
      PostApiChargepointV1EventsDataTransferRequestApiResponse,
      PostApiChargepointV1EventsDataTransferRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/DATA_TRANSFER/REQUEST`,
        method: 'POST',
        body: queryArg.dataTransferRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsDataTransferConfirmation: build.mutation<
      PostApiChargepointV1EventsDataTransferConfirmationApiResponse,
      PostApiChargepointV1EventsDataTransferConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/DATA_TRANSFER/CONFIRMATION`,
        method: 'POST',
        body: queryArg.dataTransferConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsResetRequest: build.mutation<
      PostApiChargepointV1EventsResetRequestApiResponse,
      PostApiChargepointV1EventsResetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/RESET/REQUEST`,
        method: 'POST',
        body: queryArg.resetRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsResetConfirmation: build.mutation<
      PostApiChargepointV1EventsResetConfirmationApiResponse,
      PostApiChargepointV1EventsResetConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/RESET/CONFIRMATION`,
        method: 'POST',
        body: queryArg.resetConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsTransactionEventRequest: build.mutation<
      PostApiChargepointV1EventsTransactionEventRequestApiResponse,
      PostApiChargepointV1EventsTransactionEventRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/TRANSACTION_EVENT/REQUEST`,
        method: 'POST',
        body: queryArg.transactionEventRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsTransactionEventConfirmation: build.mutation<
      PostApiChargepointV1EventsTransactionEventConfirmationApiResponse,
      PostApiChargepointV1EventsTransactionEventConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/TRANSACTION_EVENT/CONFIRMATION`,
        method: 'POST',
        body: queryArg.transactionEventConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsNotifyEventRequest: build.mutation<
      PostApiChargepointV1EventsNotifyEventRequestApiResponse,
      PostApiChargepointV1EventsNotifyEventRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/NOTIFY_EVENT/REQUEST`,
        method: 'POST',
        body: queryArg.notifyEventRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsNotifyEventConfirmation: build.mutation<
      PostApiChargepointV1EventsNotifyEventConfirmationApiResponse,
      PostApiChargepointV1EventsNotifyEventConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/NOTIFY_EVENT/CONFIRMATION`,
        method: 'POST',
        body: queryArg.notifyEventConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsAuthorizeRequest: build.mutation<
      PostApiChargepointV1EventsAuthorizeRequestApiResponse,
      PostApiChargepointV1EventsAuthorizeRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/AUTHORIZE/REQUEST`,
        method: 'POST',
        body: queryArg.authorizeRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsAuthorizeConfirmation: build.mutation<
      PostApiChargepointV1EventsAuthorizeConfirmationApiResponse,
      PostApiChargepointV1EventsAuthorizeConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/AUTHORIZE/CONFIRMATION`,
        method: 'POST',
        body: queryArg.authorizeConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsFirmwareStatusNotificationRequest: build.mutation<
      PostApiChargepointV1EventsFirmwareStatusNotificationRequestApiResponse,
      PostApiChargepointV1EventsFirmwareStatusNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/FIRMWARE_STATUS_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.firmwareStatusNotificationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsFirmwareStatusNotificationConfirmation:
      build.mutation<
        PostApiChargepointV1EventsFirmwareStatusNotificationConfirmationApiResponse,
        PostApiChargepointV1EventsFirmwareStatusNotificationConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargepoint/V1/Events/FIRMWARE_STATUS_NOTIFICATION/CONFIRMATION`,
          method: 'POST',
          body: queryArg.firmwareStatusNotificationConfirmationRequest,
          headers: {
            IdempotencyKey: queryArg.idempotencyKey,
          },
        }),
      }),
    postApiChargepointV1EventsGetDiagnosticsRequest: build.mutation<
      PostApiChargepointV1EventsGetDiagnosticsRequestApiResponse,
      PostApiChargepointV1EventsGetDiagnosticsRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/GET_DIAGNOSTICS/REQUEST`,
        method: 'POST',
        body: queryArg.getDiagnosticsRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsGetDiagnosticsConfirmation: build.mutation<
      PostApiChargepointV1EventsGetDiagnosticsConfirmationApiResponse,
      PostApiChargepointV1EventsGetDiagnosticsConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Events/GET_DIAGNOSTICS/CONFIRMATION`,
        method: 'POST',
        body: queryArg.getDiagnosticsConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargepointV1EventsDiagnosticsStatusNotificationRequest:
      build.mutation<
        PostApiChargepointV1EventsDiagnosticsStatusNotificationRequestApiResponse,
        PostApiChargepointV1EventsDiagnosticsStatusNotificationRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargepoint/V1/Events/DIAGNOSTICS_STATUS_NOTIFICATION/REQUEST`,
          method: 'POST',
          body: queryArg.diagnosticsStatusNotificationRequest,
          headers: {
            IdempotencyKey: queryArg.idempotencyKey,
          },
        }),
      }),
    postApiChargepointV1EventsDiagnosticsStatusNotificationConfirmation:
      build.mutation<
        PostApiChargepointV1EventsDiagnosticsStatusNotificationConfirmationApiResponse,
        PostApiChargepointV1EventsDiagnosticsStatusNotificationConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargepoint/V1/Events/DIAGNOSTICS_STATUS_NOTIFICATION/CONFIRMATION`,
          method: 'POST',
          body: queryArg.diagnosticsStatusNotificationConfirmationRequest,
          headers: {
            IdempotencyKey: queryArg.idempotencyKey,
          },
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1EventsByIdApiResponse =
  /** status 200 Success */ GetEventVmBaseResponse;
export type GetApiChargepointV1EventsByIdApiArg = /** Идентификтаор */ string;
export type DeleteApiChargepointV1EventsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1EventsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1EventsApiResponse =
  /** status 200 Success */ GetEventVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1EventsApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  sessionId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiChargepointV1EventsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargepointV1EventsCountApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  sessionId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiChargepointV1EventsDisconnectEventApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsDisconnectEventApiArg = {
  idempotencyKey?: string;
  disconnectEventRequest: DisconnectEventRequest;
};
export type PostApiChargepointV1EventsConnectEventApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsConnectEventApiArg = {
  idempotencyKey?: string;
  connectEventRequest: ConnectEventRequest;
};
export type PostApiChargepointV1EventsBootNotificationRequestApiResponse =
  /** status 200 Success */ BootNotificationEventVmBaseResponse;
export type PostApiChargepointV1EventsBootNotificationRequestApiArg = {
  idempotencyKey?: string;
  bootNotificationRequest: BootNotificationRequest;
};
export type PostApiChargepointV1EventsBootNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsBootNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  bootNotificationConfirmationRequest: BootNotificationConfirmationRequest;
};
export type PostApiChargepointV1EventsRemoteStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsRemoteStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStartTransactionRequest: RemoteStartTransactionRequest;
};
export type PostApiChargepointV1EventsRemoteStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsRemoteStartTransactionConfirmationApiArg =
  {
    idempotencyKey?: string;
    remoteStartTransactionConfirmationRequest: RemoteStartTransactionConfirmationRequest;
  };
export type PostApiChargepointV1EventsStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  startTransactionRequest: StartTransactionRequest;
};
export type PostApiChargepointV1EventsStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsStartTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  startTransactionConfirmationRequest: StartTransactionConfirmationRequest;
};
export type PostApiChargepointV1EventsRemoteStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsRemoteStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStopTransactionRequest: RemoteStopTransactionRequest;
};
export type PostApiChargepointV1EventsRemoteStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsRemoteStopTransactionConfirmationApiArg =
  {
    idempotencyKey?: string;
    remoteStopTransactionConfirmationRequest: RemoteStopTransactionConfirmationRequest;
  };
export type PostApiChargepointV1EventsStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  stopTransactionRequest: StopTransactionRequest;
};
export type PostApiChargepointV1EventsStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsStopTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  stopTransactionConfirmationRequest: StopTransactionConfirmationRequest;
};
export type PostApiChargepointV1EventsStatusNotificationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsStatusNotificationRequestApiArg = {
  idempotencyKey?: string;
  statusNotificationRequest: StatusNotificationRequest;
};
export type PostApiChargepointV1EventsStatusNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsStatusNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  statusNotificationConfirmationRequest: StatusNotificationConfirmationRequest;
};
export type PostApiChargepointV1EventsMeterValuesRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsMeterValuesRequestApiArg = {
  idempotencyKey?: string;
  meterValuesRequest: MeterValuesRequest;
};
export type PostApiChargepointV1EventsMeterValuesConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsMeterValuesConfirmationApiArg = {
  idempotencyKey?: string;
  meterValuesConfirmationRequest: MeterValuesConfirmationRequest;
};
export type PostApiChargepointV1EventsChangeConfigurationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsChangeConfigurationRequestApiArg = {
  idempotencyKey?: string;
  changeConfigurationRequest: ChangeConfigurationRequest;
};
export type PostApiChargepointV1EventsChangeConfigurationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsChangeConfigurationConfirmationApiArg = {
  idempotencyKey?: string;
  changeConfigurationConfirmationRequest: ChangeConfigurationConfirmationRequest;
};
export type PostApiChargepointV1EventsUnlockConnectorRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsUnlockConnectorRequestApiArg = {
  idempotencyKey?: string;
  unlockConnectorRequest: UnlockConnectorRequest;
};
export type PostApiChargepointV1EventsUnlockConnectorConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsUnlockConnectorConfirmationApiArg = {
  idempotencyKey?: string;
  unlockConnectorConfirmationRequest: UnlockConnectorConfirmationRequest;
};
export type PostApiChargepointV1EventsChangeAvailabilityRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsChangeAvailabilityRequestApiArg = {
  idempotencyKey?: string;
  changeAvailablilityRequest: ChangeAvailablilityRequest;
};
export type PostApiChargepointV1EventsChangeAvailabilityConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsChangeAvailabilityConfirmationApiArg = {
  idempotencyKey?: string;
  changeAvailablilityConfirmationRequest: ChangeAvailablilityConfirmationRequest;
};
export type PostApiChargepointV1EventsDataTransferRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsDataTransferRequestApiArg = {
  idempotencyKey?: string;
  dataTransferRequest: DataTransferRequest;
};
export type PostApiChargepointV1EventsDataTransferConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsDataTransferConfirmationApiArg = {
  idempotencyKey?: string;
  dataTransferConfirmationRequest: DataTransferConfirmationRequest;
};
export type PostApiChargepointV1EventsResetRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsResetRequestApiArg = {
  idempotencyKey?: string;
  resetRequest: ResetRequest;
};
export type PostApiChargepointV1EventsResetConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsResetConfirmationApiArg = {
  idempotencyKey?: string;
  resetConfirmationRequest: ResetConfirmationRequest;
};
export type PostApiChargepointV1EventsTransactionEventRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsTransactionEventRequestApiArg = {
  idempotencyKey?: string;
  transactionEventRequest: TransactionEventRequest;
};
export type PostApiChargepointV1EventsTransactionEventConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsTransactionEventConfirmationApiArg = {
  idempotencyKey?: string;
  transactionEventConfirmationRequest: TransactionEventConfirmationRequest;
};
export type PostApiChargepointV1EventsNotifyEventRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsNotifyEventRequestApiArg = {
  idempotencyKey?: string;
  notifyEventRequest: NotifyEventRequest;
};
export type PostApiChargepointV1EventsNotifyEventConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsNotifyEventConfirmationApiArg = {
  idempotencyKey?: string;
  notifyEventConfirmationRequest: NotifyEventConfirmationRequest;
};
export type PostApiChargepointV1EventsAuthorizeRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsAuthorizeRequestApiArg = {
  idempotencyKey?: string;
  authorizeRequest: AuthorizeRequest;
};
export type PostApiChargepointV1EventsAuthorizeConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsAuthorizeConfirmationApiArg = {
  idempotencyKey?: string;
  authorizeConfirmationRequest: AuthorizeConfirmationRequest;
};
export type PostApiChargepointV1EventsFirmwareStatusNotificationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsFirmwareStatusNotificationRequestApiArg =
  {
    idempotencyKey?: string;
    firmwareStatusNotificationRequest: FirmwareStatusNotificationRequest;
  };
export type PostApiChargepointV1EventsFirmwareStatusNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsFirmwareStatusNotificationConfirmationApiArg =
  {
    idempotencyKey?: string;
    firmwareStatusNotificationConfirmationRequest: FirmwareStatusNotificationConfirmationRequest;
  };
export type PostApiChargepointV1EventsGetDiagnosticsRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsGetDiagnosticsRequestApiArg = {
  idempotencyKey?: string;
  getDiagnosticsRequest: GetDiagnosticsRequest;
};
export type PostApiChargepointV1EventsGetDiagnosticsConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsGetDiagnosticsConfirmationApiArg = {
  idempotencyKey?: string;
  getDiagnosticsConfirmationRequest: GetDiagnosticsConfirmationRequest;
};
export type PostApiChargepointV1EventsDiagnosticsStatusNotificationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsDiagnosticsStatusNotificationRequestApiArg =
  {
    idempotencyKey?: string;
    diagnosticsStatusNotificationRequest: DiagnosticsStatusNotificationRequest;
  };
export type PostApiChargepointV1EventsDiagnosticsStatusNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1EventsDiagnosticsStatusNotificationConfirmationApiArg =
  {
    idempotencyKey?: string;
    diagnosticsStatusNotificationConfirmationRequest: DiagnosticsStatusNotificationConfirmationRequest;
  };
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type ChargePointProtocol = 'OCPP16' | 'OCPP16J' | 'OCPP20';
export type EventName =
  | 'BOOT_NOTIFICATION'
  | 'REMOTE_START_TRANSACTION'
  | 'START_TRANSACTION'
  | 'REMOTE_STOP_TRANSACTION'
  | 'STOP_TRANSACTION'
  | 'STATUS_NOTIFICATION'
  | 'METER_VALUES'
  | 'RESET'
  | 'CHANGE_CONFIGURATION'
  | 'UNLOCK_CONNECTOR'
  | 'CHANGE_AVAILABILITY'
  | 'DATA_TRANSFER'
  | 'TRANSACTION_EVENT'
  | 'NOTIFY_EVENT'
  | 'DISCONNECT'
  | 'CONNECT'
  | 'GET_DIAGNOSTICS'
  | 'DIAGNOSTICS_STATUS_NOTIFICATION';
export type EventType = 'REQUEST' | 'CONFIRMATION' | 'SYSTEM';
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type RemoteStartStopStatus = 'ACCEPTED' | 'REJECTED';
export type GetEventVm = {
  id: string;
  chargePointProtocol: ChargePointProtocol;
  name: EventName;
  type: EventType;
  chargePointId: string;
  createdDate: string;
  connectorId?: string | null;
  connectorStatus?: ConnectorStatus;
  remoteStartStopStatus?: RemoteStartStopStatus;
  transactionId?: string | null;
  idempotencyKey: string;
  sessionId?: string | null;
  instantPower?: number | null;
  instantCurrent?: number | null;
  reason?: string | null;
  chargingPercentage?: number | null;
  countTotalEnergy?: number | null;
  meterStop?: number | null;
  meterStart?: number | null;
  data?: string | null;
  warning?: string | null;
};
export type GetEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetEventVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type Reason =
  | 'UNSPECIFIED'
  | 'CONNECTION_CLOSED_BY_CONTROLLER'
  | 'CONNECTION_CLOSED_BY_STATION'
  | 'MAX_MESSAGE_FREQUENCY_EXCEEDED';
export type DisconnectEventRequest = {
  reason: Reason;
  chargePointId: string;
};
export type ConnectEventRequest = {
  chargePointId: string;
};
export type BootNotificationEventVm = {
  date: string;
  isBlocked: boolean;
};
export type BootNotificationEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: BootNotificationEventVm;
};
export type BootNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type BootNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type RemoteStartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
  innerConnectorId: number;
};
export type RemoteStartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  innerConnectorId: number;
  meterStart: number;
  data: string;
};
export type StartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  meterStop: number;
  reason: string;
  transactionId: string;
  instantPower?: number | null;
  chargingPercentage?: number | null;
  countTotalEnergy?: number | null;
};
export type StopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type StatusNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  status: ConnectorStatus;
  data: string;
  innerConnectorId: number;
};
export type StatusNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type MeterValuesRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  innerConnectorId: number;
  transactionId?: string | null;
  instantPower: number;
  instantCurrent: number;
  chargingPercentage: number;
  countTotalEnergy: number;
  warning?: string | null;
};
export type MeterValuesConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type TransactionEventRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type TransactionEventConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type NotifyEventRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type NotifyEventConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type AuthorizeRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type AuthorizeConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type FirmwareStatusNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type FirmwareStatusNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type GetDiagnosticsRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type GetDiagnosticsConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DiagnosticsStatusNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DiagnosticsStatusNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export const {
  useGetApiChargepointV1EventsByIdQuery,
  useDeleteApiChargepointV1EventsByIdMutation,
  useGetApiChargepointV1EventsQuery,
  useGetApiChargepointV1EventsCountQuery,
  usePostApiChargepointV1EventsDisconnectEventMutation,
  usePostApiChargepointV1EventsConnectEventMutation,
  usePostApiChargepointV1EventsBootNotificationRequestMutation,
  usePostApiChargepointV1EventsBootNotificationConfirmationMutation,
  usePostApiChargepointV1EventsRemoteStartTransactionRequestMutation,
  usePostApiChargepointV1EventsRemoteStartTransactionConfirmationMutation,
  usePostApiChargepointV1EventsStartTransactionRequestMutation,
  usePostApiChargepointV1EventsStartTransactionConfirmationMutation,
  usePostApiChargepointV1EventsRemoteStopTransactionRequestMutation,
  usePostApiChargepointV1EventsRemoteStopTransactionConfirmationMutation,
  usePostApiChargepointV1EventsStopTransactionRequestMutation,
  usePostApiChargepointV1EventsStopTransactionConfirmationMutation,
  usePostApiChargepointV1EventsStatusNotificationRequestMutation,
  usePostApiChargepointV1EventsStatusNotificationConfirmationMutation,
  usePostApiChargepointV1EventsMeterValuesRequestMutation,
  usePostApiChargepointV1EventsMeterValuesConfirmationMutation,
  usePostApiChargepointV1EventsChangeConfigurationRequestMutation,
  usePostApiChargepointV1EventsChangeConfigurationConfirmationMutation,
  usePostApiChargepointV1EventsUnlockConnectorRequestMutation,
  usePostApiChargepointV1EventsUnlockConnectorConfirmationMutation,
  usePostApiChargepointV1EventsChangeAvailabilityRequestMutation,
  usePostApiChargepointV1EventsChangeAvailabilityConfirmationMutation,
  usePostApiChargepointV1EventsDataTransferRequestMutation,
  usePostApiChargepointV1EventsDataTransferConfirmationMutation,
  usePostApiChargepointV1EventsResetRequestMutation,
  usePostApiChargepointV1EventsResetConfirmationMutation,
  usePostApiChargepointV1EventsTransactionEventRequestMutation,
  usePostApiChargepointV1EventsTransactionEventConfirmationMutation,
  usePostApiChargepointV1EventsNotifyEventRequestMutation,
  usePostApiChargepointV1EventsNotifyEventConfirmationMutation,
  usePostApiChargepointV1EventsAuthorizeRequestMutation,
  usePostApiChargepointV1EventsAuthorizeConfirmationMutation,
  usePostApiChargepointV1EventsFirmwareStatusNotificationRequestMutation,
  usePostApiChargepointV1EventsFirmwareStatusNotificationConfirmationMutation,
  usePostApiChargepointV1EventsGetDiagnosticsRequestMutation,
  usePostApiChargepointV1EventsGetDiagnosticsConfirmationMutation,
  usePostApiChargepointV1EventsDiagnosticsStatusNotificationRequestMutation,
  usePostApiChargepointV1EventsDiagnosticsStatusNotificationConfirmationMutation,
} = injectedRtkApi;
