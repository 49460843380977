import { useEffect } from 'react';
import { ChargePointStatus } from 'shared/api/services/chargepoint/rtk/generated/metrics';

import { useTableLimit } from 'shared/hooks/use-table-limit';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { ErrorMessage } from 'shared/ui';

import { useQueries } from '../hooks/use-queries';
import { selectTableLimit, setTableLimit } from '../store/slice';
import { TableInstance } from './table';

type Props = {
  groupId?: string;
  withTestCp?: boolean;
  statuses?: ChargePointStatus[];
  companyIds?: string[];
  notCompaniesId: string[];
};

export function TableContainer({
  groupId,
  notCompaniesId,
  companyIds,
  statuses,
  withTestCp,
}: Props) {
  const limitStateParam = useAppSelector(selectTableLimit);

  const dispatch = useAppDispatch();

  const tableLimit = useTableLimit(limitStateParam);

  useEffect(() => {
    dispatch(setTableLimit(tableLimit));
  }, [tableLimit]);

  const {
    isFetching,
    isLoading,
    chargePoints,
    chargePointsCount,
    companiesMapping,
    chargePointsError,
    chargePointsCountError,
    companiesError,
  } = useQueries({
    notCompaniesId,
    companyIds,
    statuses,
    withTestCp,
    limit: tableLimit,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (companiesError) {
    openErrorNotification(getServerErrorText(companiesError));
  }

  if (chargePointsError) {
    return <ErrorMessage text={getServerErrorText(chargePointsError)} />;
  }

  if (chargePointsCountError) {
    return <ErrorMessage text={getServerErrorText(chargePointsCountError)} />;
  }

  return (
    <TableInstance
      data={chargePoints}
      totalCount={chargePointsCount}
      loading={isFetching}
      companiesMapping={companiesMapping}
      tableLimit={tableLimit}
    />
  );
}
