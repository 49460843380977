import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { BillingTransactionListTable } from 'features/billing/transactions-table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UserBillingTransactionsPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <BillingTransactionListTable userId={userId} />;
}
