import { useSearchParams } from 'react-router-dom';

import { getQueryParams } from 'shared/lib/router/get-query-params';

import { DEFAULT_LIMIT } from '../consts';

const DEFAULT_PAGE = 1;

export const useQueryParamsPagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');

  const setPage = (page: number) => {
    if (page < 0) {
      return;
    }

    const queryParams = getQueryParams();

    setSearchParams({ ...queryParams, page: String(page) });
  };

  const setLimit = (limit: number) => {
    setSearchParams({ limit: String(limit) });

    const queryParams = getQueryParams();

    setSearchParams({ ...queryParams, limit: String(limit) });
  };

  return {
    page: page ? Number(page) : DEFAULT_PAGE,
    limit: limit ? Number(limit) : DEFAULT_LIMIT,
    setPage,
    setLimit,
  };
};
