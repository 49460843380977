import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';
import { postApiBillingV1BalancesActionCreateBody } from 'shared/api/services/billing/orval/zod/schemas';

const generatedSchema = postApiBillingV1BalancesActionCreateBody;

const FormResultSchema = z.object({
  countryId: z.string().min(1),
  balanceName: generatedSchema.shape.balanceName.unwrap(),
  value: z.number().gte(0),
});

export const FormSchema = z
  .object({
    countryId: z.string().transform(emptyStringToUndefined),
    balanceName: generatedSchema.shape.balanceName.unwrap(),
    value: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  countryId: '',
  balanceName: 'PERSONAL',
  value: '',
};
