import { Col, Spin, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';
import { useUpdateCountry } from 'shared/api/services/information/rtk';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAbility } from 'shared/lib/ability/context';
import { LabelWithHint } from 'shared/ui/styles';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

type Props = {
  country: GetCountryVm;
  mobileApps: MobileAppVm[];
};

export function Form({ country, mobileApps }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...country },
  });

  const [trigger, { isLoading }] = useUpdateCountry();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: country.id,
        updateCountryRequest: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{country.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields mobileApps={mobileApps} isDisabled={isDisabled} />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  mobileApps: MobileAppVm[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ mobileApps, isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="id"
            label="ID"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Название"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="code"
            label={
              <LabelWithHint>
                Код страны
                <Tooltip title="Двухбуквенный код страны ISO">
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="currencySymbol"
            label="Символ валюты"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="currencyText"
            label="Название валюты"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="phonePrefix"
            label={
              <LabelWithHint>
                Телефонный префикс
                <Tooltip title="Префикс страны, например +7">
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="phoneMask"
            label={
              <LabelWithHint>
                Телефонная маска
                <Tooltip
                  title={
                    <>
                      <p>
                        Формат заполнения:
                        *префикс:количество_цифр_после_префикса*
                      </p>
                      <p>Например 7:10 - формат российских номеров телефона.</p>
                      <p>
                        Могут быть указать несколько масок через запятую,
                        например 7:10,7:9
                      </p>
                    </>
                  }
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="urlFlag"
            label="URL иконки флага"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="language"
            label="Язык"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="privacyPolicyUrl"
            label="URL политики конфиденциальности"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="displayName"
            label="Отображаемое название"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomCheckbox<FormInput>
            name="isDefault"
            label="isDefault"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="mobileAppIds"
            options={mobileApps.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Мобильные приложения"
            allowClear
            mode="multiple"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'InformationService')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
