import { ADD_PREFIX, BALANCING_GROUPS } from '../names';

export const BALANCING_GROUP = ':groupId';

const ROOT = `/${BALANCING_GROUPS}`;

export const BALANCING_GROUP_ROUTES = {
  LIST: ROOT,
  BALANCING_GROUP: `${ROOT}/${BALANCING_GROUP}`,
  ADD_BALANCING_GROUP: `${ROOT}/${ADD_PREFIX}`,
};
