import { z } from 'zod';

import {
  useGetChargePointGroups,
  useGetChargePointGroupsCount,
} from 'shared/api/services/chargepoint/rtk';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';

const filteringQueryParams = z.object({});

const groupsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const groupsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
};

export const useQueries = ({ limit }: Props) => {
  const groupsParams = useTypedQueryParamsCustom(groupsQueryParams);
  const groupsCountParams = useTypedQueryParamsCustom(groupsCountQueryParams);

  const groupsQuery = useGetChargePointGroups({
    ...groupsParams,
    offset: getOffsetQueryParam(groupsParams.page, limit),
    limit,
  });

  const groupsCountQuery = useGetChargePointGroupsCount();

  const isLoading = groupsQuery.isLoading || groupsCountQuery.isLoading;
  const isFetching = groupsQuery.isFetching || groupsCountQuery.isFetching;

  return {
    isLoading,
    isFetching,
    groups: groupsQuery.data?.data ?? [],
    groupsCount: groupsCountQuery.data?.data ?? Infinity,
    groupsError: groupsQuery.error,
    groupsCountError: groupsCountQuery.error,
  };
};
