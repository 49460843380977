import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { IdentityServiceWebApiDtoGroupDto } from 'shared/api/services/identity/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { useHandleCreate } from '../hooks/use-handle-create';

const FORM_TITLE = 'Добавление пользователя';

type Props = {
  groups: IdentityServiceWebApiDtoGroupDto[];
};

export function Form({ groups }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const { handleCreate, isLoading } = useHandleCreate();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleCreate({ data });
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields groups={groups} />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = Props;

Form.Fields = function Fields({ groups }: FieldsProps) {
  // const {
  //   formState: { errors },
  // } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="userName" label="Логин" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="lastName" label="Фамилия" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="firstName" label="Имя" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="middleName" label="Отчество" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="email" label="Email" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="password"
            label="Пароль"
            type="password"
            autoComplete="new-password"
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="groupName"
            options={groups.map(({ id, name }) => ({
              value: name,
              label: name,
            }))}
            label="Группа"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="powerBiUrl" label="Ссылка на дашборд" />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
