import { Link } from 'react-router-dom';

import { USER_ROUTES } from 'shared/consts/routes/user';
import { useAbility } from 'shared/lib/ability/context';
import { NavigationTabButton } from 'shared/ui';

export function AddUserGroupLink() {
  const ability = useAbility();

  if (!ability.can('Write', 'UserService')) {
    return null;
  }

  return (
    <Link to={USER_ROUTES.ADD_PERSONS_GROUP}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
