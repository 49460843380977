import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { useLazyGetBilling } from 'shared/api/services/billing/rtk/enhanced';
import { isErrorWithMessage, openErrorNotification } from 'shared/lib';
import { HighlightedText } from 'shared/ui/styles';
import { useLazyGetSession } from 'shared/api/services/chargepoint/rtk/enhanced/sessions';

import { SessionDetailsModal } from 'entities/session';

type Props = {
  billingId: string;
};

export function SessionInfo({ billingId }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const [getBillingTrigger] = useLazyGetBilling();
  const [getSessionTrigger] = useLazyGetSession();

  if (!billingId) {
    return null;
  }

  const handleClick = async () => {
    setIsLoading(true);

    try {
      const billingRes = await getBillingTrigger(billingId).unwrap();

      if (!billingRes.data) {
        // throw
        return;
      }

      const chargingProvidedService = billingRes.data.providedServices.find(
        (el) => el.serviceType === 'CHARGING'
      );

      const sessionId = chargingProvidedService?.sessionId;

      if (!sessionId) {
        //
        return;
      }

      const sessionRes = await getSessionTrigger(sessionId).unwrap();

      if (!sessionRes.data) {
        // throw
        return;
      }

      NiceModal.show(SessionDetailsModal, { session: sessionRes.data });
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusMessage
        : 'Не удалось получить сессию';

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HighlightedText onClick={handleClick}>
      {isLoading ? 'Loading...' : 'Info'}
    </HighlightedText>
  );
}
