import { useState } from 'react';

import {
  enhancedApi,
  useAddServiceMutation,
  useAddStageMutation,
  useDeleteServicesListLinksByTargetId,
  usePutServicesListLinks,
  useUpdateServicesListMutation,
} from 'shared/api/services/billing/rtk/enhanced';
import {
  ServicesListDto,
  ServicesListLinkDto,
} from 'shared/api/services/billing/rtk/generated';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Список услуг обновлен';
const ERROR_MSG = 'Не удалось обновить список услуг';

export const useUpdateServicesList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [addService] = useAddServiceMutation();
  const [updateServicesList] = useUpdateServicesListMutation();
  const [addStage] = useAddStageMutation();
  const [setServicesListLinks] = usePutServicesListLinks();
  const [deleteServicesListLinks] = useDeleteServicesListLinksByTargetId();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    data,
    servicesList,
    servicesListLinks,
  }: {
    data: FormOutput;
    servicesList: ServicesListDto;
    servicesListLinks: ServicesListLinkDto[];
  }) => {
    const { chargingStages, reservingStages, name, chargePointIds } = data;

    const { services, id } = servicesList;

    // 1. текущие привязки
    // 2. новый список

    // на выходе: массив для удаления, массив для добавления

    const currentLinks = servicesListLinks.map((el) => el.targetId);

    const currentLinksMappping = currentLinks.reduce((acc, el) => {
      acc[el] = true;

      return acc;
    }, {} as Record<string, boolean>);

    const newLinksMapping = chargePointIds.reduce((acc, el) => {
      acc[el] = true;

      return acc;
    }, {} as Record<string, boolean>);

    const linksToDelete = currentLinks.filter((el) => !newLinksMapping[el]);
    const linksToPut = chargePointIds.filter((el) => !currentLinksMappping[el]);

    // TODO: проверять по всем параметрам - нужен ли запрос

    // if (
    //   name === servicesList.name &&
    //   !chargingStages?.length &&
    //   !reservingStages?.length
    //   //
    // ) {
    //   return;
    // }

    setIsLoading(true);

    let servicesIds = services?.map((el) => el.id) ?? [];

    try {
      // 1. Услуга "Заряд"
      //

      if (chargingStages?.length) {
        let serviceId: string;

        const service = services?.find((el) => el.type === 'CHARGING');

        if (service) {
          serviceId = service.id;
        } else {
          const addServiceRes = await addService({
            name: 'Услуга Заряд',
            type: 'CHARGING',
          }).unwrap();

          const createdServiceId = addServiceRes.data?.id;

          if (!createdServiceId) {
            throw 'Неизвестная ошибка';
          }

          serviceId = createdServiceId;

          servicesIds.push(createdServiceId);
        }

        // Добавление стадий к услуге
        //

        const addStagesRes = await Promise.all(
          chargingStages.map((stage) =>
            addStage({ ...stage, serviceId }).unwrap()
          )
        );
      }

      // 2. Услуга "Бронирование"
      //

      if (reservingStages?.length) {
        let serviceId: string;

        const service = services?.find((el) => el.type === 'RESERVING');

        if (service) {
          serviceId = service.id;
        } else {
          const addServiceRes = await addService({
            name: 'Услуга Бронирование',
            type: 'RESERVING',
          }).unwrap();

          const createdServiceId = addServiceRes.data?.id;

          if (!createdServiceId) {
            throw 'Неизвестная ошибка';
          }

          serviceId = createdServiceId;

          servicesIds.push(createdServiceId);
        }

        // Добавление стадий к услуге
        //

        const addStagesRes = await Promise.all(
          reservingStages.map((stage) =>
            addStage({ ...stage, serviceId }).unwrap()
          )
        );
      }

      // 3. Обновление списка услуг
      //

      const updateServicesListRes = await updateServicesList({
        id,
        updateServicesListRequestDto: {
          servicesIds,
          name,
        },
      }).unwrap();

      // 4. Добавление привязки ЭЗС
      //

      if (linksToPut.length) {
        const setServicesListLinksRes = await setServicesListLinks({
          servicesListsLinks: linksToPut.map((el) => ({
            servicesListId: servicesList.id,
            targetName: 'CHARGE_POINT',
            targetId: el,
          })),
        });
      }

      // 5. Удаление привязки ЭЗС
      if (linksToDelete.length) {
        for (const link of linksToDelete) {
          const deleteRes = await deleteServicesListLinks(link);
        }
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'ServicesLists', id: 'LIST' },
          {
            type: 'ServicesLists',
            id,
          },
          { type: 'ServicesListLinks', id: servicesList.id },
        ])
      );
    } catch (err) {
      // console.log('err', err);

      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdate,
    isLoading,
  };
};
