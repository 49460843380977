import { AddBillingBalanceButton } from 'features/user/add-billing-balance';
import { UserBillingBalancesList } from 'features/user/billing-balances';

import { StyledContainer } from './styles';

type Props = {
  userId: string;
};

export function UserBillingBalancesWidget({ userId }: Props) {
  return (
    <StyledContainer>
      <AddBillingBalanceButton userId={userId} />
      <UserBillingBalancesList userId={userId} />
    </StyledContainer>
  );
}
