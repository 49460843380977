import Excel from 'exceljs';

import { ReportGroupingType } from 'shared/api/services/analytics/rtk/generated';

import { buildBase } from './build-base';
import { buildGroupByPeriod } from './build-group-by-period';

type Props = {
  groupBy?: ReportGroupingType | undefined;
  ws: Excel.Worksheet;
  table: HTMLElement;
  FOOTER_ROW: number;
};

export const buildTable = (props: Props) => {
  const { groupBy, ...rest } = props;
  if (groupBy === 'PERIOD') {
    return buildGroupByPeriod(rest);
  }

  return buildBase(rest);
};
