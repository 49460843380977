import { useState } from 'react';

import {
  useAddBalancingGroupMutation,
  useToggleBalancing,
} from 'shared/api/services/balancing/rtk/enhanced';
import { enhancedApi } from 'shared/api/services/identity/rtk/generated';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Балансировка добавлена';
const ERROR_MSG = 'Не удалось добавить балансировку';

export const useCreateBalancingGroup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [addTrigger] = useAddBalancingGroupMutation();
  const [toggleTrigger] = useToggleBalancing();

  const dispatch = useAppDispatch();

  const handleCreate = async (data: FormOutput) => {
    const { isActive, chargePointIds, name, powerLimit, description } = data;

    const addBody = {
      name,
      power_limit: powerLimit,
      description: description ?? null,
      charge_points: chargePointIds.map((el) => {
        return {
          id: el,
          default_power: powerLimit,
        };
      }),
    };

    try {
      setIsLoading(true);

      const addRes = await addTrigger(addBody).unwrap();

      const groupId = addRes.id;

      if (isActive) {
        // По дефолту создается с DEACTIVATED
        // Шлем тоггл только для isActive=true
        const toggleRes = await toggleTrigger({
          groupId,
          state: 'ACTIVATED',
        });
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BalancingGroups', id: 'LIST' },
        ])
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleCreate,
  };
};
