import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_TABLE_PAGE_SIZE } from 'shared/consts/table';

type State = {
  tableColumns: {
    createdDate: boolean;
    type: boolean;
    name: boolean;
    connectorStatus: boolean;
    connectorId: boolean;
    energy: boolean;
    instantPower: boolean;
    instantCurrent: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    createdDate: true,
    type: true,
    name: true,
    connectorStatus: true,
    connectorId: true,
    energy: true,
    instantPower: true,
    instantCurrent: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectTableColumns = (state: RootState) =>
  state.events.tableColumns;
export const selectTableLimit = (state: RootState) => state.events.tableLimit;

export default slice.reducer;
