import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllBalancesApiBonusV1BalancesGet: build.query<
      GetAllBalancesApiBonusV1BalancesGetApiResponse,
      GetAllBalancesApiBonusV1BalancesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          orderingType: queryArg.orderingType,
          orderingField: queryArg.orderingField,
          userId: queryArg.userId,
          countryId: queryArg.countryId,
          balanceId: queryArg.balanceId,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
        },
      }),
    }),
    postDebitApiBonusV1BalancesActionDebitPost: build.mutation<
      PostDebitApiBonusV1BalancesActionDebitPostApiResponse,
      PostDebitApiBonusV1BalancesActionDebitPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances/action/debit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postCreditApiBonusV1BalancesActionManualCreditPost: build.mutation<
      PostCreditApiBonusV1BalancesActionManualCreditPostApiResponse,
      PostCreditApiBonusV1BalancesActionManualCreditPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances/action/manual/credit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postDebitManualApiBonusV1BalancesActionManualDebitPost: build.mutation<
      PostDebitManualApiBonusV1BalancesActionManualDebitPostApiResponse,
      PostDebitManualApiBonusV1BalancesActionManualDebitPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances/action/manual/debit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getBalancesCountApiBonusV1BalancesCountGet: build.query<
      GetBalancesCountApiBonusV1BalancesCountGetApiResponse,
      GetBalancesCountApiBonusV1BalancesCountGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances/count`,
        params: {
          userId: queryArg.userId,
          countryId: queryArg.countryId,
          balanceId: queryArg.balanceId,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
        },
      }),
    }),
    getAllTransactionsApiBonusV1BalancesTransactionsGet: build.query<
      GetAllTransactionsApiBonusV1BalancesTransactionsGetApiResponse,
      GetAllTransactionsApiBonusV1BalancesTransactionsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances/transactions`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          orderingType: queryArg.orderingType,
          orderingField: queryArg.orderingField,
          userId: queryArg.userId,
          countryId: queryArg.countryId,
          balanceId: queryArg.balanceId,
          bonusProgramId: queryArg.bonusProgramId,
          externalLinkId: queryArg.externalLinkId,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
        },
      }),
    }),
    getTransactionsCountApiBonusV1BalancesTransactionsCountGet: build.query<
      GetTransactionsCountApiBonusV1BalancesTransactionsCountGetApiResponse,
      GetTransactionsCountApiBonusV1BalancesTransactionsCountGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/balances/transactions/count`,
        params: {
          userId: queryArg.userId,
          countryId: queryArg.countryId,
          balanceId: queryArg.balanceId,
          bonusProgramId: queryArg.bonusProgramId,
          externalLinkId: queryArg.externalLinkId,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
        },
      }),
    }),
    getAllBonusLevelApiBonusV1BonusLevelsGet: build.query<
      GetAllBonusLevelApiBonusV1BonusLevelsGetApiResponse,
      GetAllBonusLevelApiBonusV1BonusLevelsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_levels`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          orderingType: queryArg.orderingType,
          orderingField: queryArg.orderingField,
        },
      }),
    }),
    createBonusProgramLevelApiBonusV1BonusLevelsPost: build.mutation<
      CreateBonusProgramLevelApiBonusV1BonusLevelsPostApiResponse,
      CreateBonusProgramLevelApiBonusV1BonusLevelsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_levels`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGet:
      build.query<
        GetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetApiResponse,
        GetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/bonus/v1/bonus_levels/bonus_program/${queryArg}`,
        }),
      }),
    deleteBonusProgramLevelApiBonusV1BonusLevelsIdDelete: build.mutation<
      DeleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteApiResponse,
      DeleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_levels/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getBonusProgramLevelByIdApiBonusV1BonusLevelsIdGet: build.query<
      GetBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetApiResponse,
      GetBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/bonus/v1/bonus_levels/${queryArg}` }),
    }),
    updateBonusProgramLevelApiBonusV1BonusLevelsIdPatch: build.mutation<
      UpdateBonusProgramLevelApiBonusV1BonusLevelsIdPatchApiResponse,
      UpdateBonusProgramLevelApiBonusV1BonusLevelsIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_levels/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.bonusProgramLevelCreate,
      }),
    }),
    getAllBonusProgramApiBonusV1BonusProgramGet: build.query<
      GetAllBonusProgramApiBonusV1BonusProgramGetApiResponse,
      GetAllBonusProgramApiBonusV1BonusProgramGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_program`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          orderingType: queryArg.orderingType,
          orderingField: queryArg.orderingField,
        },
      }),
    }),
    createBonusProgramApiBonusV1BonusProgramPost: build.mutation<
      CreateBonusProgramApiBonusV1BonusProgramPostApiResponse,
      CreateBonusProgramApiBonusV1BonusProgramPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_program`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getBonusProgramAchievementApiBonusV1BonusProgramAchievementGet: build.query<
      GetBonusProgramAchievementApiBonusV1BonusProgramAchievementGetApiResponse,
      GetBonusProgramAchievementApiBonusV1BonusProgramAchievementGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_program/achievement`,
        params: {
          user_id: queryArg,
        },
      }),
    }),
    getProgramCountApiBonusV1BonusProgramCountGet: build.query<
      GetProgramCountApiBonusV1BonusProgramCountGetApiResponse,
      GetProgramCountApiBonusV1BonusProgramCountGetApiArg
    >({
      query: () => ({ url: `/api/bonus/v1/bonus_program/count` }),
    }),
    deleteBonusProgramApiBonusV1BonusProgramIdDelete: build.mutation<
      DeleteBonusProgramApiBonusV1BonusProgramIdDeleteApiResponse,
      DeleteBonusProgramApiBonusV1BonusProgramIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_program/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getBonusProgramByIdApiBonusV1BonusProgramIdGet: build.query<
      GetBonusProgramByIdApiBonusV1BonusProgramIdGetApiResponse,
      GetBonusProgramByIdApiBonusV1BonusProgramIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/bonus/v1/bonus_program/${queryArg}` }),
    }),
    updateBonusProgramApiBonusV1BonusProgramIdPatch: build.mutation<
      UpdateBonusProgramApiBonusV1BonusProgramIdPatchApiResponse,
      UpdateBonusProgramApiBonusV1BonusProgramIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/bonus_program/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.bonusProgramRequestUpdate,
      }),
    }),
    healthCheckApiBonusV1HealthGet: build.query<
      HealthCheckApiBonusV1HealthGetApiResponse,
      HealthCheckApiBonusV1HealthGetApiArg
    >({
      query: () => ({ url: `/api/bonus/v1/health` }),
    }),
    getAllMaxBonusPayApiBonusV1MaxBonusPayGet: build.query<
      GetAllMaxBonusPayApiBonusV1MaxBonusPayGetApiResponse,
      GetAllMaxBonusPayApiBonusV1MaxBonusPayGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/max_bonus_pay`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          orderingType: queryArg.orderingType,
          orderingField: queryArg.orderingField,
        },
      }),
    }),
    createMaxBonusPayApiBonusV1MaxBonusPayPost: build.mutation<
      CreateMaxBonusPayApiBonusV1MaxBonusPayPostApiResponse,
      CreateMaxBonusPayApiBonusV1MaxBonusPayPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/max_bonus_pay`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGet:
      build.query<
        GetMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetApiResponse,
        GetMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/bonus/v1/max_bonus_pay/limiter_id/${queryArg}`,
        }),
      }),
    deleteMaxBonusPayApiBonusV1MaxBonusPayIdDelete: build.mutation<
      DeleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteApiResponse,
      DeleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/max_bonus_pay/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getMaxBonusPayByIdApiBonusV1MaxBonusPayIdGet: build.query<
      GetMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetApiResponse,
      GetMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/bonus/v1/max_bonus_pay/${queryArg}` }),
    }),
    updateMaxBonusPayApiBonusV1MaxBonusPayIdPatch: build.mutation<
      UpdateMaxBonusPayApiBonusV1MaxBonusPayIdPatchApiResponse,
      UpdateMaxBonusPayApiBonusV1MaxBonusPayIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/max_bonus_pay/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.maxBonusPayCreate,
      }),
    }),
    getAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGet: build.query<
      GetAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetApiResponse,
      GetAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/users_spend_bonuses`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          orderingType: queryArg.orderingType,
          orderingField: queryArg.orderingField,
        },
      }),
    }),
    deleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDelete:
      build.mutation<
        DeleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteApiResponse,
        DeleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/bonus/v1/users_spend_bonuses/${queryArg}`,
          method: 'DELETE',
        }),
      }),
    getUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGet:
      build.query<
        GetUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetApiResponse,
        GetUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/bonus/v1/users_spend_bonuses/${queryArg}`,
        }),
      }),
    putUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPut: build.mutation<
      PutUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutApiResponse,
      PutUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bonus/v1/users_spend_bonuses/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.userBonusesSpendRequestPut,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetAllBalancesApiBonusV1BalancesGetApiResponse =
  /** status 200 Success */ BalanceListResponse;
export type GetAllBalancesApiBonusV1BalancesGetApiArg = {
  limit?: number;
  offset?: number;
  orderingType?: 'ASC' | 'DESC';
  orderingField?: string | null;
  userId?: string | null;
  countryId?: string | null;
  balanceId?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
};
export type PostDebitApiBonusV1BalancesActionDebitPostApiResponse =
  /** status 200 Success */ BalanceDebitResponse;
export type PostDebitApiBonusV1BalancesActionDebitPostApiArg =
  BalanceActionDebit;
export type PostCreditApiBonusV1BalancesActionManualCreditPostApiResponse =
  /** status 200 Success */ BalanceCreditResponse;
export type PostCreditApiBonusV1BalancesActionManualCreditPostApiArg =
  ManualCreditRequest;
export type PostDebitManualApiBonusV1BalancesActionManualDebitPostApiResponse =
  /** status 200 Success */ BalanceDebitResponse;
export type PostDebitManualApiBonusV1BalancesActionManualDebitPostApiArg =
  ManualDebitRequest;
export type GetBalancesCountApiBonusV1BalancesCountGetApiResponse =
  /** status 200 Success */ ResponseCountModel;
export type GetBalancesCountApiBonusV1BalancesCountGetApiArg = {
  userId?: string | null;
  countryId?: string | null;
  balanceId?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
};
export type GetAllTransactionsApiBonusV1BalancesTransactionsGetApiResponse =
  /** status 200 Success */ BalanceTransactionListResponse;
export type GetAllTransactionsApiBonusV1BalancesTransactionsGetApiArg = {
  limit?: number;
  offset?: number;
  orderingType?: 'ASC' | 'DESC';
  orderingField?: string | null;
  userId?: string | null;
  countryId?: string | null;
  balanceId?: string | null;
  bonusProgramId?: string | null;
  externalLinkId?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
};
export type GetTransactionsCountApiBonusV1BalancesTransactionsCountGetApiResponse =
  /** status 200 Success */ ResponseCountModel;
export type GetTransactionsCountApiBonusV1BalancesTransactionsCountGetApiArg = {
  userId?: string | null;
  countryId?: string | null;
  balanceId?: string | null;
  bonusProgramId?: string | null;
  externalLinkId?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
};
export type GetAllBonusLevelApiBonusV1BonusLevelsGetApiResponse =
  /** status 200 Success */ BonusProgramLevelListResponse;
export type GetAllBonusLevelApiBonusV1BonusLevelsGetApiArg = {
  limit?: number;
  offset?: number;
  orderingType?: 'ASC' | 'DESC';
  orderingField?: string | null;
};
export type CreateBonusProgramLevelApiBonusV1BonusLevelsPostApiResponse =
  /** status 201 Created */ BonusProgramLevelResponse;
export type CreateBonusProgramLevelApiBonusV1BonusLevelsPostApiArg =
  BonusProgramLevelCreate;
export type GetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetApiResponse =
  /** status 200 Success */ BonusProgramLevelListResponse;
export type GetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetApiArg =
  string;
export type DeleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteApiResponse =
  /** status 200 Success */ ResponseModel;
export type DeleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteApiArg = string;
export type GetBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetApiResponse =
  /** status 200 Success */ BonusProgramLevelResponse;
export type GetBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetApiArg = string;
export type UpdateBonusProgramLevelApiBonusV1BonusLevelsIdPatchApiResponse =
  /** status 200 Success */ BonusProgramLevelResponse;
export type UpdateBonusProgramLevelApiBonusV1BonusLevelsIdPatchApiArg = {
  id: string;
  bonusProgramLevelCreate: BonusProgramLevelCreate;
};
export type GetAllBonusProgramApiBonusV1BonusProgramGetApiResponse =
  /** status 200 Success */ BonusProgramListResponse;
export type GetAllBonusProgramApiBonusV1BonusProgramGetApiArg = {
  limit?: number;
  offset?: number;
  orderingType?: 'ASC' | 'DESC';
  orderingField?: string | null;
};
export type CreateBonusProgramApiBonusV1BonusProgramPostApiResponse =
  /** status 201 Created */ BonusProgramResponse;
export type CreateBonusProgramApiBonusV1BonusProgramPostApiArg =
  BonusProgramCreate;
export type GetBonusProgramAchievementApiBonusV1BonusProgramAchievementGetApiResponse =
  /** status 200 Success */ BonusProgramAchievementResponse;
export type GetBonusProgramAchievementApiBonusV1BonusProgramAchievementGetApiArg =
  string;
export type GetProgramCountApiBonusV1BonusProgramCountGetApiResponse =
  /** status 200 Success */ ResponseCountModel;
export type GetProgramCountApiBonusV1BonusProgramCountGetApiArg = void;
export type DeleteBonusProgramApiBonusV1BonusProgramIdDeleteApiResponse =
  /** status 200 Success */ ResponseModel;
export type DeleteBonusProgramApiBonusV1BonusProgramIdDeleteApiArg = string;
export type GetBonusProgramByIdApiBonusV1BonusProgramIdGetApiResponse =
  /** status 200 Success */ BonusProgramResponse;
export type GetBonusProgramByIdApiBonusV1BonusProgramIdGetApiArg = string;
export type UpdateBonusProgramApiBonusV1BonusProgramIdPatchApiResponse =
  /** status 200 Success */ BonusProgramResponse;
export type UpdateBonusProgramApiBonusV1BonusProgramIdPatchApiArg = {
  id: string;
  bonusProgramRequestUpdate: BonusProgramRequestUpdate;
};
export type HealthCheckApiBonusV1HealthGetApiResponse =
  /** status 200 Successful Response */ any;
export type HealthCheckApiBonusV1HealthGetApiArg = void;
export type GetAllMaxBonusPayApiBonusV1MaxBonusPayGetApiResponse =
  /** status 200 SUCCESS */ MaxBonusPayListResponse;
export type GetAllMaxBonusPayApiBonusV1MaxBonusPayGetApiArg = {
  limit?: number;
  offset?: number;
  orderingType?: 'ASC' | 'DESC';
  orderingField?: string | null;
};
export type CreateMaxBonusPayApiBonusV1MaxBonusPayPostApiResponse =
  /** status 201 Created */ MaxBonusPayResponse;
export type CreateMaxBonusPayApiBonusV1MaxBonusPayPostApiArg =
  MaxBonusPayCreate;
export type GetMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetApiResponse =
  /** status 200 Successful Response */ MaxBonusPayResponse;
export type GetMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetApiArg =
  string;
export type DeleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteApiResponse =
  /** status 200 SUCCESS */ ResponseModel;
export type DeleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteApiArg = string;
export type GetMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetApiResponse =
  /** status 200 SUCCESS */ MaxBonusPayResponse;
export type GetMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetApiArg = string;
export type UpdateMaxBonusPayApiBonusV1MaxBonusPayIdPatchApiResponse =
  /** status 200 SUCCESS */ MaxBonusPayResponse;
export type UpdateMaxBonusPayApiBonusV1MaxBonusPayIdPatchApiArg = {
  id: string;
  maxBonusPayCreate: MaxBonusPayCreate;
};
export type GetAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetApiResponse =
  /** status 200 SUCCESS */ UsersBonusesSpendListResponse;
export type GetAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetApiArg = {
  limit?: number;
  offset?: number;
  orderingType?: 'ASC' | 'DESC';
  orderingField?: string | null;
};
export type DeleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteApiResponse =
  /** status 200 SUCCESS */ ResponseModel;
export type DeleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteApiArg =
  string;
export type GetUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetApiResponse =
  /** status 200 SUCCESS */ UsersBonusesSpendResponse;
export type GetUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetApiArg =
  string;
export type PutUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutApiResponse =
  /** status 200 SUCCESS */ UsersBonusesSpendResponse;
export type PutUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutApiArg = {
  userId: string;
  userBonusesSpendRequestPut: UserBonusesSpendRequestPut;
};
export type Balance = {
  id: string;
  lastUpdate: string;
  /** Id пользователя */
  userId?: string | null;
  /** Код страны */
  countryId?: string | null;
  name: string;
  /** Значение баланса */
  value: number;
  createdDate?: string;
};
export type BalanceListResponse = {
  data: Balance[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type ResponseErrorModel = {
  status?: 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BalanceDebit = {
  /** Итого зачисленных бонусов */
  bonusesDebited?: number;
};
export type BalanceDebitResponse = {
  data: BalanceDebit;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BalanceActionDebit = {
  userId: string;
  countryId: string;
  /** Идентификатор пользователя */
  limiterId?: string | null;
  /** Величина списания */
  debitSum: number | string;
  /** Идентификатор пользователя */
  externalLinkId?: string | null;
  /** Описание причины начисления от оператора */
  reason?: string | null;
};
export type BalanceCredit = {
  /** Итого списанных бонусов */
  bonusesCredited?: number;
};
export type BalanceCreditResponse = {
  data: BalanceCredit;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type ManualCreditRequest = {
  /** Id пользователя */
  userId?: string | null;
  /** Код страны */
  countryId?: string | null;
  /** Величина зачисления */
  creditSum: number | string;
  /** Описание причины начисления от оператора */
  reason?: string;
};
export type ManualDebitRequest = {
  /** Id пользователя */
  userId?: string | null;
  /** Код страны */
  countryId?: string | null;
  /** Величина списания */
  debitSum: number | string;
  /** Описание причины списания от оператора */
  reason?: string;
};
export type ResponseCountModel = {
  data?: number;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BalanceTransaction = {
  /** Id баланса */
  balanceId: string | null;
  /** Id бонусной программы */
  bonusProgramId?: string | null;
  id: string;
  createdDate: string;
  operation: 'UNDEFINED' | 'DEBIT' | 'CREDIT';
  value: number;
  /** Описание причины начисления от оператора */
  reason: string;
};
export type BalanceTransactionListResponse = {
  data: BalanceTransaction[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgramLevel = {
  id: string;
  lastUpdate: string;
  bonusProgramId: string;
  name: string;
  level: number;
  condition: number;
  awardPercent: number;
  awardSum: number;
};
export type BonusProgramLevelListResponse = {
  data: BonusProgramLevel[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgramLevelResponse = {
  data: BonusProgramLevel;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgramLevelCreate = {
  bonusProgramId: string;
  name: string;
  level: number;
  condition: number;
  awardPercent: number;
  awardSum: number;
};
export type ResponseModel = {
  data?: any;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgram = {
  id: string;
  lastUpdate: string;
  name: string;
  type?: 'ChargedByCapacity' | 'ChargedByStations' | 'SpendMoney';
  description: string;
  dateStart?: string;
  dateStop?: string;
  countryId: string;
  executionCron: string;
  frequency?: 'MONTH' | 'WEEK' | 'DAY';
  frequencyValue: number;
  isDeleted?: boolean | null;
};
export type BonusProgramListResponse = {
  data: BonusProgram[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgramResponse = {
  data: BonusProgram;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgramCreate = {
  name: string;
  type?: 'ChargedByCapacity' | 'ChargedByStations' | 'SpendMoney';
  description: string;
  dateStart?: string;
  dateStop?: string;
  countryId: string;
  executionCron: string;
  frequency?: 'MONTH' | 'WEEK' | 'DAY';
  frequencyValue: number;
  isDeleted?: boolean | null;
};
export type ExtendedBonusProgram = {
  id: string;
  lastUpdate: string;
  name: string;
  type?: 'ChargedByCapacity' | 'ChargedByStations' | 'SpendMoney';
  description: string;
  dateStart?: string;
  dateStop?: string;
  countryId: string;
  executionCron: string;
  frequency?: 'MONTH' | 'WEEK' | 'DAY';
  frequencyValue: number;
  isDeleted?: boolean | null;
  programLevels: BonusProgramLevel[];
};
export type BonusProgramAchievement = {
  bonus_program?: ExtendedBonusProgram;
  /** Потрачено за период */
  current_sum?: number;
};
export type BonusProgramAchievementResponse = {
  data: BonusProgramAchievement[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type BonusProgramRequestUpdate = {
  name?: string | null;
  type?:
    | ('ChargedByCapacity' | 'ChargedByStations' | 'SpendMoney')
    | string
    | null;
  dateStart?: string | string | null;
  dateStop?: string | string | null;
  /** Код страны */
  countryId?: string | null;
  executionCron?: string | null;
  frequency?: ('MONTH' | 'WEEK' | 'DAY') | string | null;
  frequencyValue?: number | null;
  isDeleted?: boolean | null;
};
export type MaxBonusPay = {
  id: string;
  lastUpdate: string;
  limiterId: string;
  maxBonusPayPercent: number;
};
export type MaxBonusPayListResponse = {
  data: MaxBonusPay[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type MaxBonusPayResponse = {
  data: MaxBonusPay;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type MaxBonusPayCreate = {
  limiterId: string;
  maxBonusPayPercent: number;
};
export type UsersBonusesSpend = {
  userId: string;
  isSpendBonuses: boolean;
  lastUpdate: string;
};
export type UsersBonusesSpendListResponse = {
  data: UsersBonusesSpend[];
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type UsersBonusesSpendResponse = {
  data: UsersBonusesSpend;
  status?: 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
  statusMessage?: string;
};
export type UserBonusesSpendRequestPut = {
  isSpendBonuses: boolean;
};
export const {
  useGetAllBalancesApiBonusV1BalancesGetQuery,
  usePostDebitApiBonusV1BalancesActionDebitPostMutation,
  usePostCreditApiBonusV1BalancesActionManualCreditPostMutation,
  usePostDebitManualApiBonusV1BalancesActionManualDebitPostMutation,
  useGetBalancesCountApiBonusV1BalancesCountGetQuery,
  useGetAllTransactionsApiBonusV1BalancesTransactionsGetQuery,
  useGetTransactionsCountApiBonusV1BalancesTransactionsCountGetQuery,
  useGetAllBonusLevelApiBonusV1BonusLevelsGetQuery,
  useCreateBonusProgramLevelApiBonusV1BonusLevelsPostMutation,
  useGetBonusProgramLevelByBonusProgramIdApiBonusV1BonusLevelsBonusProgramBonusProgramIdGetQuery,
  useDeleteBonusProgramLevelApiBonusV1BonusLevelsIdDeleteMutation,
  useGetBonusProgramLevelByIdApiBonusV1BonusLevelsIdGetQuery,
  useUpdateBonusProgramLevelApiBonusV1BonusLevelsIdPatchMutation,
  useGetAllBonusProgramApiBonusV1BonusProgramGetQuery,
  useCreateBonusProgramApiBonusV1BonusProgramPostMutation,
  useGetBonusProgramAchievementApiBonusV1BonusProgramAchievementGetQuery,
  useGetProgramCountApiBonusV1BonusProgramCountGetQuery,
  useDeleteBonusProgramApiBonusV1BonusProgramIdDeleteMutation,
  useGetBonusProgramByIdApiBonusV1BonusProgramIdGetQuery,
  useUpdateBonusProgramApiBonusV1BonusProgramIdPatchMutation,
  useHealthCheckApiBonusV1HealthGetQuery,
  useGetAllMaxBonusPayApiBonusV1MaxBonusPayGetQuery,
  useCreateMaxBonusPayApiBonusV1MaxBonusPayPostMutation,
  useGetMaxBonusPayByLimiterIdApiBonusV1MaxBonusPayLimiterIdLimiterIdGetQuery,
  useDeleteMaxBonusPayApiBonusV1MaxBonusPayIdDeleteMutation,
  useGetMaxBonusPayByIdApiBonusV1MaxBonusPayIdGetQuery,
  useUpdateMaxBonusPayApiBonusV1MaxBonusPayIdPatchMutation,
  useGetAllUsersSpendBonusesApiBonusV1UsersSpendBonusesGetQuery,
  useDeleteUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdDeleteMutation,
  useGetUsersSpendBonusesByUserIdApiBonusV1UsersSpendBonusesUserIdGetQuery,
  usePutUsersSpendBonusesApiBonusV1UsersSpendBonusesUserIdPutMutation,
} = injectedRtkApi;
