import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { GROUP } from 'shared/consts/routes/common';
import {
  BINDING_LOGS,
  BINDINGS,
  PERSON,
  PERSONS_LIST,
  PROFILE,
} from 'shared/consts/routes/user';
import {
  ADD_PREFIX,
  BILLINGS,
  BILLING_TRANSACTIONS,
  GROUPS,
  PAYMENTS,
  RESERVES,
  SESSIONS,
  TRANSACTIONS,
  USERS,
} from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';
import { UserLayout } from 'entities/user/ui/user-layout';
import { UsersLayout } from 'entities/user/ui/users-layout';
import { UserGroupLayout } from 'entities/user-group';

import { UserGroupsMenu } from 'features/user-group/menu';

import { UserCardListPage } from 'pages/user/cards-list';
import { UserBillingsPage } from 'pages/user/user-billings';
import { UserGroupListPage } from 'pages/user-group/list';
import { UserListPage } from 'pages/user/list';
import { EditUserGroupPage } from 'pages/user-group/edit';
import { UserGroupUserListPage } from 'pages/user-group/user-list';
import { AddUserPage } from 'pages/user/add';
import { AddUserGroupPage } from 'pages/user-group/add';
import { UserGroupSessionsPage } from 'pages/user-group/sessions';
import { UsersSessionsPage } from 'pages/user/sessions';
import { UserSessionsPage } from 'pages/user/user-sessions';
import { EditUserPage } from 'pages/user/edit';
import { UserTransactionListPage } from 'pages/user/transaction-list';
import { UsersBindingLogsPage } from 'pages/user/binding-logs';
import { UsersPaymentsPage } from 'pages/user/payments';
import { UserPaymentsPage } from 'pages/user/user-payments';
import { UserReservesPage } from 'pages/user/user-reserves';
import { UserBillingTransactionsPage } from 'pages/user/billing-transactions-list';

export const UserRoutes = (
  <Route
    element={
      <RequireAuth
        permissions={[{ controller: 'UserService', right: 'Read' }]}
      />
    }
  >
    <Route path={USERS} element={<SectionLayout menu={<UserGroupsMenu />} />}>
      <Route element={<UsersLayout />}>
        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'InformationService', right: 'Read' },
              ]}
            />
          }
        >
          <Route index element={<UserListPage />} />
        </Route>

        <Route path={GROUPS} element={<UserGroupListPage />} />

        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'ChargePointServiceChargePoint', right: 'Read' },
              ]}
            />
          }
        >
          <Route path={SESSIONS} element={<UsersSessionsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'PaymentService', right: 'Read' }]}
            />
          }
        >
          <Route path={PAYMENTS} element={<UsersPaymentsPage />} />
        </Route>
        {/* <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<PersonsReservesPage />} />
        </Route> */}
      </Route>

      <Route path={GROUP} element={<UserGroupLayout />}>
        <Route index element={<EditUserGroupPage />} />

        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'InformationService', right: 'Read' },
              ]}
            />
          }
        >
          <Route path={PERSONS_LIST} element={<UserGroupUserListPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'ChargePointServiceChargePoint', right: 'Read' },
              ]}
            />
          }
        >
          <Route path={SESSIONS} element={<UserGroupSessionsPage />} />
        </Route>
        {/* <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route path="reserves" element={<PersonsGroupReservesPage />} />
          </Route> */}
      </Route>

      <Route
        element={
          <RequireAuth
            permissions={[{ controller: 'UserService', right: 'Write' }]}
          />
        }
      >
        <Route
          path={`${GROUPS}/${ADD_PREFIX}`}
          element={<MainContentLayout element={<AddUserGroupPage />} />}
        />
      </Route>

      <Route
        element={
          <RequireAuth
            permissions={[
              { controller: 'UserService', right: 'Write' },
              {
                controller: 'InformationService',
                right: 'Read',
              },
            ]}
          />
        }
      >
        <Route path={ADD_PREFIX} element={<AddUserPage />} />
      </Route>

      <Route path={`${GROUP}/${PERSON}`} element={<UserLayout />}>
        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'ChargePointServiceChargePoint', right: 'Read' },
              ]}
            />
          }
        >
          <Route index element={<UserSessionsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              permissions={[
                { controller: 'BillingService', right: 'Read' },
                { controller: 'InformationService', right: 'Read' },
              ]}
            />
          }
        >
          <Route path={BILLINGS} element={<UserBillingsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'PaymentService', right: 'Read' }]}
            />
          }
        >
          <Route path={PAYMENTS} element={<UserPaymentsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'ReserveService', right: 'Read' }]}
            />
          }
        >
          <Route path={RESERVES} element={<UserReservesPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'PaymentService', right: 'Read' }]}
            />
          }
        >
          <Route path={BINDINGS} element={<UserCardListPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'PaymentService', right: 'Read' }]}
            />
          }
        >
          <Route path={BINDING_LOGS} element={<UsersBindingLogsPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[
                {
                  controller: 'InformationService',
                  right: 'Read',
                },
              ]}
            />
          }
        >
          <Route path={PROFILE} element={<EditUserPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'BonusService', right: 'Read' }]}
            />
          }
        >
          <Route path={TRANSACTIONS} element={<UserTransactionListPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              permissions={[{ controller: 'BillingService', right: 'Read' }]}
            />
          }
        >
          <Route
            path={BILLING_TRANSACTIONS}
            element={<UserBillingTransactionsPage />}
          />
        </Route>
      </Route>
    </Route>
  </Route>
);
