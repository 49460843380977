import { ADD_CP_PERMISSIONS } from 'entities/authorization/consts';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddChargePointButton() {
  return (
    <SectionMenuAddButton
      label="Добавить ЭЗС"
      path={CHARGE_POINT_ROUTES.ADD_CHARGE_POINT}
      permissions={ADD_CP_PERMISSIONS}
    />
  );
}
