import { z } from 'zod';

import {
  useGetCountries,
  useGetMobileApps,
} from 'shared/api/services/information/rtk';
import {
  useGetBillings,
  useGetBillingsCount,
} from 'shared/api/services/billing/rtk/enhanced';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import { getApiBillingV1BillingsQueryParams } from 'shared/api/services/billing/orval/zod/schemas';

import { getMobileAppsMapping } from 'entities/mobile-app';

const generatedSchema = getApiBillingV1BillingsQueryParams;

const filteringQueryParams = z.object({
  balanceId: z.string().uuid().optional(),
  sessionId: z.string().uuid().optional(),
  status: generatedSchema.shape.Status.optional(),
  dateFrom: z.string().datetime().optional(),
  dateTo: z.string().datetime().optional(),
  serviceType: generatedSchema.shape.ServiceType.optional(),
});

const billingsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const billingsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  userId?: string;
  chargePointsIds?: string[];
};

export const useQueries = ({ limit, userId, chargePointsIds }: Props) => {
  const billingsParams = useTypedQueryParamsCustom(billingsQueryParams);
  const billingsCountParams = useTypedQueryParamsCustom(
    billingsCountQueryParams
  );

  const billingsQuery = useGetBillings({
    ...billingsParams,
    offset: getOffsetQueryParam(billingsParams.page, limit),
    limit,
    userId,
    chargePointsIds,
  });

  const billingsCountQuery = useGetBillingsCount({
    ...billingsCountParams,
    userId,
    chargePointsIds,
  });

  const mobileAppsQuery = useGetMobileApps();
  const countriesQuery = useGetCountries(undefined);

  const isLoading =
    billingsQuery.isLoading ||
    billingsCountQuery.isLoading ||
    mobileAppsQuery.isLoading ||
    countriesQuery.isLoading;

  const isFetching = billingsQuery.isFetching || billingsCountQuery.isFetching;

  const mobileAppsMapping = getMobileAppsMapping(
    mobileAppsQuery.data?.data || []
  );

  return {
    isLoading,
    isFetching,
    billings: billingsQuery.data?.data ?? [],
    billingsCount: billingsCountQuery.data?.data ?? Infinity,
    countries: countriesQuery.data?.data ?? [],
    mobileAppsMapping,
    billingsError: billingsQuery.error,
    billingsCountError: billingsCountQuery.error,
    mobileAppsError: mobileAppsQuery.error,
    countriesError: countriesQuery.error,
  };
};
