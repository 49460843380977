import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    listBalanceGroupsApiBalancingBalanceGroupsGet: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'BalancingGroups', id: 'LIST' },
      ];
    },
    getBalanceGroupApiBalancingBalanceGroupsGroupIdGet: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'BalancingGroups', id: arg },
      ];
    },
    listChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGet: (
      endpoint
    ) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'BalancingGroupChargePoints', id: arg },
      ];
    },
    getChargePointApiBalancingChargePointsChargePointIdGet: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'BalancingGroupChargePoint', id: arg },
      ];
    },
  },
});

export const {
  useListBalanceGroupsApiBalancingBalanceGroupsGetQuery: useGetBalancingGroups,
  useGetBalanceGroupApiBalancingBalanceGroupsGroupIdGetQuery:
    useGetBalancingGroup,
  useListChargePointsByGroupIdApiBalancingChargePointsByGroupIdGroupIdGetQuery:
    useGetBalancingGroupChargePoints,
  useCreateBalanceGroupApiBalancingBalanceGroupsPostMutation:
    useAddBalancingGroupMutation,
  useUpdateBalanceGroupWithCpApiBalancingBalanceGroupsGroupIdPutMutation:
    useUpdateBalancingGroupMutation,
  useToggleBalancingApiBalancingBalanceGroupsGroupIdToggleBalancingPatchMutation:
    useToggleBalancing,
  useGetChargePointApiBalancingChargePointsChargePointIdGetQuery:
    useBalancingGroupChargePoint,
} = enhancedApi;
