import { Outlet } from 'react-router-dom';

import { Unauthorized } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';

import { Controller, Right } from 'entities/authorization';
import { Permission } from 'features/sidebar/types';

type Props = {
  permissions: Permission[];
};

export const RequireAuth = ({ permissions }: Props) => {
  const ability = useAbility();

  const allowed = permissions.every((el) => {
    const { controller, right } = el;

    return ability.can(right, controller);
  });

  return allowed ? <Outlet /> : <Unauthorized />;
};
