import { z } from 'zod';

import {
  useGetBillingTransactions,
  useGetBillingTransactionsCount,
} from 'shared/api/services/billing/rtk/enhanced';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import {
  getOffsetQueryParam,
  paginationQueryParams,
  sortingQueryParams,
} from 'shared/lib/schema';
import { getApiBillingV1BalancesTransactionsQueryParams } from 'shared/api/services/billing/orval/zod/schemas';

const generatedSchema = getApiBillingV1BalancesTransactionsQueryParams;

const filteringQueryParams = z.object({
  balanceId: z.string().optional(),
  operation: generatedSchema.shape.Operation.optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
});

const transactionsQueryParams = filteringQueryParams
  .merge(sortingQueryParams)
  .merge(paginationQueryParams);

const transactionsCountQueryParams = filteringQueryParams;

type Props = {
  limit: number;
  userId?: string;
};

export const useQueries = ({ limit, userId }: Props) => {
  const transactionsParams = useTypedQueryParamsCustom(transactionsQueryParams);
  const transactionsCountParams = useTypedQueryParamsCustom(
    transactionsCountQueryParams
  );

  const transactionsQuery = useGetBillingTransactions({
    ...transactionsParams,
    offset: getOffsetQueryParam(transactionsParams.page, limit),
    limit,
    userId,
  });

  const transactionsCountQuery = useGetBillingTransactionsCount({
    ...transactionsCountParams,
    userId,
  });

  const isLoading =
    transactionsQuery.isLoading || transactionsCountQuery.isLoading;
  const isFetching =
    transactionsQuery.isFetching || transactionsCountQuery.isFetching;

  return {
    isLoading,
    isFetching,
    transactions: transactionsQuery.data?.data ?? [],
    transactionsCount: transactionsCountQuery.data?.data ?? Infinity,
    transactionsError: transactionsQuery.error,
    transactionsCountError: transactionsCountQuery.error,
  };
};
